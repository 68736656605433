import React from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useGetActivityByIdQuery, useGetAllActivityQuery } from "../../../api/acitivitiesSlice";
import Footer from "../component/footer";
import Header from "../component/header";

const ActivityDetailPage = () => {
  return (
    <>
      <Header />
      <ActivityDetail />
      <Footer />
    </>
  );
};

const ActivityDetail = () => {
  const {id} = useParams();
  
  const data =   useGetActivityByIdQuery(id);
  const data1 = useGetAllActivityQuery();
  
  return (
    <div>
      <section class="page-title bg-1">
        <div class="overlay"></div>
        <div class="container">
          <div class="row">
            {data !== undefined ? (
              <>
                {data.status === "fulfilled" ? (
                  <>
                    <div class="col-md-12">
                      <div class="block text-center">
                        <span class="text-white">{data.data.title}</span>
                        <h1 class="text-capitalize mb-5 text-lg">
                          Event Detail
                        </h1>
                      </div>
                    </div>
                  </>
                ) : null}
              </>
            ) : null}
          </div>
        </div>
      </section>

      <section class="section blog-wrap" style={{ textAlign: "start" }}>
        <div class="container">
          <div class="row">
            {data !== undefined ? (
              <>
                {data.status === "fulfilled" ? (
                  <>
                    <div class="col-lg-8">
                      <div class="row">
                        <div class="col-lg-12 mb-5">
                          <div class="single-blog-item">
                            <img
                              src={data.data.image}
                              alt=""
                              class="img-fluid"
                            />

                            <div class="blog-item-content mt-5">
                              {/* <div class="blog-item-meta mb-3">
                                  <span class="text-color-2 text-capitalize mr-3">
                                    <i class="icofont-book-mark mr-2"></i>{" "}
                                    Equipment
                                  </span>
                                  <span class="text-muted text-capitalize mr-3">
                                    <i class="icofont-comment mr-2"></i>5
                                    Comments
                                  </span>
                                  <span class="text-black text-capitalize mr-3">
                                    <i class="icofont-calendar mr-2"></i> 28th
                                    January 2019
                                  </span>
                                </div> */}

                              <h2 class="mb-4 text-md">
                                <a href="#">{data.data.title}</a>
                              </h2>

                              <p class="lead mb-4">{data.data.subtitle}</p>

                              <p>{data.data.description}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : null}
              </>
            ) : null}
            <div class="col-lg-4">
              <div class="sidebar-wrap pl-lg-4 mt-5 mt-lg-0">
                <div class="sidebar-widget latest-post mb-3">
                  <h5>Popular Posts</h5>

                  {data1 !== undefined ? (
                    <>
                      {data1.status === "fulfilled" ? (
                        <>
                          {data1.data.ids.map((key) => (
                            <div className="py-2">
                              <span className="text-sm text-muted">
                                {data1.data.entities[key].created_at}
                              </span>
                              <h6 className="my-2">
                                <Link
                                  to={`/activity/detail/page/${data1.data.entities[key].id}`}
                                >
                                  {data1.data.entities[key].title}
                                </Link>
                              </h6>
                            </div>
                          ))}
                        </>
                      ) : null}
                    </>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ActivityDetailPage;
