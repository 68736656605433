import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Sidebar from "../../component/sidebar";
import { Grid, Typography } from "@mui/material";
import Header from "../../component/header";
import BasicTable from "../tables/BasicTable";
import {
  useGetBlogsQuery,
  useUpdateBlogMutation,
  useDeleteBlogMutation,
  useAddBlogMutation,
} from "../../../../../api/blogSlice";
import BlogAddButton from "./blogAddButton";
import { Box } from "@mui/system";

const DashboardBlogs = () => {
  return (
    <Grid container className="dashboard-full-background">
      <Grid item xs={2} spacing={4}>
        <Sidebar />
      </Grid>
      <Grid item xs={10}>
        <Header />
        <DashboardBlogsPages />
      </Grid>
    </Grid>
  );
};

const DashboardBlogsPages = () => {
  const { data, isLoading, isSuccess, isError, error } =
    useGetBlogsQuery("getBlogs");

  const [deleteBlog] = useDeleteBlogMutation();

  const deleteConfirm = (e, id) => {
    e.preventDefault();

    deleteBlog({ id });
  };

  let headers = [];
  let tableData = [];
  let content;
  if (isLoading) {
    content = <p>"Loading..."</p>;
  } else if (isSuccess) {
    headers = ["Title", "Description"];
    tableData = [];
    data.ids.forEach((key) => {
      tableData.push({
        Title: data.entities[key].subtitle,
        Description: data.entities[key].description,
        id: data.entities[key].id,
      });
    });
  }
  return (
    <>
      <Box className="table-design-background">
        <Grid container>
          <Grid item xs={6}>
            <Typography className="dashboard-home-page-text">
              News List
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Box
              style={{
                paddingTop: "15px",
                textAlign: "right",
                paddingRight: "20px",
              }}
            >
              <Link
                to="/dashboard/new/blogs"
                style={{ textDecoration: "none" }}
              >
                <BlogAddButton  />
              </Link>
            </Box>
          </Grid>
        </Grid>
        <Box style={{ padding: "1.5rem" }}>
          <BasicTable
            headers={headers}
            data={tableData}
            isDelete={true}
            deleteColor={"#f50057"}
            isEdit={true}
            editColor={"#3f51b5"}
            addColor={""}
            tableName={"blogs"}
            deleteConfirm={deleteConfirm}
          />
        </Box>
      </Box>
    </>
  );
};
export default DashboardBlogs;
