import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Box, Grid, Typography } from "@mui/material";
import Sidebar from "../../component/sidebar";
import Header from "../../component/header";
import Controls from "../forms/controls/Controls";
import { useForm, Form } from "../forms/useForm";
import { useGetCardDetailsByIdQuery } from "../../../../../api/cardDetailSlice";
import { useUpdateCardDetailMutation } from "../../../../../api/cardDetailSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCog,
  faBell,
  faSignInAlt,
  faExclamationTriangle,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { tryParseDateFromString } from "../forms/utils/tryParseDateFromString";
import { stringToDate } from "../../../../../utils/tryParseDateFromString";
import { stringDateToReadable } from "../../../../../utils/tryParseDateFromString";

const CardDetailsEditPage = () => {
  return (
    <Grid container className="dashboard-full-background">
      <Grid item xs={2} spacing={4}>
        <Sidebar />
      </Grid>
      <Grid item xs={10}>
        <Header />
        <EditCardDetailsPages />
      </Grid>
    </Grid>
  );
};

const EditCardDetailsPages = () => {
  const { id } = useParams();
  const Constantvalues = useGetCardDetailsByIdQuery(id);
  const [File, setFile] = useState([]);
  const [updateConstantvalue, setUpdateConstantvalue] = useState([]);
  const [initialFValues, setInitialFValues] = useState({
    card_no: "",
    returning_date: "",
    bookings_data: ""
  });
  const [SuccessMessege, setSuccesMessege] = useState(null);
  const [ErrorMessege, setErrorMessege] = useState(null);
  const history = useNavigate();

  useEffect(() => {
    if (Constantvalues.data) {
      setInitialFValues({
        card_no: Constantvalues.data.card_no,
        returning_date: Constantvalues.data.returning_date,
        bookings_data: Constantvalues.data.bookings_data
      });
    }
  }, [Constantvalues.data]);

  const { values, handleImageUpload, handleInputChange } = useForm(
    initialFValues,
    true,
    false,
    true
  );

  const [editConstantvalue] = useUpdateCardDetailMutation();

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    if(values.card_no)
      formData.append("card_no", values.card_no);
    if(values.returning_date)
        formData.append("returning_date", values.returning_date);
    formData.append("id", id);
    editConstantvalue(formData)
      .then((res) => {
        setSuccesMessege("Successfully Update Card Details Data.");
        setTimeout(() => {
          history("/dashboard/card-details");
        }, [3000]);
      })
      .catch((err) => {
        setErrorMessege("Some Error Occur. Please Try Again Later.");
        setTimeout(() => {
          setErrorMessege(null);
        }, [3000]);
      });
  };

  

  

  return (
    <Box className="table-design-background">
      <Typography className="dashboard-home-page-text">
        Update Card Details
      </Typography>
      <Box style={{ padding: "1.5rem" }}>
        <Form onSubmit={handleSubmit}>
          <Grid container>
            <Controls.Input
                name="card_no"
                label="Card Number"
                value={values.card_no}
                onChange={handleInputChange}
            />
            <Controls.DatePickers
                name="returning_date"
                label="Return Date"
                selected={stringToDate(values.returning_date)}
                value={values.returning_date}
                onChange={handleInputChange}
            />
          </Grid>
        </Form>
        {SuccessMessege === null ? null : (
          <Box className="successmessege">
            <Typography className="messegefonts">
              <FontAwesomeIcon icon={faCheckCircle} className="messegeicon" />
              {SuccessMessege}
            </Typography>
          </Box>
        )}
        {ErrorMessege === null ? null : (
          <Box className="errormessege">
            <Typography className="messegefonts">
              <FontAwesomeIcon
                icon={faExclamationTriangle}
                className="messegeicon"
              />
              {ErrorMessege}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default CardDetailsEditPage;
