import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Sidebar from "../../component/sidebar";
import {
    Grid,
    Modal,
    Pagination,
    Stack,
    Typography,
} from "@mui/material";
import Header from "../../component/header";
import BasicTable from "../tables/BasicTable";
import { Box } from "@mui/system";
import usePagination from "../../../../../components/reuseable/pagination";
import {
    useGetDoctorCallCardsQuery,
    useGetDoctorCallCardsByIdQuery,
    useAddDoctorCallCardMutation,
    useUpdateDoctorCallCardMutation,
    useDeleteDoctorCallCardMutation,
    useGetDoctorCallCardsMemberPaginationQuery,
    useGetDoctorCallCardsPaginationQuery,
    useGetDoctorSimCardsMemberPaginationQuery,
    useAddSimCardMutation,
    useDeleteSimCardMutation
} from "../../../../../api/doctorCallCardSlice";
import { useGetMrIDByEmailMutation } from "../../../../../api/userSlice";
import Controls from "../forms/controls/Controls";
import { useSearchFliterPageMutation } from "../../../../../api/searchApiSlice";
import { useForm1 } from "../../../../../components/forms/useForm";
import { Table } from "react-bootstrap";
import ModeEditSharpIcon from "@mui/icons-material/ModeEditSharp";
import DeleteSharpIcon from "@mui/icons-material/DeleteSharp";
import Cookies from "js-cookie";
import SimCardButton from "./simCardButton";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    // border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    borderRadius: 5,
    background: "white"
};

const SimCardDashboard = () => {

    return (
        <Grid container className="dashboard-full-background">
            <Grid item xs={2} spacing={4}>
                <Sidebar />
            </Grid>
            <Grid item xs={10}>
                <Header />
                <DashboardSimCardPagesPages />
            </Grid>
        </Grid>
    );
};

const DashboardSimCardPagesPages = () => {
    const pageNo = useLocation();
    const paramPairs = decodeURIComponent(pageNo.search.substr(1));
    const paramPairs1 = paramPairs.split('=');
    const pageNumber = paramPairs1[1];
    const [thisMr, setThisMr] = useState(null);

    const [pageId, setPageId] = useState();

    const [getMRID] = useGetMrIDByEmailMutation();

    useEffect(() => {
        getMRID(Cookies.get('user_email')).
            then((res) => {
                setThisMr(res.data.id);
            })

    }, [Cookies.get('user_email')])
    useEffect(() => {
        if (pageNumber !== undefined) {
            setPageId(pageNumber)
        } else {
            setPageId(1)
        }
    }, [pageNumber])
    const history = useNavigate();


    const { data, isLoading, isSuccess } = useGetDoctorSimCardsMemberPaginationQuery({ 'page': pageId, 'mr': thisMr });

    const [open, setOpen] = useState(false);
    const handleOpen = (e, id) => {
        setOpen(true);
    };
    const handleClose = () => setOpen(false);

    const [AddSim] = useAddSimCardMutation();

    const handleSim = () => {
        AddSim({ 'mr': thisMr, 'is_approved': false })
            .then((res) => {
                if (res.data) {
                    console.log(res.data)
                    setOpen(false);
                } else {
                    console.log('some error');
                }
            })
            .catch((err) => {
                console.log(err);
            })
    }





    const [deleteDoctorCallCard] = useDeleteSimCardMutation();

    const deleteConfirm = (e, id) => {

        e.preventDefault();
        deleteDoctorCallCard({ id });
        setTimeout(() => {
            window.location.reload(true);
        }, [2000])
    };

    let headers = [];
    let tableData = [];
    let content;

    var count;
    if (isLoading) {
        content = <p>"Loading..."</p>;
    } else if (isSuccess) {
        headers = [
            "Card No.",
            "MR Name",
            "Joint Call Date From",
            "Joint Call Date To",
            "Joint Call With",
            "Company",
            "Division"
        ];
        data.results.forEach((key, index) => {
            tableData.push({
                "Card No.": key?.card_no ? key?.card_no : "Pending",
                "MR Name": key?.mr?.user?.first_name + (key?.mr?.user?.middle_name ? " " + key?.mr?.user?.middle_name : "") + " " + key?.mr?.user?.last_name,
                id: key?.id,
                "Joint Call Date From": key.joint_work_date,
                "Joint Call Date To": key.joint_work_to_date,
                "Joint Call With": key.joint_work_with,
                "Company": key?.company,
                "Division": key?.division,
            });
        });
        var newData = data.count / 10;
        count = Math.ceil(newData);
    }

    const [page, setPage] = useState(1);
    const PER_PAGE = 10;

    const _DATA = usePagination(tableData, count, PER_PAGE);


    const handleChange = (e, p) => {
        _DATA.jump(p);
        setPage(p)
        // const queryParams = new URLSearchParams(p).toString();
        history(`/dashboard/doctors-call-card/?pageNumber=${p}`)
    };



    //   const [NMSRANO, results] = useSearchFliterPageMutation();

    //   const initialFValues = {
    //     search: "",
    //   };

    //   const { values, handleSearchClick } = useForm1(initialFValues);

    //   useEffect(() => {
    //     NMSRANO(values);
    //   }, [values]);

    return (
        <>
            <Box className="table-design-background">
                <Grid container>
                    <Grid item xs={7}>
                        <Typography className="dashboard-home-page-text">
                            Sim Card
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                    </Grid>
                    <Grid item xs={2}>
                        <Box style={{ marginTop: "1rem", marginRight: "1rem", textAlign: "end" }}>
                            <Link
                                to="/dashboard/add/sim-card"
                                style={{ textDecoration: "none" }}
                            >
                                <SimCardButton name={"Add"} />
                            </Link>
                        </Box>
                    </Grid>
                </Grid>
                <Box style={{ padding: "1.5rem" }}>
                    <>
                        <BasicTable
                            headers={headers}
                            data={tableData}
                            deleteColor={"#f50057"}
                            isEdit={false}
                            editColor={"#3f51b5"}
                            isDelete={true}
                            addColor={""}
                            tableName={"doctors-call-card"}
                            deleteConfirm={deleteConfirm}
                            activeSign={["Active"]}
                            doctorcardAvailable={false}
                            doctor={true}
                            simCard={true}
                        />
                        <Box
                            style={{
                                marginTop: "20px",
                                textAlign: "center",
                                paddingBottom: "1rem",
                                paddingLeft: "1rem",
                            }}
                        >
                            <Stack spacing={2} style={{ textAlign: "center" }}>
                                <Pagination
                                    count={count}
                                    size="small"
                                    page={parseInt(pageNumber)}
                                    variant="outlined"
                                    onChange={handleChange}
                                    color="primary"
                                />
                            </Stack>
                        </Box>
                    </>
                </Box>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <Typography
                            id="modal-modal-title"
                            className="modal-text-design"
                            variant="h6"
                            component="h2"

                        >
                            Do you want to Add Sim Card?
                        </Typography>
                        <button
                            className="yes-button"
                            onClick={handleSim}
                        >
                            Yes
                        </button>
                        <button className="no-button" onClick={handleClose}>
                            No
                        </button>
                    </Box>
                </Modal>
            </Box>
        </>
    );
};
export default SimCardDashboard;
