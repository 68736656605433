import { faCheckCircle, faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Container, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import Cookies from "js-cookie";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { usePostConfirmPasswordMutation } from "../../../api/confirmPasswordSlice";
import { useForm } from "../../dashboard/component/pages/forms/useForm";
import Footer from "../component/footer";
import Header from "../component/header";

const ChangePassword = () => {
  return (
    <>
      <Header />
      <ChangePasswordPage />
      <Footer />
    </>
  );
};

const ChangePasswordPage = () => {
  const [ChangePassword] = usePostConfirmPasswordMutation();

  const [SuccessMessege, setSuccesMessege] = useState(null);
  const [ErrorMessege, setErrorMessege] = useState(null);
  const initialFValues = {
    old_password: "",
    new_password: "",
    c_password: "",
  };

  const history = useNavigate();

  const { values, handleInputChange } = useForm(
    initialFValues,
    true,
    false,
    false
  );
  

  const clickBtn = (e) => {
    e.preventDefault();
    if (initialFValues.new_password == initialFValues.c_password) {
      const data = {
        old_password: values.old_password,
        new_password: values.new_password,
        access: Cookies.get("access"),
      };
      
      ChangePassword(data)
        .then((res) => {
          setSuccesMessege("Password has been changed.");
          setTimeout(() => {
            history("/");
          }, [3000]);
        })
        .catch((err) => {
          setErrorMessege(
            "Some error occur while changing password. Please try again later."
          );
        });
    } else {
      setErrorMessege("Password doesnot Match.");
      
      setTimeout(() => {
        setErrorMessege(null);
      }, [3000]);
    }
  };

  return (
    <Box style={{ backgroundColor: "#efefef", padding: "2rem" }}>
      <Box>
        <Container>
          <Box style={{ marginLeft: "1rem", marginRight: "1rem" }}>
            <Typography
              style={{
                fontSize: "19px",
                textAlign: "start",
                color: "black",
                fontWeight: "600",
              }}
            >
              Change Password
            </Typography>
            <Box
              style={{
                marginTop: "2rem",
                backgroundColor: "white",
                padding: "1rem",
                borderRadius: "10px",
                paddingBottom: "2rem",
              }}
            >
              <Grid spacing={5} container>
                <Grid item md={5} xs={12}>
                  <Box className="newforms" style={{ textAlign: "start" }}>
                    <Box>
                      <lable className="emailtext">Old Password*</lable>
                      <input
                        type="password"
                        name="old_password"
                        onChange={handleInputChange}
                        className="titleform2"
                        placeholder="Enter Old Password"
                        value={values.old_password}
                      />
                    </Box>
                    <Box style={{ marginTop: "10px" }}>
                      <lable className="emailtext">New Password*</lable>
                      <input
                        type="password"
                        name="new_password"
                        onChange={handleInputChange}
                        className="titleform2"
                        placeholder="Enter New Password"
                        value={values.new_password}
                      />
                    </Box>
                    <Box style={{ marginTop: "10px" }}>
                      <lable className="emailtext">Confirm Password*</lable>
                      <input
                        type="password"
                        name="c_password"
                        onChange={handleInputChange}
                        className="titleform2"
                        placeholder="Enter Confirm Password"
                        value={values.c_password}
                      />
                    </Box>
                    <Box style={{ marginTop: "5px" }}>
                      <button
                        onClick={clickBtn}
                        className="signinbutton"
                        style={{ border: "none" }}
                      >
                        Change Password
                      </button>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} md={7}>
                  <Box style={{ textAlign: "start", marginTop: "25px" }}>
                    <Typography className="emailtext">
                      Password Policy
                    </Typography>
                    <ul style={{ padding: "0px", marginTop: "20px" }}>
                      <li className="emailtext" style={{ marginLeft: "10px" }}>
                        Password Maximum Length is 15
                      </li>
                      <li
                        className="emailtext"
                        style={{ marginTop: "5px", marginLeft: "10px" }}
                      >
                        Password Minimum Length is 8
                      </li>
                      <li
                        className="emailtext"
                        style={{ marginTop: "5px", marginLeft: "10px" }}
                      >
                        At Least 3 Number of lowercase letters in password
                      </li>
                    </ul>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Container>
        {SuccessMessege === null ? null : (
          <Box className="successmessege">
            <Typography className="messegefonts">
              <FontAwesomeIcon icon={faCheckCircle} className="messegeicon" />
              {SuccessMessege}
            </Typography>
          </Box>
        )}
        {ErrorMessege === null ? null : (
          <Box className="errormessege">
            <Typography className="messegefonts">
              <FontAwesomeIcon
                icon={faExclamationTriangle}
                className="messegeicon"
              />
              {ErrorMessege}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default ChangePassword;
