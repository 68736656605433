import { createSelector, createEntityAdapter } from "@reduxjs/toolkit";

import { apiSlice } from "./apiSlice";

const newsAdapter = createEntityAdapter();

const initialState = newsAdapter.getInitialState();

const NewsSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getLifeTime: builder.query({
      query: () => "/basic/lifetime-members/",
      transformResponse: (responseData) => {
        let returnResponse = [];
        responseData.map((i) => {
          let newData = new Date(i.created_at);

          returnResponse.push({ ...i, created_at: newData.toDateString() });
        });

        return newsAdapter.setAll(initialState, returnResponse);
      },
      providesTags: (result, error, arg) => [
        { type: "Life", id: "LIST" },
        ...result.ids.map((id) => ({ type: "Life", id })),
      ],
    }),
    addLifeTime: builder.mutation({
      query: (news) => {
        return {
          url: "/basic/lifetime-members/",
          method: "POST",
          body: news,
        };
      },
      invalidatesTags: ["Life"],
    }),
    getLifeTimeById: builder.query({
      query: (id) => ({
        url: `/basic/lifetime-members/${id}`,
        method: "GET",
      }),
      providesTags: ["Life"],
    }),
    getLifeTimePagination: builder.query({
      query: (id) => ({
        url: `/basic/lifetime-members-pagination/?page=${id}`,
        method: "GET",
      }),
      providesTags: ["Life"],
    }),
    updateLifeTimePage: builder.mutation({
      query: (updateAboutPage) => {
        return {
          url: `/basic/lifetime-members/${updateAboutPage.get("id")}/`,
          method: "PATCH",
          body: updateAboutPage,
        };
      },
      invalidatesTags: ["Life"],
    }),
    deleteLifeTimePage: builder.mutation({
      query: ({ id }) => {
        return {
          url: `/basic/lifetime-members/${id}/`,
          method: "DELETE",
          body: id,
        };
      },
      invalidatesTags: ["Life"],
    }),
  }),
});

export const {
  useGetLifeTimeQuery,
  useAddLifeTimeMutation,
  useGetLifeTimeByIdQuery,
  useUpdateLifeTimePageMutation,
  useDeleteLifeTimePageMutation,
  useGetLifeTimePaginationQuery,
} = NewsSlice;
