import React from 'react'
import { TextField } from '@material-ui/core';

export default function NumberInput(props) {

    const { name, label, value,error=null, onChange, onKeyUp, defaultValue } = props;
    return (
        <TextField
            type="number"
            variant="outlined"
            label={label}
            name={name}
            value={value}
            defaultValue={defaultValue}
            onChange={onChange}
            onKeyUp={onKeyUp}
            style={{
                borderBlockColor: 'white',
                width:'100%',
                marginBottom: "15px"
            }}
            size={'medium'}
            {...(error && {error:true,helperText:error})}
        />
    )
}
