import {
    createSelector,
    createEntityAdapter
} from "@reduxjs/toolkit";

import { apiSlice } from "./apiSlice";

const statAPI = '/bookings/get-stats';

const statRegistrationAdapter = createEntityAdapter()

const initialState = statRegistrationAdapter.getInitialState()

export const statRegistrationSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getStatRegistrations: builder.query({
            query: () => statAPI,

            invalidatesTags: ['Stats']
        }),
       
    })
})

export const {
    useGetStatRegistrationsQuery,
} = statRegistrationSlice

// returns the query result object
export const selectStatRegistrationsResult = statRegistrationSlice.endpoints.getStatRegistrations.select()

// Creates memoized selector
const selectStatRegistrationssData = createSelector(
    selectStatRegistrationsResult,
    statRegistrationsResult => statRegistrationsResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllStatRegistrations,
    selectById: selectStatRegistrationById,
    selectIds: selectIds
    // Pass in a selector that returns the posts slice of state
} = statRegistrationAdapter.getSelectors(state => selectStatRegistrationssData(state) ?? initialState)