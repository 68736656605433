import {
    createSelector,
    createEntityAdapter
} from "@reduxjs/toolkit";

import { apiSlice } from "./apiSlice";

const missionsAdapter = createEntityAdapter()

const initialState = missionsAdapter.getInitialState()

export const missionSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getMissions: builder.query({
            query: () => '/basic/mission/',
            transformResponse: responseData => {
                return missionsAdapter.setAll(initialState, responseData)
            },
            providesTags: (result, error, arg) => 
            [
                { type: 'Missions', id: "LIST" },
                ...result.ids.map(id => ({ type: 'Missions', id }))
            ]
        }),
        getMissionsById: builder.query({
            query: (id)=>({

                url:`/basic/mission/${id}/`,
                method: 'GET',
            }),
            providesTags: ['Missions']
        }),
        addMission: builder.mutation({
            query: (mission) => {
            return{
                url: '/basic/mission/',
                method: 'POST',
                body: mission,
            }
        },
            invalidatesTags: ['Missions']
        }),
        updateMission: builder.mutation({
           
            query: (updateMission) => {
                return{
                url: `/basic/mission/${updateMission.get('id')}/`,
                method: 'PATCH',
                body: updateMission
                }
            },
            invalidatesTags: ['Missions']
        }),
        deleteMission: builder.mutation({
            query: ({ id }) => {
                return{
                url: `/basic/mission/${id}/`,
                method: 'DELETE',
                body: id
            }},
            invalidatesTags: ['Missions']
        }),
    })
})

export const {
    useGetMissionsQuery,
    useAddMissionMutation,
    useUpdateMissionMutation,
    useDeleteMissionMutation,
    useGetMissionsByIdQuery
} = missionSlice

// returns the query result object
export const selectMissionsResult = missionSlice.endpoints.getMissions.select()

// Creates memoized selector
const selectMissionssData = createSelector(
    selectMissionsResult,
    missionsResult => missionsResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllMissions,
    selectById: selectMissionById,
    selectIds: selectMissionIds
    // Pass in a selector that returns the posts slice of state
} = missionsAdapter.getSelectors(state => selectMissionssData(state) ?? initialState)
