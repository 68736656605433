import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Sidebar from "../../component/sidebar";
import {
  Grid,
  Pagination,
  Stack,
  Typography,
} from "@mui/material";
import Header from "../../component/header";
import BasicTable from "../tables/BasicTable";
import { Box } from "@mui/system";
import usePagination from "../../../../../components/reuseable/pagination";
import {
  useGetDoctorCallCardsQuery,
  useGetDoctorCallCardsByIdQuery,
  useAddDoctorCallCardMutation,
  useUpdateDoctorCallCardMutation,
  useDeleteDoctorCallCardMutation,
  useGetDoctorCallCardsMemberPaginationQuery,
  useGetDoctorCallCardsPaginationQuery
} from "../../../../../api/doctorCallCardSlice";
import { useGetMrIDByEmailMutation } from "../../../../../api/userSlice";
import Controls from "../forms/controls/Controls";
import { useSearchFliterPageMutation } from "../../../../../api/searchApiSlice";
import { useForm1 } from "../../../../../components/forms/useForm";
import { Table } from "react-bootstrap";
import ModeEditSharpIcon from "@mui/icons-material/ModeEditSharp";
import DoctorCallCardButton from "./doctorCallCardAddButton";
import DeleteSharpIcon from "@mui/icons-material/DeleteSharp";
import Cookies from "js-cookie";

const DoctorCallCardDashboardPage = () => {

  return (
    <Grid container className="dashboard-full-background">
      <Grid item md={2} xs={12} sm={12}>
        <Sidebar />
      </Grid>
      <Grid item md={10} xs={12} sm={12} className="res-margin-design">
        <Header />
        <DashboardDoctorCallCardPagesPages />
      </Grid>
    </Grid>
  );
};

const DashboardDoctorCallCardPagesPages = () => {
  const pageNo = useLocation();
  const paramPairs = decodeURIComponent(pageNo.search.substr(1));
  const paramPairs1 = paramPairs.split('=');
  const pageNumber = paramPairs1[1];
  const [thisMr, setThisMr] = useState(null);

  const [pageId, setPageId] = useState(1);

  const [getMRID] = useGetMrIDByEmailMutation();

  useEffect(() => {
    setPageId(1);
  }, [])

  useEffect(() => {
    getMRID(Cookies.get('user_email')).
      then((res) => {
        setThisMr(res.data.id);
      })

  }, [Cookies.get('user_email')])
  useEffect(() => {
    if (pageNumber !== undefined) {
      setPageId(pageNumber)
    } else {
      setPageId(1)
    }
  }, [pageNumber])
  const history = useNavigate();


  const { data, isLoading, isSuccess } = useGetDoctorCallCardsMemberPaginationQuery({ 'page': pageId, 'mr': Cookies.get('user_role') === "admin" ? "" : thisMr });





  const [deleteDoctorCallCard] = useDeleteDoctorCallCardMutation();

  const deleteConfirm = (e, id) => {

    e.preventDefault();
    deleteDoctorCallCard({ id });
    setTimeout(() => {
      window.location.reload(true);
    }, [2000])
  };

  let headers = [];
  let tableData = [];
  let content;
  let cardNumber;

  var count;
  if (isLoading) {
    content = <p>"Loading..."</p>;
  } else if (isSuccess) {
    headers = [
      "Card No.",
      "MR Name",
      "Company",
      "Division",
      "Doctor Name",
      "Call Date",
      "Call Type",
    ];
    data.results.forEach((key, index) => {
      cardNumber = key?.card_no
      tableData.push({
        "Card No.": key?.card_no ? key?.card_no : "Pending",
        "MR Name": key?.mr?.user?.first_name + (key?.mr?.user?.middle_name ? " " + key?.mr?.user?.middle_name : "") + " " + key?.mr?.user?.last_name,
        id: key?.id,
        "Company": key?.company,
        "Division": key?.division,
        "Doctor Name": key?.doctor_name,
        "Call Date": key?.call_date,
        "Call Type": key?.call_type
      });
    });
    var newData = data.count / 10;
    count = Math.ceil(newData);
  }


  const [page, setPage] = useState(1);
  const PER_PAGE = 10;

  const _DATA = usePagination(tableData, count, PER_PAGE);


  const handleChange = (e, p) => {
    _DATA.jump(p);
    setPage(p)
    // const queryParams = new URLSearchParams(p).toString();
    history(`/dashboard/doctors-call-card/?pageNumber=${p}`)
  };



  //   const [NMSRANO, results] = useSearchFliterPageMutation();

  //   const initialFValues = {
  //     search: "",
  //   };

  //   const { values, handleSearchClick } = useForm1(initialFValues);

  //   useEffect(() => {
  //     NMSRANO(values);
  //   }, [values]);

  return (
    <>
      <Box className="table-design-background">
        <Grid container>
          <Grid item md={7} xs={8}>
            <Typography className="dashboard-home-page-text">
              Doctors Call Card
            </Typography>
          </Grid>
          <Grid item md={3} xs={0}>
          </Grid>
          <Grid item md={2} xs={3}>
            <Box style={{ marginTop: "1rem", marginRight: "1rem", textAlign: "end" }}>
              <Link
                to="/dashboard/add/doctors-call-card"
                style={{ textDecoration: "none" }}
              >
                <DoctorCallCardButton name={"Add"} />
              </Link>
            </Box>
          </Grid>
        </Grid>
        <Box style={{ padding: "1.5rem" }}>
          <>
            <BasicTable
              headers={headers}
              data={tableData}
              deleteColor={"#f50057"}
              isEdit={true}
              editColor={"#3f51b5"}
              isDelete={true}
              addColor={""}
              tableName={"doctors-call-card"}
              deleteConfirm={deleteConfirm}
              activeSign={["Active"]}
              doctorcardAvailable={true}
              doctor={true}
            />
            <Box
              style={{
                marginTop: "20px",
                textAlign: "center",
                paddingBottom: "1rem",
                paddingLeft: "1rem",
              }}
            >
              <Stack spacing={2} style={{ textAlign: "center" }}>
                <Pagination
                  count={count}
                  size="small"
                  page={parseInt(pageNumber)}
                  variant="outlined"
                  onChange={handleChange}
                  color="primary"
                />
              </Stack>
            </Box>
          </>
        </Box>
      </Box>
    </>
  );
};
export default DoctorCallCardDashboardPage;
