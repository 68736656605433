import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useGetLifeTimeQuery } from "../../../api/lifeTimeSlice";
import Footer from "../component/footer";
import Header from "../component/header";

const LifeTimeMember = () => {
  return (
    <>
      <Header />
      <LifeTimeMemberPage />
      <Footer />
    </>
  );
};

const LifeTimeMemberPage = () => {
  const data = useGetLifeTimeQuery();
  

  return (
    <Box>
      <section className="page-title bg-1">
        <div className="overlay"></div>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="block text-center">
                <span className="text-white">Life Time Member</span>
                <h1 className="text-capitalize mb-5 text-lg">
                  Life Time Member
                </h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section team">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6">
              <div className="section-title text-center">
                <h2 className="mb-4">Meet Our Life Time Member</h2>
                <div className="divider mx-auto my-4"></div>
              </div>
            </div>
          </div>
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography style={{ fontWeight: "600" }}>S.No.</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography style={{ fontWeight: "600" }}>
                      Full Name
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography style={{ fontWeight: "600" }}>Email</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography style={{ fontWeight: "600" }}>
                      Position
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography style={{ fontWeight: "600" }}>
                      Contact Number
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data !== undefined ? (
                  <>
                    {data.status === "fulfilled" ? (
                      <>
                        {data.data.ids.map((key, index) => (
                          <TableRow>
                            <TableCell>{index + 1}</TableCell>
                            <TableCell>
                              {data.data.entities[key].name}
                            </TableCell>
                            <TableCell>
                              {data.data.entities[key].email}
                            </TableCell>
                            <TableCell>
                              {data.data.entities[key].role}
                            </TableCell>
                            <TableCell>
                              {data.data.entities[key].contact_no}
                            </TableCell>
                          </TableRow>
                        ))}
                      </>
                    ) : null}
                  </>
                ) : null}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </section>
    </Box>
  );
};

export default LifeTimeMember;
