import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import About from "../containers/templates/pages/about";
import Acitivity from "../containers/templates/pages/activity";
import Blog from "../containers/templates/pages/blog";
import Campaign from "../containers/templates/pages/campaign";
import Contact from "../containers/templates/pages/contact";
import CouncilStaff from "../containers/templates/pages/councilstaff";
import ExecutiveMember from "../containers/templates/pages/executivemember";
import FounderMember from "../containers/templates/pages/foundermember";
import Gallery from "../containers/templates/pages/gallery";
import HomePage from "../containers/templates/pages/home";
import MedicalEvents from "../containers/templates/pages/medicalevents";
import MissionAndVision from "../containers/templates/pages/missionandvision";
import News from "../containers/templates/pages/news";
import PastExecutiveMember from "../containers/templates/pages/pastexecutive";
import PastPresident from "../containers/templates/pages/pastpresident";
import PresidentMessage from "../containers/templates/pages/presidentmessage";
import RegularEvents from "../containers/templates/pages/regularevents";
import Service from "../containers/templates/pages/services";

import DashboardHome from "../containers/dashboard/component/pages/dashboardhome";

import DashboardHomePages from "../containers/dashboard/component/pages/homePageDashboard/homePageDashboardHome";
import AddHomePage from "../containers/dashboard/component/pages/homePageDashboard/homePageNewPage";
import HomePageEditPage from "../containers/dashboard/component/pages/homePageDashboard/homePageEditPage";

import DashboardAboutPages from "../containers/dashboard/component/pages/aboutDashboard/aboutPageDashboardHome";
import AddAboutPage from "../containers/dashboard/component/pages/aboutDashboard/aboutPageNewPage";
import AboutPageEditPage from "../containers/dashboard/component/pages/aboutDashboard/aboutPageEditPage";

import DashboardWorks from "../containers/dashboard/component/pages/worksDashboard/workDashboardHome";
import AddWork from "../containers/dashboard/component/pages/worksDashboard/workNewPage";
import WorkEditPage from "../containers/dashboard/component/pages/worksDashboard/workEditPage";

import DashboardMissions from "../containers/dashboard/component/pages/missionsDashboard/missionDashboardHome";
import AddMission from "../containers/dashboard/component/pages/missionsDashboard/missionNewPage";
import MissionEditPage from "../containers/dashboard/component/pages/missionsDashboard/missionEditPage";

import DashboardVisions from "../containers/dashboard/component/pages/visionsDashboard/visionDashboardHome";
import AddVision from "../containers/dashboard/component/pages/visionsDashboard/visionNewPage";
import VisionEditPage from "../containers/dashboard/component/pages/visionsDashboard/visionEditPage";

import DashboardChairpersons from "../containers/dashboard/component/pages/chairpersonDashboard/chairpersonDashboardHome";
import AddChairperson from "../containers/dashboard/component/pages/chairpersonDashboard/chairpersonNewPage";
import ChairpersonEditPage from "../containers/dashboard/component/pages/chairpersonDashboard/chairpersonEditPage";

import DashboardTestimonials from "../containers/dashboard/component/pages/testimonialDashboard/testimonialDashboardHome";
import AddTestimonial from "../containers/dashboard/component/pages/testimonialDashboard/testimonialNewPage";
import TestimonialEditPage from "../containers/dashboard/component/pages/testimonialDashboard/testimonialEditPage";

import DashboardHospitals from "../containers/dashboard/component/pages/hospitalDashboard/hospitalDashboardHome";
import AddHospital from "../containers/dashboard/component/pages/hospitalDashboard/hospitalNewPage";
import HospitalEditPage from "../containers/dashboard/component/pages/hospitalDashboard/hospitalEditPage";

import DashboardHospitalHolidays from "../containers/dashboard/component/pages/hospitalHolidayDashboard.js/hospitalHolidayDashboard";
import HospitalHolidayEditPage from "../containers/dashboard/component/pages/hospitalHolidayDashboard.js/hospitalHolidayEditPage";
import AddHospitalHoliday from "../containers/dashboard/component/pages/hospitalHolidayDashboard.js/hospitalHolidayNewPage";

import DashboardMembershipPeriod from "../containers/dashboard/component/pages/membershipPeriodDashboard/membershipPeriodDashboard";
import AddMembershipYearsPages from "../containers/dashboard/component/pages/membershipPeriodDashboard/membershipPeriodNewPage";
import EditMembershipPeriod from "../containers/dashboard/component/pages/membershipPeriodDashboard/membershipPeriodEdit/EditMembershipPeriod";

import DashboardLandingPages from "../containers/dashboard/component/pages/landingPageDashboard.js/landingPageDashboard";
import AddLandingPage from "../containers/dashboard/component/pages/landingPageDashboard.js/landingPageNewPage";
import LandingPageEditPage from "../containers/dashboard/component/pages/landingPageDashboard.js/landingPageEditPage";

import DashboardPartners from "../containers/dashboard/component/pages/partnerDashboard/partnerDashboardHome";
import AddPartner from "../containers/dashboard/component/pages/partnerDashboard/partnerNewPage";
import PartnerEditPage from "../containers/dashboard/component/pages/partnerDashboard/partnerEditPage";

import DashboardKathmanduPresidents from "../containers/dashboard/component/pages/kathmanduPresidentDashboard/kathmanduPresidentDashboard";
import AddKathmanduPresidentPage from "../containers/dashboard/component/pages/kathmanduPresidentDashboard/kathmanduNewPage";
import KathmanduPresidentEditPage from "../containers/dashboard/component/pages/kathmanduPresidentDashboard/kathmanduPresidentEditPage";

import DashboardCentralPresidents from "../containers/dashboard/component/pages/centralPresidentDashboard/centralPresidentDashboard";
import AddCentralPresidentPage from "../containers/dashboard/component/pages/centralPresidentDashboard/centralPresidentNewPage";
import CentralPresidentEditPage from "../containers/dashboard/component/pages/centralPresidentDashboard/centralPresidentEditPage";

import DashboardOfficeStaffs from "../containers/dashboard/component/pages/officeStaffDashboard/officeStaffsDashboard";
import AddOfficeStaffPage from "../containers/dashboard/component/pages/officeStaffDashboard/officeStaffsNewPage";
import OfficeStaffEditPage from "../containers/dashboard/component/pages/officeStaffDashboard/officeStaffsEditPage";

// import AddExecutiveBodiesPage from "../containers/dashboard/component/pages/executiveBodyDashboard/executiveBodiesNewPage";
// import ExecutiveBodiesEditPage from "../containers/dashboard/component/pages/executiveBodyDashboard/executiveBodiesEditPage";
// import DashboardExecutiveBodiess from "../containers/dashboard/component/pages/executiveBodyDashboard/executiveBodiesDashboard";

import BookMyHospital from "../containers/dashboard/component/pages/hospitalBookingDashboard/bookMyHospital";
import EditBooking from "../containers/dashboard/component/pages/hospitalBookingDashboard/editBooking";
import EditBookingUser from "../containers/dashboard/component/pages/hospitalBookingDashboard/editBookingsUser";

import DashboardUsers from "../containers/dashboard/component/pages/userDashboard/userDashboardHome";
import UserEditPage from "../containers/dashboard/component/pages/userDashboard/userEditPage";

import RequireAuth from "../api/requireAuthSlice";

import DashboardContacts from "../containers/dashboard/component/pages/contactDashboard/contactDashboardHome";
import ContactEditPage from "../containers/dashboard/component/pages/contactDashboard/contactEditPage";
import DetailDataPastExecutive from "../containers/templates/pages/detailDataPastExecutive";
import BlogDetail from "../containers/templates/pages/blogDetailPage";
import Login from "../containers/templates/pages/login";
import Signup from "../containers/templates/pages/signup";
import SignupNew from "../containers/templates/pages/signupNew";
import EmailVerification from "../containers/templates/pages/forgotpassword/emailVerification";
import NewPassword from "../containers/templates/pages/forgotpassword/newPassword";
import Cookies from "js-cookie";
import AllAboutPage from "../containers/dashboard/component/pages/allAboutPage";
import DashboardBlogs from "../containers/dashboard/component/pages/blogDashboard/blogDashboardHome";
import BlogEditPage from "../containers/dashboard/component/pages/blogDashboard/blogEditPage";
import AddBlog from "../containers/dashboard/component/pages/blogDashboard/blogNewPage";
// import ExecutivePageDashboard from "../containers/dashboard/component/pages/executiveBodyDashboard/executivePageDashboard";
import DashboardImageGallerys from "../containers/dashboard/component/pages/imageGalleryDashboard/imageGalleryDashboardHome";
import AddImageGallery from "../containers/dashboard/component/pages/imageGalleryDashboard/imageGalleryNewPage";
import ImageGalleryEditPage from "../containers/dashboard/component/pages/imageGalleryDashboard/imageGalleryEditPage";
import HospitalInformation from "../containers/dashboard/component/pages/hospitalInformation";
import EventDetailPage from "../containers/templates/pages/eventDetailPage";
import EventDashboardPage from "../containers/dashboard/component/pages/eventDashboard/eventPageDashboard";
import AddEventPage from "../containers/dashboard/component/pages/eventDashboard/eventPageNewPage";
import EventEditPage from "../containers/dashboard/component/pages/eventDashboard/eventPageEditPage";
import DashboardNewsPage from "../containers/dashboard/component/pages/newsDashboard/newsDashboardPage";
import NewsAddPage from "../containers/dashboard/component/pages/newsDashboard/newsPageNewPage";
import NewsEditPage from "../containers/dashboard/component/pages/newsDashboard/newsEditPage";
import ActivityDashboardPage from "../containers/dashboard/component/pages/activityDashboard/activityPageDashboard";
import AddActivityPage from "../containers/dashboard/component/pages/activityDashboard/activityPageNewPage";
import ActivityEditPage from "../containers/dashboard/component/pages/activityDashboard/activityEditPage";
import LifeTimeMember from "../containers/templates/pages/lifeTimeMember";
import LifeTimeDashboardPage from "../containers/dashboard/component/pages/lifetimeMemberDashboard/lifeTimePageDashboard";
import AddLifeTimePage from "../containers/dashboard/component/pages/lifetimeMemberDashboard/lifeTimeAddPage";
import LifeTimeEditPage from "../containers/dashboard/component/pages/lifetimeMemberDashboard/lifeTimeEditPage";
import MemberEditPage from "../containers/dashboard/component/pages/memberEditPage/memberEditPage";
import ActivityDetailPage from "../containers/templates/pages/activitiyDetailPage";
import AllBasicPage from "../containers/dashboard/component/pages/allBasicPage";
import MemberEditDashboardPage from "../containers/dashboard/component/pages/memberDashbordEditPage/memberEditPage";
import PaymentPage from "../containers/dashboard/component/pages/memberShipPayment/memberShipPayment";
import AllOtherData from "../containers/dashboard/component/pages/otherData";
import PaymentListDashboard from "../containers/dashboard/component/pages/paymentListDashboard/paymentListDashboard";
import LfNoDashboardPage from "../containers/dashboard/component/pages/lfNoDashboardPage/lfNoDashboardPage";
import LfNoEditPage from "../containers/dashboard/component/pages/lfNoDashboardPage/lfNoEditPage";
import PaymentListEdit from "../containers/dashboard/component/pages/paymentListDashboard/paymentListEdit";
import UpdateBookHospital from "../containers/dashboard/component/pages/hospitalBookingDashboard/updateBookHospital";
import AddingLfNumber from "../containers/dashboard/component/pages/lfNoDashboardPage/addLfNumber";
import ChangePassword from "../containers/templates/pages/changePassword";
import DashboardExecutiveBodiess from "../containers/dashboard/component/pages/executiveBodyDashboard/executiveBodiesDashboard";
import AddExecutiveBodiesPage from "../containers/dashboard/component/pages/executiveBodyDashboard/executiveBodiesNewPage";
import ExecutiveBodiesEditPage from "../containers/dashboard/component/pages/executiveBodyDashboard/executiveBodiesEditPage";
import UpdateBookings from "../containers/dashboard/component/pages/hospitalBookingDashboard/updateBookings";
import Athentication from "./authentication";

import MemberCard from "../containers/dashboard/component/pages/memberEditPage/memberCard";

import CardDetailsEditPage from "../containers/dashboard/component/pages/cardDetailsDashboard/cardDetailsEditPage";
import DashboardCardDetails from "../containers/dashboard/component/pages/cardDetailsDashboard/cardDetailsDashboardHome";
import AdminBookingsPage from "../containers/dashboard/component/pages/adminHotelBookings/adminBookingsPage";
import MyIdCard from "../containers/templates/pages/idCard";

import DoctorCallCardDashboardPage from "../containers/dashboard/component/pages/doctorCallCardDashboard/doctorCallCardDashboard";
import AddDoctorCallCard from "../containers/dashboard/component/pages/doctorCallCardDashboard/doctorCallCardNewPage";
import DoctorCallCardEditPage from "../containers/dashboard/component/pages/doctorCallCardDashboard/doctorCallCardEditPage";
import DoctorCallCardDashboardAdminPage from "../containers/dashboard/component/pages/doctorCallCardDashboard/doctorCallCardAdminDashboard";
import CallCardDownload from "../containers/dashboard/component/pages/doctorCallCardDashboard/callCardDownload";
import SimCardDashboard from "../containers/dashboard/component/pages/simCard/simCardDashboard";
import AddSimCard from "../containers/dashboard/component/pages/simCard/addSimCard";
import SimCardDownload from "../containers/dashboard/component/pages/simCard/simCardDownload";
import AddSimCardData from "../containers/dashboard/component/pages/simCard/addSimCardDetail";
import AdminSimCard from "../containers/dashboard/component/pages/simCard/adminSimCard";

const Routing = () => {
  const [isAuth, setIsAuth] = useState(false);

  const access = Cookies.get("access");
  const refresh = Cookies.get("refresh");
  const email = Cookies.get("email");
  const is_admin = Cookies.get("is_admin");
  const TOKEN_KEY = { access, refresh, email, is_admin };
  const [Admin, setAdmin] = useState(false);


  useEffect(() => {
    if (
      TOKEN_KEY["access"] == undefined &&
      TOKEN_KEY["refresh"] == undefined &&
      TOKEN_KEY["username"] == undefined &&
      TOKEN_KEY["is_admin"] == undefined
    ) {
      setIsAuth(false);
    } else {
      setIsAuth(true);
    }
  }, []);

  useEffect(() => {
    if (TOKEN_KEY.is_admin === true) {
      setAdmin(true);
    } else {
      setAdmin(false);
    }
  }, [Admin])


  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" exact element={<HomePage />} />
        <Route path="/about/organization" exact element={<About />} />
        {/* <Route path="/service" exact element={<Service />} /> */}
        {/* <Route path="/blog" exact element={<Blog />} /> */}
        <Route path="/contact" exact element={<Contact />} />
        <Route path="/mission/vision" exact element={<MissionAndVision />} />
        <Route path="/message/president" exact element={<PresidentMessage />} />
        <Route path="/executive/member" exact element={<ExecutiveMember />} />
        <Route path="/life/time/member" exact element={<LifeTimeMember />} />
        <Route
          path="/user/change/password"
          exact
          element={<ChangePassword />}
        />
        {/* <Route
          path="/my/id/card"
          exact
          element={<MyIdCard />}
        /> */}
        <Route
          path="/detail/page/:id"
          exact
          element={<DetailDataPastExecutive />}
        />
        <Route path="/blog/detail" exact element={<BlogDetail />} />
        <Route
          path="/past/executive/member"
          exact
          element={<PastExecutiveMember />}
        />
        <Route path="/past/president" exact element={<PastPresident />} />
        <Route path="/central/past/president" exact element={<FounderMember />} />
        <Route path="/office/staff" exact element={<CouncilStaff />} />
        <Route path="/regular/event" exact element={<RegularEvents />} />
        <Route path="/medical/event" exact element={<MedicalEvents />} />
        <Route path="/notice" exact element={<News />} />
        <Route path="/NMSRA/activity" exact element={<Acitivity />} />
        <Route path="/NMSRA/campaign" exact element={<Campaign />} />
        <Route path="/gallery" exact element={<Gallery />} />
        <Route
          path="/dashboard/edit-bookings-user"
          exact
          element={<EditBookingUser />}
        />
        <Route
          path="/dashboard/book-my-hospitals/edit/:id"
          exact
          element={<UpdateBookHospital />}
        />
        <Route
          path="/dashboard/member/payment"
          exact
          element={<PaymentPage />}
        />
        <Route
          path="/dashboard/member/payment/edit/:id"
          exact
          element={<PaymentListEdit />}
        />
        <Route
          path="/dashboard/member/payment/list"
          exact
          element={<PaymentListDashboard />}
        />
        <Route
          path="/dashboard/member/edit/data"
          exact
          element={<MemberEditDashboardPage />}
        />
        <Route
          path="/dashboard/book-my-hospitals"
          exact
          element={<BookMyHospital />}
        />
        <Route
          path="/dashboard/doctors-call-card"
          exact
          element={<DoctorCallCardDashboardPage />}
        />
        <Route
          path="/dashboard/sim-card"
          exact
          element={<SimCardDashboard />}
        />
          <Route
          path="/dashboard/sim-card/:id"
          exact
          element={<SimCardDownload />}
        />
        <Route
          path="/dashboard/add/sim-card"
          exact
          element={<AddSimCardData />}
        />
        <Route
          path="/dashboard/add/doctors-call-card"
          exact
          element={<AddDoctorCallCard />}
        />
        <Route
          path="/dashboard/doctors-call-card/print/:id"
          exact
          element={<CallCardDownload />}
        />
        <Route
          path="dashboard/doctors-call-card/edit/:id"
          exact
          element={<DoctorCallCardEditPage />}
        />
        {/* member dashboard */}
        <Route
          path="/event/detail/page/:id"
          exact
          element={<EventDetailPage />}
        />
        <Route
          path="/activity/detail/page/:id"
          exact
          element={<ActivityDetailPage />}
        />
        {isAuth !== true ? (
          <>
            <Route path="/login" exact element={<Login />} />
            <Route path="/signup" exact element={<Signup />} />
            <Route path="/signup-new" exact element={<SignupNew />} />
            <Route
              path="/forgetpassword/emailverification"
              exact
              element={<EmailVerification />}
            />
            <Route
              path="/forgetpassword/setpassword"
              exact
              element={<NewPassword />}
            />
          </>
        ) : null}
        <Route path="/dashboard/home" exact element={<DashboardHome />} />
        <Route element={<RequireAuth />}>
          <Route
            path="/dashboard/admin/doctors-call-card"
            exact
            element={<DoctorCallCardDashboardAdminPage />}
          />
           <Route
            path="/dashboard/admin/sim-card"
            exact
            element={<AdminSimCard />}
          />
          <Route path="/dashboard/home" exact element={<Athentication component={DashboardHome} allowRoles={['admin']} />} />
          <Route path="/dashboard/about" exact element={<Athentication component={AllAboutPage} allowRoles={['admin']} />} />
          <Route
            path="/dashboard/basic/data"
            exact
            element={<Athentication component={AllBasicPage} allowRoles={['admin']} />}
          />
          <Route
            path="/dashboard/life/time/Member"
            exact
            element={<Athentication component={LifeTimeDashboardPage} allowRoles={['admin']} />}
          />
          <Route
            path="dashboard/add/life/time/member"
            element={<Athentication component={AddLifeTimePage} allowRoles={['admin']} />}
          />
          <Route
            path="/dashboard/life/time/member/edit/:id"
            element={<Athentication component={LifeTimeEditPage} allowRoles={['admin']} />}
          />
          <Route
            path="/dashboard/image-gallerys"
            exact
            element={<Athentication component={DashboardImageGallerys} allowRoles={['admin']} />}
          />

          <Route path="/dashboard/news" exact element={<Athentication component={DashboardNewsPage} allowRoles={['admin']} />} />
          <Route path="/dashboard/add/news" exact element={<Athentication component={NewsAddPage} allowRoles={['admin']} />} />
          <Route
            path="/dashboard/news/edit/:id"
            exact
            element={<Athentication component={NewsEditPage} allowRoles={['admin']} />}
          />
          <Route
            path="/dashboard/activity"
            exact
            element={<Athentication component={ActivityDashboardPage} allowRoles={['admin']} />}
          />
          <Route
            path="/dashboard/add/activity"
            exact
            element={<Athentication component={AddActivityPage} allowRoles={['admin']} />}
          />
          <Route
            path="/dashboard/activity/edit/:id"
            exact
            element={<Athentication component={ActivityEditPage} allowRoles={['admin']} />}
          />
          <Route
            path="/dashboard/events"
            exact
            element={<Athentication component={EventDashboardPage} allowRoles={['admin']} />}
          />
          <Route
            path="/dashboard/add/events"
            exact
            element={<Athentication component={AddEventPage} allowRoles={['admin']} />}
          />
          <Route
            path="/dashboard/events/edit/:id"
            exact
            element={<Athentication component={EventEditPage} allowRoles={['admin']} />}
          />
          <Route
            path="/dashboard/new/image-gallerys"
            exact
            element={<Athentication component={AddImageGallery} allowRoles={['admin']} />}
          />
          <Route
            path="/dashboard/image-gallerys/edit/:id"
            exact
            element={<Athentication component={ImageGalleryEditPage} allowRoles={['admin']} />}
          />

          <Route
            path="/dashboard/home-pages"
            exact
            element={<Athentication component={DashboardHomePages} allowRoles={['admin']} />}
          />
          <Route path="/dashboard/news" element={<Athentication component={DashboardBlogs} allowRoles={['admin']} />} />
          <Route
            path="/dashboard/new/home-pages"
            exact
            element={<Athentication component={AddHomePage} allowRoles={['admin']} />}
          />
          <Route
            path="dashboard/home-pages/edit/:id"
            exact
            element={<Athentication component={HomePageEditPage} allowRoles={['admin']} />}
          />
          <Route
            path="/dashboard/blogs/edit/:id"
            exact
            element={<Athentication component={BlogEditPage} allowRoles={['admin']} />}
          />

          <Route
            path="/dashboard/about-pages"
            exact
            element={<Athentication component={DashboardAboutPages} allowRoles={['admin']} />}
          />
          <Route path="/dashboard/new/blogs" element={<Athentication component={AddBlog} allowRoles={['admin']} />} />
          <Route
            path="/dashboard/new/about-pages"
            exact
            element={<Athentication component={AddAboutPage} allowRoles={['admin']} />}
          />
          <Route
            path="dashboard/about-pages/edit/:id"
            exact
            element={<Athentication component={AboutPageEditPage} allowRoles={['admin']} />}
          />

          <Route path="/dashboard/work" exact element={<Athentication component={DashboardWorks} allowRoles={['admin']} />} />
          <Route path="/dashboard/new/works" exact element={<Athentication component={AddWork} allowRoles={['admin']} />} />
          <Route
            path="dashboard/works/edit/:id"
            exact
            element={<Athentication component={WorkEditPage} allowRoles={['admin']} />}
          />
          {/* <Route
            path="/dashboard/executive"
            element={<ExecutivePageDashboard />}
          /> */}

          <Route
            path="/dashboard/mission"
            exact
            element={<Athentication component={DashboardMissions} allowRoles={['admin']} />}
          />
          <Route
            path="/dashboard/new/missions"
            exact
            element={<Athentication component={AddMission} allowRoles={['admin']} />}
          />
          <Route
            path="dashboard/missions/edit/:id"
            exact
            element={<Athentication component={MissionEditPage} allowRoles={['admin']} />}
          />

          <Route
            path="/dashboard/vision"
            exact
            element={<Athentication component={DashboardVisions} allowRoles={['admin']} />}
          />
          <Route path="/dashboard/new/visions" exact element={<Athentication component={AddVision} allowRoles={['admin']} />} />
          <Route
            path="dashboard/visions/edit/:id"
            exact
            element={<Athentication component={VisionEditPage} allowRoles={['admin']} />}
          />

          <Route
            path="/dashboard/kathmandu-presidents"
            exact
            element={<Athentication component={DashboardKathmanduPresidents} allowRoles={['admin']} />}
          />
          <Route path="/dashboard/new/kathmandu-presidents" exact element={<Athentication component={AddKathmanduPresidentPage} allowRoles={['admin']} />} />
          <Route
            path="dashboard/kathmandu-presidents/edit/:id"
            exact
            element={<Athentication component={KathmanduPresidentEditPage} allowRoles={['admin']} />}
          />

          <Route
            path="/dashboard/central-presidents"
            exact
            element={<Athentication component={DashboardCentralPresidents} allowRoles={['admin']} />}
          />
          <Route path="/dashboard/new/central-presidents" exact element={<Athentication component={AddCentralPresidentPage} allowRoles={['admin']} />} />
          <Route
            path="dashboard/central-presidents/edit/:id"
            exact
            element={<Athentication component={CentralPresidentEditPage} allowRoles={['admin']} />}
          />

          <Route
            path="/dashboard/executive-bodies"
            exact
            element={<Athentication component={DashboardExecutiveBodiess} allowRoles={['admin']} />}
          />
          <Route path="/dashboard/new/executive-bodies" exact element={<Athentication component={AddExecutiveBodiesPage} allowRoles={['admin']} />} />
          <Route
            path="dashboard/executive-bodies/edit/:id"
            exact
            element={<Athentication component={ExecutiveBodiesEditPage} allowRoles={['admin']} />}
          />

          <Route
            path="/dashboard/office-staffs"
            exact
            element={<DashboardOfficeStaffs />}
          />
          <Route path="/dashboard/new/office-staffs" exact element={<Athentication component={AddOfficeStaffPage} allowRoles={['admin']} />} />
          <Route
            path="dashboard/office-staffs/edit/:id"
            exact
            element={<Athentication component={OfficeStaffEditPage} allowRoles={['admin']} />}
          />

          <Route
            path="/dashboard/chairperson"
            exact
            element={<Athentication component={DashboardChairpersons} allowRoles={['admin']} />}
          />
          <Route
            path="/dashboard/new/chairpersons"
            exact
            element={<Athentication component={AddChairperson} allowRoles={['admin']} />}
          />
          <Route
            path="dashboard/chairpersons/edit/:id"
            exact
            element={<Athentication component={ChairpersonEditPage} allowRoles={['admin']} />}
          />

          <Route
            path="/dashboard/testimonial"
            exact
            element={<Athentication component={DashboardTestimonials} allowRoles={['admin']} />}
          />
          <Route
            path="/dashboard/new/testimonials"
            exact
            element={<Athentication component={AddTestimonial} allowRoles={['admin']} />}
          />
          <Route
            path="dashboard/testimonials/edit/:id"
            exact
            element={<Athentication component={TestimonialEditPage} allowRoles={['admin']} />}
          />

          <Route
            path="/dashboard/landing-pages"
            exact
            element={<Athentication component={DashboardLandingPages} allowRoles={['admin']} />}
          />
          <Route
            path="/dashboard/new/landing-pages"
            exact
            element={<Athentication component={AddLandingPage} allowRoles={['admin']} />}
          />
          <Route
            path="dashboard/landing-pages/edit/:id"
            exact
            element={<Athentication component={LandingPageEditPage} allowRoles={['admin']} />}
          />

          <Route
            path="/dashboard/hospitals"
            exact
            element={<Athentication component={DashboardHospitals} allowRoles={['admin']} />}
          />
          <Route
            path="/dashboard/new/hospitals"
            exact
            element={<Athentication component={AddHospital} allowRoles={['admin']} />}
          />
          <Route
            path="dashboard/hospitals/edit/:id"
            exact
            element={<Athentication component={HospitalEditPage} allowRoles={['admin']} />}
          />

          <Route
            path="/dashboard/partners"
            exact
            element={<Athentication component={DashboardPartners} allowRoles={['admin']} />}
          />
          <Route
            path="/dashboard/new/partners"
            exact
            element={<Athentication component={AddPartner} allowRoles={['admin']} />}
          />
          <Route
            path="dashboard/partners/edit/:id"
            exact
            element={<Athentication component={PartnerEditPage} allowRoles={['admin']} />}
          />
          <Route
            path="/dashboard/hospital-holidays"
            exact
            element={<Athentication component={DashboardHospitalHolidays} allowRoles={['admin']} />}
          />
          <Route
            path="dashboard/hospital-holidays/edit/:id"
            exact
            element={<Athentication component={HospitalHolidayEditPage} allowRoles={['admin']} />}
          />
          {/* <Route
            path="/dashboard/membership-periods"
            exact
            element={<DashboardMembershipPeriod />}
          /> */}
          <Route
            path="/dashboard/new/membership-periods"
            exact
            element={<Athentication component={AddMembershipYearsPages} allowRoles={['admin']} />}
          />
          <Route
            path="/dashboard/membership-periods/edit/:id"
            exact
            element={<Athentication component={EditMembershipPeriod} allowRoles={['admin']} />}
          />
          <Route
            path="/dashboard/hospital/information"
            exact
            element={<Athentication component={HospitalInformation} allowRoles={['admin']} />}
          />
          <Route
            path="/dashboard/edit-bookings"
            exact
            element={<Athentication component={EditBooking} allowRoles={['admin']} />}
          />
          {/* <Route
            path="/dashboard/update-bookings/:id"
            exact
            element={<UpdateBookings />}
        /> */}

          <Route
            path="/dashboard/contact"
            exact
            element={<Athentication component={DashboardContacts} allowRoles={['admin']} />}
          />
          <Route
            path="/dashboard/contact/edit/:id"
            exact
            element={<Athentication component={ContactEditPage} allowRoles={['admin']} />}
          />
          {/* <Route
              path="dashboard/contacts/edit/:id"
              exact
              element={<ContactEditPage />}
            /> */}
          <Route
            path="/dashboard/lf/number"
            exact
            element={<Athentication component={LfNoDashboardPage} allowRoles={['admin']} />}
          />
          <Route
            path="/dashboard/lfno/edit/:id"
            exact
            element={<Athentication component={LfNoEditPage} allowRoles={['admin']} />}
          />
          <Route
            path="/dashboard/add/lfno"
            exact
            element={<Athentication component={AddingLfNumber} allowRoles={['admin']} />}
          />

          <Route path="/dashboard/users" exact element={<DashboardUsers />} />
          <Route
            path="/dashboard/users/edit/:id"
            exact
            element={<Athentication component={MemberEditPage} allowRoles={['admin']} />}
          />
          <Route
            path="/dashboard/users/card/:id"
            exact
            element={<Athentication component={MemberCard} allowRoles={['admin']} />}
          />
          <Route
            path="/dashboard/all/other/data"
            exact
            element={<Athentication component={AllOtherData} allowRoles={['admin']} />}
          />

          <Route
            path="/dashboard/card-details"
            exact
            element={<Athentication component={DashboardCardDetails} allowRoles={['admin']} />}
          />
          <Route
            path="/dashboard/card-details/edit/:id"
            exact
            element={<Athentication component={CardDetailsEditPage} allowRoles={['admin']} />} />
          <Route
            path="/dashboard/add/admin-bookings"
            exact
            element={<Athentication component={AdminBookingsPage} allowRoles={['admin']} />}
          />
        </Route>
        <Route path="*" exact element={<h1>404 Page Not Found</h1>} />
      </Routes>
    </BrowserRouter>
  );
};

export default Routing;
