import React, { useEffect, useState } from "react";
import Sidebar from "../../component/sidebar";
import Header from "../../component/header";
import { Box, Grid, Typography } from "@mui/material";
import Controls from "../forms/controls/Controls";
import { useForm, Form } from "../forms/useForm";
import { useGetCentralPresidentsByIdQuery,
  useGetCentralPresidentsQuery,
  useAddCentralPresidentMutation,
  useDeleteCentralPresidentMutation } from "../../../../../api/centralPastPresidentSlice";
import BlobToFile from "../forms/utils/blobToFile";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCog,
  faBell,
  faSignInAlt,
  faExclamationTriangle,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

const AddCentralPresidentPage = () => {
  return (
    <Grid container className="dashboard-full-background">
      <Grid item xs={2} spacing={4}>
        <Sidebar />
      </Grid>
      <Grid item xs={10}>
        <Header />
        <AddCentralPresidentPagePages />
      </Grid>
    </Grid>
  );
};

const AddCentralPresidentPagePages = () => {
  const [File1, setFile1] = useState([]);
  const initialFValues = {
    image: [],
    name: "",
    years: "",
  };
  const { values, handleImageUpload, handleInputChange } = useForm(
    initialFValues,
    true,
    false,
    false
  );
  const [SuccessMessege, setSuccesMessege] = useState(null);
  const [ErrorMessege, setErrorMessege] = useState(null);

  const [addCentralPresident] = useAddCentralPresidentMutation();

  useEffect(() => {
    handleImageUpload("image", File1);
  }, [File1]);

  const history = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    var file1 = BlobToFile(values.image, "centralPresident");
    if (file1.length !== 0) {
      formData.append("image", file1, "centralPresident.jpg");
    }
    formData.append("name", values.name);
    formData.append("years", values.years);
    addCentralPresident(formData)
      .then((res) => {
        setSuccesMessege("Successfully Added Central President Data.");
        setTimeout(() => {
          history("/dashboard/central-presidents");
        }, [3000]);
      })
      .catch((err) => {
        setErrorMessege("Some Error Occur. Please Try Again Later.");
        setTimeout(() => {
          setErrorMessege(null);
        }, [3000]);
      });
  };

  return (
    <Box className="table-design-background">
      <Typography className="dashboard-home-page-text">
        Add Central President Details
      </Typography>
      <Box style={{ padding: "1.5rem" }}>
        <Form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={6} style={{ textAlign: "start" }}>
              <Controls.Input
                name="name"
                label="Name"
                value={values.name}
                onChange={handleInputChange}
              />
              <Controls.Input
                name="years"
                label="Years"
                value={values.years}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={6}>
              <Box style={{ marginTop: ".4rem" }}>
                <Controls.Image setFile={setFile1} aspectRatio={930 / 1280}  />
              </Box>

            </Grid>
            <Grid>
              <Box style={{ marginLeft: "1.3rem" }}>
                <Controls.Button
                  type="submit"
                  text="Submit"
                  backgroundColor="#1b284b"
                  onClick={handleSubmit}
                />
              </Box>
            </Grid>
          </Grid>
        </Form>
        {SuccessMessege === null ? null : (
          <Box className="successmessege">
            <Typography className="messegefonts">
              <FontAwesomeIcon icon={faCheckCircle} className="messegeicon" />
              {SuccessMessege}
            </Typography>
          </Box>
        )}
        {ErrorMessege === null ? null : (
          <Box className="errormessege">
            <Typography className="messegefonts">
              <FontAwesomeIcon
                icon={faExclamationTriangle}
                className="messegeicon"
              />
              {ErrorMessege}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};
export default AddCentralPresidentPage;
