import Input from "./input";
import RadioGroup from "./RadioGroup";
import Select from "./Select";
import Checkbox from "./CheckBox";
import DatePickers from "./DatePicker";
import Button from "./Button";
import MultiSelect from "./MultiSelect";
import Search from "./searchBar";
import Image from "./image";
import DateTimePicker from "./DateTimePicker";
import File from "./file";
import NumberInput from "./integerInput";
import MultipleDatePicker from "./multipleDatePicker";
import SingleCalendar from "./SingleCalendar";
import PDFViewer from "./reactPDFViewer";
import DatePickerWithYear from "../../../../../../components/forms/controls/DatePickerWithVariableYear";

const Controls = {
    Input,
    RadioGroup,
    Select,
    Checkbox,
    DatePickers,
    Button,
    MultiSelect,
    Search,
    Image,
    DateTimePicker,
    File,
    NumberInput,
    MultipleDatePicker,
    SingleCalendar,
    PDFViewer,
    DatePickerWithYear
}

export default Controls;