import React from "react";
import Footer from "../component/footer";
import Header from "../component/header";
import Image02 from "../../../static/image/team/3.jpg";
import Image03 from "../../../static/image/team/1.jpg";
import Image04 from "../../../static/image/team/2.jpg";
import Image05 from "../../../static/image/team/4.jpg";
import { useGetMemberMembershipPeriodsByIdQuery } from "../../../api/memberMembershipPeriodSlice";
import { useParams } from "react-router-dom";
import { Box } from "@mui/system";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

const DetailDataPastExecutive = () => {
  return (
    <>
      <Header />
      <DetailDataPastExecutivePage />
      <Footer />
    </>
  );
};

const DetailDataPastExecutivePage = () => {
  const { id } = useParams();
  const data = useGetMemberMembershipPeriodsByIdQuery(id);
  

  return (
    <div>
      <section className="page-title bg-1">
        <div className="overlay"></div>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              {data !== undefined ? (
                <>
                  {data.status === "fulfilled" ? (
                    <>
                      <div className="block text-center">
                        <span className="text-white">{data.data.year} AD</span>
                        <h1 className="text-capitalize mb-5 text-lg">
                          {data.data.year} AD
                        </h1>
                      </div>
                    </>
                  ) : null}
                </>
              ) : null}
            </div>
          </div>
        </div>
      </section>
      <section className="section team">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6">
              <div className="section-title text-center">
                {data !== undefined ? (
                  <>
                    {data.status === "fulfilled" ? (
                      <h2 className="mb-4">
                        Meet Our {data.data.year} AD Past Executive Member
                      </h2>
                    ) : null}
                  </>
                ) : null}
                <div className="divider mx-auto my-4"></div>
                <p>
                  Today’s users expect effortless experiences. Don’t let
                  essential people and processes stay stuck in the past. Speed
                  it up, skip the hassles
                </p>
              </div>
            </div>
          </div>

          <div className="row">
            {data !== undefined ? (
              <>
                {data.status === "fulfilled" ? (
                  <>
                    {data.data.member_list.map((key) => (
                      <div className="col-lg-3 col-md-6 col-sm-6" key={key}>
                        <div class="member">
                          <img src={key.image} class="img-fluid" alt="" />
                          <div class="member-info">
                            <div class="member-info-content">
                              <h4>{key.name}</h4>
                              <span>{key.role}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </>
                ) : (
                  <div className="col-lg-3 col-md-6 col-sm-6">
                    <div class="member">
                      <Box>
                        <SkeletonTheme color="grey" highlightColor="#444">
                          <p>
                            <Skeleton height={250} width={"100%"} count={1} />
                          </p>
                        </SkeletonTheme>
                      </Box>
                      <div class="member-info">
                        <div class="member-info-content">
                          <h4>
                            <Skeleton />
                          </h4>
                          <span>
                            <Skeleton />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </>
            ) : null}
          </div>
        </div>
      </section>
    </div>
  );
};

export default DetailDataPastExecutive;
