import logo from './logo.svg';
import './App.css';
import Routing from './route/routing';
// import './static/css/reusable-style.css';
import './static/css/style.css';
import './static/css/icofont.css';

function App() {
  return (
    <div className="App">
      <Routing/>
    </div>
  );
}

export default App;
