import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Sidebar from "../../component/sidebar";
import { Grid, Pagination, Stack, Typography } from "@mui/material";
import Header from "../../component/header";
import BasicTable from "../tables/BasicTable";
import { Box } from "@mui/system";
import {
  useDeleteBlogMutation,
  useGetAllBlogsQuery,
  useGetBlogPaginationQuery,
} from "../../../../../api/blogSlice";
import EventAddButton from "./EventAddButton";
import usePagination from "../../../../../components/reuseable/pagination";

const EventDashboardPage = () => {
  return (
    <Grid container className="dashboard-full-background">
      <Grid item xs={2} spacing={4}>
        <Sidebar />
      </Grid>
      <Grid item xs={10}>
        <Header />
        <DashboardEventPagesPages />
      </Grid>
    </Grid>
  );
};

const DashboardEventPagesPages = () => {
  const [pageId, setPageId] = useState(1);
  const { data, isLoading, isSuccess } = useGetBlogPaginationQuery(pageId);
  

  const [deleteBlogPage] = useDeleteBlogMutation();

  const deleteConfirm = (e, id) => {
    e.preventDefault();
    deleteBlogPage({ id });
  };

  let headers = [];
  let tableData = [];
  let content;

  var count;
  if (isLoading) {
    content = <p>"Loading..."</p>;
  } else if (isSuccess) {
    headers = ["Title", "Event Type", "Active"];
    data.results.forEach((key) => {
      tableData.push({
        Title: key.title,
        id: key.id,
        "Event Type": key.event_types,
        Active: key.is_active,
      });
    });
    var newData = data.count / 10;
    count = Math.ceil(newData);
  }

  const [page, setPage] = useState(1);
  const PER_PAGE = 10;

  const _DATA = usePagination(tableData, count, PER_PAGE);

  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
    setPageId(p);
  };
  return (
    <>
      <Box className="table-design-background">
        <Grid container>
          <Grid item xs={6}>
            <Typography className="dashboard-home-page-text">
              Event Lists
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Link to="/dashboard/add/events" style={{ textDecoration: "none" }}>
              <EventAddButton />
            </Link>
          </Grid>
        </Grid>
        <Box style={{ padding: "1.5rem" }}>
          <BasicTable
            headers={headers}
            data={tableData}
            isDelete={true}
            deleteColor={"#f50057"}
            isEdit={true}
            editColor={"#3f51b5"}
            addColor={""}
            tableName={"events"}
            deleteConfirm={deleteConfirm}
            activeSign={["Active"]}
          />
        </Box>
        <Box
          style={{
            marginTop: "20px",
            textAlign: "center",
            paddingBottom: "1rem",
            paddingLeft: "1rem",
          }}
        >
          <Stack spacing={2} style={{ textAlign: "center" }}>
            <Pagination
              count={count}
              size="small"
              page={page}
              variant="outlined"
              onChange={handleChange}
            />
          </Stack>
        </Box>
      </Box>
    </>
  );
};
export default EventDashboardPage;
