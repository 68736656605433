import { createSelector, createEntityAdapter } from "@reduxjs/toolkit";

import { apiSlice } from "./apiSlice";



const ConfirmPasswordSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        postConfirmPassword: builder.mutation({
            query: (password) => {
                return {
                  url: `/user/change-password/`,
                  method: "POST",
                  body: password,
                };
              },
              invalidatesTags: ["ChangePassword"],
        })
    })
})


export const {usePostConfirmPasswordMutation} = ConfirmPasswordSlice;