import * as React from 'react';
import Datepicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"

export default function DatePickerWithYear(props) {

    const { name, label, value, onChange } = props;

    
  
    
    const convertToDefEventPara = (name, value) => ({
        target: {
            name, value
        }
    })
  return (
    <Datepicker
        label={label}
        format="yyyy-mm-dd"
        name={name}
        value={value}
        peekNextMonth
        showMonthDropdown
        showYearDropdown
        selected={value}
        className="my-datepicker"
        onChange={date =>onChange(convertToDefEventPara(name,date))}
         />

  );
}