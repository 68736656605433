import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Box, Grid, Typography } from "@mui/material";
import Sidebar from "../../component/sidebar";
import Header from "../../component/header";
import Controls from "../forms/controls/Controls";
import { useForm, Form } from "../forms/useForm";
import { useGetChairpersonsByIdQuery } from "../../../../../api/chairpersonSlice";
import BlobToFile from "../forms/utils/blobToFile";
import { useUpdateChairpersonMutation } from "../../../../../api/chairpersonSlice";
import urlToFile from "../forms/utils/urlToFile";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCog,
  faBell,
  faSignInAlt,
  faExclamationTriangle,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

const ChairpersonEditPage = () => {
  return (
    <Grid container className="dashboard-full-background">
      <Grid item xs={2} spacing={4}>
        <Sidebar />
      </Grid>
      <Grid item xs={10}>
        <Header />
        <EditChairpersonPages />
      </Grid>
    </Grid>
  );
};

const EditChairpersonPages = () => {
  const { id } = useParams();
  const Chairpersons = useGetChairpersonsByIdQuery(id);
  const [File, setFile] = useState([]);
  const [updateChairperson, setUpdateChairperson] = useState([]);
  const [initialFValues, setInitialFValues] = useState({
    image: [],
    name: "",
    message: "",
    is_active: false,
  });
  const [SuccessMessege, setSuccesMessege] = useState(null);
  const [ErrorMessege, setErrorMessege] = useState(null);
  const history = useNavigate();

  

  useEffect(() => {
    if (Chairpersons.data) {
      if(Chairpersons.data.image)
      {
        urlToFile(Chairpersons.data.image, setFile);
      }
      setInitialFValues({
        image: File,
        name: Chairpersons.data.name,
        message: Chairpersons.data.message,
        is_active: Chairpersons.data.is_active.toString(),
      });
    }
  }, [Chairpersons.data]);

  const { values, handleImageUpload, handleInputChange } = useForm(
    initialFValues,
    true,
    false,
    true
  );

  const [editChairperson] = useUpdateChairpersonMutation();

  useEffect(() => {
    handleImageUpload("image", File);
  }, [File]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    
    var file = []
    if(values.image)
    {
       file = BlobToFile(values.image, "chairperson");
    }
    if (file.length !== 0) {
      formData.append("image", file, "chairperson.jpg");
    }
    if(values.name)
      formData.append("name", values.name);
    if(values.message)
      formData.append("message", values.message);
    if(values.is_active)
      formData.append("is_active", values.is_active);
    formData.append("id", id);
    editChairperson(formData)
      .then((res) => {
        
        setSuccesMessege("Successfully Update Chairperson Page Data.");
        setTimeout(() => {
          history("/dashboard/chairperson");
        }, [3000]);
      })
      .catch((err) => {
        setErrorMessege("Some Error Occur. Please Try Again Later.");
        setTimeout(() => {
          setErrorMessege(null);
        }, [3000]);
      });
  };

  return (
    <Box className="table-design-background">
      <Typography className="dashboard-home-page-text">
        Update Chairperson Page
      </Typography>
      <Box style={{ padding: "1.5rem" }}>
        <Form onSubmit={handleSubmit}>
          <Grid container>
            <Grid item xs={6}>
              <Controls.Input
                name="name"
                label="Name"
                value={values.name}
                onChange={handleInputChange}
              />
              <Controls.Input
                name="message"
                label="Message"
                value={values.message}
                onChange={handleInputChange}
              />
              <Controls.RadioGroup
                name="is_active"
                label="Is Active"
                value={values.is_active.toString()}
                onChange={handleInputChange}
                items={[
                  { id: "false", title: "False" },
                  { id: "true", title: "True" },
                ]}
              />
            </Grid>
            <Grid item xs={6}>
              <Controls.Image setFile={setFile} aspectRatio={930 / 1280} />
            </Grid>
            <Grid style={{ marginTop: "12px" }}>
              <Controls.Button
                type="submit"
                text="Submit"
                backgroundColor="#1b284b"
                onClick={handleSubmit}
              />
            </Grid>
          </Grid>
        </Form>
        {SuccessMessege === null ? null : (
          <Box className="successmessege">
            <Typography className="messegefonts">
              <FontAwesomeIcon icon={faCheckCircle} className="messegeicon" />
              {SuccessMessege}
            </Typography>
          </Box>
        )}
        {ErrorMessege === null ? null : (
          <Box className="errormessege">
            <Typography className="messegefonts">
              <FontAwesomeIcon
                icon={faExclamationTriangle}
                className="messegeicon"
              />
              {ErrorMessege}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default ChairpersonEditPage;
