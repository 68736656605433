import {
  createSelector,
  createEntityAdapter
} from "@reduxjs/toolkit";

import { apiSlice } from "./apiSlice";

const executiveBodiessAdapter = createEntityAdapter()

const initialState = executiveBodiessAdapter.getInitialState()

export const executiveBodiesSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
      getExecutiveBodiess: builder.query({
          query: () => '/basic/council-members/',
          transformResponse: responseData => {
              return executiveBodiessAdapter.setAll(initialState, responseData)
          },
          providesTags: (result, error, arg) => 
          [
              { type: 'ExecutiveBodiess', id: "LIST" },
              ...result.ids.map(id => ({ type: 'ExecutiveBodiess', id }))
          ]
      }),
      getActiveExecutiveBodiess: builder.query({
          query: () => '/basic/council-members/?is_active=True',
          transformResponse: responseData => {
              return executiveBodiessAdapter.setAll(initialState, responseData)
          },
          providesTags: (result, error, arg) => 
          [
              { type: 'ExecutiveBodiess', id: "LIST" },
              ...result.ids.map(id => ({ type: 'ExecutiveBodiess', id }))
          ]
      }),
      
      getExecutiveBodiessById: builder.query({
          query: (id)=>({
              url:`/basic/council-members/${id}/`,
              method: 'GET',
          }),
          providesTags: ['ExecutiveBodiess']
      }),
      addExecutiveBodies: builder.mutation({
          query: (executiveBodies) => {
          return{
              url: '/basic/council-members/',
              method: 'POST',
              body: executiveBodies
          }
      },
          invalidatesTags: ['ExecutiveBodiess']
      }),
      updateExecutiveBodies: builder.mutation({
         
          query: (updateExecutiveBodies) => {
              return{
              url: `/basic/council-members/${updateExecutiveBodies.get('id')}/`,
              method: 'PATCH',
              body: updateExecutiveBodies
              }
          },
          invalidatesTags: ['ExecutiveBodiess']
      }),
      deleteExecutiveBodies: builder.mutation({
          query: ({ id }) => {
              return{
              url: `/basic/council-members/${id}/`,
              method: 'DELETE',
              body: id
          }},
          invalidatesTags: ['ExecutiveBodiess']
      }),
  })
})

export const {
  useGetExecutiveBodiessQuery,
  useAddExecutiveBodiesMutation,
  useUpdateExecutiveBodiesMutation,
  useDeleteExecutiveBodiesMutation,
  useGetExecutiveBodiessByIdQuery,
  useGetActiveExecutiveBodiessQuery,
} = executiveBodiesSlice

// returns the query result object
export const selectExecutiveBodiessResult = executiveBodiesSlice.endpoints.getExecutiveBodiess.select()

// Creates memoized selector
const selectExecutiveBodiesssData = createSelector(
  selectExecutiveBodiessResult,
  executiveBodiessResult => executiveBodiessResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
  selectAll: selectAllExecutiveBodiess,
  selectById: selectExecutiveBodiesById,
  selectIds: selectIds
  // Pass in a selector that returns the posts slice of state
} = executiveBodiessAdapter.getSelectors(state => selectExecutiveBodiesssData(state) ?? initialState)
