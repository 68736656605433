import {
    createSelector,
    createEntityAdapter
} from "@reduxjs/toolkit";

import { apiSlice } from "./apiSlice";

const memberAPI = '/basic/membership-year/';

const membershipPeriodAdapter = createEntityAdapter()

const initialState = membershipPeriodAdapter.getInitialState()

export const membershipPeriodSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getMembershipPeriods: builder.query({
            query: () => memberAPI,
            transformResponse: responseData => {
                return membershipPeriodAdapter.setAll(initialState, responseData)
            },
            providesTags: (result, error, arg) => 
            [
                { type: 'MembershipPeriods', id: "LIST" },
                ...result.ids.map(id => ({ type: 'MembershipPeriods', id }))
            ]
        }),
        getActiveMembershipPeriods: builder.query({
            query: () => `${memberAPI}?confirmation=True`,
            transformResponse: responseData => {
                return membershipPeriodAdapter.setAll(initialState, responseData)
            },
            providesTags: (result, error, arg) => 
            [
                { type: 'MembershipPeriods', id: "LIST" },
                ...result.ids.map(id => ({ type: 'MembershipPeriods', id }))
            ]
        }),
        getCurrentMembershipPeriods: builder.query({
            query: () => `${memberAPI}?is_current=True`,
            transformResponse: responseData => {
                return membershipPeriodAdapter.setAll(initialState, responseData)
            },
            providesTags: (result, error, arg) => 
            [
                { type: 'MembershipPeriods', id: "LIST" },
                ...result.ids.map(id => ({ type: 'MembershipPeriods', id }))
            ]
        }),
        getFounderMembershipPeriods: builder.query({
            query: () => `${memberAPI}?is_founder=True`,
            transformResponse: responseData => {
                return membershipPeriodAdapter.setAll(initialState, responseData)
            },
            providesTags: (result, error, arg) => 
            [
                { type: 'MembershipPeriods', id: "LIST" },
                ...result.ids.map(id => ({ type: 'MembershipPeriods', id }))
            ]
        }),

        getMembershipPeriodsById: builder.query({
            query: (id)=>({
                url:`${memberAPI}${id}/`,
                method: 'GET',
            }),
            providesTags: ['MembershipPeriods']
        }),
        addMembershipPeriod: builder.mutation({
            query: (membershipPeriod) => {
            return{
                url: `${memberAPI}`,
                method: 'POST',
                body: membershipPeriod
            }
        },
            invalidatesTags: ['MembershipPeriods']
        }),
        updateMembershipPeriod: builder.mutation({
           
            query: (updateMembershipPeriod) => {
                return{
                url: `${memberAPI}${updateMembershipPeriod.get('id')}/`,
                method: 'PATCH',
                body: updateMembershipPeriod
                }
            },
            invalidatesTags: ['MembershipPeriods']
        }),
        deleteMembershipPeriod: builder.mutation({
            query: ({ id }) => {
                return{
                url: `${memberAPI}${id}/`,
                method: 'DELETE',
                body: id
            }},
            invalidatesTags: ['MembershipPeriods']
        }),
    })
})

export const {
    useGetMembershipPeriodsQuery,
    useGetActiveMembershipPeriodsQuery,
    useGetCurrentMembershipPeriodsQuery,
    useGetFounderMembershipPeriodsQuery,
    useAddMembershipPeriodMutation,
    useUpdateMembershipPeriodMutation,
    useDeleteMembershipPeriodMutation,
    useGetMembershipPeriodsByIdQuery
} = membershipPeriodSlice

// returns the query result object
export const selectMembershipPeriodsResult = membershipPeriodSlice.endpoints.getMembershipPeriods.select()

// Creates memoized selector
const selectMembershipPeriodssData = createSelector(
    selectMembershipPeriodsResult,
    membershipPeriodsResult => membershipPeriodsResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllMembershipPeriods,
    selectById: selectMembershipPeriodById,
    selectIds: selectIds
    // Pass in a selector that returns the posts slice of state
} = membershipPeriodAdapter.getSelectors(state => selectMembershipPeriodssData(state) ?? initialState)