import React from 'react';
import { Document, Page, pdfjs } from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function PDFViewer({ pdfUrl }) {
  return (
    <Document file={pdfUrl} style={{width:"100%"}}>
      <Page pageNumber={1} />
    </Document>
  );
}

export default PDFViewer;