import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Grid, Typography } from "@mui/material";
import Sidebar from "../../component/sidebar";
import Header from "../../component/header";
import Controls from "../forms/controls/Controls";
import { useForm, Form } from "../forms/useForm";
import { useGetAboutPagesByIdQuery } from "../../../../../api/aboutPageSlice";
import BlobToFile from "../forms/utils/blobToFile";
import { useUpdateAboutPageMutation } from "../../../../../api/aboutPageSlice";
import urlToFile from "../forms/utils/urlToFile";
import { Box } from "@mui/system";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExclamationTriangle,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import {
  useGetBlogsByIdQuery,
  useUpdateBlogMutation,
} from "../../../../../api/blogSlice";
import {
  useAddLFNoMutation,
  useGetLFNoByIdQuery,
  useUpdateLFNoPageMutation,
} from "../../../../../api/lfNoSlice";

const AddingLfNumber = () => {
  return (
    <Grid container className="dashboard-full-background">
      <Grid item xs={2} spacing={4}>
        <Sidebar />
      </Grid>
      <Grid item xs={10}>
        <Header />
        <EditEventPagePages />
      </Grid>
    </Grid>
  );
};

const EditEventPagePages = () => {
  const [SuccessMessege, setSuccesMessege] = useState(null);
  const [ErrorMessege, setErrorMessege] = useState(null);
  const initialFValues = {
    member_name: "",
    lf_no: "",
    nmsra_no: "",
    company_name: "",
  };

  const history = useNavigate();

  const { values, handleInputChange } = useForm(
    initialFValues,
    true,
    false,
    false
  );



  const [addLFNoPage] = useAddLFNoMutation();

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("member_name", values.member_name);
    formData.append("lf_no", values.lf_no);
    formData.append("nmsra_no", values.nmsra_no);
    formData.append("company_name", values.company_name);
    addLFNoPage(formData)
      .then((res) => {
        if (res.error) {
          setErrorMessege(res.error.data.error);
          setTimeout(() => {
            setErrorMessege(null);
          }, [3000]);
        } else {
          setSuccesMessege("Successfully Added Lf Number.");
          setTimeout(() => {
            history("/dashboard/lf/number");
          }, [3000]);
        }
      })
      .catch((err) => {
        setErrorMessege("Some Error Occur. Please Try Again Later.");
        setTimeout(() => {
          setErrorMessege(null);
        }, [3000]);
      });
  };

  return (
    <Box className="table-design-background">
      <Typography className="dashboard-home-page-text">
        Adding LF Number
      </Typography>
      <Box style={{ padding: "1.5rem" }}>
        <Form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Controls.Input
                name="member_name"
                label="Member Name"
                value={values.member_name}
                onChange={handleInputChange}
              />
              <Controls.Input
                name="lf_no"
                label="LF Number"
                value={values.lf_no}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={6}>
              <Controls.Input
                name="nmsra_no"
                label="Membership Number"
                value={values.nmsra_no}
                onChange={handleInputChange}
              />
              <Controls.Input
                name="company_name"
                label="Company Name"
                value={values.company_name}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid>
              <Box style={{ marginLeft: "1.3rem" }}>
                <Controls.Button
                  type="submit"
                  text="Submit"
                  backgroundColor="#1b284b"
                  onClick={handleSubmit}
                />
              </Box>
            </Grid>
          </Grid>
        </Form>
        {SuccessMessege === null ? null : (
          <Box className="successmessege">
            <Typography className="messegefonts">
              <FontAwesomeIcon icon={faCheckCircle} className="messegeicon" />
              {SuccessMessege}
            </Typography>
          </Box>
        )}
        {ErrorMessege === null ? null : (
          <Box className="errormessege">
            <Typography className="messegefonts">
              <FontAwesomeIcon
                icon={faExclamationTriangle}
                className="messegeicon"
              />
              {ErrorMessege}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default AddingLfNumber;
