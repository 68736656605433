import { createSelector, createEntityAdapter } from "@reduxjs/toolkit";

import { apiSlice } from "./apiSlice";

const activitiesAdapter = createEntityAdapter();

const initialState = activitiesAdapter.getInitialState();

const AcitivitySlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getActivitiesNormal: builder.query({
      query: () => "/blog/activities/?activity_types=activity",
      transformResponse: (responseData) => {
        let returnResponse = [];
        responseData.map((i) => {
          let newData = new Date(i.created_at);

          returnResponse.push({ ...i, created_at: newData.toDateString() });
        });

        return activitiesAdapter.setAll(initialState, returnResponse);
      },
      providesTags: (result, error, arg) => [
        { type: "Activities", id: "LIST" },
        ...result.ids.map((id) => ({ type: "Activities", id })),
      ],
    }),
    getActivitiesOther: builder.query({
      query: () => "/blog/activities/?activity_types=campaigns",
      transformResponse: (responseData) => {
        let returnResponse = [];
        responseData.map((i) => {
          let newData = new Date(i.created_at);

          returnResponse.push({ ...i, created_at: newData.toDateString() });
        });

        return activitiesAdapter.setAll(initialState, returnResponse);
      },
      providesTags: (result, error, arg) => [
        { type: "Activities", id: "LIST" },
        ...result.ids.map((id) => ({ type: "Activities", id })),
      ],
    }),
    getAllActivity: builder.query({
      query: () => "/blog/activities/",
      transformResponse: (responseData) => {
        let returnResponse = [];
        responseData.map((i) => {
          let newData = new Date(i.created_at);

          returnResponse.push({ ...i, created_at: newData.toDateString() });
        });

        return activitiesAdapter.setAll(initialState, returnResponse);
      },
      providesTags: (result, error, arg) => [
        { type: "Activities", id: "LIST" },
        ...result.ids.map((id) => ({ type: "Activities", id })),
      ],
    }),
    deleteActivity: builder.mutation({
      query: ({ id }) => {
        return {
          url: `/blog/activities/${id}/`,
          method: "DELETE",
          body: id,
        };
      },
      invalidatesTags: ["Activities"],
    }),
    addActivity: builder.mutation({
      query: (data) => {
        return {
          url: `/blog/activities/`,
          method: "post",
          body: data,
        };
      },
      invalidatesTags: ["Activities"],
    }),
    getActivityById: builder.query({
      query: (id) => ({
        url: `/blog/activities/${id}/`,
        method: "GET",
      }),
      providesTags: ["Activities"],
    }),
    getActivityPagination: builder.query({
      query: (id) => ({
        url: `/blog/activities-pagination/?page=${id}`,
        method: "GET",
      }),
      providesTags: ["Activities"],
    }),
    updateActivityPage: builder.mutation({
      query: (updateActivityPage) => {
        return {
          url: `/blog/activities/${updateActivityPage.get("id")}/`,
          method: "PATCH",
          body: updateActivityPage,
        };
      },
      invalidatesTags: ["Activities"],
    }),
  }),
});
export const {
  useGetActivitiesNormalQuery,
  useGetActivitiesOtherQuery,
  useGetAllActivityQuery,
  useDeleteActivityMutation,
  useAddActivityMutation,
  useGetActivityByIdQuery,
  useUpdateActivityPageMutation,
  useGetActivityPaginationQuery,
} = AcitivitySlice;
