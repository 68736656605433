import React, { useEffect, useState } from "react";
import Sidebar from "../../component/sidebar";
import {
  Box,
  Grid,
  Modal,
  Pagination,
  Stack,
  Typography,
} from "@mui/material";
import Header from "../../component/header";
import BasicTable from "../tables/BasicTable";

import {
  useGetUsersQuery,
  useDeleteUserMutation,
  useGetActiveUsersPaginationQuery,
  useDeleteUserDataMutation,
} from "../../../../../api/userSlice";

import Controls from "../forms/controls/Controls";
import usePagination from "../../../../../components/reuseable/pagination";
import { useUpdateMemberRegistrationMutation } from "../../../../../api/memberRegistrationSlice";
import { useSearchMemberNumberMutation } from "../../../../../api/searchApiSlice";
import { useForm1 } from "../../../../../components/forms/useForm";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  // border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  borderRadius: 5,
};

const DashboardUsers = () => {
  return (
    <Grid container className="dashboard-full-background">
      <Grid item xs={2} spacing={4}>
        <Sidebar />
      </Grid>
      <Grid item xs={10}>
        <Header />
        <DashboardUsersPages />
      </Grid>
    </Grid>
  );
};

const DashboardUsersPages = () => {
  const pageNo = useLocation();
  const paramPairs = decodeURIComponent(pageNo.search.substr(1));
  const paramPairs1 = paramPairs.split('=');
  
  const pageNumber = paramPairs1[1];

  const history = useNavigate();

  const [pageId, setPageId] = useState();
  useEffect(() => {
    if (pageNumber !== undefined) {
      setPageId(pageNumber)
    } else {
      setPageId(1)
    }
    if(paramPairs1[2] !== undefined)
    {
      setFilterValue(paramPairs1[2])
    }
    else{
      setFilterValue('active')
    }

  }, [paramPairs1])
  // const { data, isLoading, isSuccess } = useGetUsersPaginationQuery(pageId);

  const [filterValue, setFilterValue] = useState("active");

  const [open1, setOpen1] = useState(false);

  const { data, isLoading, isSuccess } = useGetActiveUsersPaginationQuery({
    id: pageId,
    confirmation: filterValue === "active" ? true : false,
  });

  

  const activeUsers = useGetUsersQuery();
  const [Remarks, setRemarks] = useState("");

  const [deleteUser] = useDeleteUserMutation();
  const [deleteAllUserData] = useDeleteUserDataMutation();

  const deleteConfirm = (e, id) => {
    e.preventDefault();
    deleteUser({ id })
      .then((res) => {
        if (res) {
          let UserId = res.data.user.id;
          deleteAllUserData(UserId)
        }
      })
  };

  let headers = [];
  let tableData = [];
  let content;

  var count;
  if (isLoading) {
    content = <p>"Loading..."</p>;
  } else if (isSuccess) {
    headers = ["Full Name", "Email", "Phone No", "Company Name", "Active"];
    data.results.forEach((key) => {
      if (key.user.middle_name === null || key.user.middle_name === "null") {
        tableData.push({
          "Full Name": key.user.first_name + " " + key.user.last_name,
          Email: key.user.email,
          id: key.id,
          "Phone No": key.contact_no,
          "Company Name": key.name_of_company,
          Active: key.confirmation,
          Confirmation: key.confirmation,
          Remarks: key.remarks
        });
      } else {
        tableData.push({
          "Full Name":
            key.user.first_name +
            " " +
            key.user.middle_name +
            " " +
            key.user.last_name,

          Email: key.user.email,
          id: key.id,
          "Company Name": key.name_of_company,
          "Phone No": key.contact_no,
          Active: key.confirmation,
          Confirmation: key.confirmation,
          Remarks: key.remarks
        });
      }
    });
    var newData = data.count / 10;
    count = Math.ceil(newData);
  }

  const [page, setPage] = useState(1);
  const PER_PAGE = 10;

  const _DATA = usePagination(tableData, count, PER_PAGE);

  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
    // setPageId(p);
    history(`/dashboard/users?pageNumber=${p}&status=${filterValue}`)
  };

  const onFilterChange = (e) => {
    setFilterValue(e.target.value);
    history(`/dashboard/users?pageNumber=${page}&status=${e.target.value}`)
  };

  const [UpdateData] = useUpdateMemberRegistrationMutation();


  const [open, setOpen] = useState(false);

  const handleClose = () => setOpen(false);

  const [status, setStatus] = useState({});


  const confirmationTable = (e, id) => {
    setStatus({ 'id': id, 'status': e.target.value });
    setOpen(true)
  }

  const handleRemarks = (e, id) => {

    const formData = new FormData();
    formData.append("id", id);
    formData.append("remarks", Remarks)
    formData.append("confirmation", activeUsers.data.entities[id].confirmation);
    formData.append("email", activeUsers.data.entities[id].user.email);
    formData.append("last_name", activeUsers.data.entities[id].user.last_name);
    formData.append("academic_degree", activeUsers.data.entities[id].academic_degree);
    formData.append("academic_faculty", activeUsers.data.entities[id].academic_faculty);
    formData.append("blood_group", activeUsers.data.entities[id].blood_group);
    formData.append("contact_no", activeUsers.data.entities[id].contact_no);
    formData.append("current_position", activeUsers.data.entities[id].current_position);
    formData.append("date_of_joining", activeUsers.data.entities[id].date_of_joining);
    formData.append("division", activeUsers.data.entities[id].division)
    formData.append("emergency_contact", activeUsers.data.entities[id].emergency_contact);
    formData.append("name_of_company", activeUsers.data.entities[id].name_of_company);
    formData.append("date_of_joining", activeUsers.data.entities[id].date_of_joining);
    formData.append("permanent_country", activeUsers.data.entities[id].permanent_country);
    formData.append("permanent_district", activeUsers.data.entities[id].permanent_district);
    formData.append("permanent_local_level", activeUsers.data.entities[id].permanent_local_level);
    formData.append("permanent_street", activeUsers.data.entities[id].permanent_street);
    formData.append("permanent_ward_no", activeUsers.data.entities[id].permanent_ward_no);
    formData.append("temporary_district", activeUsers.data.entities[id].temporary_district);
    formData.append("temporary_local_level", activeUsers.data.entities[id].temporary_local_level);
    formData.append("temporary_street", activeUsers.data.entities[id].temporary_street);
    formData.append("temporary_ward_no", activeUsers.data.entities[id].temporary_ward_no);
    formData.append("territory", activeUsers.data.entities[id].territory);
    formData.append("lf_no", activeUsers.data.entities[id].user.lf_no);
    formData.append("nmsra_no", activeUsers.data.entities[id].user.nmsra_no);
    formData.append("profile_pic", activeUsers.data.entities[id].user.profile_pic);
    formData.append("academic_transcript", activeUsers.data.entities[id].academic_transcript);
    formData.append("appointment_letter", activeUsers.data.entities[id].appointment_letter);
    formData.append(
      "middle_name",
      activeUsers.data.entities[id].user.middle_name
    );
    formData.append(
      "first_name",
      activeUsers.data.entities[id].user.first_name
    );

    UpdateData(formData).then((res) => {
      setOpen1(false);
    });
  }


  const changeStatus = () => {

    const formData = new FormData();
    formData.append("id", status['id']);
    formData.append("confirmation", status['status']);
    formData.append("email", activeUsers.data.entities[status['id']].user.email);
    formData.append("last_name", activeUsers.data.entities[status['id']].user.last_name);
    formData.append("academic_degree", activeUsers.data.entities[status['id']].academic_degree);
    formData.append("academic_faculty", activeUsers.data.entities[status['id']].academic_faculty);
    formData.append("blood_group", activeUsers.data.entities[status['id']].blood_group);
    formData.append("contact_no", activeUsers.data.entities[status['id']].contact_no);
    formData.append("current_position", activeUsers.data.entities[status['id']].current_position);
    formData.append("date_of_joining", activeUsers.data.entities[status['id']].date_of_joining);
    formData.append("division", activeUsers.data.entities[status['id']].division)
    formData.append("emergency_contact", activeUsers.data.entities[status['id']].emergency_contact);
    formData.append("name_of_company", activeUsers.data.entities[status['id']].name_of_company);
    formData.append("date_of_joining", activeUsers.data.entities[status['id']].date_of_joining);
    formData.append("permanent_country", activeUsers.data.entities[status['id']].permanent_country);
    formData.append("permanent_district", activeUsers.data.entities[status['id']].permanent_district);
    formData.append("permanent_local_level", activeUsers.data.entities[status['id']].permanent_local_level);
    formData.append("permanent_street", activeUsers.data.entities[status['id']].permanent_street);
    formData.append("permanent_ward_no", activeUsers.data.entities[status['id']].permanent_ward_no);
    formData.append("temporary_district", activeUsers.data.entities[status['id']].temporary_district);
    formData.append("temporary_local_level", activeUsers.data.entities[status['id']].temporary_local_level);
    formData.append("temporary_street", activeUsers.data.entities[status['id']].temporary_street);
    formData.append("temporary_ward_no", activeUsers.data.entities[status['id']].temporary_ward_no);
    formData.append("territory", activeUsers.data.entities[status['id']].territory);
    formData.append("lf_no", activeUsers.data.entities[status['id']].user.lf_no);
    formData.append("nmsra_no", activeUsers.data.entities[status['id']].user.nmsra_no);
    formData.append("profile_pic", activeUsers.data.entities[status['id']].user.profile_pic);
    formData.append("academic_transcript", activeUsers.data.entities[status['id']].academic_transcript);
    formData.append("appointment_letter", activeUsers.data.entities[status['id']].appointment_letter);
    formData.append(
      "middle_name",
      activeUsers.data.entities[status['id']].user.middle_name
    );
    formData.append(
      "first_name",
      activeUsers.data.entities[status['id']].user.first_name
    );
    formData.append(
      "remarks",
      activeUsers.data.entities[status['id']].remarks
    );

    UpdateData(formData).then((res) => {
      if (status['status']) {
        setFilterValue("active");
        setOpen(false)
      } else {
        setFilterValue("inactive");
        setOpen(false);
      }
    });
  }
  //search data

  const [Member, results] = useSearchMemberNumberMutation();


  const initialFValues = {
    search: "",
  };
  const { values, handleSearchClick } = useForm1(initialFValues);

  useEffect(() => {
    Member(values);
  }, [values]);

  

  //end of data
  let headers1 = [];
  let tableData1 = [];
  let content1;

  // var count;
  if (results.isLoading) {
    content1 = <p>"Loading..."</p>;
  } else if (results.isSuccess) {
    headers1 = ["Full Name", "Email", "Phone No", "Company Name", "Active", ''];
    results.data.forEach((key) => {
      // if (key.user.middle_name == null) {
      //   setMiddleNameNull("");
      // } else {
      //   setMiddleNameNull(key.user.middle_name);
      // }

      if (key.user.middle_name === null || key.user.middle_name === "null") {
        tableData1.push({
          "Full Name": key.user.first_name + " " + key.user.last_name,
          Email: key.user.email,
          id: key.id,
          "Phone No": key.contact_no,
          "Company Name": key.name_of_company,
          Active: key.confirmation,
          Confirmation: key.confirmation,
          Remarks: key.remarks
        });
      } else {
        tableData1.push({
          "Full Name":
            key.user.first_name +
            " " +
            key.user.middle_name +
            " " +
            key.user.last_name,

          Email: key.user.email,
          id: key.id,
          "Company Name": key.name_of_company,
          "Phone No": key.contact_no,
          Active: key.confirmation,
          Confirmation: key.confirmation,
          Remarks: key.remarks
        });
      }
    });
  }




  return (
    <>
      <Box className="table-design-background">
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography
              id="modal-modal-title"
              className="modal-text-design"
              variant="h6"
              component="h2"
            >
              Do you want to change the status of this user?
            </Typography>
            <button
              className="yes-button"
              onClick={changeStatus}
            >
              Yes
            </button>
            <button className="no-button" onClick={handleClose}>
              No
            </button>
          </Box>
        </Modal>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Typography className="dashboard-home-page-text">
              User Lists
            </Typography>
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={3}>
            <Box style={{ marginTop: "12px" }}>
              <Controls.Search
                style={{ width: "100%" }}
                value={values.search}
                name="search"
                label="Search by NMSRA or LF Number"
                onChange={handleSearchClick}
              />
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box style={{ padding: "1rem" }}>
              <Controls.Select
                value={filterValue}
                onChange={(e) => onFilterChange(e)}
                options={[
                  { id: "active", title: "Active" },
                  { id: "inactive", title: "Inactive" },
                ]}
              />
            </Box>
          </Grid>
        </Grid>
        {values.search === "" ? (
          <>
            <Box style={{ padding: "1rem" }}>
              <BasicTable
                isCard={true}
                headers={headers}
                data={tableData}
                isDelete={true}
                deleteColor={"#f50057"}
                isEdit={true}
                editColor={"#3f51b5"}
                addColor={""}
                tableName={"users"}
                deleteConfirm={deleteConfirm}
                activeSign={["Active"]}
                cardAvailable={true}
                selectOption={[
                  {
                    header: "Confirmation",
                    options: [
                      { id: true, title: "Active" },
                      { id: false, title: "Inactive" },
                    ],
                    onChange: confirmationTable,
                    name: "confirmation",
                  },
                ]}
                remarksOption={{
                  isRemarksShow: true,
                  isRemarksAdd: true,
                  remarksSet: setRemarks,
                  onModalClick: handleRemarks,
                  setOpen1: setOpen1,
                  open1: open1
                }

                }
                member={true}
              />
            </Box>
            <Box
              style={{
                marginTop: "10px",
                textAlign: "center",
                paddingBottom: "1rem",
                paddingLeft: "1rem",
              }}
            >
              <Stack spacing={2} style={{ textAlign: "center" }}>
                <Pagination
                  count={count}
                  size="small"
                  page={parseInt(pageNumber)}
                  variant="outlined"
                  onChange={handleChange}
                  color="primary"
                />
              </Stack>
            </Box>
          </>
        ) : (
          <>
            <Box style={{ padding: "1rem" }}>
              <BasicTable
                headers={headers1}
                data={tableData1}
                isDelete={true}
                deleteColor={"#f50057"}
                isEdit={true}
                editColor={"#3f51b5"}
                addColor={""}
                tableName={"users"}
                deleteConfirm={deleteConfirm}
                activeSign={["Active"]}
                cardAvailable={true}
                selectOption={[
                  {
                    header: "Confirmation",
                    options: [
                      { id: true, title: "Active" },
                      { id: false, title: "Inactive" },
                    ],
                    onChange: confirmationTable,
                    name: "confirmation",
                  },
                ]}
                remarksOption={{
                  isRemarksShow: true,
                  isRemarksAdd: true,
                  remarksSet: setRemarks,
                  onModalClick: handleRemarks
                }}
                member={true}
              />
            </Box>
          </>
        )}
      </Box>
    </>
  );
};
export default DashboardUsers;
