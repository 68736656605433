import React, { useState, useEffect } from "react";
import {
  Grid,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import Sidebar from "../../component/sidebar";
import Header from "../../component/header";
import Controls from "../forms/controls/Controls";
import { useForm, Form } from "../forms/useForm";
import {
  useGetHospitalByIdQuery,
  useGetHospitalsQuery,
} from "../../../../../api/hospitalSlice";
import { useGetActiveHospitalHolidaysQuery } from "../../../../../api/hospitalHolidaySlice";
import {
  useDeleteHospitalBookingMutation,
  useGetHospitalBookingsByHospitalDateIdMutation,
  usePatchHospitalBookingMutation
} from "../../../../../api/hospitalBookingSlice";
import { useCreateUpdateCardDetailsMutation } from "../../../../../api/cardDetailSlice";
import { use } from "../../../../../api/cardDetailSlice";
import { Box } from "@mui/system";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExclamationTriangle,
  faCheckCircle,
  faTrashAlt,
  faEdit,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";
import * as XLSX from 'xlsx';
import { Link } from "react-router-dom";
import Cookies from "js-cookie";
import AdminBookingsAddButton from "../adminHotelBookings/adminBookingsAddButton";


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "white",
  // border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  borderRadius: 5,
};



const EditBooking = () => {
  return (
    <Grid container className="dashboard-full-background">
      <Grid item xs={2} spacing={4}>
        <Sidebar />
      </Grid>
      <Grid item xs={10}>
        <Header />
        <EditBookingPages />
      </Grid>
    </Grid>
  );
};

const EditBookingPages = () => {
  const options = [];
  const [hospitalID, setHospitalID] = useState(null);
  const [selectedDates, setSelectedDates] = useState(new Date());
  const [delete_reason, setDeleteReason] = useState("");

  const HospitalList = useGetHospitalsQuery();
  const hospitalHoliday = useGetActiveHospitalHolidaysQuery(hospitalID);
  const [HospitalBookings] = useGetHospitalBookingsByHospitalDateIdMutation();

  const [disableDates, setDisableDates] = useState([]);
  const [bookingList, setBookingList] = useState({
    data: [],
    status: "initial",
  });



  if (HospitalList.status == "fulfilled") {
    HospitalList.data.ids.map((key) => {
      options.push({ id: key, title: HospitalList.data.entities[key].name });
    });
  }

  const [deleteData] = useDeleteHospitalBookingMutation('HospitalBookings');

  useEffect(() => {
    if (hospitalHoliday.status === "fulfilled") {
      setDisableDates(hospitalHoliday.data.date);
    }
  }, [hospitalHoliday, hospitalID]);

  const [SuccessMessege, setSuccesMessege] = useState(null);
  const [ErrorMessege, setErrorMessege] = useState(null);

  const onHospitalSelect = (e) => {
    setHospitalID(e.target.value);
  };

  const onDateSelect = (e) => {
    setSelectedDates(e.target.value);
    HospitalBookings({ hospital_id: hospitalID, date: e.target.value })
      .then((res) => {
        setBookingList(res.data);

        if (res.data.data.length !== 0) {
          setSuccesMessege('All Booking List.')
          setTimeout(() => {
            setSuccesMessege(null)
          }, [3000])
        } else {
          setErrorMessege('There is no Booking on this date.')
          setTimeout(() => {
            setErrorMessege(null)
          }, [3000])
        }
      })
      .catch((err) => { });
  };

  const exportToExcel = () => {
    const workbook = XLSX.utils.book_new();
    const data = bookingList.data.map((key, index) => {
      return {
        'S.No.': index + 1,
        'MR Name': key.mr_id.user.first_name + ' ' + key.mr_id.user.middle_name + ' ' + key.mr_id.user.last_name,
        'Contact No': key.mr_id.contact_no,
        'Email': key.mr_id.user.email,
        'Company Name': key.mr_id.name_of_company,
        'Manager No': key.accompanist_number,
        'Card No': key.card_no,
        'Booking Date': key.hospital_date_id.date,
        'Hospital Name': key.hospital_date_id.hospital.name,
      }
    })
    const worksheet = XLSX.utils.json_to_sheet(data);
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, 'bookingList.xlsx');
  };

  const [open, setOpen] = useState(false);
  const [thisId, setThisId] = useState(null);

  const [open1, setOpen1] = useState(false);

  const handleOpen = (e, id) => {
    setOpen(true);
    setThisId(id);
  };

  const [BookingUpdate] = usePatchHospitalBookingMutation();
  const [createUpdateCardDetails] = useCreateUpdateCardDetailsMutation();

  const [cardId, setCardId] = useState('');
  const handleClose = () => setOpen(false);
  const handleOpen1 = (e, key) => {
    setOpen1(true);
    setCardId(key.id);

  };

  const handleClose1 = () => setOpen1(false);

  const initialFValues = {
    card_no: "",
    return_date: ""
  };
  const { values, handleInputChange } = useForm(
    initialFValues,
    true,
    false,
    false
  );



  const deleteBookingList = (e, id) => {
    e.preventDefault();
    deleteData({ id: id, delete_reason: delete_reason })
      .then((res) => {
        if (res.data == null) {
          setSuccesMessege(res.data.data)
          setTimeout(() => {
            window.location.reload();
          }, [3000])
        } else {
          setErrorMessege(res.error.data.data);
          setTimeout(() => {
            setErrorMessege(null);
          }, [3000])
        }
      })
    setOpen(false);
    // window.location.reload();

  };

  const updateCardNumber = (e) => {
    e.preventDefault();
    const data = {
      id: cardId,
      patchValue: { card_no: values.card_no },
    }
    BookingUpdate(data)
      .then((res) => {
        if (res.error) {
          setErrorMessege(res.error.data.detail)
          setOpen1(false)
          setTimeout(() => {
            setErrorMessege(null)
          }, [3000])
        } else {
          setSuccesMessege('Card Number Successfully Added.')
          setOpen1(false)
          setTimeout(() => {
            setSuccesMessege(null);
            // window.location.reload();
          }, [3000])
        }
      })

    createUpdateCardDetails({
      'card_no': values.card_no,
      'returning_date': values.return_date,
      'bookings_data': cardId
    })

  }


  const approveBtn = (e, key) => {
    e.preventDefault();
    const data = {
      id: key.id,
      patchValue: { is_visited: true },
    }
    BookingUpdate(data)
      .then((res) => {
        if (res.error) {
          setErrorMessege(res.error.data.detail)
          setTimeout(() => {
            setErrorMessege(null)
          }, [3000])
        } else {

          setSuccesMessege('Booking Successfully Accepted.')
          setTimeout(() => {
            setSuccesMessege(null);
            // window.location.reload();
          }, [3000])
        }
      })
  }

  const admin = Cookies.get('is_admin');

  const deleteReason = (e) => {
    setDeleteReason(e.target.value);
  }


  return (
    <Box>
      <Box className="table-design-background" style={{ marginBottom: "2rem" }}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Typography className="dashboard-home-page-text">
              Update Hospital Details
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Link
              to="/dashboard/add/admin-bookings"
              style={{ textDecoration: "none" }}
            >
              <AdminBookingsAddButton />
            </Link>
          </Grid>

        </Grid>
        <Box style={{ padding: "1.5rem", paddingTop: ".5rem" }}>
          <Form>
            <Grid container spacing={3}>
              <Grid item xs={6} style={{ textAlign: "start" }}>
                <Controls.Select
                  name="name"
                  label="Hospital Name"
                  value={hospitalID}
                  onChange={onHospitalSelect}
                  options={options}
                />
              </Grid>
              <Grid item xs={6}>
                <Controls.DatePickers
                  buttonText="Select Booking Date"
                  name="date"
                  onChange={onDateSelect}
                  excludeDates={disableDates}
                  selectedDate={selectedDates}
                />
              </Grid>
            </Grid>
          </Form>
        </Box>

        {SuccessMessege === null ? null : (
          <Box className="successmessege">
            <Typography className="messegefonts">
              <FontAwesomeIcon icon={faCheckCircle} className="messegeicon" />
              {SuccessMessege}
            </Typography>
          </Box>
        )}
        {ErrorMessege === null ? null : (
          <Box className="errormessege">
            <Typography className="messegefonts">
              <FontAwesomeIcon
                icon={faExclamationTriangle}
                className="messegeicon"
              />
              {ErrorMessege}
            </Typography>
          </Box>
        )}
      </Box>
      <Box className="table-design-background" style={{ marginTop: "2.5rem" }}>
        <Grid container spacing={3}>
          <Grid item xs={10}>
            <Typography className="dashboard-home-page-text" style={{ marginTop: "-1rem" }}>
              Hospital Details
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <button className="export-xlsx-design" onClick={exportToExcel}><FontAwesomeIcon icon={faDownload} style={{ marginRight: '5px' }} />Export</button>
          </Grid>
        </Grid>
        <Grid container spacing={3} >
          <Grid item xs={12}>
            <Typography style={{ display: 'none' }}>Hospital Booking List of NMSRA</Typography>
            {bookingList.status === "fulfilled" ? (
              <>
                <TableContainer component={Paper} >
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>S.No.</TableCell>
                        <TableCell>MR Name</TableCell>
                        <TableCell>Contact No</TableCell>
                        <TableCell>Email Address</TableCell>
                        <TableCell>Company Name</TableCell>
                        <TableCell>Manager No</TableCell>
                        <TableCell>Card No</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Remark</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {bookingList.data.map((key, index) => (
                        <TableRow key={key.name}>
                          <TableCell>{index + 1}</TableCell>
                          <TableCell>
                            {key.mr_id.user.first_name}{" "}
                            {key.mr_id.user.middle_name}
                            {" "}
                            {key.mr_id.user.last_name}
                          </TableCell>
                          <TableCell>{key.mr_id.contact_no}</TableCell>
                          <TableCell>{key.mr_id.user.email}</TableCell>
                          <TableCell>{key.mr_id.name_of_company}</TableCell>
                          <TableCell>{key.accompanist_number}</TableCell>
                          <TableCell><button className="yes-button" onClick={(e) => handleOpen1(e, key)}>{key.card_no === null ? <span>Edit</span> : <span>{key.card_no}</span>}</button></TableCell>
                          {
                            key.is_visited === true ?
                              <TableCell style={{ color: "green" }}>Approved</TableCell> :
                              <TableCell><button className="yes-button" onClick={(e) => approveBtn(e, key)}>Approve</button></TableCell>
                          }
                          <TableCell style={{ paddingLeft: "2rem" }}>
                            <FontAwesomeIcon
                              icon={faTrashAlt}
                              style={{ fontSize: "20px", color: "#f50057", cursor: "pointer" }}
                              onClick={(e) => handleOpen(e, key.id)}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                {/* {bookingList.data.map((key) => (
                  <>
                    <h1> {key.mr_id.user.first_name} {key.mr_id.user.last_name}</h1>
                    <h1> {key.mr_id.name_of_company}</h1>
                  </>
                ))} */}
              </>
            ) : (
              <></>
            )}
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <Typography
                  id="modal-modal-title"
                  className="modal-text-design"
                  variant="h6"
                  component="h2"
                >
                  Do you want to delete?
                </Typography>
                <Controls.Input
                  name="delete_reason"
                  label="Cancel Reason"
                  onChange={deleteReason}
                />
                <button
                  className="yes-button"
                  onClick={(e) => deleteBookingList(e, thisId)}
                >
                  Yes
                </button>
                <button className="no-button" onClick={handleClose}>
                  No
                </button>
              </Box>
            </Modal>
            <Modal
              open={open1}
              onClose={handleClose1}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <Typography
                  id="modal-modal-title"
                  className="modal-text-design"
                  variant="h6"
                  component="h2"
                >
                  Edit Card Number
                </Typography>
                <Controls.Input
                  name="card_no"
                  label="Card Number"
                  value={values.card_no}
                  onChange={handleInputChange}
                />
                <Controls.DatePickers
                  name="return_date"
                  label="Return Date"
                  value={values.return_date}
                  onChange={handleInputChange}
                />
                <Box style={{ marginTop: '20px' }}>
                  <button
                    className="yes-button"
                    onClick={updateCardNumber}
                  >
                    Save
                  </button>
                  <button className="no-button" onClick={handleClose1}>
                    No
                  </button>
                </Box>
              </Box>
            </Modal>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default EditBooking;
