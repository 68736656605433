import {
    createSelector,
    createEntityAdapter
  } from "@reduxjs/toolkit";
  
  import { apiSlice } from "./apiSlice";
  
  const officeStaffsAdapter = createEntityAdapter()
  
  const initialState = officeStaffsAdapter.getInitialState()
  
  export const officeStaffSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getOfficeStaffs: builder.query({
            query: () => '/basic/office-staffs/',
            transformResponse: responseData => {
                return officeStaffsAdapter.setAll(initialState, responseData)
            },
            providesTags: (result, error, arg) => 
            [
                { type: 'OfficeStaffs', id: "LIST" },
                ...result.ids.map(id => ({ type: 'OfficeStaffs', id }))
            ]
        }),
        getActiveOfficeStaffs: builder.query({
            query: () => '/basic/office-staffs/?is_active=True',
            transformResponse: responseData => {
                return officeStaffsAdapter.setAll(initialState, responseData)
            },
            providesTags: (result, error, arg) => 
            [
                { type: 'OfficeStaffs', id: "LIST" },
                ...result.ids.map(id => ({ type: 'OfficeStaffs', id }))
            ]
        }),
        
        getOfficeStaffsById: builder.query({
            query: (id)=>({
                url:`/basic/office-staffs/${id}/`,
                method: 'GET',
            }),
            providesTags: ['OfficeStaffs']
        }),
        addOfficeStaff: builder.mutation({
            query: (officeStaff) => {
            return{
                url: '/basic/office-staffs/',
                method: 'POST',
                body: officeStaff
            }
        },
            invalidatesTags: ['OfficeStaffs']
        }),
        updateOfficeStaff: builder.mutation({
           
            query: (updateOfficeStaff) => {
                return{
                url: `/basic/office-staffs/${updateOfficeStaff.get('id')}/`,
                method: 'PATCH',
                body: updateOfficeStaff
                }
            },
            invalidatesTags: ['OfficeStaffs']
        }),
        deleteOfficeStaff: builder.mutation({
            query: ({ id }) => {
                return{
                url: `/basic/office-staffs/${id}/`,
                method: 'DELETE',
                body: id
            }},
            invalidatesTags: ['OfficeStaffs']
        }),
    })
  })
  
  export const {
    useGetOfficeStaffsQuery,
    useAddOfficeStaffMutation,
    useUpdateOfficeStaffMutation,
    useDeleteOfficeStaffMutation,
    useGetOfficeStaffsByIdQuery,
    useGetActiveOfficeStaffsQuery,
  } = officeStaffSlice
  
  // returns the query result object
  export const selectOfficeStaffsResult = officeStaffSlice.endpoints.getOfficeStaffs.select()
  
  // Creates memoized selector
  const selectOfficeStaffssData = createSelector(
    selectOfficeStaffsResult,
    officeStaffsResult => officeStaffsResult.data // normalized state object with ids & entities
  )
  
  //getSelectors creates these selectors and we rename them with aliases using destructuring
  export const {
    selectAll: selectAllOfficeStaffs,
    selectById: selectOfficeStaffById,
    selectIds: selectIds
    // Pass in a selector that returns the posts slice of state
  } = officeStaffsAdapter.getSelectors(state => selectOfficeStaffssData(state) ?? initialState)
  