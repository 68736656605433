import React, { useEffect, useState } from "react";
import Sidebar from "../../component/sidebar";
import Header from "../../component/header";
import { Box, Grid, Typography } from "@mui/material";
import Controls from "../forms/controls/Controls";
import { useForm, Form } from "../forms/useForm";
import { useAddWorkMutation } from "../../../../../api/workSlice";
import BlobToFile from "../forms/utils/blobToFile";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCog,
  faBell,
  faSignInAlt,
  faExclamationTriangle,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

const AddWork = () => {
  return (
    <Grid container className="dashboard-full-background">
      <Grid item xs={2} spacing={4}>
        <Sidebar />
      </Grid>
      <Grid item xs={10}>
        <Header />
        <AddWorkPages />
      </Grid>
    </Grid>
  );
};

const AddWorkPages = () => {
  const [File, setFile] = useState([]);
  const initialFValues = {
    work_name: "",
    is_active: false,
  };
  const { values, handleImageUpload, handleInputChange } = useForm(
    initialFValues,
    true,
    false,
    false
  );
  const [SuccessMessege, setSuccesMessege] = useState(null);
  const [ErrorMessege, setErrorMessege] = useState(null);
  const history = useNavigate();

  const [addWork] = useAddWorkMutation();

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    if (values.work_name) {
      formData.append("work_name", values.work_name);
    }
    if (values.is_active) {
      formData.append("is_active", values.is_active);
    }

    addWork(formData)
      .then((res) => {
        setSuccesMessege("Successfully Added Objectives Data.");
        setTimeout(() => {
          history("/dashboard/work");
        }, [3000]);
      })
      .catch((err) => {
        setErrorMessege("Some Error Occur. Please Try Again Later.");
        setTimeout(() => {
          setErrorMessege(null);
        }, [3000]);
      });
  };

  return (
    <Box className="table-design-background">
      <Typography className="dashboard-home-page-text">Add Works</Typography>
      <Box style={{ padding: "1.5rem" }}>
        <Form onSubmit={handleSubmit}>
          <Grid container>
            <Grid item xs={6}>
              <Controls.Input
                name="work_name"
                label="Work Name"
                value={values.work_name}
                onChange={handleInputChange}
              />
              <Controls.RadioGroup
                name="is_active"
                label="Is Active"
                value={values.is_active}
                onChange={handleInputChange}
                items={[
                  { id: "false", title: "False" },
                  { id: "true", title: "True" },
                ]}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid style={{ marginTop: "12px" }}>
              <Controls.Button
                type="submit"
                text="Submit"
                backgroundColor="#1b284b"
                onClick={handleSubmit}
              />
            </Grid>
          </Grid>
        </Form>
        {SuccessMessege === null ? null : (
          <Box className="successmessege">
            <Typography className="messegefonts">
              <FontAwesomeIcon icon={faCheckCircle} className="messegeicon" />
              {SuccessMessege}
            </Typography>
          </Box>
        )}
        {ErrorMessege === null ? null : (
          <Box className="errormessege">
            <Typography className="messegefonts">
              <FontAwesomeIcon
                icon={faExclamationTriangle}
                className="messegeicon"
              />
              {ErrorMessege}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};
export default AddWork;
