import React, { useEffect, useState } from "react";
import Sidebar from "../../component/sidebar";
import Header from "../../component/header";
import { Box, Grid, Typography } from "@mui/material";
import Controls from "../forms/controls/Controls";
import { useForm, Form } from "../forms/useForm";
import { useAddDoctorCallCardMutation, useAddSimCardMutation } from "../../../../../api/doctorCallCardSlice";
import BlobToFile from "../forms/utils/blobToFile";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faExclamationTriangle,
    faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { useGetMrIDByEmailMutation } from "../../../../../api/userSlice";

const AddSimCardData = () => {
    return (
        // <Grid container className="dashboard-full-background">
        //   <Grid item xs={2} spacing={4}>
        //     <Sidebar />
        //   </Grid>
        //   <Grid item xs={10}>
        //     <Header />
        //     <AddSimCardDataPages />
        //   </Grid>
        // </Grid>
        <Box className="dashboard-full-background">
            <Grid container spacing={0}>
                <Grid item md={2} xs={12}>
                    <Sidebar />
                </Grid>
                <Grid item md={10} xs={12} className="res-margin-design1">
                    <Header />
                    <AddSimCardDataPages />
                </Grid>
            </Grid>
        </Box>
    );
};

const AddSimCardDataPages = () => {
    const initialFValues = {
        company: "",
        division: "",
        joint_work_date: new Date(),
        joint_work_with: "",
        joint_work_to_date: new Date(),
    };
    const [thisMr, setThisMr] = useState(null);

    const [getMRID] = useGetMrIDByEmailMutation();

    useEffect(() => {
        getMRID(Cookies.get('user_email')).
            then((res) => {
                setThisMr(res.data.id);
            })

    }, [Cookies.get('user_email')])
    const { values, handleInputChange } = useForm(
        initialFValues,
        true,
        false,
        false
    );
    const [SuccessMessege, setSuccesMessege] = useState(null);
    const [ErrorMessege, setErrorMessege] = useState(null);
    const history = useNavigate();

    const [addDoctorCard] = useAddSimCardMutation();

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const formattedDate = formatDate(values.joint_work_date);
    const formattedDate1 = formatDate(values.joint_work_to_date);

    const handleSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append("company", values.company);
        formData.append("division", values.division);
        formData.append('joint_work_date', formattedDate);
        formData.append('joint_work_to_date', formattedDate1);
        formData.append('joint_work_with', values.joint_work_with);
        formData.append('mr', thisMr);
        formData.append('is_approved', false);
        addDoctorCard(formData)
            .then((res) => {
                if (res.data) {
                    setSuccesMessege("Successfully Added Sim Card.");
                    setTimeout(() => {
                        history("/dashboard/sim-card");
                    }, [3000]);
                } else {
                    setErrorMessege("Some Error Occur. Please Try Again Later.");
                    setTimeout(() => {
                        setErrorMessege(null);
                    }, [3000]);
                }
            })
            .catch((err) => {
                setErrorMessege("Some Error Occur. Please Try Again Later.");
                setTimeout(() => {
                    setErrorMessege(null);
                }, [3000]);
            });
    };

    return (
        <Box className="table-design-background">
            <Typography className="dashboard-home-page-text">
                Add Doctors Call Card
            </Typography>
            <Box style={{ padding: "1.5rem" }}>
                <Form onSubmit={handleSubmit}>
                    <Grid container spacing={3}>
                        <Grid item md={6} xs={12}>
                            <Controls.Input
                                name="company"
                                label="Company"
                                value={values.company}
                                onChange={handleInputChange}
                            />
                            <Controls.Input
                                name="joint_work_with"
                                label="Joint Work With"
                                value={values.joint_work_with}
                                onChange={handleInputChange}
                            />
                            {/* <Box style={{ marginTop: '15px', marginLeft: '10px' }}>
                                <Controls.Select
                                    name="call_type"
                                    label="Call Type"
                                    options={
                                        [
                                            { id: "Single", title: 'Single Call' },
                                            { id: "Joint", title: "Joint Call" }
                                        ]
                                    }
                                    value={values.call_type}
                                    onChange={handleInputChange}
                                />
                            </Box> */}
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <Box style={{ marginRight: '9px' }}>
                                <Controls.DatePickers
                                    name="joint_work_to_date"
                                    label="Joint Work Date To"
                                    value={values.joint_work_to_date}
                                    onChange={handleInputChange}
                                />
                            </Box>
                            <Box style={{ marginTop: '5px', marginRight: "9px" }}>
                                <Controls.DatePickers
                                    name="joint_work_date"
                                    label="Joint Work Date From"
                                    value={values.joint_work_date}
                                    onChange={handleInputChange}
                                />
                            </Box>
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <Box style={{ marginTop: "-25px" }}>
                                <Controls.Input
                                    name="division"
                                    label="Division"
                                    value={values.division}
                                    onChange={handleInputChange}
                                />
                            </Box>
                        </Grid>
                        <Grid item md={6} xs={12}>

                        </Grid>
                        <Grid item md={1.5} xs={6}>
                            <Controls.Button
                                type="submit"
                                text="Submit"
                                backgroundColor="#1b284b"
                                onClick={handleSubmit}
                            />
                        </Grid>
                    </Grid>
                </Form>
                {SuccessMessege === null ? null : (
                    <Box className="successmessege">
                        <Typography className="messegefonts">
                            <FontAwesomeIcon icon={faCheckCircle} className="messegeicon" />
                            {SuccessMessege}
                        </Typography>
                    </Box>
                )}
                {ErrorMessege === null ? null : (
                    <Box className="errormessege">
                        <Typography className="messegefonts">
                            <FontAwesomeIcon
                                icon={faExclamationTriangle}
                                className="messegeicon"
                            />
                            {ErrorMessege}
                        </Typography>
                    </Box>
                )}
            </Box>
        </Box>
    );
};
export default AddSimCardData;
