import {
    createSelector,
    createEntityAdapter
} from "@reduxjs/toolkit";

import { apiSlice } from "./apiSlice";

const memberAPI = '/bookings/mr-registration/';

const memberRegistrationAdapter = createEntityAdapter()

const initialState = memberRegistrationAdapter.getInitialState()

export const memberRegistrationSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getMemberRegistrations: builder.query({
            query: () => memberAPI,
            transformResponse: responseData => {
                return memberRegistrationAdapter.setAll(initialState, responseData)
            },
            providesTags: (result, error, arg) => 
            [
                { type: 'MemberRegistrations', id: "LIST" },
                ...result.ids.map(id => ({ type: 'MemberRegistrations', id }))
            ]
        }),
        getActiveMemberRegistrations: builder.query({
            query: () => `${memberAPI}?confirmation=True`,
            transformResponse: responseData => {
                return memberRegistrationAdapter.setAll(initialState, responseData)
            },
            providesTags: (result, error, arg) => 
            [
                { type: 'MemberRegistrations', id: "LIST" },
                ...result.ids.map(id => ({ type: 'MemberRegistrations', id }))
            ]
        }),
        getMemberRegistrationsById: builder.query({
            query: (id)=>({
                url:`${memberAPI}${id}/`,
                method: 'GET',
            }),
            providesTags: ['MemberRegistrations']
        }),
        addMemberRegistration: builder.mutation({
            query: (memberRegistration) => {
            return{
                url: `${memberAPI}`,
                method: 'POST',
                body: memberRegistration
            }
        },
            invalidatesTags: ['MemberRegistrations']
        }),
        updateMemberRegistration: builder.mutation({
           
            query: (updateMemberRegistration) => {
                return{
                url: `${memberAPI}${updateMemberRegistration.get('id')}/`,
                method: 'PATCH',
                body: updateMemberRegistration
                }
            },
            invalidatesTags: ['MemberRegistrations']
        }),
        deleteMemberRegistration: builder.mutation({
            query: ({ id }) => {
                return{
                url: `${memberAPI}${id}/`,
                method: 'DELETE',
                body: id
            }},
            invalidatesTags: ['MemberRegistrations']
        }),
    })
})

export const {
    useGetMemberRegistrationsQuery,
    useGetActiveMemberRegistrationsQuery,
    useAddMemberRegistrationMutation,
    useUpdateMemberRegistrationMutation,
    useDeleteMemberRegistrationMutation,
    useGetMemberRegistrationsByIdQuery
} = memberRegistrationSlice

// returns the query result object
export const selectMemberRegistrationsResult = memberRegistrationSlice.endpoints.getMemberRegistrations.select()

// Creates memoized selector
const selectMemberRegistrationssData = createSelector(
    selectMemberRegistrationsResult,
    memberRegistrationsResult => memberRegistrationsResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllMemberRegistrations,
    selectById: selectMemberRegistrationById,
    selectIds: selectIds
    // Pass in a selector that returns the posts slice of state
} = memberRegistrationAdapter.getSelectors(state => selectMemberRegistrationssData(state) ?? initialState)