import React, { useState } from "react";
import TextButton from "../../../../../../components/button/buttonicon";
import RoundButton from "../../../../../../components/button/roundbutton";
import { FaPlus } from "react-icons/fa";
import { useEffect } from "react";
import Controls from "./Controls";
import { useForm1 } from "../useForm";
import useDebounce from "../../../../../../utils/debounce";
import { Box } from "@material-ui/core";
import BlobToBase64 from "../../../../../../utils/blobToBase64";
import { Grid, Tooltip } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";

const ReusableForms = ({
  originalId,
  getApi,
  fieldName,
  mappedData,
  postAPI,
  fieldList,
  deleteAPI,
  context,
  editApi,
  imageField,
  unDebouncable = ["is_activity_seasonal"],
}) => {
  const data = getApi(originalId);
  const [thisData, setThisData] = useState([]);
  const [originalData, setOriginalData] = useState({});
  const [valueArray, setValueArray] = useState([]);
  const [addData] = postAPI();
  const [deleteThis] = deleteAPI();
  const [editThis] = editApi();

  const getAllSetValues = (imageField) => {
    Object.keys(imageField).forEach((key) => {
      setValueArray((valueArray) => [
        ...valueArray,
        imageField[key]["imageFieldValue"],
      ]);
    });
  };

  useEffect(() => {
    getAllSetValues(imageField);
  }, [imageField]);

  useEffect(() => {}, [valueArray]);

  useEffect(() => {
    if (data.data) {
      setOriginalData(data.data);
      setThisData(data.data[fieldName]);
    }
  }, [data.data]);

  const addForm = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("id", originalId);
    mappedData.forEach((key) => {
      formData.append(`${fieldName}[0][${key.field_name}]`, key.default);
    });
    addData(formData);
  };

  const imageConfirm = (e, key) => {
    BlobToBase64(imageField[e.target.name]["imageFieldValue"]).then((res) => {
      updateObject(
        key.id,
        { target: { name: e.target.name, value: res } },
        setOriginalData,
        originalData,
        fieldName
      );
    });
  };

  const deleteForm = (e, key) => {
    
    e.preventDefault();
    let data = {};
    data["id"] = originalId;
    fieldList.forEach((key1) => {
      if (key1 === fieldName) {
        data[key1] = [{ id: key.id }];
      } else {
        data[key1] = [];
      }
    });
    
    deleteThis(data);
  };

  const [changeImmediate, setChangeImmediate] = useState(false);
  function updateObject(id, e, setData, data, fieldName) {
    const index = data[fieldName].findIndex((item) => item.id === id);
    const newQux = [...data[fieldName]];
    newQux[index] = { ...newQux[index], [e.target.name]: e.target.value };

    setData({ ...data, [fieldName]: [...newQux], context: context });
  }
  const editChange = (e, b, immediateChange) => {
    updateObject(b.id, e, setOriginalData, originalData, fieldName);
    if (immediateChange) {
      if (changeImmediate) {
        setChangeImmediate(false);
      } else {
        setChangeImmediate(true);
      }
    }
  };

  useEffect(() => {
    const formData = new FormData();

    formData.append("data", JSON.stringify(originalData));

    if (originalData["context"] !== undefined) {
      editThis({ data: originalData });
    }
  }, [useDebounce(originalData[fieldName], 3000), changeImmediate]);
  return (
    <>
      <Grid container spacing={3}>
        <>
          {thisData.map((key) => (
            <Grid item xs={4}>
              <Box
                style={{
                  background: "white",
                  borderRadius: "5px",
                  marginTop: "1.5rem",
                  marginBottom: "0rem",
                }}
                className="hover-to-delete-display"
              >
                <Grid container spacing={3}>
                  <Grid item xs={10}></Grid>
                  <Grid item xs={2}>
                    <span
                      className="delete-icon-hover-design"
                      onClick={(e) => deleteForm(e, key)}
                      // data-aos="fade-up"
                    >
                      <Tooltip title="Delete Member">
                        <FontAwesomeIcon
                          icon={faTrashAlt}
                          style={{ fontSize: "22px", color: "red" }}
                        />
                      </Tooltip>
                    </span>
                  </Grid>
                </Grid>
                <Box style={{ padding: "2rem" }}>
                  {mappedData.map((key1) => (
                    <>
                      {key1["field_form"] === "input" ? (
                        <Box style={{ marginBottom: "20px" }}>
                          <Controls.Input
                            name={key1["field_name"]}
                            defaultValue={key[key1["field_name"]]}
                            label={key1["label"]}
                            onChange={(e) => editChange(e, key)}
                          />
                        </Box>
                      ) : (
                        <></>
                      )}
                      {key1["field_form"] === "checkbox" ? (
                        <Box style={{ marginBottom: "20px" }}>
                          <Controls.Checkbox
                            name={key1["field_name"]}
                            value={key[key1["field_name"]]}
                            label={key1["label"]}
                            onChange={(e) => editChange(e, key, true)}
                          />
                        </Box>
                      ) : (
                        <></>
                      )}
                      {key1["field_form"] === "select" ? (
                        <Box style={{ marginBottom: "20px" }}>
                          <Controls.Select
                            name={key1["field_name"]}
                            value={key[key1["field_name"]]}
                            label={key1["label"]}
                          />
                        </Box>
                      ) : (
                        <></>
                      )}
                      {key1["field_form"] === "image" ? (
                        <>
                          <Controls.Image
                            name={key1["field_name"]}
                            setFile={
                              imageField[key1["field_name"]][
                                "imageSetFieldValue"
                              ]
                            }
                            aspectRatio={key1['aspect_ratio']}
                          />
                          <Box style={{ marginTop: "10px" }}>
                            <TextButton
                              onClick={(e) => imageConfirm(e, key)}
                              name={key1["field_name"]}
                              buttonText="Image Confirm"
                            />
                          </Box>
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                  ))}
                </Box>
              </Box>
            </Grid>
          ))}
        </>
      </Grid>
      <Box style={{ marginTop: "2rem" }}>
        <RoundButton
          classname="new-button-design"
          buttonIcon={FaPlus}
          buttonText={"Add"}
          handleClick={(e) => addForm(e)}
        />
      </Box>
    </>
  );
};

export default ReusableForms;
