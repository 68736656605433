import { createSelector, createEntityAdapter } from "@reduxjs/toolkit";

import { apiSlice } from "./apiSlice";

const CardDetailsAdapter = createEntityAdapter();

const initialState = CardDetailsAdapter.getInitialState();

export const CardDetailSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCardDetails: builder.query({
      query: () => "/bookings/card-details/",
      transformResponse: (responseData) => {
        let returnResponse = [];
        responseData.map((i) => {
          let newData = new Date(i.created_at);

          returnResponse.push({ ...i, created_at: newData.toDateString() });
        });

        return CardDetailsAdapter.setAll(initialState, returnResponse);
      },
      providesTags: (result, error, arg) => [
        { type: "CardDetails", id: "LIST" },
        ...result.ids.map((id) => ({ type: "CardDetails", id })),
      ],
    }),
    getCardDetailsById: builder.query({
      query: (id) => ({
        url: `/bookings/card-details/${id}`,
        method: "GET",
      }),
      providesTags: ["CardDetails"],
    }),
    createUpdateCardDetails: builder.mutation({
      query: (CardDetail) => {
        return {
          url: "/bookings/card-details/create_update_card_details/",
          method: "POST",
          body: CardDetail,
        };
      },
      invalidatesTags: ["CardDetails"],
    }),
    addCardDetail: builder.mutation({
      query: (CardDetail) => {
        return {
          url: "/bookings/card-details/",
          method: "POST",
          body: CardDetail,
        };
      },
      invalidatesTags: ["CardDetails"],
    }),
    updateCardDetail: builder.mutation({
      query: (updateCardDetail) => {
        return {
          url: `/bookings/card-details/${updateCardDetail.get("id")}/`,
          method: "PATCH",
          body: updateCardDetail,
        };
      },
      invalidatesTags: ["CardDetails"],
    }),
    deleteCardDetail: builder.mutation({
      query: ({ id }) => {
        return {
          url: `/bookings/card-details/${id}/`,
          method: "DELETE",
          body: id,
        };
      },
      invalidatesTags: ["CardDetails"],
    }),
    getCardDetailPagination: builder.query({
      query: (id) => {
        return {
          url: `/bookings/card-details/?page=${id}`,
          method: "GET",
        };
      },
      invalidatesTags: ["CardDetails"],
    }),
  }),
});

export const {
  useGetCardDetailsQuery,
  useAddCardDetailMutation,
  useUpdateCardDetailMutation,
  useDeleteCardDetailMutation,
  useGetCardDetailsByIdQuery,
  useGetCardDetailPaginationQuery,
  useCreateUpdateCardDetailsMutation
} = CardDetailSlice;

// returns the query result object
export const selectCardDetailsResult = CardDetailSlice.endpoints.getCardDetails.select();

// Creates memoized selector
const selectCardDetailssData = createSelector(
  selectCardDetailsResult,
  (CardDetailsResult) => CardDetailsResult.data // normalized state object with ids & entities
);

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
  selectAll: selectAllCardDetails,
  selectById: selectCardDetailById,
  selectIds: selectIds,
  // Pass in a selector that returns the posts slice of state
} = CardDetailsAdapter.getSelectors(
  (state) => selectCardDetailssData(state) ?? initialState
);
