import React from "react";
import { Link } from "react-router-dom";
import { Grid, Box, Typography } from "@mui/material";

const CardDesignOne = ({ cardLink, cardImage, cardName }) => {
  return (
    <Box className="card-design-services3">
      <Link to={`/${cardLink}`} className="dash-text-decoration">
        <Grid xs={3} item className="new-margin-auto">
          <Box>
            <img
              src={cardImage}
              className="new-image-width"
              alt="requirement-gathering"
            />
          </Box>
        </Grid>
        <Box>
          <Typography
            variant="h5"
            className="steps-text-margin"
            style={{fontSize: '18px',fontWeight:'700'}}
          >
            {cardName}
          </Typography>
        </Box>
      </Link>
    </Box>
  );
};

export default CardDesignOne;
