import React from "react";
import Footer from "../component/footer";
import Header from "../component/header";
import Sign from "../../../static/image/about/sign.png";
import { useGetMissionsQuery } from "../../../api/missionSlice";
import { useGetVisionsQuery } from "../../../api/visionSlice";
import { useGetWorksQuery } from "../../../api/workSlice";
import Skeleton from "react-loading-skeleton";

const MissionAndVision = () => {
  return (
    <>
      <Header />
      <MissionAndVisionPage />
      <Footer />
    </>
  );
};

const MissionAndVisionPage = () => {
  const data = useGetMissionsQuery("getMissions");
  const data1 = useGetVisionsQuery("getVision");
  const data2 = useGetWorksQuery("getWorks");
  

  return (
    <div style={{ textAlign: "start" }}>
      <section className="page-title bg-1">
        <div className="overlay"></div>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="block text-center">
                <span className="text-white">Mission & Vision</span>
                <h1 className="text-capitalize mb-5 text-lg">
                  Mission & Vision
                </h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="fetaure-page mt-5">
        <div className="container">
          <div className="client-info">
            <h4>Mission</h4>
          </div>
          {data !== undefined ? (
            <>
              {data.status === "fulfilled" ? (
                <>
                  {data.data.ids.length === 0 ? (
                    <p>
                      <li>
                        <Skeleton height={30} />
                      </li>
                      <li>
                        <Skeleton height={30} />
                      </li>
                      <li>
                        <Skeleton height={30} />
                      </li>
                    </p>
                  ) : (
                    <>
                      {data.data.ids.map((key) => (
                        <p key={key}>
                          <li>{data.data.entities[key].mission_name}</li>
                        </p>
                      ))}
                    </>
                  )}
                </>
              ) : (
                <p>
                  <li>
                    <Skeleton height={30} />
                  </li>
                  <li>
                    <Skeleton height={30} />
                  </li>
                  <li>
                    <Skeleton height={30} />
                  </li>
                </p>
              )}
            </>
          ) : null}
        </div>
      </section>
      <section className="fetaure-page mt-5">
        <div className="container">
          <div className="client-info">
            <h4>Vision</h4>
          </div>
          {data1 !== undefined ? (
            <>
              {data1.status === "fulfilled" ? (
                <>
                  {data1.data.ids.length === 0 ? (
                    <p>
                      <li>
                        <Skeleton height={30} />
                      </li>
                      <li>
                        <Skeleton height={30} />
                      </li>
                      <li>
                        <Skeleton height={30} />
                      </li>
                    </p>
                  ) : (
                    <>
                      {data1.data.ids.map((key) => (
                        <p key={key}>
                          <li>{data1.data.entities[key].vision_name}</li>
                        </p>
                      ))}
                    </>
                  )}
                </>
              ) : (
                <p>
                  <li>
                    <Skeleton height={30} />
                  </li>
                  <li>
                    <Skeleton height={30} />
                  </li>
                  <li>
                    <Skeleton height={30} />
                  </li>
                </p>
              )}
            </>
          ) : null}
        </div>
      </section>
      <section className="fetaure-page mt-5 mb-5">
        <div className="container">
          <div className="client-info">
            <h4>Objectives</h4>
          </div>
          {data2 !== undefined ? (
            <>
              {data2.status === "fulfilled" ? (
                <>
                  {data2.data.ids.length === 0 ? (
                    <p>
                      <li>
                        <Skeleton height={30} />
                      </li>
                      <li>
                        <Skeleton height={30} />
                      </li>
                      <li>
                        <Skeleton height={30} />
                      </li>
                    </p>
                  ) : (
                    <>
                      {data2.data.ids.map((key) => (
                        <p>
                          <li>{data2.data.entities[key].work_name}</li>
                        </p>
                      ))}
                    </>
                  )}
                </>
              ) : (
                <p>
                  <li>
                    <Skeleton height={30} />
                  </li>
                  <li>
                    <Skeleton height={30} />
                  </li>
                  <li>
                    <Skeleton height={30} />
                  </li>
                </p>
              )}
            </>
          ) : null}
        </div>
      </section>
    </div>
  );
};
export default MissionAndVision;
