import React, { useState, useEffect } from "react";
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import Sidebar from "../../component/sidebar";
import Header from "../../component/header";
import Controls from "../forms/controls/Controls";
import { useForm, Form } from "../forms/useForm";
import {
  useGetHospitalByIdQuery,
  useGetHospitalsQuery,
  useGetHospitalIdQuery
} from "../../../../../api/hospitalSlice";
import { useGetActiveHospitalHolidaysQuery } from "../../../../../api/hospitalHolidaySlice";
import {
  useDeleteHospitalBookingMutation,
  useGetAllMRBookingLessDatesMutation,
  useGetMRHospitalBookingsByHospitalDateIdMutation,
} from "../../../../../api/hospitalBookingSlice";

import { Box } from "@mui/system";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCog,
  faBell,
  faSignInAlt,
  faExclamationTriangle,
  faCheckCircle,
  faTrashAlt,
  faEdit,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { Link } from "react-router-dom";
import { setCharAt } from "../../../../../utils/replaceStringIndex";

const EditBookingUser = () => {
  return (
    <Grid container className="dashboard-full-background">
      <Grid item md={2} xs={12} sm={12} >
        <Sidebar />
      </Grid>
      <Grid item md={10} xs={12} sm={12} className="res-margin-design">
        <Header />
        <EditBookingUserPages />
      </Grid>
    </Grid>
  );
};

const EditBookingUserPages = () => {
  const options = [];
  const [selectedDates, setSelectedDates] = useState(new Date());

  const [HospitalBookings] = useGetMRHospitalBookingsByHospitalDateIdMutation();

  const [disableDates, setDisableDates] = useState([]);
  const [bookingList, setBookingList] = useState({
    data: [],
    status: "initial",
  });

  const highlightedStyles = {
    backgroundColor: "#ffcc80",
    color: "#000",
  };

  const [mrBookingLessDates] = useGetAllMRBookingLessDatesMutation();
  const [deleteData] = useDeleteHospitalBookingMutation();

  const deleteConfirm = (e, id) => {
    e.preventDefault();
    deleteData({ id })
      .then((response) => {
        if (response.data) {
          setSuccesMessege(response.data.data);
          setTimeout(() => {
            window.location.reload(true);
          }, [3000])
        } else {
          setErrorMessege(response.error.data.data)
          setTimeout(() => {
            setErrorMessege(null);
          }, [3000])
        }
      })

  };

  const hospitalDateData = useGetHospitalIdQuery()

  useEffect(() => {
    mrBookingLessDates({ mr_id: Cookies.get("access") }).then((res) => {
      const dateList = []
      res.data.data.map((isoDate) => {
        dateList.push(new Date(isoDate.split('T')[0]))
      }
      );

      setDisableDates(dateList);
    });
  }, [Cookies.get("access")]);

  const [SuccessMessege, setSuccesMessege] = useState(null);
  const [ErrorMessege, setErrorMessege] = useState(null);

  const onDateSelect = (e) => {
    setSelectedDates(e.target.value);
    HospitalBookings({ mr_id: Cookies.get("access"), date: e.target.value })
      .then((res) => {
        if (res.data !== undefined) setBookingList(res.data);
        setSuccesMessege("Your All Booking Lists.");
        setTimeout(() => {
          setSuccesMessege(null);
        }, [3000]);
      })
      .catch((error) => {
        setErrorMessege(error.data.error);
        setTimeout(() => {
          setErrorMessege(null);
        }, [3000]);
      });
  };

  return (
    <Box>
      <Box className="table-design-background">
        <Typography className="dashboard-home-page-text">
          Select Hospital Booking Date
        </Typography>
        <Box style={{ padding: "1.5rem", paddingTop: ".5rem" }}>
          <Form>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Controls.DatePickers
                  buttonText="Select Booking Date"
                  name="date"
                  onChange={onDateSelect}
                  highlightedDates={disableDates}
                  selectedDate={selectedDates}
                  modifiersStyles={{
                    highlighted: highlightedStyles,
                  }}
                />
              </Grid>
            </Grid>
          </Form>
        </Box>

        {SuccessMessege === null ? null : (
          <Box className="successmessege">
            <Typography className="messegefonts">
              <FontAwesomeIcon icon={faCheckCircle} className="messegeicon" />
              {SuccessMessege}
            </Typography>
          </Box>
        )}
        {ErrorMessege === null ? null : (
          <Box className="errormessege">
            <Typography className="messegefonts">
              <FontAwesomeIcon
                icon={faExclamationTriangle}
                className="messegeicon"
              />
              {ErrorMessege}
            </Typography>
          </Box>
        )}
      </Box>
      <Box className="table-design-background" style={{ marginTop: "1.5rem" }}>
        <Typography className="dashboard-home-page-text">
          Hospital Bookings List
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            {bookingList.status === "fulfilled" ? (
              <>
                <TableContainer component={Paper}>
                  <Table aria-label="simple table" className="table-width1">
                    <TableHead>
                      <TableRow>
                        <TableCell>S.No.</TableCell>
                        <TableCell>MPO Name</TableCell>
                        <TableCell>Contact Number</TableCell>
                        <TableCell>Email</TableCell>
                        <TableCell>Hospital Name</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Remark</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {bookingList.data.map((key, index) => (
                        <TableRow key={key.name}>
                          <TableCell>{index + 1}</TableCell>
                          <TableCell>
                            {key.mr_id.user.first_name}{" "}
                            {key.mr_id.user.last_name}
                          </TableCell>
                          <TableCell>{key.mr_id.contact_no}</TableCell>
                          <TableCell>{key.mr_id.user.email}</TableCell>
                          <TableCell>{key.hospital_date_id.hospital.name}</TableCell>
                          {
                            key.is_visited === true ?
                              <TableCell style={{ color: "green" }}>Approved</TableCell> :
                              <TableCell style={{ color: '#3f51b5' }}>Pending</TableCell>
                          }
                          <TableCell style={{ paddingLeft: "2rem" }}>
                            <Link to={`/dashboard/book-my-hospitals/edit/${key.id}`}>
                              <FontAwesomeIcon
                                icon={faEdit}
                                style={{
                                  fontSize: "20px",
                                  color: "#3f51b5",
                                  marginRight: "10px",
                                }}
                              />
                            </Link>
                            <FontAwesomeIcon
                              icon={faTrashAlt}
                              style={{
                                fontSize: "20px",
                                color: "#f50057",
                                cursor: "pointer",
                              }}
                              onClick={(e) => deleteConfirm(e, key.id)}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            ) : (
              <></>
            )}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default EditBookingUser;
