import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Sidebar from "../../component/sidebar";
import { Grid, Typography } from "@mui/material";
import Header from "../../component/header";
import BasicTable from "../tables/BasicTable";

import { useGetOfficeStaffsByIdQuery,
useGetOfficeStaffsQuery,
useAddOfficeStaffMutation,
useDeleteOfficeStaffMutation,
useUpdateOfficeStaffMutation } from "../../../../../api/officeStaffsSlice";
import OfficeStaffsAddButton from "./officeStaffsAddButton";
import { Box } from "@mui/system";

const DashboardOfficeStaffs = () => {
  return (
    <Grid container className="dashboard-full-background">
      <Grid item xs={2} spacing={4}>
        <Sidebar />
      </Grid>
      <Grid item xs={10}>
        <Header />
        <DashboardOfficeStaffsPages />
      </Grid>
    </Grid>
  );
};

const DashboardOfficeStaffsPages = () => {
  const { data, isLoading, isSuccess, isError, error } =
    useGetOfficeStaffsQuery("getOfficeStaff");

  const [deleteOfficeStaff] = useDeleteOfficeStaffMutation();

  const deleteConfirm = (e, id) => {
    e.preventDefault();
    
    deleteOfficeStaff({ id });
  };

  let headers = [];
  let tableData = [];
  let content;
  if (isLoading) {
    content = <p>"Loading..."</p>;
  } else if (isSuccess) {
    headers = ['id', "Name", "Role"];
    tableData = [];
    data.ids.forEach((key) => {
      tableData.push({
        id: data.entities[key].id,
        Name: data.entities[key].name,
        Role: data.entities[key].role
      });
    });
  }
  return (
    <>
      <Box className="table-design-background">
        <Grid container>
          <Grid item xs={6}>
            <Typography className="dashboard-home-page-text">Office Staffs Details</Typography>
          </Grid>
          <Grid item xs={6}>
            <Link to="/dashboard/new/office-staffs" style={{textDecoration:'none'}}>
              <OfficeStaffsAddButton/>
            </Link>
          </Grid>
        </Grid>
        <Box style={{ padding: "1rem" }}>
          <BasicTable
            headers={headers}
            data={tableData}
            isDelete={true}
            deleteColor={"#f50057"}
            isEdit={true}
            editColor={"#3f51b5"}
            addColor={""}
            tableName={"office-staffs"}
            deleteConfirm={deleteConfirm}
            activeSign={["Active"]}
          />
        </Box>
      </Box>
    </>
  );
};
export default DashboardOfficeStaffs;
