import {
    createSelector,
    createEntityAdapter
} from "@reduxjs/toolkit";

import { apiSlice } from "./apiSlice";
import convertArrayRepeatedToObject from "../utils/convertArrayRepeatedToObject";
import checkArrayType from "../utils/checkArrayType";

const hospitalBookingsAdapter = createEntityAdapter()

const initialState = hospitalBookingsAdapter.getInitialState()

export const hospitalBookingSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getHospitalBookings: builder.query({
            query: () => 'bookings/hospital-date-mr-map/',
            transformResponse: responseData => {
                return hospitalBookingsAdapter.setAll(initialState, responseData)
            },
            providesTags: ['HospitalBookings']
        }),
        getHospitalBookingById: builder.query({
            query: (id) => ({
                url: `/bookings/hospital-holidays/${id}/`,
                method: 'GET',
            }),
            providesTags: ['HospitalBookings']
        }),
        getBookingListById: builder.query({
            query: (id) => ({
                url: `/bookings/hospital-date-mr-map/${id}/`,
                method: 'GET',
            }),
            providesTags: ['HospitalBookings']
        }),
        getHospitalBookingsByHospitalDateId: builder.mutation({
            query: (data) => ({
                url: `bookings/hospital-date-mr-map/hospital_bookings_list/`,
                method: 'POST',
                body: data,
            }),
            providesTags: (result, error, arg) =>
                [
                    { type: 'HospitalBookings', id: "LIST" },
                    ...result.ids.map(id => ({ type: 'HospitalBookings', id }))
                ]
        }),
        getHospitalBookingsId: builder.query({
            query: (id) => ({
                url: `bookings/hospital-date-mr-map/${id}/`,
                method: 'GET',
            }),
            providesTags: ['HospitalBookings']
        }),
        getMRHospitalBookingsByHospitalDateId: builder.mutation({
            query: (data) => ({
                url: `bookings/hospital-date-mr-map/mr_hospital_bookings_list/`,
                method: 'POST',
                body: data,
            }),
            providesTags: (result, error, arg) =>
                [
                    { type: 'HospitalBookings', id: "LIST" },
                    ...result.ids.map(id => ({ type: 'HospitalBookings', id }))
                ]
        }),
        addHospitalBooking: builder.mutation({
            query: (hospitalBooking) => {

                return {
                    url: 'bookings/hospital-date-mr-map/',
                    method: 'POST',
                    body: hospitalBooking
                }
            },
            invalidatesTags: ['HospitalBookings']
        }),
        patchHospitalBooking: builder.mutation({
            query: (id) => {

                return {
                    url: `bookings/hospital-date-mr-map/${id.id}/`,
                    method: 'PATCH',
                    body: id.patchValue,
                }
            },
            invalidatesTags: ['HospitalBookings']
        }),
        updateHospitalBooking: builder.mutation({

            query: (updateHospitalBooking) => {
                return {
                    url: `bookings/hospital-date-mr-map/`,
                    method: 'POST',
                    body: updateHospitalBooking
                }
            },
            invalidatesTags: ['HospitalBookings']
        }),
        deleteHospitalBooking: builder.mutation({
            query: (data) => {
                return {
                    url: `bookings/hospital-date-mr-map/${data['id']}/`,
                    method: 'DELETE',
                    body: data['delete_reason']
                }
            },
            invalidatesTags: ['HospitalBookings']
        }),
        checkHospitalBooking: builder.mutation({
            query: (bookings) => {

                return {
                    url: `bookings/check-bookings/`,
                    method: 'POST',
                    body: bookings
                }
            },
            invalidatesTags: ['HospitalBookings']
        }),
        getAllMRBookingLessDates: builder.mutation({
            query: (mr_id) => {
                return {
                    url: `bookings/hospital-date-mr-map/mr_all_bookings_less_dates/`,
                    method: 'POST',
                    body: mr_id
                }
            },
            invalidatesTags: ['HospitalBookings']
        }),
        checkBooking: builder.mutation({
            query: (booking) => {
                return {
                    url: `/bookings/hospital-date-mr-map/`,
                    method: "POST",
                    body: booking,
                }
            },
            invalidatesTags: ['HospitalBookings']
        }),
        updateBooking: builder.mutation({
            query: (booking) => {
                return {
                    url: `/bookings/hospital-date-mr-map/${booking.get('id')}/`,
                    method: "PATCH",
                    body: booking,
                }
            },
            invalidatesTags: ['HospitalBookings']
        }),
        getExcludedHospitalDates: builder.mutation({
            query: (body) => ({
                url: `/bookings/get-excluded-hospital-dates/`,
                method: 'POST',
                body: body,
            }),
            transformResponse: responseData =>{
                
                if(responseData.length>0)
                {
                    responseData = responseData.map((dateString) => checkArrayType(dateString) === "string" ? new Date(dateString): dateString);
                    return responseData;
                }
                else{
                    return []
                }
            },
        }),
    })
})

export const {
    useGetHospitalBookingsQuery,
    useGetMRHospitalBookingsByHospitalDateIdMutation,
    useAddHospitalBookingMutation,
    useUpdateHospitalBookingMutation,
    useDeleteHospitalBookingMutation,
    useGetHospitalBookingByIdQuery,
    useGetHospitalBookingsByHospitalDateIdMutation,
    useCheckHospitalBookingMutation,
    useGetAllMRBookingLessDatesMutation,
    useGetBookingListByIdQuery,
    useCheckBookingMutation,
    useUpdateBookingMutation,
    useGetHospitalBookingsIdQuery,
    usePatchHospitalBookingMutation,
    useGetExcludedHospitalDatesMutation
} = hospitalBookingSlice

// returns the query result object
export const selectHospitalBookingsResult = hospitalBookingSlice.endpoints.getHospitalBookings.select()

// Creates memoized selector
const selectHospitalBookingssData = createSelector(
    selectHospitalBookingsResult,
    hospitalBookingsResult => hospitalBookingsResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllHospitalBookings,
    selectById: selectAboutPageById,
    selectIds: selectIds
    // Pass in a selector that returns the posts slice of state
} = hospitalBookingsAdapter.getSelectors(state => selectHospitalBookingssData(state) ?? initialState)
