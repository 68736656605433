function checkArrayType(array) {
    let isStringArray = true;
    let isDateArray = true;
    for (let i = 0; i < array.length; i++) {
        if (typeof array[i] !== 'string') {
            isStringArray = false;
        }
        if (!(array[i] instanceof Date)) {
            isDateArray = false;
        }
    }
    if (isStringArray) {
        return 'string';
    } else if (isDateArray) {
        return 'date';
    } else {
        return 'mixed';
    }
}

export default checkArrayType;