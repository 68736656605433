import { Box, Button, Grid, Typography } from "@material-ui/core";
import { useParams } from "react-router-dom";
import Sidebar from "../../component/sidebar";
import Header from "../../component/header";
import React, { useState, useEffect } from "react";
import Controls from "../forms/controls/Controls";
import { useForm, Form } from "../forms/useForm";
import useDebounce from "../../../../../utils/debounce";
import { useGetHospitalsQuery } from "../../../../../api/hospitalSlice";
import { useGetActiveHospitalHolidaysQuery } from "../../../../../api/hospitalHolidaySlice";
import {
  useCheckHospitalBookingMutation,
  useAddHospitalBookingMutation,
} from "../../../../../api/hospitalBookingSlice";
import axios from "axios";
import { BASE_URL } from "../../../../../constant/baseURL";
import Cookies from "js-cookie";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";
import { useGetExcludedHospitalDatesMutation } from "../../../../../api/hospitalBookingSlice";

const BookMyHospital = () => {
  return (
    <Box className="dashboard-full-background">
      <Grid container spacing={0}>
        <Grid item md={2} xs={12}>
          <Sidebar />
        </Grid>
        <Grid item md={10} xs={12} className="res-margin-design1">
          <Header />
          <BookMyHospitalPage />
        </Grid>
      </Grid>
    </Box>
  );
};

const BookMyHospitalPage = () => {
  const [hospitalID, setHospitalID] = useState(null);
  const [disableDates, setDisableDates] = useState([]);
  const hospitalData = useGetHospitalsQuery();
  const hospitalHoliday = useGetActiveHospitalHolidaysQuery(hospitalID);
  const [selectedDates, setSelectedDates] = useState(new Date());
  console.log(selectedDates);
  const [accompanistNo, setAccompanistNo] = useState(0);
  const [bookingResponse, setBookingResponse] = useState({
    is_bookable: false,
    current_bookings: 0,
    error: "No data selected",
  });

  const [SuccessMessege, setSuccesMessege] = useState(null);
  const [ErrorMessege, setErrorMessege] = useState(null);
  const [checkHospitalBookings] = useCheckHospitalBookingMutation();
  const [doBookings] = useAddHospitalBookingMutation();
  useEffect(() => {
    if (hospitalHoliday.status === "fulfilled") {

      setDisableDates(hospitalHoliday.data.date);
    }
  }, [hospitalHoliday, hospitalID]);

  const [excludedHospitalDates] = useGetExcludedHospitalDatesMutation();
  const [excludedDate, setExcludedDate] = useState();

  let hospitalList = [];
  if (hospitalData.status === "fulfilled") {
    hospitalData.data.ids.forEach((key) => {
      hospitalList.push({
        id: key,
        title: hospitalData.data.entities[key].name,
      });
    });
  }

  const selectHospital = (e) => {
    setHospitalID(e.target.value);
    excludedHospitalDates(
      {
        hospital_id: e.target.value,
        user_email: Cookies.get('user_email')
      }
    ).then((res)=>{
      setExcludedDate(res.data)
    })
  };
  const accompanistNumber = (e) => {
    setAccompanistNo(e.target.value);
  }

  const onDateSelect = (e) => {
    setSelectedDates(e.target.value);

    checkHospitalBookings({
      hospital_id: hospitalID,
      date: e.target.value,
      user: Cookies.get("access"),
    })
      .then((response) => {
        setBookingResponse(response.data)
        if (response.error) {
          setErrorMessege(response.error.data.error)
          setTimeout(() => {
            setErrorMessege(null);
          }, [2000]);
        } else {
          setSuccesMessege("Date is Valid.")
          setTimeout(() => {
            setSuccesMessege(null);
          }, [2000]);
        }
      })
      .catch((err) => {
        setBookingResponse(err.response.data);
      });
  };



  const confirmDate = () => {

    if (bookingResponse?.data?.is_bookable) {
      doBookings({
        hospital_date_id: bookingResponse?.data?.hospital_date_id,
        mr_id: Cookies.get("access"),
        accompanist_number: accompanistNo
      })
        .then((response) => {
          setSuccesMessege("Booking request has been sent. You will receive mail after your booking have been approved!!!");

          setTimeout(() => {
            setSuccesMessege(null);
          }, [3000]);
        })
        .catch((err) => {
          setErrorMessege("Some Error occur while Showing a Data.");
          setTimeout(() => {
            setErrorMessege(null);
          }, [3000]);
        });
    }
  };

  const checkBookingDate = (e) => { };
  //   useEffect(() => {
  //     // debugger;
  //
  //
  //     if (membershipYears.data !== undefined) {
  //
  //       setInitialFValues({
  //         edit: true,
  //         year: membershipYears.data.year,
  //         is_current: membershipYears.data.is_current.toString(),
  //         is_founder: membershipYears.data.is_founder.toString(),
  //         member_list: membershipYears.data.member_list,
  //       });
  //     }
  //   }, [
  //     membershipYears.data
  //   ]);

  //   const { values, handleImageUpload, handleInputChange } = useForm(
  //     initialFValues,
  //     true,
  //     false,
  //     true
  //   );

  //   const [editMembershipYears] = useUpdateMemberMembershipPeriodMutation();

  //   useEffect(() => {

  //     if (membershipYears.data) {

  //       editWithoutImage(noLoop, setNoLoop, editMembershipYears, values, id, context);
  //     }
  //   }, [
  //     useDebounce(values.year, 3000),
  //     values.is_dinner,
  //     values.is_current,
  //     values.is_founder,
  //   ]);
  return (
    <>
      <Box className="table-design-background">
        <Typography className="dashboard-home-page-text">
          Book My Hospital
        </Typography>
        <Box style={{ margin: "1.5rem", paddingBottom: "2rem" }}>
          <Form style={{ marginTop: "1rem" }}>
            <Grid container spacing={3} style={{ marginTop: "1rem" }}>
              <Grid item xs={12} md={6}>
                <Controls.Select
                  name="hospital"
                  label="Select Hospital"
                  onChange={selectHospital}
                  options={hospitalList}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Controls.DatePickers
                  buttonText="Select Booking Date"
                  name="date"
                  onChange={onDateSelect}
                  excludeDates={Array.from(disableDates).concat(excludedDate)}
                  selectedDate={selectedDates}

                // excludeDates={disableDates}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Controls.Select
                  name="accompanist_number"
                  label="Accompanist Number"
                  onChange={accompanistNumber}
                  options={[
                    { id: 0, title: "0" },
                    { id: 1, title: '1' },
                    { id: 2, title: '2' }
                  ]}
                />
              </Grid>
              {/* <Grid item xs={6}></Grid> */}
              <Grid item>
                <Controls.Button
                  onClick={confirmDate}
                  text={"Confirm Bookings"}
                />
              </Grid>
            </Grid>
          </Form>
          {SuccessMessege === null ? null : (
            <Box className="successmessege">
              <Typography className="messegefonts">
                <FontAwesomeIcon icon={faCheckCircle} className="messegeicon" />
                {SuccessMessege}
              </Typography>
            </Box>
          )}
          {ErrorMessege === null ? null : (
            <Box className="errormessege">
              <Typography className="messegefonts">
                <FontAwesomeIcon
                  icon={faExclamationTriangle}
                  className="messegeicon"
                />
                {ErrorMessege}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};

export default BookMyHospital;
