import React, { useEffect, useState } from "react";
import Sidebar from "../../component/sidebar";
import Header from "../../component/header";
import { Grid, Typography } from "@mui/material";
import Controls from "../forms/controls/Controls";
import { useForm, Form } from "../forms/useForm";
import BlobToFile from "../forms/utils/blobToFile";
import { Box } from "@mui/system";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCog,
  faBell,
  faSignInAlt,
  faExclamationTriangle,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { useAddBlogMutation } from "../../../../../api/blogSlice";
import { useAddLifeTimeMutation } from "../../../../../api/lifeTimeSlice";

const AddLifeTimePage = () => {
  return (
    <Grid container className="dashboard-full-background">
      <Grid item xs={2} spacing={4}>
        <Sidebar />
      </Grid>
      <Grid item xs={10}>
        <Header />
        <AddEventPagePages />
      </Grid>
    </Grid>
  );
};

const AddEventPagePages = () => {
  const [File, setFile] = useState([]);
  const [SuccessMessege, setSuccesMessege] = useState(null);
  const [ErrorMessege, setErrorMessege] = useState(null);
  const initialFValues = {
    // image: [],
    name: "",
    email: "",
    contact_no: "",
    role: "",
  };
  const { values, handleImageUpload, handleInputChange } = useForm(
    initialFValues,
    true,
    false,
    false
  );
  const history = useNavigate();

  const [addLifeTimePage] = useAddLifeTimeMutation();

  useEffect(() => {
    handleImageUpload("image", File);
  }, [File]);

  const thisFile = (e) => {};

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    var isTrueSet = values.is_active === "true";
    var file = BlobToFile(values.image, "aboutPage");
    if (file.length !== 0) {
      formData.append("image", file, "aboutPage.jpg");
    }
    formData.append("name", values.name);
    formData.append("email", values.email);
    formData.append("contact_no", values.contact_no);
    formData.append("role", values.role);
    // formData.append("event_types", values.event_types);
    addLifeTimePage(formData);
    try {
      setSuccesMessege("Successfully Added Life Time Member Page Data.");
      setTimeout(() => {
        history("/dashboard/life/time/Member");
      }, [3000]);
    } catch (error) {
      setErrorMessege("Some Error Occur. Please Try Again Later.");
      setTimeout(() => {
        setErrorMessege(null);
      }, [3000]);
    }
  };

  return (
    <Box className="table-design-background">
      <Typography className="dashboard-home-page-text">
        Add Life Time Member
      </Typography>
      <Box style={{ padding: "1.5rem" }}>
        <Form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Controls.Input
                name="name"
                label="Full Name"
                value={values.name}
                onChange={handleInputChange}
              />
              <Controls.Input
                name="email"
                label="Email"
                value={values.email}
                onChange={handleInputChange}
              />
              {/* <Controls.RadioGroup
                name="is_active"
                label="Is Active"
                value={values.is_active}
                onChange={handleInputChange}
                items={[
                  { id: "false", title: "False" },
                  { id: "true", title: "True" },
                ]}
              /> */}
            </Grid>
            <Grid item xs={6}>
              <Controls.Input
                name="contact_no"
                label="Contact Number"
                value={values.contact_no}
                onChange={handleInputChange}
              />
              <Controls.Input
                name="role"
                label="Role"
                value={values.role}
                onChange={handleInputChange}
              />
              {/* <Box
                style={{
                  marginBottom: "12px",
                  marginTop: "7px",
                  marginLeft: "8px",
                }}
              >
                <Controls.Image setFile={setFile} aspectRatio={636 / 566} />
              </Box> */}
            </Grid>
            <Grid>
              <Box style={{ marginLeft: "1.5rem", marginTop: "1rem" }}>
                <Controls.Button
                  type="submit"
                  text="Submit"
                  backgroundColor="#1b284b"
                  onClick={handleSubmit}
                />
              </Box>
            </Grid>
          </Grid>
        </Form>
        {SuccessMessege === null ? null : (
          <Box className="successmessege">
            <Typography className="messegefonts">
              <FontAwesomeIcon icon={faCheckCircle} className="messegeicon" />
              {SuccessMessege}
            </Typography>
          </Box>
        )}
        {ErrorMessege === null ? null : (
          <Box className="errormessege">
            <Typography className="messegefonts">
              <FontAwesomeIcon
                icon={faExclamationTriangle}
                className="messegeicon"
              />
              {ErrorMessege}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};
export default AddLifeTimePage;
