import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Box, Grid, Typography } from "@mui/material";
import Sidebar from "../../component/sidebar";
import Header from "../../component/header";
import Controls from "../forms/controls/Controls";
import { useForm, Form } from "../forms/useForm";
import { useGetBlogsByIdQuery } from "../../../../../api/blogSlice";
import BlobToFile from "../forms/utils/blobToFile";
import { useUpdateBlogMutation } from "../../../../../api/blogSlice";
import urlToFile from "../forms/utils/urlToFile";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCog,
  faBell,
  faSignInAlt,
  faExclamationTriangle,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

const BlogEditPage = () => {
  return (
    <Grid container className="dashboard-full-background">
      <Grid item xs={2} spacing={4}>
        <Sidebar />
      </Grid>
      <Grid item xs={10}>
        <Header />
        <EditBlogPages />
      </Grid>
    </Grid>
  );
};

const EditBlogPages = () => {
  const { id } = useParams();
  const Blogs = useGetBlogsByIdQuery(id);
  const [File, setFile] = useState([]);
  const [updateBlog, setUpdateBlog] = useState([]);
  const [initialFValues, setInitialFValues] = useState({
    image: [],
    subtitle: "",
    description: "",
  });
  const [SuccessMessege, setSuccesMessege] = useState(null);
  const [ErrorMessege, setErrorMessege] = useState(null);
  const history = useNavigate();

  useEffect(() => {
    if (Blogs.data) {
      urlToFile(Blogs.data.image, setFile);
      setInitialFValues({
        pdf_file: File,
        subtitle: Blogs.data.subtitle,
        description: Blogs.data.description,
      });
    }
  }, [Blogs.data]);
  

  const { values, handleImageUpload, handleInputChange } = useForm(
    initialFValues,
    true,
    false,
    true
  );

  const [editBlog] = useUpdateBlogMutation();

  useEffect(() => {
    handleImageUpload("pdf_file", File);
  }, [File]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    var file = BlobToFile(values.pdf_file, "blog");
    if (file.length !== 0) {
      formData.append("pdf_file", file, "blog.jpg");
    }
    if(values.title)
      formData.append("subtitle", values.title);
    // if(values.subtitle)
    //   formData.append("subtitle", values.subtitle);
    if(values.text)
      formData.append("description", values.description);
    formData.append("id", id);
    editBlog(formData)
      .then((res) => {
        setSuccesMessege("Successfully Update Notice Page Data.");
        setTimeout(() => {
          history("/dashboard/blogs");
        }, [3000]);
      })
      .catch((err) => {
        setErrorMessege("Some Error Occur. Please Try Again Later.");
        setTimeout(() => {
          setErrorMessege(null);
        }, [3000]);
      });
  };

  return (
    <Box className="table-design-background">
      <Typography className="dashboard-home-page-text">
        Update News Page
      </Typography>
      <Box style={{ padding: "1.5rem" }}>
        <Form onSubmit={handleSubmit}>
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <Controls.Input
                name="title"
                label="Title"
                value={values.subtitle}
                onChange={handleInputChange}
              />
              {/* <Controls.Input
                name="subtitle"
                label="Sub-Title"
                value={values.subtitle}
                onChange={handleInputChange}
              /> */}
              <Controls.Input
                name="description"
                label="Description"
                value={values.description}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={6}>
              <Controls.Image setFile={setFile} />
              <Controls.File onChange={handleInputChange}  name="pdf_file" />
            </Grid>
            <Grid item xs={2}>
              <Controls.Button
                type="submit"
                text="Submit"
                backgroundColor="#1b284b"
                onClick={handleSubmit}
              />
            </Grid>
          </Grid>
        </Form>
        {SuccessMessege === null ? null : (
          <Box className="successmessege">
            <Typography className="messegefonts">
              <FontAwesomeIcon icon={faCheckCircle} className="messegeicon" />
              {SuccessMessege}
            </Typography>
          </Box>
        )}
        {ErrorMessege === null ? null : (
          <Box className="errormessege">
            <Typography className="messegefonts">
              <FontAwesomeIcon
                icon={faExclamationTriangle}
                className="messegeicon"
              />
              {ErrorMessege}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default BlogEditPage;
