import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Sidebar from "../../component/sidebar";
import { Grid, Typography } from "@mui/material";
import Header from "../../component/header";
import BasicTable from "../tables/BasicTable";
import {
    useGetCardDetailPaginationQuery,
useUpdateCardDetailMutation,
useDeleteCardDetailMutation } from "../../../../../api/cardDetailSlice";
import CardDetailsAddButton from "./cardDetailsAddButton";
import { Box } from "@mui/system";
import { getPaginationPagesCount } from "../../../../../utils/getPaginationPagesCount";
import { stringToDate, stringDateToReadable } from "../../../../../utils/tryParseDateFromString";

const DashboardCardDetails = () => {
  return (
    <Grid container className="dashboard-full-background">
      <Grid item xs={2} spacing={4}>
        <Sidebar />
      </Grid>
      <Grid item xs={10}>
        <Header />
        <DashboardCardDetailsPages />
      </Grid>
    </Grid>
  );
};

const DashboardCardDetailsPages = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [headers, setHeaders] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [status, setStatus] = useState([]);
  const { data, isLoading, isSuccess, isError, error } =
    useGetCardDetailPaginationQuery(currentPage)

  

  const [deleteConstantvalue] = useDeleteCardDetailMutation();

  const deleteConfirm = (e, id) => {
    e.preventDefault();
    
    deleteConstantvalue({ id });
  };

  let content;
  useEffect(()=>{
  if (isLoading) {
    content = <p>"Loading..."</p>;
  } else if (isSuccess) {
    setHeaders(["Mr Name", "Card No", "Returning Date", "Booking Date", "Hospital"]);
    let tableDatas = [];
    data.forEach((key) => {
      tableDatas.push({
        "Mr Name": key?.bookings_data?.mr_id?.user?.first_name+" "+key?.bookings_data?.mr_id?.user?.last_name,
        "Card No": key?.card_no,
        "Returning Date": stringDateToReadable(key?.returning_date),
        "Booking Date": stringDateToReadable(key?.bookings_data?.hospital_date_id?.date),
        "Hospital": key?.bookings_data?.hospital_date_id?.hospital?.name,
        
        id: key.id,
      });
    });
    setTableData(tableDatas);
    setTotalPages(getPaginationPagesCount(data.count, 10));  }},[isLoading, isSuccess, data])
  return (
    <>
      <Box className="table-design-background">
        <Grid container>
          <Grid item xs={6}>
            <Typography className="dashboard-home-page-text">
              Card Details List
            </Typography>
          </Grid>
        </Grid>
        <Box style={{ padding: "1.5rem" }}>
          <BasicTable
            headers={headers}
            data={tableData}
            isDelete={true}
            deleteColor={"#f50057"}
            isEdit={false}
            editColor={"#3f51b5"}
            addColor={""}
            tableName={"card-details"}
            deleteConfirm={deleteConfirm}
            activeSign={['Active']}
            setCurrentPage={setCurrentPage}
            setTotalPages={setTotalPages}
            totalPages={totalPages}
          />
        </Box>
      </Box>
    </>
  );
};
export default DashboardCardDetails;
