import React, { useEffect, useRef, useState } from "react";
import Sidebar from "../../component/sidebar";
import { Container, Grid, Typography } from "@mui/material";
import Header from "../../component/header";
import { Box } from "@mui/system";
import {
  useGetMemberRegistrationsByIdQuery,
} from "../../../../../api/memberRegistrationSlice";
import { useParams } from "react-router-dom";
import Logo from "../../../../../static/image/icon.png";
import html2canvas from "html2canvas";
import IdcardImage from "../../../../../static/image/idcardimage.png";
import { useGetImageBase64Mutation } from "../../../../../api/userSlice";
import DigitalSignature from "../../../../../static/image/digitalsignature.png";


const MemberCard = () => {
  return (
    <Grid container className="dashboard-full-background">
      <Grid item xs={2} spacing={4}>
        <Sidebar />
      </Grid>
      <Grid item xs={10}>
        <Header />
        <MemberCardPage />
      </Grid>
    </Grid>
  );
};


const MemberCardPage = () => {
  const { id } = useParams();
  const [img, setImage] = useState("");
  const MemberData = useGetMemberRegistrationsByIdQuery(id);
  

  const [image] = useGetImageBase64Mutation()

  useEffect(() => {
    if (MemberData.status == "fulfilled" && MemberData !== undefined) {
      
      image({ 'user_id': MemberData?.data?.user?.id }).then((res) => {
        setImage(res.data)
      })
    }
  }, [MemberData])

  console.log('MemberData',MemberData);

  const componentRef = useRef(null);
  const captureComponentAsImage = async () => {
    // Capture the component as a canvas
    const canvas = await html2canvas(componentRef.current);

    // Convert the canvas to an image data URL (PNG format)
    const imageDataURL = canvas.toDataURL("idcard/png");

    // Create a download link and trigger the download
    const a = document.createElement("a");
    a.href = imageDataURL;
    a.download = "captured-image.png";
    a.click();
  }
  const transform = `rotate(${-90}deg)`;

  return (
    <>
      <Box style={{ paddingBottom: "3rem" }}>
        <Box>
          <Container>
            <Grid container spacing={0}>
              <Grid item xs={5} style={{ margin: "0 auto" }}>
                <Box style={{ marginBottom: "10px" }}>
                  <Grid container>
                    <Grid item xs={8}>
                      <Typography style={{ color: "white", textAlign: "left", fontWeight: '700', fontSize: '16px' }}>Identity Card</Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <button className="id-card-download-button" onClick={captureComponentAsImage}>Download Id Card</button>
                    </Grid>
                  </Grid>
                </Box>
                <Box >
                  <Box className="" style={{
                    backgroundImage: `url(${IdcardImage})`,
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    borderRadius: '20px',
                    zIndex: 2,
                    width: '480px',
                    height: "640px"
                  }} ref={componentRef}>
                    {
                      MemberData !== undefined ?
                        <>
                          {
                            MemberData.status === 'fulfilled' ?
                              <>
                                <Box
                                  style={{
                                    marginTop: "2rem",
                                    // backgroundColor: "white",
                                    padding: "1rem",
                                    borderRadius: "20px",
                                    paddingBottom: "2rem",
                                    width: '480px',
                                    maxHeight: "640px"
                                  }}
                                >
                                  <Box style={{ marginBottom: '0px' }}>
                                    <p style={{ marginBottom: "-10px", fontSize: '13px', textAlign: 'end', marginRight: '10px', color: '#046704', fontWeight: '600' }}>NTU Regd Number: 85\059 </p>
                                  </Box>
                                  {/* <img src={Logo} style={{ position: 'absolute', width: '300px', height: "300px", opacity: "0.2", left: "48.5%", top: "46%" }} alt="IdcardImage" /> */}
                                  <Grid container >
                                    <Grid item xs={2.5}>
                                      <img src={Logo} style={{ marginLeft: "13px" }} className="img-fluid" />
                                    </Grid>
                                    <Grid item xs={9.5}>
                                      <Box className="id-card-border-design">
                                      </Box>
                                      <Box className="id-card-height">
                                        <h1 className="id-card-company-name">NEPAL MEDICAL AND SALES REPRESENTATIVE ASSOCIATION</h1>
                                      </Box>
                                      <Box className="id-card-border-design1">
                                      </Box>
                                    </Grid>
                                  </Grid>
                                  <Box style={{ marginTop: "10px" }}>
                                    <Grid container spacing={3}>
                                      <Grid item xs={1.8}>
                                        <h1 className="id-card-name">I<br /> D<br />E<br />N<br />T<br />I<br />T<br />Y <br /> <br />C<br />A<br />R<br />D</h1>
                                      </Grid>
                                      <Grid item xs={9.2}>
                                        <Grid container spacing={2}>
                                          <Grid item md={3}></Grid>
                                          <Grid item md={6}>
                                            <Box style={{ alignSelf: 'center' }}>
                                              <img src={`data:image/png;base64,${img}`} style={{ position: 'relative' }} className="id-card-image-size" />
                                              <img src={DigitalSignature} style={{ position: "absolute", width: "100px", top: '26.2rem' }} alt="signature" />
                                            </Box>
                                          </Grid>
                                          <Grid item ms={3}>

                                          </Grid>
                                          <Grid item xs={12}>
                                            <Box style={{ marginTop: "20px" }}>
                                              <h1 className="id-card-name1">{MemberData.data.user.first_name} {MemberData.data.user.middle_name === null ? 'name' : MemberData.data.user.middle_name} {MemberData.data.user.last_name}</h1>
                                            </Box>
                                            <Box style={{ marginTop: "15px", position: 'relative' }}>
                                              <Grid container >
                                                <Grid item xs={6}>
                                                  {/* <p className="status-id-card-text">Status:</p> */}
                                                  <p className="status-id-card-text">Blood Group:</p>
                                                  <p className="status-id-card-text">Contact Number:</p>
                                                  <p className="status-id-card-text">Address:</p>
                                                  <p className="status-id-card-text">Imm Contact:</p>
                                                  {/* <p className="status-id-card-text">L.f. Number:</p>
                                            <p className="status-id-card-text">NMSRA Number:</p> */}
                                                </Grid>
                                                <Grid item xs={6}>
                                                  {/* <p className="status-id-card-text">Executive Member</p> */}
                                                  <p className="status-id-card-text">{MemberData.data.blood_group ? MemberData.data.blood_group : 'No Blood Group'}</p>
                                                  <p className="status-id-card-text">{MemberData.data.contact_no}</p>
                                                  <p className="status-id-card-text new-text-capitized">{MemberData.data.permanent_local_level}</p>
                                                  <p className="status-id-card-text">{MemberData.data.emergency_contact}</p>
                                                  {/* <p className="status-id-card-text">{MemberData.data.user.lf_no ? MemberData.data.user.lf_no : 'No L.f. Number'}</p>
                                            <p className="status-id-card-text">{MemberData.data.user.nmsra_no ? MemberData.data.user.nmsra_no : 'No NMSRA Number'}</p> */}
                                                </Grid>
                                              </Grid>
                                            </Box>
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                      <Grid item xs={1}>
                                        <p className="upside-down-text" style={{ transform, marginTop: "20rem" }}>this card is valid upto dec {new Date().getFullYear()}</p>
                                      </Grid>
                                    </Grid>
                                    <Box style={{ paddingTop: '2.4rem', position: 'relative' }}>
                                      <Grid container spacimg={3}>
                                        <Grid item xs={3.5}>
                                          <p className="white-text-card">Membership Number: {MemberData.data.user.nmsra_no}</p>
                                          <p className="white-text-card">L.f. Number: {MemberData.data.user.lf_no}</p>
                                          <p className="white-text-card">Date of issue: Jan {new Date().getFullYear()}</p>
                                        </Grid>
                                        <Grid >
                                          <p style={{ borderLeft: '3px solid white', height: '50px', borderRadius: '10px', marginLeft: '10px', marginTop: '7px' }}></p>
                                        </Grid>
                                        <Grid item xs={7.5}>
                                          <Box style={{ marginLeft: '12px' }}>
                                            <p className="white-text-card">Janakpur, Nepal, Ph: 9844008353, 9854027006</p>
                                            <p className="white-text-card">nmsrajanakpur@gmail.com</p>
                                            <p className="white-text-card">URL: www.nmsrajnk.org</p>
                                          </Box>
                                        </Grid>
                                      </Grid>
                                    </Box>
                                  </Box>
                                </Box>
                              </>
                              : null
                          }
                        </> : null
                    }
                  </Box>
                </Box>
              </Grid>
            </Grid>

          </Container>
        </Box>
      </Box>
    </>
  )
}

export default MemberCard;