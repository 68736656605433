import {
    createSelector,
    createEntityAdapter
} from "@reduxjs/toolkit";

import { apiSlice } from "./apiSlice";

const DoctorCallCardsAdapter = createEntityAdapter()

const initialState = DoctorCallCardsAdapter.getInitialState()

export const DoctorCallCardSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getDoctorCallCards: builder.query({
            query: () => '/bookings/doctor-call-card/',
            transformResponse: responseData => {
                return DoctorCallCardsAdapter.setAll(initialState, responseData)
            },
            providesTags: (result, error, arg) =>
                [
                    { type: 'DoctorCallCards', id: "LIST" },
                    ...result.ids.map(id => ({ type: 'DoctorCallCards', id }))
                ]
        }),
        getDoctorCallCardsPagination: builder.query({
            query: (page) => ({
                url: `/bookings/doctor-call-card-pagination/?page=${page}`,
                method: 'GET',
            }),
            providesTags: ['DoctorCallCards']
        }),
        getDoctorCallCardsById: builder.query({
            query: (id) => ({
                url: `/bookings/doctor-call-card/${id}/`,
                method: 'GET',
            }),
            transformResponse: responseData => {
                responseData['call_date'] = new Date(responseData['call_date'])
                return responseData
            },
            providesTags: ['DoctorCallCards']
        }),
        getDoctorCallCardsDateFilter: builder.mutation({
            query: (body) => ({
                url: `/bookings/doctor-call-card/date_filter/`,
                method: 'POST',
                body: { 'date': body }
            }),
            providesTags: ['DoctorCallCards']
        }),
        getDoctorCallCardsMemberPagination: builder.query({
            query: (page) => ({
                url: `/bookings/doctor-call-card-pagination/?page=${page['page']}&mr=${page['mr']}`,
                method: 'GET',
            }),
            providesTags: ['DoctorCallCards']
        }),
        getDoctorSimCardsMemberPagination: builder.query({
            query: (page) => ({
                url: `/bookings/doctor-sim-card-pagination/?page=${page['page']}&mr=${page['mr']}`,
                method: 'GET',
            }),
            providesTags: ['DoctorCallCards']
        }),
        getAllSimCardsMemberPagination: builder.query({
            query: (page) => ({
                url: `/bookings/doctor-sim-card-pagination/?page=${page}`,
                method: 'GET',
            }),
            providesTags: ['DoctorCallCards']
        }),
        getDoctorSimCardsMemberPaginationById: builder.query({
            query: (id) => ({
                url: `/bookings/doctor-sim-card-pagination/${id}/`,
                method: 'GET',
            }),
            providesTags: ['DoctorCallCards']
        }),
        addSimCard: builder.mutation({
            query: (DoctorCallCard) => {
                return {
                    url: '/bookings/doctor-sim-card-pagination/',
                    method: 'POST',
                    body: DoctorCallCard
                }
            },
            invalidatesTags: ['DoctorCallCards']
        }),
        addDoctorCallCard: builder.mutation({
            query: (DoctorCallCard) => {
                return {
                    url: '/bookings/doctor-call-card/',
                    method: 'POST',
                    body: DoctorCallCard
                }
            },
            invalidatesTags: ['DoctorCallCards']
        }),
        updateDoctorCallCard: builder.mutation({

            query: (updateDoctorCallCard) => {
                return {
                    url: `/bookings/doctor-call-card/${updateDoctorCallCard.get('id')}/`,
                    method: 'PATCH',
                    body: updateDoctorCallCard
                }
            },
            invalidatesTags: ['DoctorCallCards']
        }),
        updateSimCard: builder.mutation({

            query: (updateDoctorCallCard) => {
                return {
                    url: `/bookings/doctor-sim-card-pagination/${updateDoctorCallCard.get('id')}/`,
                    method: 'PATCH',
                    body: updateDoctorCallCard
                }
            },
            invalidatesTags: ['DoctorCallCards']
        }),
        deleteDoctorCallCard: builder.mutation({
            query: ({ id }) => {
                return {
                    url: `/bookings/doctor-call-card/${id}/`,
                    method: 'DELETE',
                    body: id
                }
            },
            invalidatesTags: ['DoctorCallCards']
        }),
        deleteSimCard: builder.mutation({
            query: ({ id }) => {
                return {
                    url: `/bookings/doctor-sim-card-pagination/${id}/`,
                    method: 'DELETE',
                    body: id
                }
            },
            invalidatesTags: ['DoctorCallCards']
        }),
    })
})

export const {
    useGetDoctorCallCardsQuery,
    useAddDoctorCallCardMutation,
    useUpdateDoctorCallCardMutation,
    useDeleteDoctorCallCardMutation,
    useGetDoctorCallCardsByIdQuery,
    useGetDoctorCallCardsPaginationQuery,
    useGetDoctorCallCardsMemberPaginationQuery,
    useGetDoctorCallCardsDateFilterMutation,
    useGetDoctorSimCardsMemberPaginationQuery,
    useAddSimCardMutation,
    useGetDoctorSimCardsMemberPaginationByIdQuery,
    useDeleteSimCardMutation,
    useGetAllSimCardsMemberPaginationQuery,
    useUpdateSimCardMutation,
} = DoctorCallCardSlice

// returns the query result object
export const selectDoctorCallCardsResult = DoctorCallCardSlice.endpoints.getDoctorCallCards.select()

// Creates memoized selector
const selectDoctorCallCardssData = createSelector(
    selectDoctorCallCardsResult,
    DoctorCallCardsResult => DoctorCallCardsResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllDoctorCallCards,
    selectById: selectDoctorCallCardById,
    selectIds: selectIds
    // Pass in a selector that returns the posts slice of state
} = DoctorCallCardsAdapter.getSelectors(state => selectDoctorCallCardssData(state) ?? initialState)
