import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import axios from "axios";
import Cookies from "js-cookie";
import axiosInstance from "../constant/axios";
import { BASE_URL } from "../constant/baseURL";
import {setCredentials, logOut} from "./authSlice";


const baseQuery = fetchBaseQuery({
  baseUrl: BASE_URL,
  prepareHeaders: (headers, { getState }) => {
      const token = Cookies.get('access')
      if (token) {
          headers.set("Authorization", `Bearer ${token}`)
      }
      return headers
  }
})
const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions)

  if (result?.error?.status === 401) {
      
      // send refresh token to get new access token 
      const refreshResult = await axios.post(`${BASE_URL}/user/token/refresh/`,{refresh: Cookies.get('refresh')})
      if (refreshResult?.data?.access) {
          // store the new token 
          Cookies.set('access', refreshResult.data.access);
          // retry the original query with new access token 
          result = await baseQuery(args, api, extraOptions)
      } else {
          api.dispatch(logOut())
      }
  }

  return result
}
export const apiSlice = createApi({
  reducerPath: "api", // optional
  baseQuery: baseQueryWithReauth,
  tagTypes: [
    "Blogs",
    "HomePages",
    "AboutPages",
    "ImageGallerys",
    "Contacts",
    "Testimonials",
    "Works",
    "Missions",
    "Visions",
    "Members",
    "DonateInformations",
    "Chairpersons",
    "Hospitals",
    "HopsitalHolidays",
    "MembershipPeriod",
    "LandingPages",
    "News",
    "Activities",
    "MrRegistration",
    "Mrid",
    "searchFliterPage",
    "Payment",
    "ChangePassword",
    "Stats",
    'KathmanduPresidents',
    'ExecutiveBodiess',
    'OfficeStaffs',
    'CentralPresidents',
    'HospitalBookings',
    'AdminHospitalBookings'
  ],
  endpoints: (builder) => ({}),
});






