import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Box, Grid, Typography } from "@mui/material";
import Sidebar from "../../component/sidebar";
import Header from "../../component/header";
import Controls from "../forms/controls/Controls";
import { useForm, Form } from "../forms/useForm";
import { useGetVisionsByIdQuery } from "../../../../../api/visionSlice";
import BlobToFile from "../forms/utils/blobToFile";
import { useUpdateVisionMutation } from "../../../../../api/visionSlice";
import urlToFile from "../forms/utils/urlToFile";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCog,
  faBell,
  faSignInAlt,
  faExclamationTriangle,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

const VisionEditPage = () => {
  return (
    <Grid container className="dashboard-full-background">
      <Grid item xs={2} spacing={4}>
        <Sidebar />
      </Grid>
      <Grid item xs={10}>
        <Header />
        <EditVisionPages />
      </Grid>
    </Grid>
  );
};

const EditVisionPages = () => {
  const { id } = useParams();

  const Visions = useGetVisionsByIdQuery(id);
  const [File, setFile] = useState([]);
  const [updateVision, setUpdateVision] = useState([]);
  const [initialFValues, setInitialFValues] = useState({
    vision_name: "",
    is_active: false,
  });

  const [SuccessMessege, setSuccesMessege] = useState(null);
  const [ErrorMessege, setErrorMessege] = useState(null);
  const history = useNavigate();

  useEffect(() => {
    if (Visions.data) {
      urlToFile(Visions.data.image, setFile);
      setInitialFValues({
        vision_name: Visions.data.vision_name,
        is_active: Visions.data.is_active,
      });
    }
  }, [Visions.data]);

  const { values, handleImageUpload, handleInputChange } = useForm(
    initialFValues,
    true,
    false,
    true
  );

  const [editVision] = useUpdateVisionMutation();

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    if (values.vision_name) formData.append("vision_name", values.vision_name);
    if (values.is_active) formData.append("is_active", values.is_active);
    formData.append("id", id);
    editVision(formData)
      .then((res) => {
        setSuccesMessege("Successfully Updated Visions Data.");
        setTimeout(() => {
          history("/dashboard/vision");
        }, [3000]);
      })
      .catch((err) => {
        setErrorMessege("Some Error Occur. Please Try Again Later.");
        setTimeout(() => {
          setErrorMessege(null);
        }, [3000]);
      });
  };

  return (
    <Box className="table-design-background">
      <Typography className="dashboard-home-page-text">
        Update Vision List
      </Typography>
      <Box style={{ padding: "1.5rem" }}>
        <Form onSubmit={handleSubmit}>
          <Grid container>
            <Grid item xs={6}>
              <Controls.Input
                name="vision_name"
                label="Vision Name"
                value={values.vision_name}
                onChange={handleInputChange}
              />

              <Controls.RadioGroup
                name="is_active"
                label="Is Active"
                value={values.is_active.toString()}
                onChange={handleInputChange}
                items={[
                  { id: "false", title: "False" },
                  { id: "true", title: "True" },
                ]}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid style={{ marginTop: "12px" }}>
              <Controls.Button
                type="submit"
                text="Submit"
                backgroundColor="#1b284b"
                onClick={handleSubmit}
              />
            </Grid>
          </Grid>
        </Form>
        {SuccessMessege === null ? null : (
          <Box className="successmessege">
            <Typography className="messegefonts">
              <FontAwesomeIcon icon={faCheckCircle} className="messegeicon" />
              {SuccessMessege}
            </Typography>
          </Box>
        )}
        {ErrorMessege === null ? null : (
          <Box className="errormessege">
            <Typography className="messegefonts">
              <FontAwesomeIcon
                icon={faExclamationTriangle}
                className="messegeicon"
              />
              {ErrorMessege}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default VisionEditPage;
