import React, { useEffect, useState } from "react";
import Sidebar from "../../component/sidebar";
import { Grid, Typography } from "@mui/material";
import Header from "../../component/header";
import { Box } from "@mui/system";
import BlobToFile from "../../../../../components/forms/utils/blobToFile";
import {
  useAddMemberRegistrationMutation,
  useGetMemberRegistrationsByIdQuery,
  useUpdateMemberRegistrationMutation,
} from "../../../../../api/memberRegistrationSlice";
import { useNavigate } from "react-router-dom";
import { useForm1 } from "../../../../../components/forms/useForm";
import moment from "moment";
import MemberFormPage from "./memberForm";
import Cookies from "js-cookie";
import { useForm } from "../forms/useForm";
import { useParams } from "react-router-dom";
import urlToFile from "../forms/utils/urlToFile";
import { useGetMrRegistrationPaymentQuery } from "../../../../../api/mrRegistrationSlice";
import axios from "axios";
import { BASE_URL } from "../../../../../constant/baseURL";
import { useGetMrIDByAccessTokenQuery } from "../../../../../api/userSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";

const MemberEditDashboardPage = () => {
  return (
    <Grid container className="dashboard-full-background">
      <Grid item md={2} xs={12}>
        <Sidebar />
      </Grid>
      <Grid item md={10} xs={12} className="res-margin-design1">
        <Header />
        <DashboardActivityPagesPages />
      </Grid>
    </Grid>
  );
};

const DashboardActivityPagesPages = () => {
  const id = useGetMrIDByAccessTokenQuery(Cookies.get("access"));


  const [File1, setFile1] = useState([]);
  const [File2, setFile2] = useState([]);
  const [File3, setFile3] = useState([]);
  const [File4, setFile4] = useState([]);
  const [SuccessMessege, setSuccesMessege] = useState(null);
  const [ErrorMessege, setErrorMessege] = useState(null);
  const [initialFValues, setInitialFValues] = useState({
    first_name: "",
    middle_name: "",
    last_name: "",
    email: "",
    permanent_street: "",
    permanent_ward_no: null,
    permanent_local_level: "",
    permanent_district: "",
    permanent_country: "",
    temporary_street: "",
    temporary_ward_no: null,
    temporary_local_level: "",
    temporary_district: "",
    contact_no: "",
    blood_group: "",
    name_of_company: "",
    date_of_joining: "",
    division: "",
    current_position: "",
    territory: "",
    academic_degree: "",
    academic_faculty: "",
    appointment_letter: [],
    academic_transcript: [],
    confirmation: false,
    password: "",
    cpassword: "",
    payment_details: [],
    emergency_contact: "",
    year: "",
    nmsra_no: "",
    lf_no: "",
  });
  const MemberData = useGetMemberRegistrationsByIdQuery(id?.data?.id);

  const PaymentData = useGetMrRegistrationPaymentQuery(MemberData?.data?.id);
  const [newData, setNewData] = useState([]);
  const [NewDate, setNewDate] = useState(null);

  useEffect(() => {
    if (MemberData.data) {
      if (MemberData.data.user.profile_pic) {
        urlToFile(MemberData.data.user.profile_pic, setFile1);
      }
      if (MemberData.data.appointment_letter) {
        urlToFile(MemberData.data.appointment_letter, setFile2);
      }
      if (MemberData.data.academic_transcript) {
        urlToFile(MemberData.data.academic_transcript, setFile3);
      }
      if (MemberData.data.date_of_joining) {
        const data = new Date(MemberData.data.date_of_joining)
        setNewDate(data);
      }
      if (PaymentData.data) {
        if (PaymentData.data.length != 0) {
          if (PaymentData.data[0].payment_details) {
            urlToFile(PaymentData.data[0].payment_details, setFile4);
          }
          if (PaymentData.data[0].year) {
            setNewData(PaymentData.data[0].year);
          }
        }
      }

      setInitialFValues({
        first_name: MemberData.data.user.first_name,
        middle_name: MemberData.data.user.middle_name,
        last_name: MemberData.data.user.last_name,
        email: MemberData.data.user.email,
        permanent_street: MemberData.data.permanent_street,
        permanent_ward_no: MemberData.data.permanent_ward_no,
        permanent_local_level: MemberData.data.permanent_local_level,
        permanent_district: MemberData.data.permanent_district,
        permanent_country: MemberData.data.permanent_country,
        temporary_street: MemberData.data.temporary_street,
        temporary_ward_no: MemberData.data.temporary_ward_no,
        temporary_local_level: MemberData.data.temporary_local_level,
        temporary_district: MemberData.data.temporary_district,
        contact_no: MemberData.data.contact_no,
        blood_group: MemberData.data.blood_group,
        name_of_company: MemberData.data.name_of_company,
        date_of_joining: NewDate,
        division: MemberData.data.division,
        current_position: MemberData.data.current_position,
        territory: MemberData.data.territory,
        academic_degree: MemberData.data.academic_degree,
        academic_faculty: MemberData.data.academic_faculty,
        appointment_letter: File2,
        academic_transcript: File3,
        profile_pic: File1,
        confirmation: false,
        payment_details: File4,
        emergency_contact: MemberData.data.emergency_contact,
        nmsra_no: MemberData.data.user.nmsra_no,
        lf_no: MemberData.data.user.lf_no,
      });
    }
  }, [MemberData.data, PaymentData.data]);
  const { values, handleImageUpload, handleInputChange } = useForm(
    initialFValues,
    true,
    false,
    true
  );




  const history = useNavigate();

  const [updateMemberRegistration] =
    useUpdateMemberRegistrationMutation(values);

  useEffect(() => {
    handleImageUpload("profile_pic", File1);
  }, [File1]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    var file1 = BlobToFile(values.profile_pic, "homePage1");
    if (file1.length !== 0) {
      formData.append("profile_pic", file1, "profile_pic.jpg");
    }

    formData.append("id", id.data.id);
    for (var key in values) {
      if (key !== "profile_pic") {
        formData.append(key, values[key]);
      }
      if (key === "date_of_joining") {
        formData.append(key, moment(values[key]).format("YYYY-MM-DD"));
      }
      if (
        (key === "academic_transcript" || key === "appointment_letter") &&
        values[key] === undefined
      ) {
        formData.append(key, []);
      }
    }
    updateMemberRegistration(formData)
      .then((res) => {
        setSuccesMessege("Successfully Edited User Updated.");
        setTimeout(() => {
          history("/dashboard/member/edit/data");
        }, [3000]);
      })
      .catch((err) => {
        setErrorMessege("Some Error Occur. Please Try Again Later.");
        setTimeout(() => {
          setErrorMessege(null);
        }, [3000]);
      });
  };
  return (
    <>
      <Box className="table-design-background">
        <Grid container>
          <Grid item xs={12} md={6}>
            <Typography className="dashboard-home-page-text">
              Edit Member Lists
            </Typography>
          </Grid>
        </Grid>
        <Box
          style={{
            paddingLeft: "1.5rem",
            paddingRight: "1.5rem",
            paddingBottom: "1.5rem",
          }}
        >
          <MemberFormPage
            handleInputChange={handleInputChange}
            values={values}
            handleSubmit={handleSubmit}
            handleFileUpload={handleImageUpload}
            setImage={setFile1}
            MemberData={MemberData}
            PaymentData={PaymentData}
          />
        </Box>
        {SuccessMessege === null ? null : (
          <Box className="successmessege">
            <Typography className="messegefonts">
              <FontAwesomeIcon icon={faCheckCircle} className="messegeicon" />
              {SuccessMessege}
            </Typography>
          </Box>
        )}
        {ErrorMessege === null ? null : (
          <Box className="errormessege">
            <Typography className="messegefonts">
              <FontAwesomeIcon
                icon={faExclamationTriangle}
                className="messegeicon"
              />
              {ErrorMessege}
            </Typography>
          </Box>
        )}
      </Box>
    </>
  );
};
export default MemberEditDashboardPage;
