import React, { useEffect, useState } from 'react'
import { makeStyles } from "@material-ui/core";

export function useForm(initialFValues, validateOnChange = false, validate, edit) {
    const [values, setValues] = useState(initialFValues);
    
    const [valueArray, setValueArray] = useState([]);
    const [errors, setErrors] = useState({});

    useEffect(()=>{
        if(edit)
        {
            setValues(initialFValues);
        }
    },[initialFValues])

    const handleInputChange = (e) => {

        const { name, value } = e.target
        
        
        
        setValues({
            ...values,
            [name]: value
        })
        // if (validateOnChange)
        //     validate({ [name]: value })
    }

    const handleMultipleInput = (e) =>{
        const {name, value} = e.target;
        setValueArray(typeof value === 'string' ? value.split(',') : value)
        setValues({
            ...values,
            [name]: value
        })
        if (validateOnChange)
            validate({ [name]: value })
    }

    const resetForm = () => {
        setValues(initialFValues);
        setErrors({})
    }

    const handleSearchClick = (e) =>{
        const keys = Object.keys(values);
        setValues({
            ...values,
            [keys[0]]: e
        })
    }

    const handleImageUpload=(name, file) =>{
        setValues({
            ...values,
            [name]: file
        })
    }

    return {
        values,
        setValues,
        errors,
        setErrors,
        handleInputChange,
        resetForm,
        handleMultipleInput,
        valueArray,
        handleSearchClick,
        handleImageUpload
    }
}


const useStyles = makeStyles(theme => ({
    root: {
        '& .MuiFormControl-root': {
            width: '80%',
            margin: theme.spacing(1)
        }
    }
}))

export function Form(props) {

    const classes = useStyles();
    const { children, ...other } = props;
    return (
        <form className={classes.root} autoComplete="off" {...other}>
            {props.children}
        </form>
    )
}