import React from "react";
import Footer from "../component/footer";
import Header from "../component/header";
import Sign from "../../../static/image/about/sign.png";
import Image1 from "../../../static/image/about/about-1.jpg";
import Image2 from "../../../static/image/about/about-2.jpg";
import Image3 from "../../../static/image/about/about-3.jpg";
import Image4 from "../../../static/image/about/about-4.jpg";
import Image5 from "../../../static/image/about/3.png";
import Image6 from "../../../static/image/about/4.png";
import Image7 from "../../../static/image/about/1.png";
import Image8 from "../../../static/image/about/2.png";
import Image9 from "../../../static/image/about/5.png";
import Image01 from "../../../static/image/about/6.png";
import { useGetAboutPagesQuery } from "../../../api/aboutPageSlice";
import { Box } from "@mui/material";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

const About = () => {
  return (
    <>
      <Header />
      <AboutPage />
      <Footer />
    </>
  );
};

const AboutPage = () => {
  const data = useGetAboutPagesQuery();

  return (
    <div style={{ textAlign: "start" }}>
      <section className="page-title bg-1">
        <div className="overlay"></div>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="block text-center">
                <span className="text-white">About Organization</span>
                <h1 className="text-capitalize mb-5 text-lg">
                  About Organization
                </h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      {data !== undefined ? (
        <>
          {data.status === "fulfilled" ? (
            <>
              {data.data.ids.length === 0 ? (
                <section className="section">
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-6">
                        <Box>
                          <SkeletonTheme color="grey" highlightColor="#444">
                            <p>
                              <Skeleton height={350} width={"100%"} count={1} />
                            </p>
                          </SkeletonTheme>
                        </Box>
                      </div>
                      <div className="col-lg-6">
                        <div className="section-title">
                          <h2 className="mb-4">
                            <Skeleton />
                          </h2>
                          <div className="divider  my-4"></div>
                          <div className="testimonial-block">
                            <div className="client-info">
                              <h4>
                                <Skeleton height={245} />
                              </h4>
                            </div>
                            {/* <p>{data.data.entities[key].description}</p> */}
                            <i className="icofont-quote-right"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row align-items-center">
                      <div className="col-lg-6 testimonial-wrap offset-lg-6"></div>
                    </div>
                  </div>
                </section>
              ) : (
                <>
                  {data.data.ids.map((key) => (
                    <section className="section" key={key}>
                      <div className="container">
                        <div className="row">
                          <div className="col-lg-6">
                            <img
                              src={data.data.entities[key].image}
                              alt="about-imagee"
                              style={{ width: "100%" }}
                            />
                          </div>
                          <div className="col-lg-6">
                            <div className="section-title">
                              <h2 className="mb-4">
                                {data.data.entities[key].title}
                              </h2>
                              <div className="divider  my-4"></div>
                              <div className="testimonial-block">
                                <div className="client-info">
                                  <h4>{data.data.entities[key].subtitle}</h4>
                                </div>
                                <p>{data.data.entities[key].description}</p>
                                <i className="icofont-quote-right"></i>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row align-items-center">
                          <div className="col-lg-6 testimonial-wrap offset-lg-6"></div>
                        </div>
                      </div>
                    </section>
                  ))}
                </>
              )}
            </>
          ) : (
            <section className="section">
              <div className="container">
                <div className="row">
                  <div className="col-lg-6">
                    <Box>
                      <SkeletonTheme color="grey" highlightColor="#444">
                        <p>
                          <Skeleton height={350} width={"100%"} count={1} />
                        </p>
                      </SkeletonTheme>
                    </Box>
                  </div>
                  <div className="col-lg-6">
                    <div className="section-title">
                      <h2 className="mb-4">
                        <Skeleton />
                      </h2>
                      <div className="divider  my-4"></div>
                      <div className="testimonial-block">
                        <div className="client-info">
                          <h4>
                            <Skeleton height={245} />
                          </h4>
                        </div>
                        {/* <p>{data.data.entities[key].description}</p> */}
                        <i className="icofont-quote-right"></i>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row align-items-center">
                  <div className="col-lg-6 testimonial-wrap offset-lg-6"></div>
                </div>
              </div>
            </section>
          )}
        </>
      ) : null}
    </div>
  );
};

export default About;
