import React, { useEffect, useRef, useState } from "react";
import Header from "../component/header";
import Footer from "../component/footer";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { Grid } from "@material-ui/core";
import { useLoginMutation } from "../../../api/authAPISlice";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";

const Login = () => {
  return (
    <>
      <Header />
      <LoginPage />
      <Footer />
    </>
  );
};

const LoginPage = () => {
  const [email, setEmail] = useState(Cookies.get('email'));
  const [password, setPassword] = useState(Cookies.get('password'));
  const [loginAPI] = useLoginMutation();
  const [SuccessMessege, setSuccesMessege] = useState(null);
  const [ErrorMessege, setErrorMessege] = useState(null);
  const history = useNavigate();



  const rememberCheck = useRef(null);


  const onEmail = (e) => setEmail(e.target.value);
  const onPassword = (e) => setPassword(e.target.value);

  const [showPassword, setShowPassword] = useState(false);

  const handlePasswordToggle = () => {
    setShowPassword(!showPassword);
  };


  const clickBtn = (e) => {
    e.preventDefault();
    let loginData = {};
    if (email.includes('@')) {
      loginData = { 'email': email, 'password': password }
    }
    else {
      loginData = { 'nmsra_no': email, 'password': password }
    }



    loginAPI(loginData)
      .then((res) => {
        if (rememberCheck.current.checked === true) {
          Cookies.set("email", email);
          Cookies.set("password", password);
        } else {
          Cookies.remove("email");
          Cookies.remove("password");
        }
        Cookies.set("access", res.data.data.tokens.access);
        Cookies.set("refresh", res.data.data.tokens.refresh);
        Cookies.set("user_email", res.data.data.email);
        setSuccesMessege("Successfully Logged In.");
        setTimeout(() => {
          if (res.data.data.is_admin == true) {
            Cookies.set('user_role', 'admin')
            history("/dashboard/edit-bookings");
          } else {
            history("/dashboard/edit-bookings-user");
          }
        }, [3000]);
      })
      .catch((err) => {
        setErrorMessege("Email or Password doesnot Match.");
        setTimeout(() => {
          setErrorMessege(null);
        }, [3000]);
      });
  };

  return (
    <Box className="signinmargin res-padding" style={{ textAlign: "start" }}>
      <Grid
        item
        sm={12}
        md={6}
        xs={12}
        style={{ margin: "0 auto" }}
        className="signinauto"
      >
        <Grid container>
          <Grid item md={6} xs={12} className="signinleft ">
            <Box>
              <Grid
                item
                xs={3}
                style={{ margin: "0 auto" }}
                className="signinleftmargin "
              >
                <Box>
                  <Typography className="signintext">Login</Typography>
                </Box>
                <Box>
                  <Typography className="signintext1">
                    or use your account
                  </Typography>
                </Box>
              </Grid>
            </Box>
            <Box className="newforms">
              <Box>
                <lable className="emailtext">Email or NMSRA Number*</lable>
                <input
                  type="email"
                  onChange={onEmail}
                  className="titleform2"
                  placeholder="Enter Your Email or NMSRA Number"
                  value={email}
                />
              </Box>
              <Box className="passwordbtn">
                <lable className="emailtext">Password*</lable>
                <Grid container>
                  <Grid item xs={11}>
                    <input
                      type={showPassword ? 'text' : 'password'}
                      onChange={onPassword}
                      className="titleform3"
                      placeholder="Enter Your Password"
                      value={password}
                    />
                  </Grid>
                  <Grid item xs={1}>
                    <Box className="titleform4" onClick={handlePasswordToggle}>
                      {showPassword ? < AiFillEyeInvisible size={20} /> : <AiFillEye size={20} />}
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              <Grid container>
                <Grid item xs={6}>
                  <Box className="remembermargin">
                    <input type="checkbox" ref={rememberCheck} />
                    <lable className="fs_13">Remember Me</lable>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box className="forgotpassword1">
                    <Link
                      className="forgotpassword"
                      to="/forgetpassword/emailverification"
                    >
                      Forgot Password?
                    </Link>
                  </Box>
                </Grid>
              </Grid>
              <button
                onClick={clickBtn}
                className="signinbutton"
                style={{ border: "none" }}
              >
                Login
              </button>
            </Box>
          </Grid>
          <Grid item md={6} xs={12} className="leftcolor">
            <Box className="signinnayamargin">
              <Typography className="accounttext">
                Are you already registered to NMSRA?
              </Typography>
              <Link to="/signup">
                <button className="signupnewbtn">Old Sign Up</button>
              </Link>
              {/* <Typography className="accounttext" style={{ marginTop: "40px" }}>
                Are you new to NMSRA?
              </Typography>
              <Link to="/signup-new">
                <button className="signupnewbtn">New Sign Up</button>
              </Link> */}
            </Box>
          </Grid>
        </Grid>
      </Grid>
      {SuccessMessege === null ? null : (
        <Box className="successmessege">
          <Typography className="messegefonts">
            <FontAwesomeIcon icon={faCheckCircle} className="messegeicon" />
            {SuccessMessege}
          </Typography>
        </Box>
      )}
      {ErrorMessege === null ? null : (
        <Box className="errormessege">
          <Typography className="messegefonts">
            <FontAwesomeIcon
              icon={faExclamationTriangle}
              className="messegeicon"
            />
            {ErrorMessege}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default Login;
