import React, { useState, useMemo } from "react";
import {
  faCheckCircle,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Grid } from "@material-ui/core";
import { Button, Typography } from "@mui/material";
import { Box } from "@mui/system";
import countryList from "react-select-country-list";
import { Stepper } from "react-form-stepper";
import MultiStep from "react-multistep";
import Controls from "../../../../../components/forms/controls/Controls";
import moment from "moment/moment";

const Step1 = ({ values, handleInputChange }) => {
  const [userType, setUserType] = useState(false);

  const onUserType = (e) => {
    if (e.target.value == "nmsra_member") {
      setUserType(true);
    } else {
      setUserType(false);
    }
  };
  return (
    <Box style={{ marginBottom: "15px" }}>
      <Grid container spacing={3}>
        <Grid item md={4} xs={12}>
          <Box>
            <Controls.Input
              onChange={handleInputChange}
              value={values.first_name}
              name={"first_name"}
              label={"First Name"}
            />
          </Box>
        </Grid>
        <Grid item md={4} xs={12}>
          <Box>
            <Controls.Input
              onChange={handleInputChange}
              value={values.middle_name}
              name={"middle_name"}
              label={"Middle Name"}
            />
          </Box>
        </Grid>
        <Grid item md={4} xs={12}>
          <Box>
            <Controls.Input
              onChange={handleInputChange}
              value={values.last_name}
              name={"last_name"}
              label={"Last Name"}
            />
          </Box>
        </Grid>
      </Grid>
      <Box className="passwordbtn">
        <Controls.Input
          onChange={handleInputChange}
          value={values.email}
          name={"email"}
          label={"Email"}
        />
      </Box>
      <Grid container spacing={3}>
        <Grid item md={6} xs={12}>
          <Box className="passwordbtn">
            <Controls.Input
              onChange={handleInputChange}
              value={values.contact_no}
              name={"contact_no"}
              label={"Contact No"}
            />
          </Box>
        </Grid>
        <Grid item md={6} xs={12}>
          <Box className="passwordbtn">
            <Controls.Input
              onChange={handleInputChange}
              value={values.emergency_contact}
              name={"emergency_contact"}
              label={"Family Contact"}
            />
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={3}></Grid>
    </Box>
  );
};
const Step2 = ({ values, handleInputChange }) => {
  const countryLists = [];
  const options = useMemo(() => countryList().getData(), []);
  options.forEach((country) => {
    countryLists.push({ id: country.value, title: country.label });
  });
  return (
    <Box>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Box className="passwordbtn">
            <Controls.Input
              onChange={handleInputChange}
              value={values.permanent_street}
              name="permanent_street"
              label="Tole/Street"
            />
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box className="passwordbtn">
            <Controls.Input
              onChange={handleInputChange}
              value={values.permanent_ward_no}
              name={"permanent_ward_no"}
              label={"Ward Number"}
            />
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Box className="passwordbtn mt-2">
            <Controls.Select
              onChange={handleInputChange}
              value={values.permanent_country}
              name={"permanent_country"}
              label={"Permanent Country"}
              options={[
                { id: "Nepal", title: "Nepal" },
                { id: "India", title: "India" },
              ]}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box className="passwordbtn">
            <Controls.Input
              onChange={handleInputChange}
              value={values.permanent_district}
              name={"permanent_district"}
              label={"Permanent District"}
            />
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Box className="passwordbtn">
            <Controls.Input
              onChange={handleInputChange}
              value={values.permanent_local_level}
              name={"permanent_local_level"}
              label={"Permanent Local Level"}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
const Step3 = ({ values, handleInputChange }) => {
  return (
    <Box>
      <Box className="mt-4">
        <span style={{ fontWeight: "bolder" }}>Temporary Address</span>
      </Box>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Box className="passwordbtn">
            <Controls.Input
              onChange={handleInputChange}
              value={values.temporary_street}
              name={"temporary_street"}
              label={"Temporary Street"}
            />
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box className="passwordbtn">
            <Controls.Input
              onChange={handleInputChange}
              value={values.temporary_ward_no}
              name={"temporary_ward_no"}
              label={"Temporary Ward No"}
            />
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Box className="passwordbtn">
            <Controls.Input
              onChange={handleInputChange}
              value={values.temporary_local_level}
              name={"temporary_local_level"}
              label={"Temporary Local Level"}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box className="passwordbtn">
            <Controls.Input
              onChange={handleInputChange}
              value={values.temporary_district}
              name={"temporary_district"}
              label={"Temporary District"}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

const Step4 = ({ values, handleInputChange }) => {
  
  
  return (
    <Box>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Box className="passwordbtn">
            <Controls.Select
              onChange={handleInputChange}
              value={values.blood_group}
              name={"blood_group"}
              label={"Blood Group"}
              options={[
                { id: "A+", title: "A Positive" },
                { id: "A-", title: "A Negative" },
                { id: "B+", title: "B Positive" },
                { id: "B-", title: "B Negative" },
                { id: "O+", title: "O Positive" },
                { id: "O-", title: "O Negative" },
                { id: "AB+", title: "AB Positive" },
                { id: "AB-", title: "AB Negative" },
              ]}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box className="passwordbtn">
            <Controls.Input
              onChange={handleInputChange}
              value={values.name_of_company}
              name={"name_of_company"}
              label={"Name of Company"}
            />
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Box className="passwordbtn">
            <label className="emailtext">Date of Joining*</label>
            <Controls.DatePickerWithYear
              onChange={handleInputChange}
              value={values.date_of_joining!==null?moment(values.date_of_joining).toDate():new Date()}
              name={"date_of_joining"}
              label={"Date of Joining"}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box className="passwordbtn">
            <Controls.Input
              onChange={handleInputChange}
              value={values.current_position}
              name={"current_position"}
              label={"Current Position"}
            />
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Box className="passwordbtn">
            <Controls.Input
              onChange={handleInputChange}
              value={values.division}
              name={"division"}
              label={"Division"}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box className="passwordbtn">
            <Controls.Input
              onChange={handleInputChange}
              value={values.territory}
              name={"territory"}
              label={"Territory"}
            />
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Box className="passwordbtn">
            <Controls.Input
              onChange={handleInputChange}
              value={values.nmsra_no}
              name={"nmsra_no"}
              label={"NMSRA Membership No."}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box className="passwordbtn">
            <Controls.Input
              onChange={handleInputChange}
              value={values.lf_no}
              name={"lf_no"}
              label={"LF No"}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

const Step5 = ({
  values,
  handleInputChange,
  setImage,
  handleSubmit,
  MemberData,
  PaymentData,
}) => {
  return (
    <Box style={{ marginBottom: "15px" }}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Box className="passwordbtn">
            <label className="emailtext">Degree*</label>
            <Controls.Select
              onChange={handleInputChange}
              value={values.academic_degree}
              name={"academic_degree"}
              label={"Academic Degree"}
              options={[
                {
                  id: "bachelors",
                  title: "Bachelors",
                },
                {
                  id: "master and above",
                  title: "Masters and above",
                },
              ]}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box className="passwordbtn">
            <label className="emailtext">Faculty*</label>
            <Controls.Input
              onChange={handleInputChange}
              value={values.academic_faculty}
              name={"academic_faculty"}
              label={"Academic Faculty"}
            />
          </Box>
        </Grid>
      </Grid>
      <Box className="mt-4">
        <span style={{ fontWeight: "bolder" }}>Enclosed Documents</span>
      </Box>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Box className="passwordbtn">
            <label className="emailtext">Appointment Letter of Company*</label>
            <Controls.File
              name={"appointment_letter"}
              onChange={handleInputChange}
            />
            <Button
              variant="contained"
              component="label"
              style={{ marginTop: "16px" }}
            >
              {MemberData !== undefined ? (
                <>
                  {MemberData.status === "fulfilled" ? (
                    <>
                      <a
                        href={MemberData.data.appointment_letter}
                        target="_blank"
                        style={{ color: "white" }}
                      >
                        See Previous File
                      </a>
                    </>
                  ) : (
                    <p>Not Uploaded</p>
                  )}
                </>
              ) : null}
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box className="passwordbtn">
            <label className="emailtext">Academic Transcript*</label>
            {/* <Button variant="contained" component="label" style={{marginTop:'16px'}}>
                    Upload File
                    <input type="file" hidden />
                  </Button> */}
            <Controls.File
              name={"academic_transcript"}
              onChange={handleInputChange}
            />
            <Button
              variant="contained"
              component="label"
              style={{ marginTop: "16px" }}
            >
              {MemberData !== undefined ? (
                <>
                  {MemberData.status === "fulfilled" ? (
                    <>
                      <a
                        href={MemberData.data.academic_transcript}
                        target="_blank"
                        style={{ color: "white" }}
                      >
                        See Previous File
                      </a>
                    </>
                  ) : (
                    <p>Not Uploaded</p>
                  )}
                </>
              ) : null}
            </Button>
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Box className="passwordbtn">
            <label className="emailtext">PP Size Photographs*</label>
            <Controls.Image setFile={setImage} aspectRatio={1 / 1} />
            <Button
              variant="contained"
              component="label"
              style={{ marginTop: "16px" }}
            >
              {MemberData !== undefined ? (
                <>
                  {MemberData.status === "fulfilled" ? (
                    <>
                      <a
                        href={MemberData.data.user.profile_pic}
                        target="_blank"
                        style={{ color: "white" }}
                      >
                        See Previous File
                      </a>
                    </>
                  ) : (
                    <p>Not Uploaded</p>
                  )}
                </>
              ) : null}
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <label className="emailtext">Payment File*</label>
          <Controls.File
            name={"payment_details"}
            onChange={handleInputChange}
          />
          <Button
            variant="contained"
            component="label"
            style={{ marginTop: "16px" }}
          >
            {PaymentData !== undefined ? (
              <>
                {PaymentData.status === "fulfilled" ? (
                  <>
                    {PaymentData.data === [] ? (
                      <>
                        <a href="#" style={{ color: "white" }}>
                          No File Uploaded
                        </a>
                      </>
                    ) : (
                      <>
                        {PaymentData.data.map((key) => (
                          <a
                            href={key.payment_details}
                            target="_blank"
                            style={{ color: "white" }}
                          >
                            See Previous File
                          </a>
                        ))}
                      </>
                    )}
                  </>
                ) : (
                  <p>Not Uploaded</p>
                )}
              </>
            ) : null}
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Box className="passwordbtn">
            <Controls.Select
              name={"year"}
              title="year"
              label={"Select Payment Year"}
              value={values.year}
              onChange={handleInputChange}
              options={[
                { id: "2022", title: "2022" },
                { id: "2023", title: "2023" },
                { id: "2024", title: "2024" },
                { id: "2025", title: "2025" },
                { id: "2026", title: "2026" },
                { id: "2027", title: "2027" },
                { id: "2028", title: "2028" },
                { id: "2029", title: "2029" },
                { id: "2030", title: "2030" },
                { id: "2031", title: "2031" },
                { id: "2032", title: "2032" },
                { id: "2033", title: "2033" },
              ]}
            />
          </Box>
        </Grid>
      </Grid>
      <button
        className="signinbutton"
        onClick={handleSubmit}
        style={{ border: "none" }}
      >
        Sign Up
      </button>
    </Box>
  );
};

const MemberFormPage = ({
  handleInputChange,
  values,
  handleSubmit,
  setImage,
  MemberData,
  PaymentData,
}) => {
  const steps = [
    {
      name: "Step 1",
      component: (
        <Step1 handleInputChange={handleInputChange} values={values} />
      ),
    },
    {
      name: "Step 2",
      component: (
        <Step2 handleInputChange={handleInputChange} values={values} />
      ),
    },
    {
      name: "Step 3",
      component: (
        <Step3 handleInputChange={handleInputChange} values={values} />
      ),
    },
    {
      name: "Step 4",
      component: (
        <Step4
          handleInputChange={handleInputChange}
          values={values}
          handleSubmit={handleSubmit}
        />
      ),
    },
    {
      name: "Step 5",
      component: (
        <Step5
          handleInputChange={handleInputChange}
          values={values}
          setImage={setImage}
          handleSubmit={handleSubmit}
          MemberData={MemberData}
          PaymentData={PaymentData}
        />
      ),
    },
  ];

  return (
    <Box className="newforms" style={{ textAlign: "start" }}>
      <MultiStep
        activeStep={0}
        steps={steps}
        prevStyle={{
          backgroundColor: "#ccc",
          color: "#fff",
          borderRadius: "5px",
          padding: "10px 20px",
        }}
        nextStyle={{
          backgroundColor: "#007bff",
          color: "#fff",
          borderRadius: "5px",
          padding: "10px 20px",
        }}
        handleInputChange={handleInputChange}
        values={values}
        setImage={setImage}
        MemberData={MemberData}
      />
    </Box>
  );
};

export default MemberFormPage;
