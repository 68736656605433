import React from "react";
import Footer from "../component/footer";
import Header from "../component/header";
import Image02 from "../../../static/image/team/3.jpg";
import Image03 from "../../../static/image/team/1.jpg";
import Image04 from "../../../static/image/team/2.jpg";
import Image05 from "../../../static/image/team/4.jpg";
import { Link } from "react-router-dom";
import { useGetMembershipPeriodsQuery } from "../../../api/membershipPeriodSlice";
import { Box } from "@mui/system";
import MemberSkeleton from "./skeletons/memberSkeleton";
import { useGetKathmanduPresidentsQuery } from "../../../api/kathmanduPresidentSlice";

const PastExecutiveMember = () => {
  return (
    <>
      <Header />
      <PastExecutiveMemberPage />
      <Footer />
    </>
  );
};

const PastExecutiveMemberPage = () => {
  const data = useGetKathmanduPresidentsQuery();

  return (
    <div>
      <section className="page-title bg-1">
        <div className="overlay"></div>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="block text-center">
                <span className="text-white">Past President Member</span>
                <h1 className="text-capitalize mb-5 text-lg">
                  Past President Member
                </h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section team">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6">
              <div className="section-title text-center">
                <h2 className="mb-4">Meet Our Past President Member</h2>
                <div className="divider mx-auto my-4"></div>
                <p>
                  Today’s users expect effortless experiences. Don’t let
                  essential people and processes stay stuck in the past. Speed
                  it up, skip the hassles
                </p>
              </div>
            </div>
          </div>

          <div className="row">
            {data !== undefined ? (
              <>
                {data.status === "fulfilled" ? (
                  <>
                    {data.data.ids.length !== 0 ? (
                      <>
                        {data.data.ids.map((key) => (
                          <div className="col-lg-3 col-md-6 col-sm-6" key={key}>
                            <div class="member">
                              <img
                                src={data.data.entities[key].image}
                                class="img-fluid"
                                alt=""
                              />
                              <div class="member-info">
                                <div class="member-info-content">
                                  <h4>{data.data.entities[key].name}</h4>
                                  <span>{data.data.entities[key].years}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </>
                    ) : (
                      <MemberSkeleton />
                    )}
                  </>
                ) : (
                  <MemberSkeleton />
                )}
              </>
            ) : null}
          </div>
        </div>
      </section>
    </div>
  );
};

export default PastExecutiveMember;
