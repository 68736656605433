import React from "react";
import TextButton from "../../../../../components/button/buttonicon";

const DoctorCallCardButton = ({name}) =>{

    return(
        <>
            <TextButton buttonText={name}/>

        </>
    )
}

export default DoctorCallCardButton;