import React, { useEffect, useState } from "react";
import Sidebar from "../../component/sidebar";
import Header from "../../component/header";
import { Box, Grid, Typography } from "@mui/material";
import Controls from "../forms/controls/Controls";
import { useForm, Form } from "../forms/useForm";
import { useAddHomePageMutation } from "../../../../../api/homePageSlice";
import BlobToFile from "../forms/utils/blobToFile";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCog,
  faBell,
  faSignInAlt,
  faExclamationTriangle,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

const AddHomePage = () => {
  return (
    <Grid container className="dashboard-full-background">
      <Grid item xs={2} spacing={4}>
        <Sidebar />
      </Grid>
      <Grid item xs={10}>
        <Header />
        <AddHomePagePages />
      </Grid>
    </Grid>
  );
};

const AddHomePagePages = () => {
  const [File1, setFile1] = useState([]);
  const [File2, setFile2] = useState([]);
  const [File3, setFile3] = useState([]);
  const initialFValues = {
    image1: [],
    image2: [],
    image3: [],
    title: "",
    subtitle: "",
    is_active: "false",
  };
  const { values, handleImageUpload, handleInputChange } = useForm(
    initialFValues,
    true,
    false,
    false
  );
  const [SuccessMessege, setSuccesMessege] = useState(null);
  const [ErrorMessege, setErrorMessege] = useState(null);

  const [addHomePage] = useAddHomePageMutation();

  useEffect(() => {
    handleImageUpload("image1", File1);
  }, [File1]);
  useEffect(() => {
    handleImageUpload("image2", File2);
  }, [File2]);
  useEffect(() => {
    handleImageUpload("image3", File3);
  }, [File3]);

  const thisFile = (e) => {};
  const history = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    var isTrueSet = values.is_active === "true";

    var file1 = BlobToFile(values.image1, "homePage");
    if (file1.length !== 0) {
      formData.append("image1", file1, "homePage.jpg");
    }
    var file2 = BlobToFile(values.image2, "homePage");
    if (file2.length !== 0) {
      formData.append("image2", file2, "homePage.jpg");
    }
    var file3 = BlobToFile(values.image3, "homePage");
    if (file3.length !== 0) {
      formData.append("image3", file3, "homePage.jpg");
    }
    formData.append("title", values.title);
    formData.append("subtitle", values.subtitle);
    formData.append("is_active", isTrueSet);
    addHomePage(formData)
      .then((res) => {
        setSuccesMessege("Successfully Added Home Page Data.");
        setTimeout(() => {
          history("/dashboard/home-pages");
        }, [3000]);
      })
      .catch((err) => {
        setErrorMessege("Some Error Occur. Please Try Again Later.");
        setTimeout(() => {
          setErrorMessege(null);
        }, [3000]);
      });
  };

  return (
    <Box className="table-design-background">
      <Typography className="dashboard-home-page-text">
        Add Home Page Details
      </Typography>
      <Box style={{ padding: "1.5rem" }}>
        <Form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={6} style={{ textAlign: "start" }}>
              <Controls.Input
                name="title"
                label="Title"
                value={values.title}
                onChange={handleInputChange}
              />
              <Controls.Input
                name="subtitle"
                label="Sub-Title"
                value={values.subtitle}
                onChange={handleInputChange}
              />
              <Controls.RadioGroup
                name="is_active"
                label="Is Active"
                value={values.is_active}
                onChange={handleInputChange}
                items={[
                  { id: "false", title: "False" },
                  { id: "true", title: "True" },
                ]}
              />
            </Grid>
            <Grid item xs={6}>
              <Box style={{ marginTop: ".4rem" }}>
                <Controls.Image setFile={setFile1} aspectRatio={350 / 312} />
              </Box>
              <Box style={{ marginTop: ".4rem" }}>
                <Controls.Image setFile={setFile2} aspectRatio={350 / 312} />
              </Box>
              <Box style={{ marginTop: ".4rem" }}>
                <Controls.Image setFile={setFile3} aspectRatio={350 / 500} />
              </Box>
            </Grid>
            <Grid>
              <Box style={{ marginLeft: "1.3rem" }}>
                <Controls.Button
                  type="submit"
                  text="Submit"
                  backgroundColor="#1b284b"
                  onClick={handleSubmit}
                />
              </Box>
            </Grid>
          </Grid>
        </Form>
        {SuccessMessege === null ? null : (
          <Box className="successmessege">
            <Typography className="messegefonts">
              <FontAwesomeIcon icon={faCheckCircle} className="messegeicon" />
              {SuccessMessege}
            </Typography>
          </Box>
        )}
        {ErrorMessege === null ? null : (
          <Box className="errormessege">
            <Typography className="messegefonts">
              <FontAwesomeIcon
                icon={faExclamationTriangle}
                className="messegeicon"
              />
              {ErrorMessege}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};
export default AddHomePage;
