import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Grid, Typography } from "@mui/material";
import Sidebar from "../../component/sidebar";
import Header from "../../component/header";
import Controls from "../forms/controls/Controls";
import { useForm, Form } from "../forms/useForm";
import { useGetHospitalByIdQuery } from "../../../../../api/hospitalSlice";
import { useUpdateHospitalMutation } from "../../../../../api/hospitalSlice";
import { useGetActiveHospitalsQuery } from "../../../../../api/hospitalSlice";
import {
  useGetActiveHospitalHolidaysQuery,
  useUpdateHospitalHolidayMutation,
} from "../../../../../api/hospitalHolidaySlice";
import { Box } from "@mui/system";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExclamationTriangle,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

const HospitalHolidayEditPage = () => {
  return (
    <Grid container className="dashboard-full-background">
      <Grid item xs={2} spacing={4}>
        <Sidebar />
      </Grid>
      <Grid item xs={10}>
        <Header />
        <EditHospitalHolidayPages />
      </Grid>
    </Grid>
  );
};

const EditHospitalHolidayPages = () => {
  const { id } = useParams();
  const [ID, setID] = useState(null);
  const Hospitals = useGetActiveHospitalHolidaysQuery(ID);

  
  const hospitalList = useGetActiveHospitalsQuery();
  const [File, setFile] = useState([]);
  const [updateHospital, setUpdateHospital] = useState([]);
  const [initialFValues, setInitialFValues] = useState({
    hospital: "",
    date: [],
    is_holiday: "false",
  });
  const [SuccessMessege, setSuccesMessege] = useState(null);
  const [ErrorMessege, setErrorMessege] = useState(null);
  

  const hospitalNames = []
  if(hospitalList.status === "fulfilled"){
    hospitalList.data.ids.map((key)=>{
      hospitalNames.push({'id': key, 'title': hospitalList.data.entities[key].name})
    })
  }

  
  
  
  
  useEffect(() => {
    if (Hospitals.data) {
      setInitialFValues({
        hospital: Hospitals.data.length>0?Hospitals.data.hospital[0].id:ID,
        date: Hospitals.data.date,
        is_holiday: Hospitals.data.is_holiday,
      });
    }
  }, [Hospitals.data]);

  const { values, handleImageUpload, handleInputChange } = useForm(
    initialFValues,
    true,
    false,
    true
  );

 useEffect(()=>{
  setID(values.hospital)
 },[values])

 

  const [editHospitals] = useUpdateHospitalHolidayMutation();

  const history = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    const postData = [];
    values.date.forEach((element) => {
      postData.push({
        hospital: values.hospital,
        is_holiday: values.is_holiday,
        date: element,
      });
    });

    

    editHospitals(postData)
      .then((res) => {
        setSuccesMessege("Successfully Updated Hospital Holiday Data.");
        setTimeout(() => {
          history("/dashboard/hospital-holidays");
        }, [3000]);
      })
      .catch((err) => {
        setErrorMessege("Some Error Occur. Please Try Again Later.");
        setTimeout(() => {
          setErrorMessege(null);
        }, [3000]);
      });
  };

  return (
    <Box className="table-design-background">
      <Typography className="dashboard-home-page-text">
        Update Hospital Details
      </Typography>
      <Box style={{ padding: "1.5rem" }}>
        <Form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={6} style={{ textAlign: "start" }}>
              <Controls.Select
                name="hospital"
                label="Hospital Name"
                value={values.hospital}
                options={hospitalNames}
                onChange={handleInputChange}
              />
              <Controls.RadioGroup
                name="is_holiday"
                label="Active"
                value={values.is_holiday.toString()}
                onChange={handleInputChange}
                items={[
                  { id: "false", title: "False" },
                  { id: "true", title: "True" },
                ]}
              />
            </Grid>
            <Grid item xs={6}>
              <Controls.MultipleDatePicker
                buttonText="Select Holidays"
                name="date"
                onChange={handleInputChange}
                selectedDates={values.date}
              />
            </Grid>
            <Grid item style={{ textAlign: "start", marginTop: "-3.2rem" }}>
              <Box style={{ marginTop: "1rem" }}>
                <Controls.Button
                  type="submit"
                  text="Submit"
                  backgroundColor="#1b284b"
                  onClick={handleSubmit}
                />
              </Box>
            </Grid>
          </Grid>
        </Form>
        {SuccessMessege === null ? null : (
          <Box className="successmessege">
            <Typography className="messegefonts">
              <FontAwesomeIcon icon={faCheckCircle} className="messegeicon" />
              {SuccessMessege}
            </Typography>
          </Box>
        )}
        {ErrorMessege === null ? null : (
          <Box className="errormessege">
            <Typography className="messegefonts">
              <FontAwesomeIcon
                icon={faExclamationTriangle}
                className="messegeicon"
              />
              {ErrorMessege}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default HospitalHolidayEditPage;
