import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

const MemberSkeleton = () => {
  return (
    <>
      <div className="col-lg-3 col-md-6 col-sm-6">
        <div className="team-block mb-5 mb-lg-0">
          <SkeletonTheme color="grey" highlightColor="#444">
            <p>
              <Skeleton height={250} width={"100%"} count={1} />
            </p>
          </SkeletonTheme>

          <div className="content">
            <h4 className="mt-4 mb-0">
              <Skeleton width={"100%"} count={1} />
            </h4>
            <p></p>
            <Skeleton width={"100%"} count={1} />
          </div>
        </div>
      </div>
      <div className="col-lg-3 col-md-6 col-sm-6">
        <div className="team-block mb-5 mb-lg-0">
          <SkeletonTheme color="grey" highlightColor="#444">
            <p>
              <Skeleton height={250} width={"100%"} count={1} />
            </p>
          </SkeletonTheme>

          <div className="content">
            <h4 className="mt-4 mb-0">
              <Skeleton width={"100%"} count={1} />
            </h4>
            <p></p>
            <Skeleton width={"100%"} count={1} />
          </div>
        </div>
      </div>
      <div className="col-lg-3 col-md-6 col-sm-6">
        <div className="team-block mb-5 mb-lg-0">
          <SkeletonTheme color="grey" highlightColor="#444">
            <p>
              <Skeleton height={250} width={"100%"} count={1} />
            </p>
          </SkeletonTheme>

          <div className="content">
            <h4 className="mt-4 mb-0">
              <Skeleton width={"100%"} count={1} />
            </h4>
            <p></p>
            <Skeleton width={"100%"} count={1} />
          </div>
        </div>
      </div>
      <div className="col-lg-3 col-md-6 col-sm-6">
        <div className="team-block mb-5 mb-lg-0">
          <SkeletonTheme color="grey" highlightColor="#444">
            <p>
              <Skeleton height={250} width={"100%"} count={1} />
            </p>
          </SkeletonTheme>

          <div className="content">
            <h4 className="mt-4 mb-0">
              <Skeleton width={"100%"} count={1} />
            </h4>
            <p></p>
            <Skeleton width={"100%"} count={1} />
          </div>
        </div>
      </div>
    </>
  );
};

export default MemberSkeleton;
