import { createSelector, createEntityAdapter } from "@reduxjs/toolkit";

import { apiSlice } from "./apiSlice";

const hospitalsAdapter = createEntityAdapter();

const initialState = hospitalsAdapter.getInitialState();

export const hospitalSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getHospitals: builder.query({
      query: () => "bookings/hospital/",
      transformResponse: (responseData) => {
        return hospitalsAdapter.setAll(initialState, responseData);
      },
      providesTags: (result, error, arg) => [
        { type: "Hospitals", id: "LIST" },
        ...result.ids.map((id) => ({ type: "Hospitals", id })),
      ],
    }),
    getActiveHospitals: builder.query({
      query: () => "/bookings/hospital/?is_active=True",
      transformResponse: (responseData) => {
        return hospitalsAdapter.setAll(initialState, responseData);
      },
      providesTags: (result, error, arg) => [
        { type: "Hospitals", id: "LIST" },
        ...result.ids.map((id) => ({ type: "Hospitals", id })),
      ],
    }),
    getHospitalById: builder.query({
      query: (id) => ({
        url: `/bookings/hospital/${id}/`,
        method: "GET",
      }),
      providesTags: ["Hospitals"],
    }),
    getHospitalPagination: builder.query({
      query: (id) => ({
        url: `/bookings/hospital-pagination/?page=${id}`,
        method: "GET",
      }),
      providesTags: ["Hospitals"],
    }),
    addHospital: builder.mutation({
      query: (hospital) => {
        return {
          url: "/bookings/hospital/",
          method: "POST",
          body: hospital,
        };
      },
      invalidatesTags: ["Hospitals"],
    }),
    updateHospital: builder.mutation({
      query: (updateHospital) => {
        return {
          url: `/bookings/hospital/${updateHospital.get("id")}/`,
          method: "PATCH",
          body: updateHospital,
        };
      },
      invalidatesTags: ["Hospitals"],
    }),
    deleteHospital: builder.mutation({
      query: ({ id }) => {
        return {
          url: `/bookings/hospital/${id}/`,
          method: "DELETE",
          body: id,
        };
      },
      invalidatesTags: ["Hospitals"],
    }),
    getHospitalId: builder.query({
      query: (id) => ({
        url: `/bookings/hospital-date/${id}/`,
        method: "GET",
      }),
      providesTags: ["Hospitals"],
    }),
  }),
});

export const {
  useGetHospitalsQuery,
  useGetActiveHospitalsQuery,
  useAddHospitalMutation,
  useUpdateHospitalMutation,
  useDeleteHospitalMutation,
  useGetHospitalByIdQuery,
  useGetHospitalPaginationQuery,
  useGetHospitalIdQuery,
} = hospitalSlice;

// returns the query result object
export const selectHospitalsResult =
  hospitalSlice.endpoints.getHospitals.select();

// Creates memoized selector
const selectHospitalssData = createSelector(
  selectHospitalsResult,
  (hospitalsResult) => hospitalsResult.data // normalized state object with ids & entities
);

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
  selectAll: selectAllHospitals,
  selectById: selectAboutPageById,
  selectIds: selectIds,
  // Pass in a selector that returns the posts slice of state
} = hospitalsAdapter.getSelectors(
  (state) => selectHospitalssData(state) ?? initialState
);
