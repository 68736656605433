import React from "react";
import Footer from "../component/footer";
import Header from "../component/header";
import Image1 from "../../../static/image/blog/blog-1.jpg";
import Image2 from "../../../static/image/blog/blog-2.jpg";
import Image3 from "../../../static/image/blog/blog-4.jpg";
import { useGetActivitiesNormalQuery } from "../../../api/acitivitiesSlice";
import { Link } from "react-router-dom";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

const Acitivity = () => {
  return (
    <>
      <Header />
      <AcitivityPage />
      <Footer />
    </>
  );
};

const AcitivityPage = () => {
  const data = useGetActivitiesNormalQuery();
  return (
    <div style={{ textAlign: "start" }}>
      <section class="page-title bg-1">
        <div class="overlay"></div>
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="block text-center">
                <span class="text-white">NMSRA Activity</span>
                <h1 class="text-capitalize mb-5 text-lg">
                  NMSRA Activity Articles
                </h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="section blog-wrap">
        <div class="container">
          <div class="row">
            <div class="col-lg-8">
              <div className="row">
                {data !== undefined ? (
                  <>
                    {data.status === "fulfilled" ? (
                      <>
                        {data.data.ids.length !== 0 ? (
                          <>
                            {data.data.ids.map((key) => (
                              <div
                                className="col-lg-12 col-md-12 mb-5"
                                key={key}
                              >
                                <div className="blog-item">
                                  <div className="blog-thumb">
                                    <img
                                      src={data.data.entities[key].image}
                                      alt=""
                                      className="img-fluid "
                                    />
                                  </div>

                                  <div className="blog-item-content">
                                    <h2 className="mt-3 mb-3">
                                      <a href="#">
                                        {data.data.entities[key].title}
                                      </a>
                                    </h2>

                                    <p className="mb-4">
                                      {data.data.entities[key].subtitle}
                                    </p>

                                    <Link
                                      to={`/activity/detail/page/${data.data.entities[key].id}`}
                                      className="btn btn-main btn-icon btn-round-full"
                                    >
                                      Read More{" "}
                                      <i className="icofont-simple-right ml-2  "></i>
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </>
                        ) : (
                          <div className="blog-item">
                            <div className="blog-thumb">
                              {/* <img src={Image1} alt="" className="img-fluid " /> */}
                              <SkeletonTheme
                                color="grey"
                                className="img-fluid"
                                highlightColor="#444"
                              >
                                <Skeleton height={506} width={760} count={1} />
                              </SkeletonTheme>
                            </div>

                            <div className="blog-item-content">
                              <h2 className="mt-3 mb-3">
                                <SkeletonTheme
                                  color="grey"
                                  className="img-fluid"
                                  highlightColor="#444"
                                >
                                  <Skeleton height={25} width={760} count={1} />
                                </SkeletonTheme>
                              </h2>

                              <p className="mb-4">
                                <SkeletonTheme
                                  color="grey"
                                  className="img-fluid"
                                  highlightColor="#444"
                                >
                                  <Skeleton
                                    height={100}
                                    width={760}
                                    count={1}
                                  />
                                </SkeletonTheme>
                              </p>

                              <Link
                                to="#"
                                className="btn btn-main btn-icon btn-round-full"
                              >
                                <SkeletonTheme
                                  color="grey"
                                  className="img-fluid"
                                  highlightColor="#444"
                                >
                                  <Skeleton height={15} width={70} count={1} />
                                </SkeletonTheme>
                              </Link>
                            </div>
                          </div>
                        )}
                      </>
                    ) : null}
                  </>
                ) : null}
              </div>
            </div>
            <div class="col-lg-4">
              <div class="sidebar-wrap pl-lg-4 mt-5 mt-lg-0">
                <div class="sidebar-widget latest-post mb-3">
                  <h5>Popular Posts</h5>

                  {data !== undefined ? (
                    <>
                      {data.status === "fulfilled" ? (
                        <>
                          {data.data.ids.length !== 0 ? (
                            <>
                              {data.data.ids.map((key) => (
                                <div class="py-2">
                                  <span class="text-sm text-muted">
                                    {data.data.entities[key].created_at}
                                  </span>
                                  <h6 class="my-2">
                                    <Link
                                      to={`/activity/detail/page/${data.data.entities[key].id}`}
                                    >
                                      {data.data.entities[key].title}
                                    </Link>
                                  </h6>
                                </div>
                              ))}
                            </>
                          ) : (
                            <>
                              <div class="py-2">
                                <span class="text-sm text-muted">
                                  <SkeletonTheme
                                    color="grey"
                                    className="img-fluid"
                                    highlightColor="#444"
                                  >
                                    <Skeleton
                                      height={20}
                                      width={"100%"}
                                      count={1}
                                    />
                                  </SkeletonTheme>
                                </span>
                                <h6 class="my-2">
                                  <Link to="#">
                                    <SkeletonTheme
                                      color="grey"
                                      className="img-fluid"
                                      highlightColor="#444"
                                    >
                                      <Skeleton
                                        height={20}
                                        width={"100%"}
                                        count={1}
                                      />
                                    </SkeletonTheme>
                                  </Link>
                                </h6>
                              </div>
                              <div class="py-2">
                                <span class="text-sm text-muted">
                                  <SkeletonTheme
                                    color="grey"
                                    className="img-fluid"
                                    highlightColor="#444"
                                  >
                                    <Skeleton
                                      height={20}
                                      width={"100%"}
                                      count={1}
                                    />
                                  </SkeletonTheme>
                                </span>
                                <h6 class="my-2">
                                  <Link to="#">
                                    <SkeletonTheme
                                      color="grey"
                                      className="img-fluid"
                                      highlightColor="#444"
                                    >
                                      <Skeleton
                                        height={20}
                                        width={"100%"}
                                        count={1}
                                      />
                                    </SkeletonTheme>
                                  </Link>
                                </h6>
                              </div>
                              <div class="py-2">
                                <span class="text-sm text-muted">
                                  <SkeletonTheme
                                    color="grey"
                                    className="img-fluid"
                                    highlightColor="#444"
                                  >
                                    <Skeleton
                                      height={20}
                                      width={"100%"}
                                      count={1}
                                    />
                                  </SkeletonTheme>
                                </span>
                                <h6 class="my-2">
                                  <Link to="#">
                                    <SkeletonTheme
                                      color="grey"
                                      className="img-fluid"
                                      highlightColor="#444"
                                    >
                                      <Skeleton
                                        height={20}
                                        width={"100%"}
                                        count={1}
                                      />
                                    </SkeletonTheme>
                                  </Link>
                                </h6>
                              </div>
                            </>
                          )}
                        </>
                      ) : null}
                    </>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Acitivity;
