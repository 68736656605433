import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Sidebar from "../../component/sidebar";
import { Box, Grid, Typography } from "@mui/material";
import Header from "../../component/header";
import BasicTable from "../tables/BasicTable";
import {
  useGetVisionsQuery,
  useUpdateVisionMutation,
  useDeleteVisionMutation,
  useAddVisionMutation,
} from "../../../../../api/visionSlice";

import VisionAddButton from "./visionAddButton";

const DashboardVisions = () => {
  return (
    <Grid container className="dashboard-full-background">
      <Grid item xs={2} spacing={4}>
        <Sidebar />
      </Grid>
      <Grid item xs={10}>
        <Header />
        <DashboardVisionsPages />
      </Grid>
    </Grid>
  );
};

const DashboardVisionsPages = () => {
  const { data, isLoading, isSuccess, isError, error } =
    useGetVisionsQuery("getVisions");

  const [deleteVision] = useDeleteVisionMutation();

  const deleteConfirm = (e, id) => {
    e.preventDefault();
    deleteVision({ id });
  };

  let headers = [];
  let tableData = [];
  let content;
  if (isLoading) {
    content = <p>"Loading..."</p>;
  } else if (isSuccess) {
    headers = [
      "Vision Name",
      "Active"
    ];
    tableData = [];
    data.ids.forEach((key) => {
      tableData.push({
        "Vision Name": data.entities[key].vision_name,
        "Active": data.entities[key].is_active,
        id: data.entities[key].id,
      });
    });
  }
  return (
    <>
      <Box className="table-design-background">
        <Grid container>
          <Grid item xs={6}>
            <Typography className="dashboard-home-page-text">
              Vision Lists
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Link to="/dashboard/new/visions">
              <VisionAddButton />
            </Link>
          </Grid>
        </Grid>
        <Box style={{ padding: "1rem" }}>
          <BasicTable
            headers={headers}
            data={tableData}
            isDelete={true}
            deleteColor={"#f50057"}
            isEdit={true}
            editColor={"#3f51b5"}
            addColor={""}
            tableName={"visions"}
            deleteConfirm={deleteConfirm}
            activeSign={['Active']}
          />
        </Box>
      </Box>
    </>
  );
};
export default DashboardVisions;
