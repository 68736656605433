import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Sidebar from "../../component/sidebar";
import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import Header from "../../component/header";
import BasicTable from "../tables/BasicTable";
import {
  useGetTestimonialsQuery,
  useUpdateTestimonialMutation,
  useDeleteTestimonialMutation,
  useAddTestimonialMutation,
} from "../../../../../api/testimonialSlice";
import { BASE_URL } from "../../../../../constant/baseURL";
import Cookies from "js-cookie";
import axios from "axios";
import { useGetMemberRegistrationsByIdQuery } from "../../../../../api/memberRegistrationSlice";
import { useGetMrIDByAccessTokenQuery } from "../../../../../api/userSlice";
import { useGetMrRegistrationPaymentQuery } from "../../../../../api/mrRegistrationSlice";
import ModeEditSharpIcon from "@mui/icons-material/ModeEditSharp";

const PaymentListDashboard = () => {
  return (
    <Grid container className="dashboard-full-background">
      <Grid item md={2} xs={12}>
        <Sidebar />
      </Grid>
      <Grid item md={10} xs={12} className="res-margin-design">
        <Header />
        <DashboardTestimonialsPages />
      </Grid>
    </Grid>
  );
};

const DashboardTestimonialsPages = () => {
  const id = useGetMrIDByAccessTokenQuery(Cookies.get("access"));

  const datas = useGetMemberRegistrationsByIdQuery(id?.data?.id);

  const paymentData = useGetMrRegistrationPaymentQuery(datas?.data?.id);


  return (
    <>
      <Box className="table-design-background">
        <Grid container>
          <Grid item xs={6}>
            <Typography className="dashboard-home-page-text">
              Payment Lists
            </Typography>
          </Grid>
          {/* <Grid item xs={6}>
            <Link to="/dashboard/new/testimonials">
              <TestimonialAddButton />
            </Link>
          </Grid> */}
        </Grid>
        <Box style={{ padding: "1rem" }}>
          <TableContainer>
            <Table aria-label="simple table" className="table-width">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography style={{ fontSize: "15px", fontWeight: "600" }}>
                      S.No.
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography style={{ fontSize: "15px", fontWeight: "600" }}>
                      Payment Year
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography style={{ fontSize: "15px", fontWeight: "600" }}>
                      Pdf File
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography style={{ fontSize: "15px", fontWeight: "600" }}>
                      Actions
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {paymentData !== undefined ? (
                  <>
                    {paymentData.status === "fulfilled" ? (
                      <>
                        {paymentData.data.map((row, index) => (
                          <TableRow>
                            <TableCell>{index + 1}</TableCell>
                            <TableCell>{row.year}</TableCell>
                            <TableCell>
                              <p
                                style={{
                                  paddingTop: "10px",
                                  paddingBottom: "0px",
                                }}
                              >
                                <a
                                  href={row.payment_details}
                                  className="view-payment-document"
                                  target="_blank"
                                >
                                  Payment Document{" "}
                                </a>
                              </p>
                            </TableCell>
                            <TableCell>
                              <Link to={`/dashboard/member/payment/edit/${row.id}`}>
                                <ModeEditSharpIcon
                                  sx={{ m: 0.5 }}
                                  style={{ color: "#3f51b5" }}
                                />
                              </Link>
                            </TableCell>
                          </TableRow>
                        ))}
                      </>
                    ) : null}
                  </>
                ) : null}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </>
  );
};
export default PaymentListDashboard;
