import React, { useEffect, useState } from "react";
import Sidebar from "../../component/sidebar";
import { Grid, Typography } from "@mui/material";
import Header from "../../component/header";
import { Box } from "@mui/system";
import BlobToFile from "../../../../../components/forms/utils/blobToFile";
import {
  useAddMemberRegistrationMutation,
  useGetMemberRegistrationsByIdQuery,
  useUpdateMemberRegistrationMutation,
} from "../../../../../api/memberRegistrationSlice";
import { useNavigate } from "react-router-dom";
import { useForm1 } from "../../../../../components/forms/useForm";
import { isMoment } from "moment";
import MemberFormPage from "./memberForm";
import Cookies from "js-cookie";
import { useForm } from "../forms/useForm";
import { useParams } from "react-router-dom";
import urlToFile from "../forms/utils/urlToFile";
import { useGetMrRegistrationPaymentQuery } from "../../../../../api/mrRegistrationSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import moment from "moment/moment";
import { convertDateToString } from "../../../../../components/forms/utils/convertDateToString";



const ActivityDashboardPage = () => {
  return (
    <Grid container className="dashboard-full-background">
      <Grid item xs={2} spacing={4}>
        <Sidebar />
      </Grid>
      <Grid item xs={10}>
        <Header />
        <DashboardActivityPagesPages />
      </Grid>
    </Grid>
  );
};

const DashboardActivityPagesPages = () => {
  const { id } = useParams();

  const [File1, setFile1] = useState([]);
  const [File2, setFile2] = useState([]);
  const [File3, setFile3] = useState([]);
  const [File4, setFile4] = useState([]);
  const [SuccessMessege, setSuccesMessege] = useState(null);
  const [ErrorMessege, setErrorMessege] = useState(null);
  const [initialFValues, setInitialFValues] = useState({
    first_name: "",
    middle_name: "",
    last_name: "",
    email: "",
    permanent_street: "",
    permanent_ward_no: null,
    permanent_local_level: "",
    permanent_district: "",
    permanent_country: "",
    temporary_street: "",
    temporary_ward_no: null,
    temporary_local_level: "",
    temporary_district: "",
    contact_no: "",
    blood_group: "",
    name_of_company: "",
    date_of_joining: "",
    division: "",
    current_position: "",
    territory: "",
    academic_degree: "",
    academic_faculty: "",
    appointment_letter: [],
    academic_transcript: [],
    confirmation: false,
    password: "",
    cpassword: "",
    payment_details: [],
    emergency_contact: "",
    year: "",
    nmsra_no: "",
    lf_no: "",
  });

  const MemberData = useGetMemberRegistrationsByIdQuery(id);


  const PaymentData = useGetMrRegistrationPaymentQuery(MemberData?.data?.id);
  const [newData, setNewData] = useState([]);

  const [AllMiddleName, setAllMiddleName] = useState("");
  const [NewDate, setNewDate] = useState(null);




  useEffect(() => {
    if (MemberData.data) {
      if (MemberData.data.user.profile_pic && typeof (MemberData.data.user.profile_pic) === "string") {

        urlToFile(MemberData.data.user.profile_pic, setFile1);
      }
      if (PaymentData.data) {
        if (PaymentData.data.length) {

          if (PaymentData.data[0].payment_details) {
            urlToFile(PaymentData.data[0].payment_details, setFile4);
          }

          if (PaymentData.data[0].year) {
            setNewData(PaymentData.data[0].year);
          }
          if (MemberData.data.user.middle_name === "") {
            setAllMiddleName("");
          } else {
            setAllMiddleName(MemberData.data.user.middle_name);
          }
        }
      }

      setInitialFValues({
        first_name: MemberData.data.user.first_name,
        middle_name: AllMiddleName,
        last_name: MemberData.data.user.last_name,
        email: MemberData.data.user.email,
        permanent_street: MemberData.data.permanent_street,
        permanent_ward_no: MemberData.data.permanent_ward_no,
        permanent_local_level: MemberData.data.permanent_local_level,
        permanent_district: MemberData.data.permanent_district,
        permanent_country: MemberData.data.permanent_country,
        temporary_street: MemberData.data.temporary_street,
        temporary_ward_no: MemberData.data.temporary_ward_no,
        temporary_local_level: MemberData.data.temporary_local_level,
        temporary_district: MemberData.data.temporary_district,
        contact_no: MemberData.data.contact_no,
        blood_group: MemberData.data.blood_group,
        name_of_company: MemberData.data.name_of_company,
        date_of_joining: MemberData.data.date_of_joining,
        division: MemberData.data.division,
        current_position: MemberData.data.current_position,
        territory: MemberData.data.territory,
        academic_degree: MemberData.data.academic_degree,
        academic_faculty: MemberData.data.academic_faculty,
        appointment_letter: MemberData.data.appointment_letter,
        academic_transcript: MemberData.data.academic_transcript,
        profile_pic: File1,
        confirmation: MemberData.data.confirmation ? true : false,
        payment_details: MemberData.data.payment_details,
        nmsra_no: MemberData.data.user.nmsra_no,
        lf_no: MemberData.data.user.lf_no,
        emergency_contact: MemberData.data.emergency_contact,
        id: id,
        year: newData,
      });
    }
  }, [MemberData.data, PaymentData]);
  const { values, handleImageUpload, handleInputChange } = useForm(
    initialFValues,
    true,
    false,
    true
  );

  






  const history = useNavigate();

  const [updateMemberRegistration] =
    useUpdateMemberRegistrationMutation(values);

  useEffect(() => {
    handleImageUpload("profile_pic", File1);
  }, [File1]);

  // if(values.date_of_joining)
  // {
  //   
  // }


  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    

    if (values.profile_pic || values.profile_pic.length !== 0) {
      var file1 = BlobToFile(values.profile_pic, "profile_pic");
      if (file1.length !== 0) {
        formData.append("profile_pic", file1, "profile_pic.jpg");
      }
    }
    // if(values.academic_transcript || values.academic_transcript.length !== 0)
    // {
    //   
    //   var file2 = BlobToFile(values.academic_transcript, "academic_transcript");
    //   if(file2.length !== 0)
    //   {
    //     formData.append("academic_transcript", file2, "academic_transcript");
    //   }
    // }
    // if(values.appointment_letter || values.appointment_letter.length !== 0)
    // {
    //   
    //   var file3 = BlobToFile(values.appointment_letter, "appointment_letter");
    //   if(file3.length !== 0)
    //   {
    //     formData.append("appointment_letter", file3, "appointment_letter");
    //   }
    // }
    // 
    // if(values.payment_details || values.payment_details.length !== 0)
    // {
    //   var file4 = BlobToFile(values.payment_details, "payment_details");
    //   if(file4.length !== 0)
    //   {
    //     formData.append("payment_details", file4, "payment_details")
    //   }
    // }
    for (var key in values) {
      if (key !== "profile_pic") {
        formData.append(key, values[key]);
      }
    }
    updateMemberRegistration(formData)
      .then((res) => {
        if (res.error) {
          
          setErrorMessege(res.error.data);
          setTimeout(() => {
            setErrorMessege(null);
          }, [3000]);
        } else {
          setSuccesMessege("Successfully Edited User Updated.");
          setTimeout(() => {
            history("/dashboard/users");
          }, [3000]);
        }
      })
      .catch((err) => {
      });
  };
  return (
    <>
      <Box className="table-design-background">
        <Grid container>
          <Grid item xs={6}>
            <Typography className="dashboard-home-page-text">
              Edit Member Lists
            </Typography>
          </Grid>
        </Grid>
        <Box
          style={{
            paddingLeft: "1.5rem",
            paddingRight: "1.5rem",
            paddingBottom: "1.5rem",
          }}
        >
          <MemberFormPage
            handleInputChange={handleInputChange}
            values={values}
            handleSubmit={handleSubmit}
            handleFileUpload={handleImageUpload}
            setImage={setFile1}
            MemberData={MemberData}
            PaymentData={PaymentData}
          />
        </Box>
        {SuccessMessege === null ? null : (
          <Box className="successmessege">
            <Typography className="messegefonts">
              <FontAwesomeIcon icon={faCheckCircle} className="messegeicon" />
              {SuccessMessege}
            </Typography>
          </Box>
        )}
        {ErrorMessege === null ? null : (
          <Box className="errormessege">
            <Typography className="messegefonts">
              <FontAwesomeIcon
                icon={faExclamationTriangle}
                className="messegeicon"
              />
              {ErrorMessege}
            </Typography>
          </Box>
        )}
      </Box>
    </>
  );
};
export default ActivityDashboardPage;
