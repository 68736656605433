import {
  faCheckCircle,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Grid } from "@material-ui/core";
import { Button, Typography } from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../../../constant/baseURL";
import Footer from "../component/footer";
import Header from "../component/header";
import SignUpPageNew from "./signupNewPage";
import { useAddMemberRegistrationMutation } from "../../../api/memberRegistrationSlice";
import BlobToFile from "../../../components/forms/utils/blobToFile";
import { useForm1 } from "../../../components/forms/useForm";
import moment from "moment/moment";

const SignupNew = () => {
  return (
    <>
      <Header />
      <SignupNewPage />
      <Footer />
    </>
  );
};

const SignupNewPage = () => {
  const [File1, setFile1] = useState([]);
  const [SuccessMessege, setSuccesMessege] = useState(null);
  const [ErrorMessege, setErrorMessege] = useState(null);
  const [Active, setActive] = useState(false);
  const [isSamePermanent, setIsSamePermanent] = useState(false);

  const initialFValues = {
    first_name: "",
    middle_name: "",
    last_name: "",
    permanent_street: "",
    permanent_ward_no: 0,
    permanent_local_level: "",
    permanent_district: "",
    permanent_country: "",
    temporary_street: "",
    temporary_ward_no: 0,
    temporary_local_level: "",
    temporary_district: "",
    contact_no: "",
    blood_group: "",
    name_of_company: "",
    date_of_joining: new Date(),
    division: "",
    current_position: "",
    territory: "",
    academic_degree: "",
    academic_faculty: "",
    appointment_letter: [],
    academic_transcript: [],
    profile_pic: [],
    confirmation: false,
    password: "",
    cpassword: "",
    payment_details: [],
    emergency_contact: "",
    year: "",
    lf_no: "",
    nmsra_no: "",
  };
  const { values, handleImageUpload, handleInputChange } = useForm1(
    initialFValues,
    true,
    false,
    false
  );

  const history = useNavigate();

  const [addMemberRegistration] = useAddMemberRegistrationMutation();

  useEffect(() => {
    handleImageUpload("profile_pic", File1);
  }, [File1]);

  const acceptTerms = (e) => {
    setActive(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();

    if (values.profile_pic.length !== 0) {
      let file1 = BlobToFile(values.profile_pic, "homePage1");

      if (file1.length !== 0) {
        formData.append("profile_pic", file1, "profile_pic.jpg");
      }
    }
    if (values.payment_details) {
      formData.append("payment_details", values.payment_details);
    }
    for (let key in values) {
      if (key !== "profile_pic") {
        formData.append(key, values[key]);
      }
      if (key === "date_of_joining") {
        formData.append(key, moment(values[key]).format("YYYY-MM-DD"));
      }
      if (
        (key === "academic_transcript" || key === "appointment_letter") &&
        values[key] === undefined
      ) {
        formData.append(key, []);
      }
      if (isSamePermanent) {
        formData.append("temporary_street", values.permanent_street);
        formData.append("temporary_local_level", values.permanent_local_level);
        formData.append("temporary_ward_no", values.permanent_ward_no);
        formData.append("temporary_district", values.permanent_district);
      }
    }
    if (values.academic_transcript == null) {
      setErrorMessege('Upload Your Academic Degree.')
      setTimeout(() => {
        setErrorMessege(null);
      }, [3000])
    }
    else {
      if (values.appointment_letter==null) {
        setErrorMessege('Upload Your Appointment Letter.')
        setTimeout(() => {
          setErrorMessege(null);
        }, [3000])
      }
      else {
        if (values.profile_pic==null) {
          setErrorMessege('Upload Your Profile Picture.')
          setTimeout(() => {
            setErrorMessege(null);
          }, [3000])
        } else {
          if (Active) {
            addMemberRegistration(formData)
              .then((response) => {
                if (response.error) {
                  
                  setErrorMessege(response.error.data.user.email);
                  setTimeout(() => {
                    setErrorMessege(null);
                  }, [3000]);
                } else {
                  setSuccesMessege("Successfully Registered User.");
                  setTimeout(() => {
                    history("/login");
                  }, [3000]);
                }
              })
              .catch((err) => { });
          } else {
            setErrorMessege("You have not accepted terms and condition.");
            setTimeout(() => {
              setErrorMessege(null);
            }, [3000]);
          }
        }
      }
    }
  };
  return (
    <Box className="signinmargin res-padding" style={{ textAlign: "start" }}>
      <Grid item xs={12} md={8} className="signinauto">
        <Grid container>
          <Grid item xs={12} md={5} className="leftcolor res-display">
            <Box className="signinnayamargin1">
              <Typography className="accounttext">
                Already Have an Account?
              </Typography>
              <Link to="/login">
                <button className="signupnewbtn">Login</button>
              </Link>
            </Box>
          </Grid>
          <Grid item md={7} xs={12} className="signinleft">
            <Box>
              <Typography className="signintext">Sign Up</Typography>
            </Box>
            <SignUpPageNew
              handleInputChange={handleInputChange}
              values={values}
              handleSubmit={handleSubmit}
              handleFileUpload={handleImageUpload}
              setImage={setFile1}
              acceptTerms={acceptTerms}
              setIsSamePermanent={setIsSamePermanent}
              isSamePermanent={isSamePermanent}
            />
            {SuccessMessege === null ? null : (
              <Box className="successmessege">
                <Typography className="messegefonts">
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    className="messegeicon"
                  />
                  {SuccessMessege}
                </Typography>
              </Box>
            )}
            {ErrorMessege === null ? null : (
              <Box className="errormessege">
                <Typography className="messegefonts">
                  <FontAwesomeIcon
                    icon={faExclamationTriangle}
                    className="messegeicon"
                  />
                  {ErrorMessege}
                </Typography>
              </Box>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SignupNew;
