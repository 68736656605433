import React, { useEffect, useState } from "react";
import Sidebar from "../../component/sidebar";
import Header from "../../component/header";
import { Box, Grid, Typography } from "@mui/material";
import Controls from "../forms/controls/Controls";
import { useForm, Form } from "../forms/useForm";
import { useAddBlogMutation } from "../../../../../api/blogSlice";
import BlobToFile from "../forms/utils/blobToFile";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCog,
  faBell,
  faSignInAlt,
  faExclamationTriangle,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

const AddBlog = () => {
  return (
    <Grid container className="dashboard-full-background">
      <Grid item xs={2} spacing={4}>
        <Sidebar />
      </Grid>
      <Grid item xs={10}>
        <Header />
        <AddBlogPages />
      </Grid>
    </Grid>
  );
};

const AddBlogPages = () => {
  const [File, setFile] = useState([]);
  const [PDF,setPDF] = useState([]);
  const initialFValues = {
    image: [],
    subtitle: "",
    description: "",
    pdf_file:[],
  };
  const { values, handleImageUpload, handleInputChange } = useForm(
    initialFValues,
    true,
    false,
    false
  );
  const [SuccessMessege, setSuccesMessege] = useState(null);
  const [ErrorMessege, setErrorMessege] = useState(null);
  const history = useNavigate();

  const [addBlog] = useAddBlogMutation();

  useEffect(() => {
    handleImageUpload("image", File);
  }, [File]);

  const thisFile = (e) => {};
  

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    var file = BlobToFile(values.image, "blog");
    if (file.length !== 0) {
      formData.append("image", file, "blog.jpg");
    }
    formData.append("subtitle", values.subtitle);
    formData.append("description", values.description);
    formData.append('pdf_file',values.pdf_file[0]);
    addBlog(formData)
      .then((res) => {
        setSuccesMessege("Successfully Added Notice Page Data.");
        setTimeout(() => {
          history("/dashboard/news");
        }, [3000]);
      })
      .catch((err) => {
        setErrorMessege("Some Error Occur. Please Try Again Later.");
        setTimeout(() => {
          setErrorMessege(null);
        }, [3000]);
      });
  };

  return (
    <Box className="table-design-background">
      <Typography className="dashboard-home-page-text">
        Add News Page
      </Typography>
      <Box style={{ padding: "1.5rem" }}>
        <Form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Controls.Input
                name="subtitle"
                label="Title"
                value={values.subtitle}
                onChange={handleInputChange}
              />
              <Controls.Input
                name="description"
                label="Description"
                value={values.description}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={6}>
              <Controls.Image setFile={setFile} aspectRatio={368 / 246} />
              <Controls.File onChange={handleInputChange}  name="pdf_file" />
            </Grid>
            <Grid item xs={2}>
              <Controls.Button
                type="submit"
                text="Submit"
                backgroundColor="#1b284b"
                onClick={handleSubmit}
              />
            </Grid>
          </Grid>
        </Form>
        {SuccessMessege === null ? null : (
          <Box className="successmessege">
            <Typography className="messegefonts">
              <FontAwesomeIcon icon={faCheckCircle} className="messegeicon" />
              {SuccessMessege}
            </Typography>
          </Box>
        )}
        {ErrorMessege === null ? null : (
          <Box className="errormessege">
            <Typography className="messegefonts">
              <FontAwesomeIcon
                icon={faExclamationTriangle}
                className="messegeicon"
              />
              {ErrorMessege}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};
export default AddBlog;
