import {
    createSelector,
    createEntityAdapter
} from "@reduxjs/toolkit";

import { apiSlice } from "./apiSlice";

const landingPagesAdapter = createEntityAdapter()

const initialState = landingPagesAdapter.getInitialState()

export const landingPageSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getLandingPages: builder.query({
            query: () => '/basic/landing-page/',
            transformResponse: responseData => {
                return landingPagesAdapter.setAll(initialState, responseData)
            },
            providesTags: (result, error, arg) => 
            [
                { type: 'LandingPages', id: "LIST" },
                ...result.ids.map(id => ({ type: 'LandingPages', id }))
            ]
        }),
        getActiveLandingPages: builder.query({
            query: () => '/basic/landing-page/?is_active=True',
            transformResponse: responseData => {
                return landingPagesAdapter.setAll(initialState, responseData)
            },
            providesTags: (result, error, arg) => 
            [
                { type: 'LandingPages', id: "LIST" },
                ...result.ids.map(id => ({ type: 'LandingPages', id }))
            ]
        }),
        
        getLandingPagesById: builder.query({
            query: (id)=>({
                url:`/basic/landing-page/${id}/`,
                method: 'GET',
            }),
            providesTags: ['LandingPages']
        }),
        addLandingPage: builder.mutation({
            query: (landingPage) => {
            return{
                url: '/basic/landing-page/',
                method: 'POST',
                body: landingPage
            }
        },
            invalidatesTags: ['LandingPages']
        }),
        updateLandingPage: builder.mutation({
           
            query: (updateLandingPage) => {
                return{
                url: `/basic/landing-page/${updateLandingPage.get('id')}/`,
                method: 'PATCH',
                body: updateLandingPage
                }
            },
            invalidatesTags: ['LandingPages']
        }),
        deleteLandingPage: builder.mutation({
            query: ({ id }) => {
                return{
                url: `/basic/landing-page/${id}/`,
                method: 'DELETE',
                body: id
            }},
            invalidatesTags: ['LandingPages']
        }),
    })
})

export const {
    useGetLandingPagesQuery,
    useAddLandingPageMutation,
    useUpdateLandingPageMutation,
    useDeleteLandingPageMutation,
    useGetLandingPagesByIdQuery,
    useGetActiveLandingPagesQuery,
} = landingPageSlice

// returns the query result object
export const selectLandingPagesResult = landingPageSlice.endpoints.getLandingPages.select()

// Creates memoized selector
const selectLandingPagessData = createSelector(
    selectLandingPagesResult,
    landingPagesResult => landingPagesResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllLandingPages,
    selectById: selectLandingPageById,
    selectIds: selectIds
    // Pass in a selector that returns the posts slice of state
} = landingPagesAdapter.getSelectors(state => selectLandingPagessData(state) ?? initialState)