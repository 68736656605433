import {
    createSelector,
    createEntityAdapter
} from "@reduxjs/toolkit";

import { apiSlice } from "./apiSlice";

const visionsAdapter = createEntityAdapter()

const initialState = visionsAdapter.getInitialState()

export const visionSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getVisions: builder.query({
            query: () => '/basic/vision/',
            transformResponse: responseData => {
                return visionsAdapter.setAll(initialState, responseData)
            },
            providesTags: (result, error, arg) => 
            [
                { type: 'Visions', id: "LIST" },
                ...result.ids.map(id => ({ type: 'Visions', id }))
            ]
        }),
        getVisionsById: builder.query({
            query: (id)=>({

                url:`/basic/vision/${id}/`,
                method: 'GET',
            }),
            providesTags: ['Visions']
        }),
        addVision: builder.mutation({
            query: (vision) => {
            return{
                url: '/basic/vision/',
                method: 'POST',
                body: vision,
            }
        },
            invalidatesTags: ['Visions']
        }),
        updateVision: builder.mutation({
           
            query: (updateVision) => {
                return{
                url: `/basic/vision/${updateVision.get('id')}/`,
                method: 'PATCH',
                body: updateVision
                }
            },
            invalidatesTags: ['Visions']
        }),
        deleteVision: builder.mutation({
            query: ({ id }) => {
                return{
                url: `/basic/vision/${id}/`,
                method: 'DELETE',
                body: id
            }},
            invalidatesTags: ['Visions']
        }),
    })
})

export const {
    useGetVisionsQuery,
    useAddVisionMutation,
    useUpdateVisionMutation,
    useDeleteVisionMutation,
    useGetVisionsByIdQuery
} = visionSlice

// returns the query result object
export const selectVisionsResult = visionSlice.endpoints.getVisions.select()

// Creates memoized selector
const selectVisionssData = createSelector(
    selectVisionsResult,
    visionsResult => visionsResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllVisions,
    selectById: selectVisionById,
    selectIds: selectVisionIds
    // Pass in a selector that returns the posts slice of state
} = visionsAdapter.getSelectors(state => selectVisionssData(state) ?? initialState)
