import {
    createSelector,
    createEntityAdapter
} from "@reduxjs/toolkit";

import { apiSlice } from "./apiSlice";
import convertArrayRepeatedToObject from "../utils/convertArrayRepeatedToObject";
import checkArrayType from "../utils/checkArrayType";

const hospitalHolidaysAdapter = createEntityAdapter()

const initialState = hospitalHolidaysAdapter.getInitialState()

export const hospitalHolidaySlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getHospitalHolidays: builder.query({
            query: () => 'bookings/hospital-holidays/',
            transformResponse: responseData => {
                return hospitalHolidaysAdapter.setAll(initialState, responseData)
            },
            providesTags: []
        }),
        getActiveHospitalHolidays: builder.query({

            query: (hospital_id) => `/bookings/hospital-date/?is_holiday=True&hospital=${hospital_id}`,
            transformResponse: responseData =>{
                
                if(responseData.length>0)
                {
                    let objectData = convertArrayRepeatedToObject(responseData);
                    objectData['date'] = objectData['date'].map((dateString) => checkArrayType(dateString) === "string" ? new Date(dateString): dateString);
                    return objectData;
                }
                else{
                    return {date: [], hospital:[], is_holiday:true};
                }
            },
         
            providesTags: []
        }),
        getHospitalHolidayById: builder.query({
            query: (id)=>({
                url:`/bookings/hospital-holidays/${id}/`,
                method: 'GET',
            }),
            providesTags: []
        }),
        addHospitalHoliday: builder.mutation({
            query: (hospitalHoliday) => {
            
            return{
                url: '/bookings/hospital-holidays/',
                method: 'POST',
                body: hospitalHoliday
            }
        },
            invalidatesTags: []
        }),
        updateHospitalHoliday: builder.mutation({
           
            query: (updateHospitalHoliday) => {
                
                
                return{
                url: `/bookings/hospital-date/bulk_update_hospital_date/`,
                method: 'POST',
                body: updateHospitalHoliday
                }
            },
            invalidatesTags: []
        }),
        deleteHospitalHoliday: builder.mutation({
            query: ({ id }) => {
                
                return{
                url: `/bookings/hospital-holidays/${id}/`,
                method: 'DELETE',
                body: id
            }},
            invalidatesTags: []
        }),
    })
})

export const {
    useGetHospitalHolidaysQuery,
    useGetActiveHospitalHolidaysQuery,
    useAddHospitalHolidayMutation,
    useUpdateHospitalHolidayMutation,
    useDeleteHospitalHolidayMutation,
    useGetHospitalHolidayByIdQuery
} = hospitalHolidaySlice

// returns the query result object
export const selectHospitalHolidaysResult = hospitalHolidaySlice.endpoints.getHospitalHolidays.select()

// Creates memoized selector
const selectHospitalHolidayssData = createSelector(
    selectHospitalHolidaysResult,
    hospitalHolidaysResult => hospitalHolidaysResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllHospitalHolidays,
    selectById: selectAboutPageById,
    selectIds: selectIds
    // Pass in a selector that returns the posts slice of state
} = hospitalHolidaysAdapter.getSelectors(state => selectHospitalHolidayssData(state) ?? initialState)
