import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Box, Grid, Typography } from "@mui/material";
import Sidebar from "../../component/sidebar";
import Header from "../../component/header";
import Controls from "../forms/controls/Controls";
import { useForm, Form } from "../forms/useForm";
import {
  useGetDoctorCallCardsByIdQuery,
  useUpdateDoctorCallCardMutation
} from "../../../../../api/doctorCallCardSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExclamationTriangle,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

const DoctorCallCardEditPage = () => {
  return (
    <Grid container className="dashboard-full-background">
      <Grid item md={2} xs={12} sm={12}>
        <Sidebar />
      </Grid>
      <Grid item md={10} xs={12} sm={12} className="res-margin-design">
        <Header />
        <EditDoctorCallCardPages />
      </Grid>
    </Grid>
  );
};

const EditDoctorCallCardPages = () => {
  const { id } = useParams();
  const DoctorsCallCard = useGetDoctorCallCardsByIdQuery(id);
  const [updateDoctor, setUpdateDoctor] = useState([]);
  const [initialFValues, setInitialFValues] = useState({
    company: "",
    division: "",
    doctor_name: "",
    call_date: new Date(),
    call_type: ""
  });
  const [SuccessMessege, setSuccesMessege] = useState(null);
  const [ErrorMessege, setErrorMessege] = useState(null);
  const history = useNavigate();

  useEffect(() => {
    if (DoctorsCallCard.data) {
      setInitialFValues({
        company: DoctorsCallCard.data.company,
        division: DoctorsCallCard.data.division,
        doctor_name: DoctorsCallCard.data.doctor_name,
        call_date: DoctorsCallCard.data.call_date,
        call_type: DoctorsCallCard.data.call_type
      });
    }
  }, [DoctorsCallCard.data]);

  const { values, handleImageUpload, handleInputChange } = useForm(
    initialFValues,
    true,
    false,
    true
  );

  const [editDoctorCallCard] = useUpdateDoctorCallCardMutation();



  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    if (values.company)
      formData.append("company", values.company);
    if (values.division)
      formData.append("division", values.division);
    if (values.doctor_name)
      formData.append("doctor_name", values.doctor_name);
    if (values.call_date)
      formData.append("call_date", values.call_date)
    if (values.call_type)
      formData.append("call_type", values.call_type)
    formData.append("id", id);
    editDoctorCallCard(formData)
      .then((res) => {
        setSuccesMessege("Successfully Update Doctor Call Card Data.");
        setTimeout(() => {
          history("/dashboard/doctors-call-card");
        }, [3000]);
      })
      .catch((err) => {
        setErrorMessege("Some Error Occur. Please Try Again Later.");
        setTimeout(() => {
          setErrorMessege(null);
        }, [3000]);
      });
  };

  return (
    <Box className="table-design-background">
      <Typography className="dashboard-home-page-text">
        Update Doctor List
      </Typography>
      <Box style={{ padding: "1.5rem" }}>
        <Form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <Controls.Input
                name="company"
                label="Company"
                value={values.company}
                onChange={handleInputChange}
              />
              <Controls.Input
                name="doctor_name"
                label="Doctor Name"
                value={values.doctor_name}
                onChange={handleInputChange}
              />
              <Box style={{ marginLeft: '10px', marginTop: '10px' }}>
                <Controls.Select
                  name="call_type"
                  label="Call Type"
                  options={
                    [
                      { id: "Single", title: 'Single Call' },
                      { id: "Joint", title: "Joint Call" }
                    ]
                  }
                  value={values.call_type}
                  onChange={handleInputChange}
                />
              </Box>
            </Grid>
            <Grid item md={6} xs={12}>
              <Controls.Input
                name="division"
                label="Division"
                value={values.division}
                onChange={handleInputChange}
              />
              <Box style={{ marginLeft: '10px' }}>
                <Controls.DatePickers
                  name="call_date"
                  label="Call Date"
                  value={values.call_date}
                  onChange={handleInputChange}
                />
              </Box>
            </Grid>
            <Grid md={1.5} xs={3} style={{ marginTop: "12px", marginLeft: "25px" }}>
              <Controls.Button
                type="submit"
                text="Submit"
                backgroundColor="#1b284b"
                onClick={handleSubmit}
              />
            </Grid>
          </Grid>
        </Form>
        {SuccessMessege === null ? null : (
          <Box className="successmessege">
            <Typography className="messegefonts">
              <FontAwesomeIcon icon={faCheckCircle} className="messegeicon" />
              {SuccessMessege}
            </Typography>
          </Box>
        )}
        {ErrorMessege === null ? null : (
          <Box className="errormessege">
            <Typography className="messegefonts">
              <FontAwesomeIcon
                icon={faExclamationTriangle}
                className="messegeicon"
              />
              {ErrorMessege}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default DoctorCallCardEditPage;
