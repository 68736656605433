import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Sidebar from "../../component/sidebar";
import { Grid, Typography } from "@mui/material";
import Header from "../../component/header";
import BasicTable from "../tables/BasicTable";
import { useGetKathmanduPresidentsByIdQuery,
useGetKathmanduPresidentsQuery,
useAddKathmanduPresidentMutation,
useDeleteKathmanduPresidentMutation } from "../../../../../api/kathmanduPresidentSlice";
import KathmanduPresidentAddButton from "./kathmanduPresidentAddButton";
import { Box } from "@mui/system";

const DashboardKathmanduPresidents = () => {
  return (
    <Grid container className="dashboard-full-background">
      <Grid item xs={2} spacing={4}>
        <Sidebar />
      </Grid>
      <Grid item xs={10}>
        <Header />
        <DashboardKathmanduPresidentsPages />
      </Grid>
    </Grid>
  );
};

const DashboardKathmanduPresidentsPages = () => {
  const { data, isLoading, isSuccess, isError, error } =
    useGetKathmanduPresidentsQuery("getKathmanduPresident");

  const [deleteKathmanduPresident] = useDeleteKathmanduPresidentMutation();

  const deleteConfirm = (e, id) => {
    e.preventDefault();
    
    deleteKathmanduPresident({ id });
  };

  let headers = [];
  let tableData = [];
  let content;
  if (isLoading) {
    content = <p>"Loading..."</p>;
  } else if (isSuccess) {
    headers = ['id', "Name", "Years"];
    tableData = [];
    data.ids.forEach((key) => {
      tableData.push({
        id: data.entities[key].id,
        Name: data.entities[key].name,
        Years: data.entities[key].years
      });
    });
  }
  return (
    <>
      <Box className="table-design-background">
        <Grid container>
          <Grid item xs={6}>
            <Typography className="dashboard-home-page-text">Janakpur President Details</Typography>
          </Grid>
          <Grid item xs={6}>
            <Link to="/dashboard/new/kathmandu-presidents" style={{textDecoration:'none'}}>
              <KathmanduPresidentAddButton />
            </Link>
          </Grid>
        </Grid>
        <Box style={{ padding: "1rem" }}>
          <BasicTable
            headers={headers}
            data={tableData}
            isDelete={true}
            deleteColor={"#f50057"}
            isEdit={true}
            editColor={"#3f51b5"}
            addColor={""}
            tableName={"kathmandu-presidents"}
            deleteConfirm={deleteConfirm}
            activeSign={["Active"]}
          />
        </Box>
      </Box>
    </>
  );
};
export default DashboardKathmanduPresidents;
