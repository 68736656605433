import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Grid, Typography } from "@mui/material";
import Sidebar from "../../component/sidebar";
import Header from "../../component/header";
import Controls from "../forms/controls/Controls";
import { useForm, Form } from "../forms/useForm";
import { useGetAboutPagesByIdQuery } from "../../../../../api/aboutPageSlice";
import BlobToFile from "../forms/utils/blobToFile";
import { useUpdateAboutPageMutation } from "../../../../../api/aboutPageSlice";
import urlToFile from "../forms/utils/urlToFile";
import { Box } from "@mui/system";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExclamationTriangle,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import {
  useGetBlogsByIdQuery,
  useUpdateBlogMutation,
} from "../../../../../api/blogSlice";
import { useGetLifeTimeByIdQuery, useUpdateLifeTimePageMutation } from "../../../../../api/lifeTimeSlice";

const LifeTimeEditPage = () => {
  return (
    <Grid container className="dashboard-full-background">
      <Grid item xs={2} spacing={4}>
        <Sidebar />
      </Grid>
      <Grid item xs={10}>
        <Header />
        <EditEventPagePages />
      </Grid>
    </Grid>
  );
};

const EditEventPagePages = () => {
  const { id } = useParams();
  const AboutPages = useGetLifeTimeByIdQuery(id);
  const [File, setFile] = useState([]);
  const [SuccessMessege, setSuccesMessege] = useState(null);
  const [ErrorMessege, setErrorMessege] = useState(null);
  const [updateAboutPage, setUpdateAboutPage] = useState([]);
  const [initialFValues, setInitialFValues] = useState({
    name: "",
    email: "",
    contact_no: "",
    role: "",
  });
  
  const history = useNavigate();

  useEffect(() => {
    if (AboutPages.data) {
      if (AboutPages.data.image) {
        urlToFile(AboutPages.data.image, setFile);
      }
      setInitialFValues({
        name: AboutPages.data.name,
        email: AboutPages.data.email,
        contact_no: AboutPages.data.contact_no,
        role: AboutPages.data.role,
      });
    }
  }, [AboutPages.data]);

  const { values, handleImageUpload, handleInputChange } = useForm(
    initialFValues,
    true,
    false,
    true
  );

  const [editLifePage] = useUpdateLifeTimePageMutation();

  useEffect(() => {
    handleImageUpload("image", File);
  }, [File]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    // var file = BlobToFile(values.image, "aboutPage");
    // var isTrueSet = values.is_active === "true";
    // if (file.length !== 0) {
    //   formData.append("image", file, "aboutPage.jpg");
    // }
    if (values.name) formData.append("name", values.name);
    if (values.email) formData.append("email", values.email);
    if (values.contact_no) formData.append("contact_no", values.contact_no);
    if (values.role) formData.append("role", values.role);
    formData.append("id", id);
    editLifePage(formData)
      .then((res) => {
        setSuccesMessege("Successfully Updated Life Time Member Page Data.");
        setTimeout(() => {
          history("/dashboard/life/time/Member");
        }, [3000]);
      })
      .catch((err) => {
        setErrorMessege("Some Error Occur. Please Try Again Later.");
        setTimeout(() => {
          setErrorMessege(null);
        }, [3000]);
      });
  };

  return (
    <Box className="table-design-background">
      <Typography className="dashboard-home-page-text">
        Update Life Time Member Page
      </Typography>
      <Box style={{ padding: "1.5rem" }}>
        <Form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Controls.Input
                name="name"
                label="Full Name"
                value={values.name}
                onChange={handleInputChange}
              />
              <Controls.Input
                name="email"
                label="Email"
                value={values.email}
                onChange={handleInputChange}
              />
              {/* <Controls.RadioGroup
                name="is_active"
                label="Is Active"
                value={values.is_active}
                onChange={handleInputChange}
                items={[
                  { id: "false", title: "False" },
                  { id: "true", title: "True" },
                ]}
              /> */}
            </Grid>
            <Grid item xs={6}>
              <Controls.Input
                name="contact_no"
                label="Contact Number"
                value={values.contact_no}
                onChange={handleInputChange}
              />
              <Controls.Input
                name="role"
                label="Role"
                value={values.role}
                onChange={handleInputChange}
              />
              {/* <Box
                style={{
                  marginBottom: "12px",
                  marginTop: "7px",
                  marginLeft: "8px",
                }}
              >
                <Controls.Image setFile={setFile} aspectRatio={636 / 566} />
              </Box> */}
            </Grid>
            <Grid>
              <Box style={{ marginLeft: "1.5rem", marginTop: "1rem" }}>
                <Controls.Button
                  type="submit"
                  text="Submit"
                  backgroundColor="#1b284b"
                  onClick={handleSubmit}
                />
              </Box>
            </Grid>
          </Grid>
        </Form>
        {SuccessMessege === null ? null : (
          <Box className="successmessege">
            <Typography className="messegefonts">
              <FontAwesomeIcon icon={faCheckCircle} className="messegeicon" />
              {SuccessMessege}
            </Typography>
          </Box>
        )}
        {ErrorMessege === null ? null : (
          <Box className="errormessege">
            <Typography className="messegefonts">
              <FontAwesomeIcon
                icon={faExclamationTriangle}
                className="messegeicon"
              />
              {ErrorMessege}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default LifeTimeEditPage;
