import { Box } from "@mui/material";
import React from "react";
import TextButton from "../../../../../components/button/buttonicon";

const LifeTimeMemberButton = () => {
  return (
    <Box style={{marginTop:'1rem',textAlign:"right",marginRight:"1.2rem"}}>
      <TextButton buttonText="Add Lifetime Member" />
    </Box>
  );
};

export default LifeTimeMemberButton;
