import { createSelector, createEntityAdapter } from "@reduxjs/toolkit";

import { apiSlice } from "./apiSlice";

const blogsAdapter = createEntityAdapter();

const initialState = blogsAdapter.getInitialState();

export const blogSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getBlogs: builder.query({
      query: () => "/blog/events/?event_types=normal",
      transformResponse: (responseData) => {
        let returnResponse = [];
        responseData.map((i) => {
          let newData = new Date(i.created_at);

          returnResponse.push({ ...i, created_at: newData.toDateString() });
        });

        return blogsAdapter.setAll(initialState, returnResponse);
      },
      providesTags: (result, error, arg) => [
        { type: "Blogs", id: "LIST" },
        ...result.ids.map((id) => ({ type: "Blogs", id })),
      ],
    }),
    getBlogSpecial: builder.query({
      query: () => "/blog/events/?event_types=special",
      transformResponse: (responseData) => {
        let returnResponse = [];
        responseData.map((i) => {
          let newData = new Date(i.created_at);

          returnResponse.push({ ...i, created_at: newData.toDateString() });
        });

        return blogsAdapter.setAll(initialState, returnResponse);
      },
      providesTags: (result, error, arg) => [
        { type: "Blogs", id: "LIST" },
        ...result.ids.map((id) => ({ type: "Blogs", id })),
      ],
    }),
    getAllBlogs: builder.query({
      query: () => "/blog/events/",
      transformResponse: (responseData) => {
        let returnResponse = [];
        responseData.map((i) => {
          let newData = new Date(i.created_at);

          returnResponse.push({ ...i, created_at: newData.toDateString() });
        });

        return blogsAdapter.setAll(initialState, returnResponse);
      },
      providesTags: (result, error, arg) => [
        { type: "Blogs", id: "LIST" },
        ...result.ids.map((id) => ({ type: "Blogs", id })),
      ],
    }),
    getBlogsById: builder.query({
      query: (id) => ({
        url: `/blog/events/${id}`,
        method: "GET",
      }),
      providesTags: ["Blogs"],
    }),
    addBlog: builder.mutation({
      query: (blog) => {
        return {
          url: "/blog/events/",
          method: "POST",
          body: blog,
        };
      },
      invalidatesTags: ["Blogs"],
    }),
    updateBlog: builder.mutation({
      query: (updateBlog) => {
        return {
          url: `/blog/events/${updateBlog.get("id")}/`,
          method: "PATCH",
          body: updateBlog,
        };
      },
      invalidatesTags: ["Blogs"],
    }),
    deleteBlog: builder.mutation({
      query: ({ id }) => {
        return {
          url: `/blog/events/${id}/`,
          method: "DELETE",
          body: id,
        };
      },
      invalidatesTags: ["Blogs"],
    }),
    getCount: builder.query({
      query: () => {
        return {
          url: `/blog/events-pagination/get_count/`,
          method: "GET",
        };
      },
      invalidatesTags: ["Blogs"],
    }),
    getBlogPagination: builder.query({
      query: (id) => {
        return {
          url: `/blog/events-pagination/?page=${id}`,
          method: "GET",
        };
      },
      invalidatesTags: ["Blogs"],
    }),
  }),
});

export const {
  useGetBlogsQuery,
  useGetBlogSpecialQuery,
  useGetAllBlogsQuery,
  useAddBlogMutation,
  useUpdateBlogMutation,
  useDeleteBlogMutation,
  useGetBlogsByIdQuery,
  useGetCountQuery,
  useGetBlogPaginationQuery,
} = blogSlice;

// returns the query result object
export const selectBlogsResult = blogSlice.endpoints.getBlogs.select();

// Creates memoized selector
const selectBlogssData = createSelector(
  selectBlogsResult,
  (blogsResult) => blogsResult.data // normalized state object with ids & entities
);

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
  selectAll: selectAllBlogs,
  selectById: selectBlogById,
  selectIds: selectIds,
  // Pass in a selector that returns the posts slice of state
} = blogsAdapter.getSelectors(
  (state) => selectBlogssData(state) ?? initialState
);
