import {
    createSelector,
    createEntityAdapter
} from "@reduxjs/toolkit";

import { apiSlice } from "./apiSlice";

const aboutPagesAdapter = createEntityAdapter()

const initialState = aboutPagesAdapter.getInitialState()

export const aboutPageSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getAboutPages: builder.query({
            query: () => '/basic/about/',
            transformResponse: responseData => {
                return aboutPagesAdapter.setAll(initialState, responseData)
            },
            providesTags: (result, error, arg) => 
            [
                { type: 'AboutPages', id: "LIST" },
                ...result.ids.map(id => ({ type: 'AboutPages', id }))
            ]
        }),
        getActiveAboutPages: builder.query({
            query: () => '/basic/about/?is_active=True',
            transformResponse: responseData => {
                return aboutPagesAdapter.setAll(initialState, responseData)
            },
            providesTags: (result, error, arg) => 
            [
                { type: 'AboutPages', id: "LIST" },
                ...result.ids.map(id => ({ type: 'AboutPages', id }))
            ]
        }),
        getAboutPagesById: builder.query({
            query: (id)=>({
                url:`/basic/about/${id}/`,
                method: 'GET',
            }),
            providesTags: ['AboutPages']
        }),
        addAboutPage: builder.mutation({
            query: (aboutPage) => {
            
            return{
                url: '/basic/about/',
                method: 'POST',
                body: aboutPage
            }
        },
            invalidatesTags: ['AboutPages']
        }),
        updateAboutPage: builder.mutation({
           
            query: (updateAboutPage) => {
                
                
                return{
                url: `/basic/about/${updateAboutPage.get('id')}/`,
                method: 'PATCH',
                body: updateAboutPage
                }
            },
            invalidatesTags: ['AboutPages']
        }),
        deleteAboutPage: builder.mutation({
            query: ({ id }) => {
                
                return{
                url: `/basic/about/${id}/`,
                method: 'DELETE',
                body: id
            }},
            invalidatesTags: ['AboutPages']
        }),
    })
})

export const {
    useGetAboutPagesQuery,
    useGetActiveAboutPagesQuery,
    useAddAboutPageMutation,
    useUpdateAboutPageMutation,
    useDeleteAboutPageMutation,
    useGetAboutPagesByIdQuery
} = aboutPageSlice

// returns the query result object
export const selectAboutPagesResult = aboutPageSlice.endpoints.getAboutPages.select()

// Creates memoized selector
const selectAboutPagessData = createSelector(
    selectAboutPagesResult,
    aboutPagesResult => aboutPagesResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllAboutPages,
    selectById: selectAboutPageById,
    selectIds: selectIds
    // Pass in a selector that returns the posts slice of state
} = aboutPagesAdapter.getSelectors(state => selectAboutPagessData(state) ?? initialState)
