import React, { useState, useEffect } from 'react'
import { Box, Button, Grid, Typography } from "@material-ui/core";
import Controls from '../forms/controls/Controls';
import { useGetExcludedHospitalDatesMutation } from '../../../../../api/hospitalBookingSlice';
import { useGetActiveHospitalHolidaysQuery } from '../../../../../api/hospitalHolidaySlice';
import { useGetHospitalsQuery } from '../../../../../api/hospitalSlice';
import Sidebar from '../../component/sidebar';
import Header from '../../component/header';
import { useForm, Form } from "../forms/useForm";
import { useAddHospitalBookingMutation } from '../../../../../api/hospitalBookingSlice';


const AdminBookings = () => {
    return (
        <Box className="dashboard-full-background">
            <Grid container spacing={0}>
                <Grid item xs={2}>
                    <Sidebar />
                </Grid>
                <Grid item xs={10}>
                    <Header />
                    <AdminBookingsPage />
                </Grid>
            </Grid>
        </Box>
    );
};

const AdminBookingsPage = () => {
    const [hospitalID, setHospitalID] = useState(null);
    const [disableDates, setDisableDates] = useState([]);
    const [userEmail, setUserEmail] = useState("");
    const [excludedDate, setExcludedDate] = useState();
    const hospitalHoliday = useGetActiveHospitalHolidaysQuery(hospitalID);
    const [accompanistNo, setAccompanistNo] = useState(0);
    const [selectedDates, setSelectedDates] = useState(new Date());

    const [addHospital] = useAddHospitalBookingMutation();

    const selectUser = (e) => {
        setUserEmail(e.target.value);
    }
    const accompanistNumber = (e) => {
        setAccompanistNo(e.target.value);
    }

    useEffect(() => {
        if (hospitalHoliday.status === "fulfilled") {
            setDisableDates(hospitalHoliday.data.date);
        }
    }, [hospitalHoliday, hospitalID]);
    const selectHospital = (e) => {
        setHospitalID(e.target.value);
    };
    const onDateSelect = (e) => {
        setSelectedDates(e.target.value);
    }
    const hospitalData = useGetHospitalsQuery();
    let hospitalList = [];
    if (hospitalData.status === "fulfilled") {
        hospitalData.data.ids.forEach((key) => {
            hospitalList.push({
                id: key,
                title: hospitalData.data.entities[key].name,
            });
        });
    }

    const confirmDate = (e) => {
        addHospital({
            'is_admin': true,
            'hospital_id': hospitalID,
            'nmsra_no': userEmail,
            'selected_date': selectedDates,
            'accompanist_number': accompanistNo
        }).then((res)=>{
            
        })
    }


    const [excludedHospitalDates] = useGetExcludedHospitalDatesMutation();
    return (
        <Box className="table-design-background">
            <Typography className="dashboard-home-page-text">
                Book My Hospital
            </Typography>
            <Box style={{ margin: "1.5rem", paddingBottom: "2rem" }}>
                <Form style={{ marginTop: "1rem" }}>
                    <Grid container spacing={3} style={{ marginTop: "1rem" }}>
                        <Grid item xs={6}>
                            <Controls.Select
                                name="hospital"
                                label="Select Hospital"
                                onChange={selectHospital}
                                options={hospitalList}
                            />
                        </Grid>
                        <Controls.Input
                            name="nmsra_no"
                            label="NMSRA No*"
                            onChange={selectUser} />

                        <Grid item xs={6}>
                            <Controls.DatePickers
                                buttonText="Select Booking Date"
                                name="date"
                                onChange={onDateSelect}
                                excludeDates={disableDates}
                                selectedDate={selectedDates}

                            // excludeDates={disableDates}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Controls.Select
                                name="accompanist_number"
                                label="Accompanist Number"
                                onChange={accompanistNumber}
                                options={[
                                    { id: 0, title: "0" },
                                    { id: 1, title: '1' },
                                    { id: 2, title: '2' }
                                ]}
                            />
                        </Grid>
                        {/* <Grid item xs={6}></Grid> */}
                        <Grid item>
                            <Controls.Button
                                onClick={confirmDate}
                                text={"Confirm Bookings"}
                            />
                        </Grid>
                    </Grid>
                </Form>
            </Box>
        </Box>
                    )
}

export default AdminBookings;