import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, } from "react-router-dom";
import Sidebar from "../../component/sidebar";
import { Grid, Pagination, Stack, Typography } from "@mui/material";
import Header from "../../component/header";
import BasicTable from "../tables/BasicTable";
import { Box } from "@mui/system";
import {
  useDeleteBlogMutation,
  useGetAllBlogsQuery,
} from "../../../../../api/blogSlice";
import LifeTimeMemberButton from "./lifeTimeAddButton";
import {
  useDeleteLifeTimePageMutation,
  useGetLifeTimePaginationQuery,
  useGetLifeTimeQuery,
} from "../../../../../api/lifeTimeSlice";
import EventAddButton from "../eventDashboard/EventAddButton";
import usePagination from "../../../../../components/reuseable/pagination";

const LifeTimeDashboardPage = () => {
  return (
    <Grid container className="dashboard-full-background">
      <Grid item xs={2} spacing={4}>
        <Sidebar />
      </Grid>
      <Grid item xs={10}>
        <Header />
        <DashboardEventPagesPages />
      </Grid>
    </Grid>
  );
};

const DashboardEventPagesPages = () => {
  const pageNo = useLocation();
  const paramPairs = decodeURIComponent(pageNo.search.substr(1));
  const paramPairs1 = paramPairs.split('=');
  const pageNumber = paramPairs1[1];

  const [pageId, setPageId] = useState();
  useEffect(() => {
    if (pageNumber !== undefined) {
      
      setPageId(pageNumber)
    } else {
      setPageId(1)
    }
  }, [pageNumber])
  const history = useNavigate();
  const { data, isLoading, isSuccess } = useGetLifeTimePaginationQuery(pageId);


  const [deleteBlogPage] = useDeleteLifeTimePageMutation();

  const deleteConfirm = (e, id) => {
    e.preventDefault();
    deleteBlogPage({ id });
  };

  let headers = [];
  let tableData = [];
  let content;

  var count;
  if (isLoading) {
    content = <p>"Loading..."</p>;
  } else if (isSuccess) {
    headers = ["Full Name", "Email", "Contact Number", "Role"];
    data.results.forEach((key) => {
      tableData.push({
        "Full Name": key.name,
        Email: key.email,
        id: key.id,
        "Contact Number": key.contact_no,
        Role: key.role,
      });
    });
    var newData = data.count / 8;
    count = Math.ceil(newData);
  }

  const [page, setPage] = useState(1);
  const PER_PAGE = 8;

  const _DATA = usePagination(tableData, count, PER_PAGE);

  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
    // setPageId(p);
    history(`/dashboard/life/time/Member?pageNumber=${p}`)
  };

  return (
    <>
      <Box className="table-design-background">
        <Grid container>
          <Grid item xs={6}>
            <Typography className="dashboard-home-page-text">
              Life Time Member Lists
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Link
              to="/dashboard/add/life/time/member"
              style={{ textDecoration: "none" }}
            >
              <LifeTimeMemberButton />
            </Link>
          </Grid>
        </Grid>
        <Box style={{ padding: "1.5rem" }}>
          <BasicTable
            headers={headers}
            data={tableData}
            isDelete={true}
            deleteColor={"#f50057"}
            isEdit={true}
            editColor={"#3f51b5"}
            addColor={""}
            tableName={"life/time/member"}
            deleteConfirm={deleteConfirm}
            activeSign={["Active"]}
          />
        </Box>
        <Box
          style={{
            marginTop: "20px",
            textAlign: "center",
            paddingBottom: "1rem",
            paddingLeft: "1rem",
          }}
        >
          <Stack spacing={2} style={{ textAlign: "center" }}>
            <Pagination
              count={count}
              size="small"
              page={parseInt(pageNumber)}
              variant="outlined"
              onChange={handleChange}
              color="primary"
            />
          </Stack>
        </Box>
      </Box>
    </>
  );
};
export default LifeTimeDashboardPage;
