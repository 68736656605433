import { Box, Container, Grid, Typography } from "@mui/material";
import React, { useRef } from "react";
import Sidebar from "../../component/sidebar";
import Header from "../../component/header";
import { useParams } from "react-router-dom";
import Logo from "../../../../../static/image/icon.png";
import html2canvas from "html2canvas";
import { useGetDoctorCallCardsByIdQuery } from "../../../../../api/doctorCallCardSlice";
import moment from "moment";
import Stamp from "../../../../../static/image/stamp.png";



const CallCardDownload = () => {
    return (
        <Grid container className="dashboard-full-background">
            <Grid item xs={2} spacing={4}>
                <Sidebar />
            </Grid>
            <Grid item xs={10}>
                <Header />
                <CallCardDownloadPage />
            </Grid>
        </Grid>
    )
}


const CallCardDownloadPage = () => {
    const { id } = useParams();

    const { data } = useGetDoctorCallCardsByIdQuery(id);

    const componentRef = useRef(null);
    const captureComponentAsImage = async () => {
        const canvas = await html2canvas(componentRef.current);

        const imageDataURL = canvas.toDataURL("idcard/png");

        const a = document.createElement("a");
        a.href = imageDataURL;
        a.download = "doctor-card.png";
        a.click();
    }

    return (
        <Box style={{ paddingBottom: "3rem" }}>
            <Box>
                <Container>
                    <Grid container spacing={0}>
                        <Grid item md={5.5} style={{ margin: "0 auto" }}>
                            <Box style={{ marginBottom: "10px" }}>
                                <Grid container>
                                    <Grid item xs={10}>
                                        <Typography style={{ color: "white", textAlign: "left", fontWeight: '700', fontSize: '16px' }}>Doctor Call Card</Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <button className="id-card-download-button" onClick={captureComponentAsImage}>Download</button>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box style={{ marginTop: "30px" }}>
                                <Box className="" style={{
                                    borderRadius: '20px',
                                    zIndex: 2,
                                    // width: '480px',
                                    // height: "640px",
                                    padding: '20px',
                                    backgroundColor: 'white',
                                }} ref={componentRef}>
                                    <Grid container spacing={0}>
                                        <Grid item xs={2}>
                                            <img src={Logo} style={{ marginLeft: "13px" }} className="img-fluid" />
                                        </Grid>
                                        <Grid item xs={10}>
                                            <h1 className="id-card-company-name" style={{ textAlign: 'center', fontSize: "24px", marginTop: "-5px" }}>Nmsra Janakpur Unit</h1>
                                            <p style={{ marginTop: "10px", fontSize: '14px', color: 'black', fontWeight: '600', textAlign: "center", textDecoration: "underline" }}>Doctor Call Card </p>
                                        </Grid>
                                    </Grid>
                                    <Box style={{ marginTop: "15px", position: 'relative' }}>
                                        <Grid container >
                                            <Grid item xs={5}>
                                                {/* <p className="status-id-card-text">Status:</p> */}
                                                {/* <p className="status-id-card-text">Card Number:</p> */}
                                                <p className="status-id-card-text">MR Name:</p>
                                                <p className="status-id-card-text">Company Name/Division:</p>
                                                <p className="status-id-card-text">Doctor Name:</p>
                                                <p className="status-id-card-text">Call Date:</p>
                                                <p className="status-id-card-text">Call Type:</p>
                                                {/* <p className="status-id-card-text">L.f. Number:</p>
                                            <p className="status-id-card-text">NMSRA Number:</p> */}
                                            </Grid>
                                            <Grid item xs={7}>
                                                {/* <p className="status-id-card-text">Executive Member</p> */}
                                                {/* <p className="status-id-card-text">{data?.card_no ? "Approved" : "Pending"}</p> */}
                                                <p className="status-id-card-text">{data?.mr?.user?.first_name + (data?.mr?.user?.middle_name ? " " + data?.mr?.user?.middle_name : "") + " " + data?.mr?.user?.last_name}</p>
                                                <p className="status-id-card-text new-text-capitized">{data?.company}/{data?.division}</p>
                                                <p className="status-id-card-text">{data?.doctor_name}</p>
                                                <p className="status-id-card-text">{moment(data?.call_date).format('D MMM YYYY')}</p>
                                                <p className="status-id-card-text">{data?.call_type}</p>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <Box style={{ marginTop: "40px", position: 'relative' }}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={3}>
                                                <Box style={{ textAlign: 'center' }}>
                                                    <img src={Stamp} alt="stamp" style={{ width: "15%", position: 'absolute', left: '1rem', top: "-20px" }} />
                                                    <p style={{ borderTop: "3px solid black", marginBottom: "-2px" }}></p>
                                                    <p className="status-id-card-text" style={{ textAlign: 'center' }}>For NMSRA</p>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={6}>

                                            </Grid>
                                            <Grid item xs={3}>
                                                <Box style={{ textAlign: 'center' }}>
                                                    <p style={{ borderTop: "3px solid black", marginBottom: "-2px" }}></p>
                                                    <p className="status-id-card-text" style={{ textAlign: 'center' }}>MR Signature</p>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </Box>
    )
}

export default CallCardDownload;