import {
    createSelector,
    createEntityAdapter
} from "@reduxjs/toolkit";

import { apiSlice } from "./apiSlice";

const partnersAdapter = createEntityAdapter()

const initialState = partnersAdapter.getInitialState()

export const partnerSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getPartners: builder.query({
            query: () => '/basic/partners/',
            transformResponse: responseData => {
                return partnersAdapter.setAll(initialState, responseData)
            },
            providesTags: (result, error, arg) => 
            [
                { type: 'Partners', id: "LIST" },
                ...result.ids.map(id => ({ type: 'Partners', id }))
            ]
        }),
        getActivePartners: builder.query({
            query: () => '/basic/partners/?is_active=True',
            transformResponse: responseData => {
                return partnersAdapter.setAll(initialState, responseData)
            },
            providesTags: (result, error, arg) => 
            [
                { type: 'Partners', id: "LIST" },
                ...result.ids.map(id => ({ type: 'Partners', id }))
            ]
        }),
        getPartnersById: builder.query({
            query: (id)=>({

                url:`/basic/partners/${id}/`,
                method: 'GET',
            }),
            providesTags: ['Partners']
        }),
        addPartner: builder.mutation({
            query: (partner) => {
            return{
                url: '/basic/partners/',
                method: 'POST',
                body: partner,
            }
        },
            invalidatesTags: ['Partners']
        }),
        updatePartner: builder.mutation({
           
            query: (updatePartner) => {
                return{
                url: `/basic/partners/${updatePartner.get('id')}/`,
                method: 'PATCH',
                body: updatePartner
                }
            },
            invalidatesTags: ['Partners']
        }),
        deletePartner: builder.mutation({
            query: ({ id }) => {
                return{
                url: `/basic/partners/${id}/`,
                method: 'DELETE',
                body: id
            }},
            invalidatesTags: ['Partners']
        }),
    })
})

export const {
    useGetPartnersQuery,
    useGetActivePartnersQuery,
    useAddPartnerMutation,
    useUpdatePartnerMutation,
    useDeletePartnerMutation,
    useGetPartnersByIdQuery
} = partnerSlice

// returns the query result object
export const selectPartnersResult = partnerSlice.endpoints.getPartners.select()

// Creates memoized selector
const selectPartnerssData = createSelector(
    selectPartnersResult,
    partnersResult => partnersResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllPartners,
    selectById: selectPartnerById,
    selectIds: selectPartnerIds
    // Pass in a selector that returns the posts slice of state
} = partnersAdapter.getSelectors(state => selectPartnerssData(state) ?? initialState)
