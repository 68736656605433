import { Grid } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import CardDesignOne from "../component/cardDesign";
import Header from "../component/header";
import Sidebar from "../component/sidebar";
import Image6 from "../../../../static/image/testimonial.png";

const HospitalInformation = () => {
  return (
    <Box className="dashboard-full-background">
      <Grid container spacing={0}>
        <Grid item xs={2}>
          <Sidebar />
        </Grid>
        <Grid item xs={10}>
          <Header />
          <HospitalInformationPage />
        </Grid>
      </Grid>
    </Box>
  );
};

const HospitalInformationPage = () => {
  return (
    <Box style={{ padding: "2rem" }}>
      <Grid container spacing={3}>
        <Grid item xs={3}>
          <CardDesignOne
            cardImage={Image6}
            cardName="Hospital List"
            cardLink="dashboard/hospitals"
          />
        </Grid>
        <Grid item xs={3}>
          <CardDesignOne
            cardImage={Image6}
            cardName="Hospital Holidays"
            cardLink="dashboard/hospital-holidays"
          />
        </Grid>
        <Grid item xs={3}>
          <CardDesignOne
            cardImage={Image6}
            cardName="Book my Hospital"
            cardLink="dashboard/book-my-hospitals"
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default HospitalInformation;
