import React from "react";
import TextButton from "../../../../../components/button/buttonicon";

const SimCardButton = ({name,onClick}) =>{

    return(
        <>
            <TextButton buttonText={name} onClick={onClick}/>

        </>
    )
}

export default SimCardButton;