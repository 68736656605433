import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Sidebar from "../../component/sidebar";
import { Box, Grid, Typography } from "@mui/material";
import Header from "../../component/header";
import BasicTable from "../tables/BasicTable";
import {
  useGetWorksQuery,
  useUpdateWorkMutation,
  useDeleteWorkMutation,
  useAddWorkMutation,
} from "../../../../../api/workSlice";

import WorkAddButton from "./workAddButton";

const DashboardWorks = () => {
  return (
    <Grid container className="dashboard-full-background">
      <Grid item xs={2} spacing={4}>
        <Sidebar />
      </Grid>
      <Grid item xs={10}>
        <Header />
        <DashboardWorksPages />
      </Grid>
    </Grid>
  );
};

const DashboardWorksPages = () => {
  const { data, isLoading, isSuccess, isError, error } =
    useGetWorksQuery("getWorks");

  const [deleteWork] = useDeleteWorkMutation();

  const deleteConfirm = (e, id) => {
    e.preventDefault();
    deleteWork({ id });
  };

  let headers = [];
  let tableData = [];
  let content;
  if (isLoading) {
    content = <p>"Loading..."</p>;
  } else if (isSuccess) {
    headers = [
      "Work Name",
      "Active"
    ];
    tableData = [];
    data.ids.forEach((key) => {
      tableData.push({
        "Work Name": data.entities[key].work_name,
        "Active": data.entities[key].is_active,
        id: data.entities[key].id,
      });
    });
  }
  return (
    <>
      <Box className="table-design-background">
        <Grid container>
          <Grid item xs={6}>
            <Typography className="dashboard-home-page-text">
              Objective Lists
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Link to="/dashboard/new/works">
              <WorkAddButton />
            </Link>
          </Grid>
        </Grid>
        <Box style={{ padding: "1rem" }}>
          <BasicTable
            headers={headers}
            data={tableData}
            isDelete={true}
            deleteColor={"#f50057"}
            isEdit={true}
            editColor={"#3f51b5"}
            addColor={""}
            tableName={"works"}
            deleteConfirm={deleteConfirm}
            activeSign={['Active']}
          />
        </Box>
      </Box>
    </>
  );
};
export default DashboardWorks;
