import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Grid, Typography } from "@mui/material";
import Sidebar from "../../component/sidebar";
import Header from "../../component/header";
import Controls from "../forms/controls/Controls";
import { useForm, Form } from "../forms/useForm";
import { useGetAboutPagesByIdQuery } from "../../../../../api/aboutPageSlice";
import BlobToFile from "../forms/utils/blobToFile";
import { useUpdateAboutPageMutation } from "../../../../../api/aboutPageSlice";
import urlToFile from "../forms/utils/urlToFile";
import { Box } from "@mui/system";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCog,
  faBell,
  faSignInAlt,
  faExclamationTriangle,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

const AboutPageEditPage = () => {
  return (
    <Grid container className="dashboard-full-background">
      <Grid item xs={2} spacing={4}>
        <Sidebar />
      </Grid>
      <Grid item xs={10}>
        <Header />
        <EditAboutPagePages />
      </Grid>
    </Grid>
  );
};

const EditAboutPagePages = () => {
  const { id } = useParams();
  const AboutPages = useGetAboutPagesByIdQuery(id);
  const [File, setFile] = useState([]);
  const [SuccessMessege, setSuccesMessege] = useState(null);
  const [ErrorMessege, setErrorMessege] = useState(null);
  const [updateAboutPage, setUpdateAboutPage] = useState([]);
  const [initialFValues, setInitialFValues] = useState({
    image: [],
    title: "",
    subtitle: "",
    description: "",
    is_active: "false",
  });
  const history = useNavigate();

  useEffect(() => {
    
    if (AboutPages.data) {
      if (AboutPages.data.image) {
        urlToFile(AboutPages.data.image, setFile);
      }
      setInitialFValues({
        image: File,
        title: AboutPages.data.title,
        subtitle: AboutPages.data.subtitle,
        description: AboutPages.data.description,
        is_active: AboutPages.data.is_active.toString(),
      });
    }
  }, [AboutPages.data]);

  const { values, handleImageUpload, handleInputChange } = useForm(
    initialFValues,
    true,
    false,
    true
  );

  const [editAboutPage] = useUpdateAboutPageMutation();

  useEffect(() => {
    handleImageUpload("image", File);
  }, [File]);


  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    var file = BlobToFile(values.image, "aboutPage");
    var isTrueSet = values.is_active === "true";
    if (file.length !== 0) {
      formData.append("image", file, "aboutPage.jpg");
    }
    if(values.title)
      formData.append("title", values.title);
    if(values.subtitle)
      formData.append("subtitle", values.subtitle);
    if(values.description)
      formData.append("description", values.description);
    formData.append("is_active", isTrueSet);
    formData.append("id", id);
    editAboutPage(formData)
      .then((res) => {
        setSuccesMessege("Successfully Updated About Page Data.");
        setTimeout(() => {
          history("/dashboard/about-pages");
        }, [3000]);
      })
      .catch((err) => {
        setErrorMessege("Some Error Occur. Please Try Again Later.");
        setTimeout(() => {
          setErrorMessege(null);
        }, [3000]);
      });
  };

  return (
    <Box className="table-design-background">
      <Typography className="dashboard-home-page-text">
        Update About Page
      </Typography>
      <Box style={{ padding: "1.5rem" }}>
        <Form onSubmit={handleSubmit}>
          <Grid container>
            <Grid item xs={6}>
              <Controls.Input
                name="title"
                label="Title"
                value={values.title}
                onChange={handleInputChange}
              />
              <Controls.Input
                name="subtitle"
                label="Sub-Title"
                value={values.subtitle}
                onChange={handleInputChange}
              />
              <Controls.Input
                name="description"
                label="Description"
                value={values.description}
                onChange={handleInputChange}
              />

              <Controls.RadioGroup
                name="is_active"
                label="Is Active"
                value={values.is_active}
                onChange={handleInputChange}
                items={[
                  { id: "false", title: "False" },
                  { id: "true", title: "True" },
                ]}
              />
            </Grid>
            <Grid item xs={6}>
              <Box style={{ marginBottom: "12px" }}>
                <Controls.Image setFile={setFile} aspectRatio={636 / 566} />
              </Box>
            </Grid>
            <Grid>
              <Controls.Button
                type="submit"
                text="Submit"
                backgroundColor="#1b284b"
                onClick={handleSubmit}
              />
            </Grid>
          </Grid>
        </Form>
        {SuccessMessege === null ? null : (
          <Box className="successmessege">
            <Typography className="messegefonts">
              <FontAwesomeIcon icon={faCheckCircle} className="messegeicon" />
              {SuccessMessege}
            </Typography>
          </Box>
        )}
        {ErrorMessege === null ? null : (
          <Box className="errormessege">
            <Typography className="messegefonts">
              <FontAwesomeIcon
                icon={faExclamationTriangle}
                className="messegeicon"
              />
              {ErrorMessege}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default AboutPageEditPage;
