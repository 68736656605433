import {
  createSelector,
  createEntityAdapter
} from "@reduxjs/toolkit";

import { apiSlice } from "./apiSlice";

const imageGallerysAdapter = createEntityAdapter()

const initialState = imageGallerysAdapter.getInitialState()

export const imageGallerySlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
      getGallery: builder.query({
          query: () => '/gallery/image/',
          transformResponse: responseData => {
              return imageGallerysAdapter.setAll(initialState, responseData)
          },
          providesTags: (result, error, arg) => 
          [
              { type: 'ImageGallerys', id: "LIST" },
              ...result.ids.map(id => ({ type: 'ImageGallerys', id }))
          ]
      }),
      getActiveImageGallerys: builder.query({
          query: () => '/gallery/image/?is_active=True',
          transformResponse: responseData => {
              return imageGallerysAdapter.setAll(initialState, responseData)
          },
          providesTags: (result, error, arg) => 
          [
              { type: 'ImageGallerys', id: "LIST" },
              ...result.ids.map(id => ({ type: 'ImageGallerys', id }))
          ]
      }),
      getImageGallerysPagination: builder.query({
          query: (id)=>({
              url:`/gallery/image-pagination/?page=${id}`,
              method: 'GET',
          }),
          providesTags: ['ImageGallerys']
      }),
      getImageGallerysById: builder.query({
          query: (id)=>({
              url:`/gallery/image/${id}/`,
              method: 'GET',
          }),
          providesTags: ['ImageGallerys']
      }),
      addImageGallery: builder.mutation({
          query: (imageGallery) => {
          return{
              url: '/gallery/image/',
              method: 'POST',
              body: imageGallery
          }
      },
          invalidatesTags: ['ImageGallerys']
      }),
      updateImageGallery: builder.mutation({
         
          query: (updateImageGallery) => {
              return{
              url: `/gallery/image/${updateImageGallery.get('id')}/`,
              method: 'PATCH',
              body: updateImageGallery
              }
          },
          invalidatesTags: ['ImageGallerys']
      }),
      deleteImageGallery: builder.mutation({
          query: ({ id }) => {
              return{
              url: `/gallery/image/${id}/`,
              method: 'DELETE',
              body: id
          }},
          invalidatesTags: ['ImageGallerys']
      }),
  })
})

export const {
  useGetGalleryQuery,
  useAddImageGalleryMutation,
  useUpdateImageGalleryMutation,
  useDeleteImageGalleryMutation,
  useGetImageGallerysByIdQuery,
  useGetActiveImageGallerysQuery,
  useGetImageGallerysPaginationQuery,
} = imageGallerySlice

// returns the query result object
export const selectImageGallerysResult = imageGallerySlice.endpoints.getGallery.select()

// Creates memoized selector
const selectImageGalleryssData = createSelector(
  selectImageGallerysResult,
  imageGallerysResult => imageGallerysResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
  selectAll: selectAllImageGallerys,
  selectById: selectImageGalleryById,
  selectIds: selectIds
  // Pass in a selector that returns the posts slice of state
} = imageGallerysAdapter.getSelectors(state => selectImageGalleryssData(state) ?? initialState)
