import React, { useEffect, useState } from "react";
import Sidebar from "../../component/sidebar";
import Header from "../../component/header";
import { Box, Grid, Typography } from "@mui/material";
import Controls from "../forms/controls/Controls";
import { useForm, Form } from "../forms/useForm";
import { useAddDoctorCallCardMutation } from "../../../../../api/doctorCallCardSlice";
import BlobToFile from "../forms/utils/blobToFile";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExclamationTriangle,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

const AddDoctorCallCard = () => {
  return (
    // <Grid container className="dashboard-full-background">
    //   <Grid item xs={2} spacing={4}>
    //     <Sidebar />
    //   </Grid>
    //   <Grid item xs={10}>
    //     <Header />
    //     <AddDoctorCallCardPages />
    //   </Grid>
    // </Grid>
    <Box className="dashboard-full-background">
    <Grid container spacing={0}>
      <Grid item md={2} xs={12}>
        <Sidebar />
      </Grid>
      <Grid item md={10} xs={12} className="res-margin-design1">
        <Header />
        <AddDoctorCallCardPages />
      </Grid>
    </Grid>
  </Box>
  );
};

const AddDoctorCallCardPages = () => {
  const initialFValues = {
    company: "",
    division: "",
    doctor_name: "",
    call_date: new Date(),
    call_type: ""
  };
  const { values, handleInputChange } = useForm(
    initialFValues,
    true,
    false,
    false
  );
  const [SuccessMessege, setSuccesMessege] = useState(null);
  const [ErrorMessege, setErrorMessege] = useState(null);
  const history = useNavigate();

  const [addDoctorCard] = useAddDoctorCallCardMutation();

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("company", values.company);
    formData.append("division", values.division);
    formData.append("doctor_name", values.doctor_name);
    formData.append('call_date', values.call_date);
    formData.append('call_type', values.call_type);
    formData.append('email', Cookies.get('user_email'));
    addDoctorCard(formData)
      .then((res) => {
        setSuccesMessege("Successfully Added Doctors Call Card.");
        setTimeout(() => {
          history("/dashboard/doctors-call-card");
        }, [3000]);
      })
      .catch((err) => {
        setErrorMessege("Some Error Occur. Please Try Again Later.");
        setTimeout(() => {
          setErrorMessege(null);
        }, [3000]);
      });
  };

  return (
    <Box className="table-design-background">
      <Typography className="dashboard-home-page-text">
        Add Doctors Call Card
      </Typography>
      <Box style={{ padding: "1.5rem" }}>
        <Form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <Controls.Input
                name="company"
                label="Company"
                value={values.company}
                onChange={handleInputChange}
              />
              <Controls.Input
                name="doctor_name"
                label="Doctor Name"
                value={values.doctor_name}
                onChange={handleInputChange}
              />
              <Box style={{ marginTop: '15px', marginLeft: '10px' }}>
                <Controls.Select
                  name="call_type"
                  label="Call Type"
                  options={
                    [
                      { id: "Single", title: 'Single Call' },
                      { id: "Joint", title: "Joint Call" }
                    ]
                  }
                  value={values.call_type}
                  onChange={handleInputChange}
                />
              </Box>
            </Grid>
            <Grid item md={6} xs={12}>
              <Box style={{ marginRight: '9px' }}>
                <Controls.Input
                  name="division"
                  label="Division"
                  value={values.speciality}
                  onChange={handleInputChange}
                />
              </Box>
              <Box style={{ marginLeft: '9px' }}>
                <Controls.DatePickers
                  name="call_date"
                  label="Call Date"
                  value={values.call_date}
                  onChange={handleInputChange}
                />
              </Box>
            </Grid>
            <Grid item md={1.2} xs={6} style={{marginLeft:'5px'}}>
              <Controls.Button
                type="submit"
                text="Submit"
                backgroundColor="#1b284b"
                onClick={handleSubmit}
              />
            </Grid>
          </Grid>
        </Form>
        {SuccessMessege === null ? null : (
          <Box className="successmessege">
            <Typography className="messegefonts">
              <FontAwesomeIcon icon={faCheckCircle} className="messegeicon" />
              {SuccessMessege}
            </Typography>
          </Box>
        )}
        {ErrorMessege === null ? null : (
          <Box className="errormessege">
            <Typography className="messegefonts">
              <FontAwesomeIcon
                icon={faExclamationTriangle}
                className="messegeicon"
              />
              {ErrorMessege}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};
export default AddDoctorCallCard;
