import { createSelector, createEntityAdapter } from "@reduxjs/toolkit";

import { apiSlice } from "./apiSlice";

const activitiesAdapter = createEntityAdapter();

const initialState = activitiesAdapter.getInitialState();

const MrRegistrationSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getMrRegistrationPayment: builder.query({
      query: (id) => ({
        url: `/bookings/mr-registration-payment/?mr_id=${id}`,
        method: "GET",
      }),
      providesTags: ["Mrid"],
    }),
    postPayment: builder.mutation({
      query: (PATCH) => {
        return {
          url: `/bookings/mr-registration-payment/`,
          method: "POST",
          body: PATCH,
        };
      },
      invalidatesTags: ["Payment"],
    }),
    patchPayment: builder.mutation({
      query: (PATCH) => {
        return {
          url: `/bookings/mr-registration-payment/${PATCH.get("id")}/`,
          method: "PATCH",
          body: PATCH,
        };
      },
      invalidatesTags: ["Payment"],
    }),
    getPaymentById:builder.query({
      query:(id)=>{
        return{
        url:`/bookings/mr-registration-payment/${id}/`,
        method: "GET",
        }
      }
    }),
  }),
});
export const { useGetMrRegistrationPaymentQuery, usePostPaymentMutation,useGetPaymentByIdQuery,usePatchPaymentMutation } =
  MrRegistrationSlice;
