import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Grid, Typography } from "@mui/material";
import Sidebar from "../../component/sidebar";
import Header from "../../component/header";
import Controls from "../forms/controls/Controls";
import { useForm, Form } from "../forms/useForm";

import {
  useGetExecutiveBodiessByIdQuery,
  useGetExecutiveBodiessQuery,
  useAddExecutiveBodiesMutation,
  useDeleteExecutiveBodiesMutation,
  useUpdateExecutiveBodiesMutation
} from "../../../../../api/executiveBodiesSlice";
import BlobToFile from "../forms/utils/blobToFile";
import urlToFile from "../forms/utils/urlToFile";
import { Box } from "@mui/system";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCog,
  faBell,
  faSignInAlt,
  faExclamationTriangle,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

const ExecutiveBodiesEditPage = () => {
  return (
    <Grid container className="dashboard-full-background">
      <Grid item xs={2} spacing={4}>
        <Sidebar />
      </Grid>
      <Grid item xs={10}>
        <Header />
        <EditExecutiveBodiesPagePages />
      </Grid>
    </Grid>
  );
};

const EditExecutiveBodiesPagePages = () => {
  const { id } = useParams();
  const executiveBodies = useGetExecutiveBodiessByIdQuery(id);
  const [File1, setFile1] = useState([]);
  const [updateExecutiveBodiesPage, setExecutiveBodiesPage] = useState([]);
  const [initialFValues, setInitialFValues] = useState({
    image: [],
    name: "",
    role: "",
    order: 0,
  });
  const [SuccessMessege, setSuccesMessege] = useState(null);
  const [ErrorMessege, setErrorMessege] = useState(null);

  useEffect(() => {

    if (executiveBodies.data) {
      if (executiveBodies.data.image) {
        urlToFile(executiveBodies.data.image, setFile1);
      }

      setInitialFValues({
        image: File1,
        name: executiveBodies.data.name,
        role: executiveBodies.data.role,
        order: executiveBodies.data.order
      });
    }
  }, [executiveBodies.data]);

  const { values, handleImageUpload, handleInputChange } = useForm(
    initialFValues,
    true,
    false,
    true
  );

  const [editExecutiveBodiess] = useUpdateExecutiveBodiesMutation();

  useEffect(() => {
    handleImageUpload("image", File1);
  }, [File1]);


  const history = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    var file1 = BlobToFile(values.image, "ExecutiveBodies");
    if (file1.length !== 0) {
      formData.append("image", file1, "centralPresident.jpg");
    }
    if (values.name)
      formData.append("name", values.name);
    if (values.role)
      formData.append("role", values.role);
    if(values.order)
      formData.append("order", values.order);
    formData.append("id", id);
    editExecutiveBodiess(formData)
      .then((res) => {
        
        setSuccesMessege("Successfully Updated Executive Bodies Data.");
        setTimeout(() => {
          history("/dashboard/executive-bodies");
        }, [3000]);
      })
      .catch((err) => {
        setErrorMessege("Some Error Occur. Please Try Again Later.");
        setTimeout(() => {
          setErrorMessege(null);
        }, [3000]);
      });
  };

  return (
    <Box className="table-design-background">
      <Typography className="dashboard-home-page-text">
        Update Executive Bodies Details
      </Typography>
      <Box style={{ padding: "1.5rem" }}>
        <Form onSubmit={handleSubmit}>
          <Grid container spacing={8}>
            <Grid item xs={6} style={{ textAlign: "start" }}>
              <Controls.Input
                name="name"
                label="Name"
                value={values.name}
                onChange={handleInputChange}
              />
              <Controls.Input
                name="role"
                label="Role"
                value={values.role}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={6}>
              <Controls.Input
                name="order"
                label="Order"
                value={values.order}
                onChange={handleInputChange}
              />
              <Controls.Image setFile={setFile1} aspectRatio={1045 / 899} />
            </Grid>
            <Grid item style={{ textAlign: "start", marginTop: "-3.2rem" }}>
              <Controls.Button
                type="submit"
                text="Submit"
                backgroundColor="#1b284b"
                onClick={handleSubmit}
              />
            </Grid>
          </Grid>
        </Form>
        {SuccessMessege === null ? null : (
          <Box className="successmessege">
            <Typography className="messegefonts">
              <FontAwesomeIcon icon={faCheckCircle} className="messegeicon" />
              {SuccessMessege}
            </Typography>
          </Box>
        )}
        {ErrorMessege === null ? null : (
          <Box className="errormessege">
            <Typography className="messegefonts">
              <FontAwesomeIcon
                icon={faExclamationTriangle}
                className="messegeicon"
              />
              {ErrorMessege}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default ExecutiveBodiesEditPage;
