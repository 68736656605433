import react from "react";
import { Box, Link } from "@mui/material";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

const HomePageSkeleton = () => {
  return (
    <div className="row align-items-center">
      <div className="col-lg-4 col-sm-6">
        <div className="about-img">
          <Box>
            <SkeletonTheme color="grey" highlightColor="#444">
              <p>
                <Skeleton height={300} width={"100%"} count={1} />
              </p>
            </SkeletonTheme>
          </Box>
          <Box>
            <SkeletonTheme color="grey" highlightColor="#444">
              <p>
                <Skeleton height={300} width={"100%"} count={1} />
              </p>
            </SkeletonTheme>
          </Box>
        </div>
      </div>
      <div className="col-lg-4 col-sm-6">
        <div className="about-img mt-4 mt-lg-0">
          <Box>
            <SkeletonTheme color="grey" highlightColor="#444">
              <p>
                <Skeleton height={320} width={"100%"} count={1} />
              </p>
            </SkeletonTheme>
          </Box>
        </div>
      </div>
      <div className="col-lg-4">
        <div className="about-content pl-4 mt-4 mt-lg-0">
          <h2 className="title-color">
            <Skeleton width={"100%"} count={1} />
          </h2>
          <p className="mt-4 mb-5">
            <Skeleton width={"100%"} count={1} />
          </p>

          <Link to="/signup" className="btn btn-main-2 btn-round-full btn-icon">
            <Skeleton width={"100%"} count={1} />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default HomePageSkeleton;
