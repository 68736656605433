import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { useGetNewsQuery } from "../../../api/newSlice";
import Footer from "../component/footer";
import Header from "../component/header";

const News = () => {
  return (
    <>
      <Header />
      <NewsPage />
      <Footer />
    </>
  );
};

const NewsPage = () => {
  const data = useGetNewsQuery();
  

  return (
    <div>
      <section class="page-title bg-1">
        <div class="overlay"></div>
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="block text-center">
                <span class="text-white">News</span>
                <h1 class="text-capitalize mb-5 text-lg">News articles</h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="fetaure-page mt-5">
        <div className="container">
          <div>
            <div className="section-title ">
              <h2 className="text-md mb-2">Know What's Happening in NMSRA</h2>
              <div className="divider mx-auto my-4"></div>
              <div className="mt-5">
                <div className="row">
                  {data !== undefined ? (
                    <>
                      {data.status === "fulfilled" ? (
                        <>
                          {data.data.ids.length !== 0 ? (
                            <>
                              {data.data.ids.map((key) => (
                                <div className="col-lg-6 mb-5">
                                  <a
                                    href={data.data.entities[key].pdf_file}
                                    target="_blank"
                                  >
                                    <div className="bg-white shadow rounded">
                                      <div className="row">
                                        <div className="col-2">
                                          <div className="side-design-news">
                                            <p
                                              style={{
                                                fontSize: "35px",
                                                fontWeight: "bold",
                                                marginBottom: "-5px",
                                                color: "white",
                                              }}
                                            >
                                              {data.data.entities[key].created_at[2]}
                                              <br />{" "}
                                              <span
                                                style={{
                                                  fontSize: "17px",
                                                  fontWeight: "normal",
                                                }}
                                              >
                                                {data.data.entities[key].created_at[1]}
                                              </span>{" "}
                                            </p>
                                          </div>
                                        </div>
                                        <div className="col-8">
                                          <p className="new-fonts-news">
                                            {data.data.entities[key].title}
                                          </p>
                                        </div>
                                        <div className="col-2 ">
                                          <div
                                            style={{
                                              marginTop: "23px",
                                              textAlign: "start",
                                            }}
                                          >
                                            <i className="icofont-file-pdf icon-design-news"></i>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </a>
                                </div>
                              ))}
                            </>
                          ) : (
                            <>
                              <div className="col-lg-6 mb-5">
                                <SkeletonTheme
                                  color="grey"
                                  highlightColor="#444"
                                >
                                  <p>
                                    <Skeleton
                                      height={100}
                                      width={"100%"}
                                      count={1}
                                    />
                                  </p>
                                </SkeletonTheme>
                              </div>
                              <div className="col-lg-6 mb-5">
                                <SkeletonTheme
                                  color="grey"
                                  highlightColor="#444"
                                >
                                  <p>
                                    <Skeleton
                                      height={100}
                                      width={"100%"}
                                      count={1}
                                    />
                                  </p>
                                </SkeletonTheme>
                              </div>
                            </>
                          )}
                        </>
                      ) : null}
                    </>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default News;
