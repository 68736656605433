import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Sidebar from "../../component/sidebar";
import {
  Grid,
  Pagination,
  Stack,
  Typography,
} from "@mui/material";
import Header from "../../component/header";
import BasicTable from "../tables/BasicTable";
import { Box } from "@mui/system";
import usePagination from "../../../../../components/reuseable/pagination";
import {
  useGetDoctorCallCardsQuery,
  useGetDoctorCallCardsByIdQuery,
  useAddDoctorCallCardMutation,
  useUpdateDoctorCallCardMutation,
  useDeleteDoctorCallCardMutation,
  useGetDoctorCallCardsMemberPaginationQuery,
  useGetDoctorCallCardsPaginationQuery,
  useGetDoctorCallCardsDateFilterMutation
} from "../../../../../api/doctorCallCardSlice";
import { useGetMrIDByEmailMutation } from "../../../../../api/userSlice";
import Controls from "../forms/controls/Controls";
import { useSearchFliterPageMutation } from "../../../../../api/searchApiSlice";
import { useForm1 } from "../../../../../components/forms/useForm";
import { Table } from "react-bootstrap";
import ModeEditSharpIcon from "@mui/icons-material/ModeEditSharp";
import DoctorCallCardButton from "./doctorCallCardAddButton";
import DeleteSharpIcon from "@mui/icons-material/DeleteSharp";
import Cookies from "js-cookie";

const DoctorCallCardDashboardAdminPage = () => {

  return (
    <Grid container className="dashboard-full-background">
      <Grid item xs={2} spacing={4}>
        <Sidebar />
      </Grid>
      <Grid item xs={10}>
        <Header />
        <DashboardDoctorCallCardAdminPagesPages />
      </Grid>
    </Grid>
  );
};

const DashboardDoctorCallCardAdminPagesPages = () => {
  const pageNo = useLocation();
  const paramPairs = decodeURIComponent(pageNo.search.substr(1));
  const paramPairs1 = paramPairs.split('=');
  const pageNumber = paramPairs1[1];
  const [thisMr, setThisMr] = useState(null);
  const [filterDate, setFilterDate] = useState(new Date())
  const [pageId, setPageId] = useState(1);

  const { data, isLoading, isSuccess } = useGetDoctorCallCardsMemberPaginationQuery({ 'page': pageId, 'mr': '' });

  // const [data, setData] = useState([]);


  const [getMRID] = useGetMrIDByEmailMutation();

  useEffect(() => {
    getMRID(Cookies.get('user_email')).
      then((res) => {
        setThisMr(res.data.id);
      })

  }, [Cookies.get('user_email')])
  useEffect(() => {
    if (pageNumber !== undefined) {
      setPageId(pageNumber)
    } else {
      setPageId(1)
    }
  }, [pageNumber])
  const history = useNavigate();


  // const [filterCallCard] = useGetDoctorCallCardsDateFilterMutation();

  // useEffect(() => {
  //   filterCallCard(filterDate)
  //     .then((res) => {
  //       setData(res.data)
  //     })
  // }, [filterDate])


  const [deleteDoctorCallCard] = useDeleteDoctorCallCardMutation();

  const [updateDoctorCallCard] = useUpdateDoctorCallCardMutation();

  const deleteConfirm = (e, id) => {

    e.preventDefault();
    deleteDoctorCallCard({ id });
  };



  let headers = [];
  let tableData = [];
  let content;

  var count;
  // const isSuccess = true;
  if (isLoading) {
    content = <p>"Loading..."</p>;
  }
  else if (isSuccess) {
    headers = [
      "Card No.",
      "MR Name",
      "Company",
      "Division",
      "Doctor Name",
      "Call Date",
      "Call Type",
    ];
    data?.results?.forEach((key, index) => {
      tableData.push({
        "Card No.": key?.card_no ? key?.card_no : "Pending",
        "MR Name": key?.mr?.user?.first_name + (key?.mr?.user?.middle_name ? " " + key?.mr?.user?.middle_name : "") + " " + key?.mr?.user?.last_name,
        id: key?.id,
        "Company": key?.company,
        "Division": key?.division,
        "Doctor Name": key?.doctor_name,
        "Call Date": key?.call_date,
        "Call Type": key?.call_type,
        "Confirmation": key?.is_approved
      });
    });
    var newData = data.count / 10;
    count = Math.ceil(newData);
  }

  const [page, setPage] = useState(1);
  const PER_PAGE = 10;

  const _DATA = usePagination(tableData, count, PER_PAGE);


  const handleChange = (e, p) => {
    _DATA.jump(p);
    setPage(p)
    // const queryParams = new URLSearchParams(p).toString();
    history(`/dashboard/doctors-call-card/?pageNumber=${p}`)
  };

  const handleDateChange = (e) => {
    console.log(e)
    setFilterDate(e.target.value);
  }

  const confirmationTable = (e, id) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('is_admin', true);
    formData.append([e.target.name], e.target.value);
    formData.append('id', id)
    updateDoctorCallCard(formData)

  }



  //   const [NMSRANO, results] = useSearchFliterPageMutation();

  //   const initialFValues = {
  //     search: "",
  //   };

  //   const { values, handleSearchClick } = useForm1(initialFValues);

  //   useEffect(() => {
  //     NMSRANO(values);
  //   }, [values]);

  return (
    <>
      <Box className="table-design-background">
        <Grid container>
          <Grid item xs={7}>
            <Typography className="dashboard-home-page-text">
              Doctors Call Card
            </Typography>
          </Grid>
          <Grid item xs={3}>
          </Grid>
          <Grid item xs={2}>
            <Box style={{ marginRight: '20px', marginTop: "5px" }}>
              {/* <Controls.DatePickers
                value={filterDate}
                onChange={handleDateChange}
              /> */}
            </Box>
          </Grid>
        </Grid>
        <Box style={{ padding: "1.5rem" }}>
          <>
            <BasicTable
              headers={headers}
              data={tableData}
              deleteColor={"#f50057"}
              isEdit={true}
              editColor={"#3f51b5"}
              isDelete={true}
              addColor={""}
              tableName={"doctors-call-card"}
              deleteConfirm={deleteConfirm}
              activeSign={["Active"]}
              doctorcardAvailable={true}
              doctor={true}
              selectOption={[
                {
                  header: "Confirmation",
                  options: [
                    { id: true, title: "Confirm" },
                    { id: false, title: "Cancel" },
                  ],
                  onChange: confirmationTable,
                  name: "is_approved",
                },
              ]}
            />
            <Box
              style={{
                marginTop: "20px",
                textAlign: "center",
                paddingBottom: "1rem",
                paddingLeft: "1rem",
              }}
            >
              <Stack spacing={2} style={{ textAlign: "center" }}>
                <Pagination
                  count={count}
                  size="small"
                  page={parseInt(pageNumber)}
                  variant="outlined"
                  onChange={handleChange}
                  color="primary"
                />
              </Stack>
            </Box>
          </>
        </Box>
      </Box>
    </>
  );
};
export default DoctorCallCardDashboardAdminPage;
