import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Sidebar from "../../component/sidebar";
import { Box, Grid, Typography } from "@mui/material";
import Header from "../../component/header";
import BasicTable from "../tables/BasicTable";
import {
  useGetPartnersQuery,
  useUpdatePartnerMutation,
  useDeletePartnerMutation,
  useAddPartnerMutation,
} from "../../../../../api/partnerSlice";
import PartnerAddButton from "./partnerAddButton";

const DashboardPartners = () => {
  return (
    <Grid container className="dashboard-full-background">
      <Grid item xs={2} spacing={4}>
        <Sidebar />
      </Grid>
      <Grid item xs={10}>
        <Header />
        <DashboardPartnersPages />
      </Grid>
    </Grid>
  );
};

const DashboardPartnersPages = () => {
  const { data, isLoading, isSuccess, isError, error } =
    useGetPartnersQuery("getPartners");

  const [deletePartner] = useDeletePartnerMutation();

  const deleteConfirm = (e, id) => {
    e.preventDefault();
    deletePartner({ id });
  };

  let headers = [];
  let tableData = [];
  let content;
  if (isLoading) {
    content = <p>"Loading..."</p>;
  } else if (isSuccess) {
    headers = [
      "Partner Name",
      "Partner Photo",
      "Active"
    ];
    tableData = [];
    data.ids.forEach((key) => {
      tableData.push({
        "Partner Name": data.entities[key].partner_name,
        "Partner Photo": data.entities[key].partner_image,
        "Active": data.entities[key].is_active,
        id: data.entities[key].id,
      });
    });
  }
  return (
    <>
      <Box className="table-design-background">
        <Grid container>
          <Grid item xs={6}>
            <Typography className="dashboard-home-page-text">
              Partner Lists
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Link to="/dashboard/new/partners">
              <PartnerAddButton />
            </Link>
          </Grid>
        </Grid>
        <Box style={{ padding: "1rem" }}>
          <BasicTable
            headers={headers}
            data={tableData}
            isDelete={true}
            deleteColor={"#f50057"}
            isEdit={true}
            editColor={"#3f51b5"}
            addColor={""}
            tableName={"partners"}
            deleteConfirm={deleteConfirm}
            activeSign={['Active']}
          />
        </Box>
      </Box>
    </>
  );
};
export default DashboardPartners;
