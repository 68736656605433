import React from "react";
import Footer from "../component/footer";
import Header from "../component/header";
import Image02 from "../../../static/image/team/3.jpg";
import Image03 from "../../../static/image/team/1.jpg";
import Image04 from "../../../static/image/team/2.jpg";
import Image05 from "../../../static/image/team/4.jpg";

const PastPresident = () => {
  return (
    <>
      <Header />
      <PastPresidentPage />
      <Footer />
    </>
  );
};

const PastPresidentPage = () => {
  return (
    <div>
      <section className="page-title bg-1">
        <div className="overlay"></div>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="block text-center">
                <span className="text-white">Past President</span>
                <h1 className="text-capitalize mb-5 text-lg">
                  Past President
                </h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section team">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6">
              <div className="section-title text-center">
                <h2 className="mb-4">Meet Our Past President</h2>
                <div className="divider mx-auto my-4"></div>
                <p>
                  Today’s users expect effortless experiences. Don’t let
                  essential people and processes stay stuck in the past. Speed
                  it up, skip the hassles
                </p>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="team-block mb-5 mb-lg-0">
                <img src={Image03} alt="" className="img-fluid w-100" />

                <div className="content">
                  <h4 className="mt-4 mb-0">
                    <a href="doctor-single.html">John Marshal</a>
                  </h4>
                  <p>Internist, Emergency Physician</p>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="team-block mb-5 mb-lg-0">
                <img src={Image04} alt="" className="img-fluid w-100" />

                <div className="content">
                  <h4 className="mt-4 mb-0">
                    <a href="doctor-single.html">Marshal Root</a>
                  </h4>
                  <p>Surgeon, Сardiologist</p>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="team-block mb-5 mb-lg-0">
                <img src={Image02} alt="" className="img-fluid w-100" />

                <div className="content">
                  <h4 className="mt-4 mb-0">
                    <a href="doctor-single.html">Siamon john</a>
                  </h4>
                  <p>Internist, General Practitioner</p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="team-block">
                <img src={Image05} alt="" className="img-fluid w-100" />

                <div className="content">
                  <h4 className="mt-4 mb-0">
                    <a href="doctor-single.html">Rishat Ahmed</a>
                  </h4>
                  <p>Orthopedic Surgeon</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default PastPresident;
