import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Sidebar from "../../component/sidebar";
import { Grid, Pagination, Stack, Typography } from "@mui/material";
import Header from "../../component/header";
import BasicTable from "../tables/BasicTable";
import {
  useGetAboutPagesQuery,
  useUpdateAboutPageMutation,
  useDeleteAboutPageMutation,
  useAddAboutPageMutation,
} from "../../../../../api/aboutPageSlice";
import { Box } from "@mui/system";
import {
  useDeleteNewsPageMutation,
  useGetNewsPaginationQuery,
  useGetNewsQuery,
} from "../../../../../api/newSlice";
import NewsAddButton from "./newsPageAddButton";
import usePagination from "../../../../../components/reuseable/pagination";

const DashboardNewsPage = () => {
  return (
    <Grid container className="dashboard-full-background">
      <Grid item xs={2} spacing={4}>
        <Sidebar />
      </Grid>
      <Grid item xs={10}>
        <Header />
        <DashboardNewsPagesPages />
      </Grid>
    </Grid>
  );
};

const DashboardNewsPagesPages = () => {
  const [pageId, setPageId] = useState(1);
  const { data, isLoading, isSuccess } = useGetNewsPaginationQuery(pageId);

  const [deleteNewsPage] = useDeleteNewsPageMutation();

  const deleteConfirm = (e, id) => {
    e.preventDefault();
    deleteNewsPage({ id });
  };
  let headers = [];
  let tableData = [];
  let content;

  var count;
  if (isLoading) {
    content = <p>"Loading..."</p>;
  } else if (isSuccess) {
    headers = ["Title", "Sub-Title", "Active", "Description"];
    data.results.forEach((key) => {
      tableData.push({
        Title: key.title,
        id: key.id,
        "Sub-Title": key.subtitle,
        Active: key.is_active,
        Description: key.description,
      });
    });
    var newData = data.count / 10;
    count = Math.ceil(newData);
  }
  

  const [page, setPage] = useState(1);
  const PER_PAGE = 10;

  const _DATA = usePagination(tableData, count, PER_PAGE);

  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
    setPageId(p);
  };

  return (
    <>
      <Box className="table-design-background">
        <Grid container>
          <Grid item xs={6}>
            <Typography className="dashboard-home-page-text">
              News Lists
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Link to="/dashboard/add/news" style={{ textDecoration: "none" }}>
              <NewsAddButton />
            </Link>
          </Grid>
        </Grid>
        <Box style={{ padding: "1.5rem" }}>
          <BasicTable
            headers={headers}
            data={tableData}
            isDelete={true}
            deleteColor={"#f50057"}
            isEdit={true}
            editColor={"#3f51b5"}
            addColor={""}
            tableName={"news"}
            deleteConfirm={deleteConfirm}
            activeSign={["Active"]}
          />
        </Box>
        <Box
          style={{
            marginTop: "20px",
            textAlign: "center",
            paddingBottom: "1rem",
            paddingLeft: "1rem",
          }}
        >
          <Stack spacing={2} style={{ textAlign: "center" }}>
            <Pagination
              count={count}
              size="small"
              page={page}
              variant="outlined"
              onChange={handleChange}
            />
          </Stack>
        </Box>
      </Box>
    </>
  );
};
export default DashboardNewsPage;
