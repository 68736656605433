import React from "react";
import Footer from "../component/footer";
import Header from "../component/header";
import Image03 from "../../../static/image/team/1.jpg";
import { useGetChairpersonsActiveQuery } from "../../../api/chairpersonSlice";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { Box } from "@mui/system";

const PresidentMessage = () => {
  return (
    <>
      <Header />
      <PresidentMessagePage />
      <Footer />
    </>
  );
};

const PresidentMessagePage = () => {
  const data = useGetChairpersonsActiveQuery('"getChairpersonsActive"');
  

  return (
    <div>
      <section className="page-title bg-1">
        <div className="overlay"></div>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="block text-center">
                <span className="text-white">Message from President</span>
                <h1 className="text-capitalize mb-5 text-lg">
                  Message from President
                </h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="fetaure-page mt-5 mb-5">
        <div className="container">
          {data !== undefined ? (
            <>
              {data.status === "fulfilled" ? (
                <>
                  {data.data.ids.length === 0 ? (
                    <div className="row">
                      <div className="col-lg-4">
                        <Box>
                          <SkeletonTheme color="grey" highlightColor="#444">
                            <p>
                              <Skeleton height={350} width={"100%"} count={1} />
                            </p>
                          </SkeletonTheme>
                        </Box>
                        <div style={{ textAlign: "start" }}>
                          <ul className="list-inline footer-socials mt-4">
                            <li className="list-inline-item">
                              <a href="">
                                <Skeleton />
                              </a>
                            </li>
                            <li className="list-inline-item">
                              <a href="">
                                <Skeleton />
                              </a>
                            </li>
                            <li className="list-inline-item">
                              <a href="">
                                <Skeleton />
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="col-lg-8">
                        <div
                          className="section-title "
                          style={{ textAlign: "start" }}
                        >
                          <h2 className="text-md mb-2">
                            <Skeleton />
                          </h2>
                          <div
                            className="divider  my-4"
                            style={{ textAlign: "start" }}
                          ></div>
                          <p className="mb-5">
                            <Skeleton height={245} />
                          </p>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <>
                      {data.data.ids.map((key) => (
                        <div className="row">
                          <div className="col-lg-4">
                            <img
                              src={data.data.entities[key].image}
                              className="w-100"
                              alt="president-image"
                            />
                            <div style={{ textAlign: "start" }}>
                              <ul className="list-inline footer-socials mt-4">
                                <li className="list-inline-item">
                                  <a href="">
                                    <i className="icofont-facebook"></i>
                                  </a>
                                </li>
                                <li className="list-inline-item">
                                  <a href="">
                                    <i className="icofont-twitter"></i>
                                  </a>
                                </li>
                                <li className="list-inline-item">
                                  <a href="">
                                    <i className="icofont-linkedin"></i>
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="col-lg-8">
                            <div
                              className="section-title "
                              style={{ textAlign: "start" }}
                            >
                              <h2 className="text-md mb-2">
                                {data.data.entities[key].name}
                              </h2>
                              <div
                                className="divider  my-4"
                                style={{ textAlign: "start" }}
                              ></div>
                              <p className="mb-5">
                                {data.data.entities[key].message}
                              </p>
                            </div>
                          </div>
                        </div>
                      ))}
                    </>
                  )}
                </>
              ) : (
                <div className="row">
                  <div className="col-lg-4">
                    <Box>
                      <SkeletonTheme color="grey" highlightColor="#444">
                        <p>
                          <Skeleton height={350} width={"100%"} count={1} />
                        </p>
                      </SkeletonTheme>
                    </Box>
                    <div style={{ textAlign: "start" }}>
                      <ul className="list-inline footer-socials mt-4">
                        <li className="list-inline-item">
                          <a href="">
                            <Skeleton />
                          </a>
                        </li>
                        <li className="list-inline-item">
                          <a href="">
                            <Skeleton />
                          </a>
                        </li>
                        <li className="list-inline-item">
                          <a href="">
                            <Skeleton />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-8">
                    <div
                      className="section-title "
                      style={{ textAlign: "start" }}
                    >
                      <h2 className="text-md mb-2">
                        <Skeleton />
                      </h2>
                      <div
                        className="divider  my-4"
                        style={{ textAlign: "start" }}
                      ></div>
                      <p className="mb-5">
                        <Skeleton height={245} />
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </>
          ) : null}
        </div>
      </section>
    </div>
  );
};

export default PresidentMessage;
