import {
    createSelector,
    createEntityAdapter
} from "@reduxjs/toolkit";

import { apiSlice } from "./apiSlice";

const usersAdapter = createEntityAdapter()

const initialState = usersAdapter.getInitialState()

export const userSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getUsers: builder.query({
            query: () => '/bookings/mr-registration/',
            transformResponse: responseData => {
                return usersAdapter.setAll(initialState, responseData)
            },
            providesTags: (result, error, arg) =>
                [
                    { type: 'Users', id: "LIST" },
                    ...result.ids.map(id => ({ type: 'Users', id }))
                ]
        }),
        getUsersById: builder.query({
            query: (id) => ({

                url: `/bookings/mr-registration/${id}/`,
                method: 'GET',
            }),
            providesTags: ['Users']
        }),
        getActiveUsersPagination: builder.query({

            query: (value) => ({

                url: `/bookings/mr-registration-pagination/?page=${value['id']}&confirmation=${value['confirmation']}`,
                method: 'GET',
            }),
            providesTags: ['Users']
        }),
        getUsersPagination: builder.query({
            query: (id) => ({

                url: `/bookings/mr-registration-pagination/?page=${id}`,
                method: 'GET',
            }),
            providesTags: ['Users']
        }),
        addUser: builder.mutation({
            query: (user) => {
                return {
                    url: '/bookings/mr-registration/',
                    method: 'POST',
                    body: user,
                }
            },
            invalidatesTags: ['Users']
        }),
        updateUser: builder.mutation({

            query: (updateUser) => {
                return {
                    url: `/bookings/mr-registration/${updateUser.get('id')}/`,
                    method: 'PATCH',
                    body: updateUser
                }
            },
            invalidatesTags: ['Users']
        }),
        deleteUser: builder.mutation({
            query: ({ id }) => {
                return {
                    url: `/bookings/mr-registration/${id}/`,
                    method: 'DELETE',
                    body: id
                }
            },
            invalidatesTags: ['Users']
        }),
        deleteUserData: builder.mutation({
            query: ( userId ) => {
                
                return {
                    url: `/user/user-list/${userId}/`,
                    method: 'DELETE',
                    body: userId
                }
            },
            invalidatesTags: ['Users']
        }),
        getMrIDByAccessToken: builder.query({
            query: (access1) => ({

                url: `/bookings/get-id/`,
                method: 'POST',
                body: { access: access1 }
            }),
            providesTags: ['Users']
        }),
        getMrIDByEmail: builder.mutation({
            query: (email) => ({

                url: `/bookings/get-id-mail/`,
                method: 'POST',
                body: { email: email }
            }),
            providesTags: ['Users']
        }),
        getNMSRANo: builder.query({
            query: () => ({

                url: `/user/get-nmsra-no`,
                method: 'GET',
            }),
        }),
        getImageBase64: builder.mutation({
            query: (data) => ({

                url: `/user/get-image-base-64/`,
                method: 'POST',
                body: data,
            }),
        }),
    })
})

export const {
    useGetUsersQuery,
    useAddUserMutation,
    useUpdateUserMutation,
    useDeleteUserMutation,
    useGetUsersByIdQuery,
    useGetUsersPaginationQuery,
    useGetActiveUsersPaginationQuery,
    useGetMrIDByAccessTokenQuery,
    useDeleteUserDataMutation,
    useGetNMSRANoQuery,
    useGetImageBase64Mutation,
    useGetMrIDByEmailMutation
} = userSlice

// returns the query result object
export const selectUsersResult = userSlice.endpoints.getUsers.select()

// Creates memoized selector
const selectUserssData = createSelector(
    selectUsersResult,
    usersResult => usersResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllUsers,
    selectById: selectUserById,
    selectIds: selectUserIds
    // Pass in a selector that returns the posts slice of state
} = usersAdapter.getSelectors(state => selectUserssData(state) ?? initialState)
