import {
    createSelector,
    createEntityAdapter
} from "@reduxjs/toolkit";

import { apiSlice } from "./apiSlice";

const memberAPI = '/basic/membership-years-members/';

const memberMembershipPeriodAdapter = createEntityAdapter();

const initialState = memberMembershipPeriodAdapter.getInitialState();

export const memberMembershipPeriodSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getMemberMembershipPeriods: builder.query({
            query: () => memberAPI,
            transformResponse: responseData => {
                return memberMembershipPeriodAdapter.setAll(initialState, responseData)
            },
            providesTags: (result, error, arg) => 
            [
                { type: 'MemberMembershipPeriods', id: "LIST" },
                ...result.ids.map(id => ({ type: 'MemberMembershipPeriods', id }))
            ]
        }),
        getMemberMembershipPeriodsById: builder.query({
            query: (id)=>({
                url:`${memberAPI}${id}/`,
                method: 'GET',
            }),
            providesTags: ['MemberMembershipPeriods']
        }),

        addMemberMembershipPeriod: builder.mutation({
            query: (memberMembershipPeriod) => {
            return{
                url: memberAPI,
                method: 'POST',
                body: memberMembershipPeriod,
            }
        },
            invalidatesTags: ['MemberMembershipPeriods']
        }),
        updateMemberMembershipPeriod: builder.mutation({
           
            query: (updateMemberMembershipPeriod) => {
                
                return{
  
                headers: {
                    'Content-Type': 'application/json; charset=UTF-8',

                },
                url: `${memberAPI}${updateMemberMembershipPeriod['data']['id']}/`,
                method: 'PATCH',
                body: updateMemberMembershipPeriod['data']
                }
            },
            invalidatesTags: ['MemberMembershipPeriods'],
            async onQueryStarted({ id, ...patch }, { dispatch, queryFulfilled }) {
                const patchResult = dispatch(
                  apiSlice.util.updateQueryData('getMemberMembershipPeriods', id, (draft) => {
                    Object.assign(draft, patch)
                  })
                )
                try {
                  await queryFulfilled
                } catch {
                  patchResult.undo()
        
                  /**
                   * Alternatively, on failure you can invalidate the corresponding cache tags
                   * to trigger a re-fetch:
                   * dispatch(api.util.invalidateTags(['Post']))
                   */
                }
              },
        }),
        deleteMemberMembershipPeriod: builder.mutation({
            query: ( id ) => {
                
                return{
                url: `${memberAPI}${id.id}/`,
                method: 'DELETE',
                body: id
            }},
            invalidatesTags: ['MemberMembershipPeriods']
        }),
    })
})

export const {
    useGetMemberMembershipPeriodsQuery,
    useAddMemberMembershipPeriodMutation,
    useUpdateMemberMembershipPeriodMutation,
    useDeleteMemberMembershipPeriodMutation,
    useGetMemberMembershipPeriodsByIdQuery,
} = memberMembershipPeriodSlice

// returns the query result object
export const selectMemberMembershipPeriodsResult = memberMembershipPeriodSlice.endpoints.getMemberMembershipPeriods.select()

// Creates memoized selector
const selectMemberMembershipPeriodssData = createSelector(
    selectMemberMembershipPeriodsResult,
    memberMembershipPeriodsResult => memberMembershipPeriodsResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllMemberMembershipPeriods,
    selectById: selectMemberMembershipPeriodById,
    selectIds: selectIds
    // Pass in a selector that returns the posts slice of state
} = memberMembershipPeriodAdapter.getSelectors(state => selectMemberMembershipPeriodssData(state) ?? initialState)



