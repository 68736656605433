import React from "react";
import FileUpload from "react-mui-fileuploader";

function File(props) {

    const {onChange, name} = props;

    const convertToDefEventPara = (name, value) => ({
      target: {
          name, value
      }
    })
  
    const handleFilesChange = (value) => {
      onChange(convertToDefEventPara(name, value[0]));
    };

    return (
        <>
          <FileUpload
            multiFile={false}
            onFilesChange={handleFilesChange}
            onContextReady={(context) => {}}
          />
        </>
      )
};

export default File;


