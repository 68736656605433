import { Button, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import Header from "../../component/header";
import Sidebar from "../../component/sidebar";
import Controls from "../../../../../components/forms/controls/Controls";
import Qr from "../../../../../static/image/qr_code.jpeg";
import axios from "axios";
import { BASE_URL } from "../../../../../constant/baseURL";
import Cookies from "js-cookie";
import { useForm } from "../forms/useForm";
import {
  useAddMemberRegistrationMutation,
  useUpdateMemberRegistrationMutation,
} from "../../../../../api/memberRegistrationSlice";
import { useGetMrIDByAccessTokenQuery } from "../../../../../api/userSlice";
import {
  useGetPaymentByIdQuery,
  usePatchPaymentMutation,
  usePostPaymentMutation,
} from "../../../../../api/mrRegistrationSlice";
import {  useNavigate, useNavigation, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";

const PaymentListEdit = () => {
  return (
    <Box className="dashboard-full-background">
      <Grid container spacing={0}>
        <Grid item xs={2}>
          <Sidebar />
        </Grid>
        <Grid item xs={10}>
          <Header />
          <PaymentPageDesign />
        </Grid>
      </Grid>
    </Box>
  );
};

const PaymentPageDesign = () => {
  const { id } = useParams();

  const paymentData = useGetPaymentByIdQuery(id);

  const [SuccessMessege, setSuccesMessege] = useState(null);
  const [ErrorMessege, setErrorMessege] = useState(null);

  const [initialFValues, setInitialFValues] = useState({
    payment_details: [],
    year: "",
  });

  useEffect(() => {
    if (paymentData.data) {
      setInitialFValues({
        payment_details: paymentData.data.payment_details,
        year: paymentData.data.year,
      });
    }
  }, [paymentData.data]);

  const { values, handleInputChange } = useForm(
    initialFValues,
    true,
    false,
    true
  );

  const [updatePayment] = usePatchPaymentMutation();

  const history = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    if(paymentData.data.id){
        formData.append('id',paymentData.data.id);
    }
    if (paymentData.data.mr_id) {
      formData.append("mr_id", paymentData.data.mr_id);
    }
    formData.append("year", values.year);
    formData.append("payment_details", values.payment_details);
    updatePayment(formData).then((res) => {
      setSuccesMessege("Successfully Updated your Payment.");
      setTimeout(() => {
        history("/dashboard/member/payment/list");
      }, [3000]);
    }).catch((err)=>{
        setErrorMessege('Some Error Occur.Please Try Again Later.')
        setTimeout(()=>{
            setErrorMessege(null);
        },[3000])
    })
  };
  return (
    <Box className="table-design-background">
      <Grid container>
        <Grid item xs={6}>
          <Typography className="dashboard-home-page-text">
            Membership Payment
          </Typography>
        </Grid>
      </Grid>
      <Box
        style={{
          padding: "1rem",
          paddinBottom: "2rem",
          paddingTop: "0rem",
          textAlign: "start",
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12} md={7}>
            <Box className="passwordbtn">
              <label className="emailtext">Select Payment Year*</label>
              <Controls.Select
                id="year"
                title="year"
                name={"year"}
                label={"Select Payment Year"}
                value={values.year}
                onChange={handleInputChange}
                options={[
                  { id: "2022", title: "2022" },
                  { id: "2023", title: "2023" },
                  { id: "2024", title: "2024" },
                  { id: "2025", title: "2025" },
                  { id: "2026", title: "2026" },
                  { id: "2027", title: "2027" },
                  { id: "2028", title: "2028" },
                  { id: "2029", title: "2029" },
                  { id: "2030", title: "2030" },
                  { id: "2031", title: "2031" },
                  { id: "2032", title: "2032" },
                  { id: "2033", title: "2033" },
                ]}
              />
            </Box>
            <Box className="passwordbtn">
              <label className="emailtext">Payment File*</label>
              <Controls.File
                name={"payment_details"}
                onChange={handleInputChange}
              />
              <Grid item xs={4}>
                <Button
                  variant="contained"
                  component="label"
                  style={{ marginTop: "16px" }}
                >
                  <a
                    href={values.payment_details}
                    target="_blank"
                    style={{ color: "white" }}
                  >
                    See Previous File
                  </a>
                </Button>
              </Grid>
            </Box>
            <Grid xs={2} item style={{ marginTop: "12px" }}>
              <Controls.Button
                type="submit"
                text="Submit"
                backgroundColor="#1b284b"
                onClick={handleSubmit}
              />
            </Grid>
          </Grid>
          <Grid item xs={5}>
            <img src={Qr} alt="qr_code" style={{ width: "100%" }} />
          </Grid>
        </Grid>
      </Box>
      {SuccessMessege === null ? null : (
        <Box className="successmessege">
          <Typography className="messegefonts">
            <FontAwesomeIcon icon={faCheckCircle} className="messegeicon" />
            {SuccessMessege}
          </Typography>
        </Box>
      )}
      {ErrorMessege === null ? null : (
        <Box className="errormessege">
          <Typography className="messegefonts">
            <FontAwesomeIcon
              icon={faExclamationTriangle}
              className="messegeicon"
            />
            {ErrorMessege}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default PaymentListEdit;
