import { createSelector, createEntityAdapter } from "@reduxjs/toolkit";
import { BASE_URL } from "../constant/baseURL";

import { apiSlice } from "./apiSlice";

const lfNoAdapter = createEntityAdapter();

const initialState = lfNoAdapter.getInitialState();

const URL = '/bookings/lf-no/';

const LFNoSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getLFNo: builder.query({
      query: () => URL,
      transformResponse: (responseData) => {
        let returnResponse = [];
        responseData.map((i) => {
          let newData = new Date(i.created_at);

          returnResponse.push({ ...i, created_at: newData.toDateString() });
        });

        return lfNoAdapter.setAll(initialState, returnResponse);
      },
      providesTags: (result, error, arg) => [
        { type: "LFNO", id: "LIST" },
        ...result.ids.map((id) => ({ type: "LFNO", id })),
      ],
    }),
    addLFNo: builder.mutation({
      query: (lfNo) => {
        return {
          url: URL,
          method: "POST",
          body: lfNo,
        };
      },
      invalidatesTags: ["LFNO"],
    }),
    getLFNoById: builder.query({
      query: (id) => ({
        url: `${URL}${id}`,
        method: "GET",
      }),
      providesTags: ["LFNO"],
    }),
    getLFNoByLFNo:builder.query({
        query: (id) => ({
          url: `${URL}?lf_no=${id}`,
          method: "GET",
        }),
        providesTags: ["LFNO"],
      }),
    getLFNoPagination: builder.query({
      query: (id) => ({
        url: `${URL}?page=${id}`,
        method: "GET",
      }),
      providesTags: ["LFNO"],
    }),
    updateLFNoPage: builder.mutation({
      query: (updateAboutPage) => {
        return {
          url: `${URL}${updateAboutPage.get("id")}/`,
          method: "PATCH",
          body: updateAboutPage,
        };
      },
      invalidatesTags: ["LFNO"],
    }),
    deleteLFNoPage: builder.mutation({
      query: ({ id }) => {
        return {
          url: `${URL}${id}/`,
          method: "DELETE",
          body: id,
        };
      },
      invalidatesTags: ["LFNO"],
    }),
  }),
});

export const {
  useGetLFNoQuery,
  useAddLFNoMutation,
  useGetLFNoByIdQuery,
  useUpdateLFNoPageMutation,
  useDeleteLFNoPageMutation,
  useGetLFNoPaginationQuery,
  useGetLFNoByLFNoQuery
} = LFNoSlice;

