import React, { useEffect, useState } from "react";
import Sidebar from "../../component/sidebar";
import Header from "../../component/header";
import { Box, Grid, Typography } from "@mui/material";
import Controls from "../forms/controls/Controls";
import { useForm, Form } from "../forms/useForm";
import { useAddHospitalMutation } from "../../../../../api/hospitalSlice";
import BlobToFile from "../forms/utils/blobToFile";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCog,
  faBell,
  faSignInAlt,
  faExclamationTriangle,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

const AddHospital = () => {
  return (
    <Grid container className="dashboard-full-background">
      <Grid item xs={2} spacing={4}>
        <Sidebar />
      </Grid>
      <Grid item xs={10}>
        <Header />
        <AddHospitalPages />
      </Grid>
    </Grid>
  );
};

const AddHospitalPages = () => {
  const initialFValues = {
    name: "",
    address: "",
    is_active: "false",
    fixed_allotment: 0,
    minimum_gap: 0,
    frequency_in_month: 0,
  };
  const { values, handleImageUpload, handleInputChange } = useForm(
    initialFValues,
    true,
    false,
    false
  );
  const [SuccessMessege, setSuccesMessege] = useState(null);
  const [ErrorMessege, setErrorMessege] = useState(null);

  const [addHospital] = useAddHospitalMutation();

  const history = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    var isTrueSet = values.is_active === "true";
    formData.append("name", values.name);
    formData.append("address", values.address);
    formData.append("fixed_allotment", values.fixed_allotment);
    formData.append("is_active", isTrueSet);
    formData.append('minimum_gap', values.minimum_gap);
    formData.append('frequency_in_month', values.frequency_in_month);
    addHospital(formData)
      .then((res) => {
        setSuccesMessege("Successfully Added Hospital Data.");
        setTimeout(() => {
          history("/dashboard/hospitals");
        }, [3000]);
      })
      .catch((err) => {
        setErrorMessege("Some Error Occur. Please Try Again Later.");
        setTimeout(() => {
          setErrorMessege(null);
        }, [3000]);
      });
  };

  return (
    <Box className="table-design-background">
      <Typography className="dashboard-home-page-text">
        Add Hospital Details
      </Typography>
      <Box style={{ padding: "1.5rem" }}>
        <Form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={6} style={{ textAlign: "start" }}>
              <Controls.Input
                name="name"
                label="Hospital Name"
                value={values.name}
                onChange={handleInputChange}
              />
              <Controls.NumberInput
                name="fixed_allotment"
                label="Fixed Allotment"
                value={values.fixed_allotment}
                onChange={handleInputChange}
              />
              <Controls.NumberInput
                name="minimum_gap"
                label="Minimum Gap in days"
                value={values.minimum_gap}
                onChange={handleInputChange}
              />
              <Controls.NumberInput
                name="frequency_in_month"
                label="Frequency of Visit in Month"
                value={values.frequency_in_month}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={6}>
              <Controls.Input
                name="address"
                label="Hospital Address"
                value={values.address}
                onChange={handleInputChange}
              />
              <Controls.RadioGroup
                name="is_active"
                label="Is Active"
                value={values.is_active}
                onChange={handleInputChange}
                items={[
                  { id: "false", title: "False" },
                  { id: "true", title: "True" },
                ]}
              />
            </Grid>
            <Grid>
              <Box style={{ marginLeft: "1.3rem" }}>
                <Controls.Button
                  type="submit"
                  text="Submit"
                  backgroundColor="#1b284b"
                  onClick={handleSubmit}
                />
              </Box>
            </Grid>
          </Grid>
        </Form>
        {SuccessMessege === null ? null : (
          <Box className="successmessege">
            <Typography className="messegefonts">
              <FontAwesomeIcon icon={faCheckCircle} className="messegeicon" />
              {SuccessMessege}
            </Typography>
          </Box>
        )}
        {ErrorMessege === null ? null : (
          <Box className="errormessege">
            <Typography className="messegefonts">
              <FontAwesomeIcon
                icon={faExclamationTriangle}
                className="messegeicon"
              />
              {ErrorMessege}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};
export default AddHospital;
