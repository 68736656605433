export function tryParseDateFromString(dateStringCandidateValue, format = "ymd") {
    const candidate = (dateStringCandidateValue || ``)
      .split(/[ :\-\/]/g).map(Number).filter(v => !isNaN(v));
    const toDate = () => {
      format = [...format].reduce((acc, val, i) => ({ ...acc,  [val]: i }), {});
      const parts = 
        [candidate[format.y], candidate[format.m] - 1, candidate[format.d] ]
          .concat(candidate.length > 3 ? candidate.slice(3) : []);
      const checkDate = d => d.getDate && 
        ![d.getFullYear(), d.getMonth(), d.getDate()]
          .find( (v, i) => v !== parts[i] ) && d || undefined;
      
      return checkDate( new Date(Date.UTC(...parts)) );
    };
  
    return candidate.length < 3 ? undefined : toDate();
  }


export function stringToDate(inputString){
  const timezoneOffsetMatch = inputString.match(/([+-])(\d{2}):(\d{2})$/);
  let timezoneOffsetMinutes = 0;

  if (timezoneOffsetMatch) {
    const [, sign, hours, minutes] = timezoneOffsetMatch;
    timezoneOffsetMinutes = (sign === "-" ? -1 : 1) * (parseInt(hours, 10) * 60 + parseInt(minutes, 10));
  }

  // Removing the timezone offset from the input string
  const inputStringWithoutOffset = inputString.replace(/([+-]\d{2}:\d{2})$/, "");

  // Parsing the string to a Date object
  const dateObject = new Date(inputStringWithoutOffset);

  // Applying the timezone offset to the Date object
  dateObject.setMinutes(dateObject.getMinutes() - timezoneOffsetMinutes)
  return dateObject;
}

export function stringDateToReadable(inputString)
{
  // Extracting the timezone offset from the input string
  const timezoneOffsetMatch = inputString.match(/([+-])(\d{2}):(\d{2})$/);
  let timezoneOffsetMinutes = 0;

  if (timezoneOffsetMatch) {
    const [, sign, hours, minutes] = timezoneOffsetMatch;
    timezoneOffsetMinutes = (sign === "-" ? -1 : 1) * (parseInt(hours, 10) * 60 + parseInt(minutes, 10));
  }

  // Removing the timezone offset from the input string
  const inputStringWithoutOffset = inputString.replace(/([+-]\d{2}:\d{2})$/, "");

  // Parsing the string to a Date object
  const dateObject = new Date(inputStringWithoutOffset);

  // Applying the timezone offset to the Date object
  dateObject.setMinutes(dateObject.getMinutes() - timezoneOffsetMinutes);

  // Converting the Date object to a readable date string
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  const dateOnlyString = dateObject.toLocaleDateString(undefined, options);
  return dateOnlyString;
}