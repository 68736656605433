import { Box } from "@mui/material";
import React from "react";
import TextButton from '../../../../../components/button/buttonicon';

const HomePageAddButton = () =>{

    return(
        <Box style={{marginTop:'1rem',textAlign:"right",marginRight:"1.2rem"}}>
            <TextButton buttonText="Add Home Data"  />
        </Box>
    )
}

export default HomePageAddButton;