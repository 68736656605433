import { Box, Button, Grid, Typography } from "@material-ui/core";
import { useParams } from "react-router-dom";
import Sidebar from "../../../component/sidebar";
import Header from "../../../component/header";
import EditMembers from "./EditMembers";
import React, { useState, useEffect } from "react";
import Controls from "../../forms/controls/Controls";
import { useForm, Form } from "../../forms/useForm";
import {
  useGetMemberMembershipPeriodsByIdQuery,
  useUpdateMemberMembershipPeriodMutation
} from "../../../../../../api/memberMembershipPeriodSlice";
import useDebounce from "../../../../../../utils/debounce";
import editWithoutImage from "../../../../../../utils/editUtils/editWithoutImage";
import convertBooleanToString from "../../../../../../utils/convertBooleanToString";

const EditMembershipPeriod = () => {
  return (
    <Box className="dashboard-full-background">
      <Grid container spacing={0}>
        <Grid item xs={2}>
          <Sidebar />
        </Grid>
        <Grid item xs={10}>
          <Header />
          <EditMembershipPeriodPage />
        </Grid>
      </Grid>
    </Box>
  );
};

const EditMembershipPeriodPage = () => {
  const { id } = useParams();
  const [noLoop, setNoLoop] = useState(true);
  const context = { 'member_list': 'normal' }

  const membershipYears = useGetMemberMembershipPeriodsByIdQuery(id);
  const [initialFValues, setInitialFValues] = useState({
    edit: false,
    year: "",
    is_current: "false",
    is_founder: "false",
    member_list: []
  });


  useEffect(() => {
    // debugger;
    
    
    if (membershipYears.data !== undefined) {
      
      setInitialFValues({
        edit: true,
        year: membershipYears.data.year,
        is_current: membershipYears.data.is_current.toString(),
        is_founder: membershipYears.data.is_founder.toString(),
        member_list: membershipYears.data.member_list,
      });
    }
  }, [
    membershipYears.data
  ]);


  const { values, handleImageUpload, handleInputChange } = useForm(
    initialFValues,
    true,
    false,
    true
  );

  const [editMembershipYears] = useUpdateMemberMembershipPeriodMutation();

  useEffect(() => {
    
    
    if (membershipYears.data) {
      
      editWithoutImage(noLoop, setNoLoop, editMembershipYears, values, id, context);
    }
  }, [
    useDebounce(values.year, 3000),
    values.is_dinner,
    values.is_current,
    values.is_founder,
  ]);
  return (
    <>
      <Box className="table-design-background" style={{ marginRight: "1rem" }}>
        <Typography className="dashboard-home-page-text">
          Update Members Page
        </Typography>
        <Box style={{padding:'1rem'}}>
          <Form style={{ marginTop: "1rem" }}>
            <Grid container spacing={3} style={{ marginTop: "1rem" }}>
              <Grid item xs={4}>
                <Controls.Input
                  name="year"
                  label="Membership Period"
                  value={values.year}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={4}>
                <Controls.RadioGroup
                  name="is_current"
                  label="Is Current"
                  value={values.is_current}
                  onChange={handleInputChange}
                  items={[
                    { id: "false", title: "False" },
                    { id: "true", title: "True" },
                  ]}
                />
              </Grid>
              <Grid item xs={4}>
                <Controls.RadioGroup
                  name="is_founder"
                  label="Is Founder"
                  value={values.is_founder}
                  onChange={handleInputChange}
                  items={[
                    { id: "false", title: "False" },
                    { id: "true", title: "True" },
                  ]}
                />
              </Grid>
            </Grid>
          </Form>
        </Box>
      </Box>
      <Box
        style={{padding:"2rem", paddingTop: "0rem", textAlign: "start" }}
      >
        <Grid container spacing={3}>
          <EditMembers
            name="member_list"
            value={values.member_list}
            onChange={handleInputChange}
            id={id}
            context={context}
            editApi={useUpdateMemberMembershipPeriodMutation}
          />
        </Grid>
      </Box>
    </>
  );
};

export default EditMembershipPeriod;
