import {
    createSelector,
    createEntityAdapter
} from "@reduxjs/toolkit";

import { apiSlice } from "./apiSlice";

const testimonialsAdapter = createEntityAdapter()

const initialState = testimonialsAdapter.getInitialState()

export const testimonialSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getTestimonials: builder.query({
            query: () => '/basic/testimonial/',
            transformResponse: responseData => {
                return testimonialsAdapter.setAll(initialState, responseData)
            },
            providesTags: (result, error, arg) => 
            [
                { type: 'Testimonials', id: "LIST" },
                ...result.ids.map(id => ({ type: 'Testimonials', id }))
            ]
        }),
        getTestimonialsById: builder.query({
            query: (id)=>({

                url:`/basic/testimonial/${id}/`,
                method: 'GET',
            }),
            providesTags: ['Testimonials']
        }),
        addTestimonial: builder.mutation({
            query: (testimonial) => {
            return{
                url: '/basic/testimonial/',
                method: 'POST',
                body: testimonial,
            }
        },
            invalidatesTags: ['Testimonials']
        }),
        updateTestimonial: builder.mutation({
           
            query: (updateTestimonial) => {
                return{
                url: `/basic/testimonial/${updateTestimonial.get('id')}/`,
                method: 'PATCH',
                body: updateTestimonial
                }
            },
            invalidatesTags: ['Testimonials']
        }),
        deleteTestimonial: builder.mutation({
            query: ({ id }) => {
                return{
                url: `/basic/testimonial/${id}/`,
                method: 'DELETE',
                body: id
            }},
            invalidatesTags: ['Testimonials']
        }),
    })
})

export const {
    useGetTestimonialsQuery,
    useAddTestimonialMutation,
    useUpdateTestimonialMutation,
    useDeleteTestimonialMutation,
    useGetTestimonialsByIdQuery,
} = testimonialSlice

// returns the query result object
export const selectTestimonialsResult = testimonialSlice.endpoints.getTestimonials.select()

// Creates memoized selector
const selectTestimonialssData = createSelector(
    selectTestimonialsResult,
    testimonialsResult => testimonialsResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllTestimonials,
    selectById: selectTestimonialById,
    selectIds: selectTestimonialIds
    // Pass in a selector that returns the posts slice of state
} = testimonialsAdapter.getSelectors(state => selectTestimonialssData(state) ?? initialState)
