import { Box } from "@mui/material";
import React from "react";
import TextButton from "../../../../../components/button/buttonicon";

const WorkAddButton = () =>{

    return(
        <Box style={{marginTop:'1rem',textAlign:"right",marginRight:"1.2rem"}}>
            <TextButton buttonText="Add Objectives" />

        </Box>
    )
}

export default WorkAddButton;