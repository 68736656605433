import axios from 'axios';
import Cookies from 'js-cookie';
import { BASE_URL } from './baseURL';




const baseURL = `${BASE_URL}`;

const axiosInstance = axios.create({
	baseURL: baseURL,
	timeout: 5000,
	headers: {
		Authorization: Cookies.get('access') ?
			'JWT ' + Cookies.get('access') :
			null,
		'Content-Type': 'application/json',
		accept: 'application/json',
	},
});

axiosInstance.interceptors.response.use(
	(response) => {
		return response;
	},
	async function (error) {
     
		const originalRequest = error.config;

		if (typeof error.response === 'undefined') {
			return Promise.reject(error);
		}

		if (
			error.response.status === 401 &&
			originalRequest.url === baseURL + '/user/api/token/refresh'
		) {
			window.location.href = '/login/';
			return Promise.reject(error);
		}

		if (
			error.response.data.code === 'token_not_valid' &&
			error.response.status === 401 &&
			error.response.statusText === 'Unauthorized'
		) {
          
			const refreshToken = Cookies.get('refresh');

			if (refreshToken) {
				const tokenParts = JSON.parse(atob(refreshToken.split('.')[1]));

				// exp date in token is expressed in seconds, while now() returns milliseconds:
				const now = Math.ceil(Date.now() / 1000);
			

				if (tokenParts.exp > now) {
					return axiosInstance
						.post('/user/token/refresh/', {
							refresh: refreshToken
						})
						.then((response) => {
							Cookies.set('access', response.data.access);
							Cookies.set('refresh',response.data.refresh)

							axiosInstance.defaults.headers['Authorization'] =
								'JWT ' + response.data.access;
							originalRequest.headers['Authorization'] =
								'JWT ' + response.data.access;

							return axiosInstance(originalRequest);
						})
						.catch((err) => {
						
						});
				} else {
			
					window.location.href = '/login/';
				}
			} else {
			
				window.location.href = '/login/';
			}
		}

		// specific error handling done elsewhere
		return Promise.reject(error);
	}
);
export default axiosInstance;

