import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Grid, Typography } from "@mui/material";
import Sidebar from "../../component/sidebar";
import Header from "../../component/header";
import Controls from "../forms/controls/Controls";
import { useForm, Form } from "../forms/useForm";
import { useGetLandingPagesByIdQuery } from "../../../../../api/landingPageSlice";
import BlobToFile from "../forms/utils/blobToFile";
import { useUpdateLandingPageMutation } from "../../../../../api/landingPageSlice";
import urlToFile from "../forms/utils/urlToFile";
import { Box } from "@mui/system";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCog,
  faBell,
  faSignInAlt,
  faExclamationTriangle,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

const LandingPageEditPage = () => {
  return (
    <Grid container className="dashboard-full-background">
      <Grid item xs={2} spacing={4}>
        <Sidebar />
      </Grid>
      <Grid item xs={10}>
        <Header />
        <EditLandingPagePages />
      </Grid>
    </Grid>
  );
};

const EditLandingPagePages = () => {
  const { id } = useParams();
  const LandingPages = useGetLandingPagesByIdQuery(id);
  const [File1, setFile1] = useState([]);
  const [updateLandingPage, setUpdateLandingPage] = useState([]);
  const [initialFValues, setInitialFValues] = useState({
    image1: [],
    title: "",
    description: "",
    is_active: "false",
  });
  const [SuccessMessege, setSuccesMessege] = useState(null);
  const [ErrorMessege, setErrorMessege] = useState(null);

  useEffect(() => {
    
    if (LandingPages.data) {
      if (LandingPages.data.image1) {
        urlToFile(LandingPages.data.image1, setFile1);
      }

      setInitialFValues({
        image1: File1,
        title: LandingPages.data.title,
        description: LandingPages.data.description,
        is_active: LandingPages.data.is_active.toString(),
      });
    }
  }, [LandingPages.data]);

  const { values, handleImageUpload, handleInputChange } = useForm(
    initialFValues,
    true,
    false,
    true
  );

  const [editLandingPage] = useUpdateLandingPageMutation();

  useEffect(() => {
    handleImageUpload("image1", File1);
  }, [File1]);


  const history = useNavigate();

  
  


  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    
    var isTrueSet = values.is_active === "true";
    var file1 = BlobToFile(values.image1, "landingPage");
    if (file1.length !== 0) {
      formData.append("image1", file1, "landingPage.jpg");
    }
    if(values.title)
      formData.append("title", values.title);
    if(values.description)
      formData.append("description", values.description);
    formData.append("is_active", isTrueSet);
    formData.append("id", id);
    editLandingPage(formData)
      .then((res) => {
        setSuccesMessege("Successfully Updated Landing Page Data.");
        setTimeout(() => {
          history("/dashboard/landing-pages");
        }, [3000]);
      })
      .catch((err) => {
        setErrorMessege("Some Error Occur. Please Try Again Later.");
        setTimeout(() => {
          setErrorMessege(null);
        }, [3000]);
      });
  };

  return (
    <Box className="table-design-background">
      <Typography className="dashboard-home-page-text">
        Update Landing Page Details
      </Typography>
      <Box style={{ padding: "1.5rem" }}>
        <Form onSubmit={handleSubmit}>
          <Grid container spacing={8}>
            <Grid item xs={6} style={{ textAlign: "start" }}>
              <Controls.Input
                name="title"
                label="Title"
                value={values.title}
                onChange={handleInputChange}
              />
              <Controls.Input
                name="description"
                label="Sub-Title"
                value={values.description}
                onChange={handleInputChange}
              />
              <Controls.RadioGroup
                name="is_active"
                label="Active"
                value={values.is_active}
                onChange={handleInputChange}
                items={[
                  { id: "false", title: "False" },
                  { id: "true", title: "True" },
                ]}
              />
            </Grid>
            <Grid item xs={6}>
              <Controls.Image setFile={setFile1} aspectRatio={1440 / 550} />
            </Grid>
            <Grid item style={{ textAlign: "start", marginTop: "-3.2rem" }}>
              <Controls.Button
                type="submit"
                text="Submit"
                backgroundColor="#1b284b"
                onClick={handleSubmit}
              />
            </Grid>
          </Grid>
        </Form>
        {SuccessMessege === null ? null : (
          <Box className="successmessege">
            <Typography className="messegefonts">
              <FontAwesomeIcon icon={faCheckCircle} className="messegeicon" />
              {SuccessMessege}
            </Typography>
          </Box>
        )}
        {ErrorMessege === null ? null : (
          <Box className="errormessege">
            <Typography className="messegefonts">
              <FontAwesomeIcon
                icon={faExclamationTriangle}
                className="messegeicon"
              />
              {ErrorMessege}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default LandingPageEditPage;
