import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Box, Grid, Typography } from "@mui/material";
import Sidebar from "../../component/sidebar";
import Header from "../../component/header";
import Controls from "../forms/controls/Controls";
import { useForm, Form } from "../forms/useForm";
import { useGetTestimonialsByIdQuery } from "../../../../../api/testimonialSlice";
import BlobToFile from "../forms/utils/blobToFile";
import { useUpdateTestimonialMutation } from "../../../../../api/testimonialSlice";
import urlToFile from "../forms/utils/urlToFile";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCog,
  faBell,
  faSignInAlt,
  faExclamationTriangle,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

const TestimonialEditPage = () => {
  return (
    <Grid container className="dashboard-full-background">
      <Grid item xs={2} spacing={4}>
        <Sidebar />
      </Grid>
      <Grid item xs={10}>
        <Header />
        <EditTestimonialPages />
      </Grid>
    </Grid>
  );
};

const EditTestimonialPages = () => {
  const { id } = useParams();

  const Testimonials = useGetTestimonialsByIdQuery(id);
  const [File, setFile] = useState([]);
  const [updateTestimonial, setUpdateTestimonial] = useState([]);
  const [initialFValues, setInitialFValues] = useState({
    client_photo: [],
    client_name: "",
    client_testimonial: "",
    is_active: false,
  });

  const [SuccessMessege, setSuccesMessege] = useState(null);
  const [ErrorMessege, setErrorMessege] = useState(null);
  const history = useNavigate();

  useEffect(() => {
    if (Testimonials.data) {
      urlToFile(Testimonials.data.client_photo, setFile);
      setInitialFValues({
        client_photo: File,
        client_name: Testimonials.data.client_name,
        client_testimonial: Testimonials.data.client_testimonial,
        is_active: Testimonials.data.is_active,
      });
    }
  }, [Testimonials.data]);

  const { values, handleImageUpload, handleInputChange } = useForm(
    initialFValues,
    true,
    false,
    true
  );

  

  const [editTestimonial] = useUpdateTestimonialMutation();

  useEffect(() => {
    handleImageUpload("client_photo", File);
  }, [File]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    var file = BlobToFile(values.client_photo, "testimonial");
    
    
    if (file.length !== 0) {
      
      formData.append("client_photo", file, "testimonial.jpg");
    }
    if (values.client_name) formData.append("client_name", values.client_name);
    if (values.client_testimonial)
      formData.append("client_testimonial", values.client_testimonial);
    if (values.is_active) formData.append("is_active", values.is_active);
    formData.append("id", id);
    editTestimonial(formData)
      .then((res) => {
        setSuccesMessege("Successfully Updated Testimonials Data.");
        setTimeout(() => {
          history("/dashboard/testimonial");
        }, [3000]);
      })
      .catch((err) => {
        setErrorMessege("Some Error Occur. Please Try Again Later.");
        setTimeout(() => {
          setErrorMessege(null);
        }, [3000]);
      });
  };

  return (
    <Box className="table-design-background">
      <Typography className="dashboard-home-page-text">
        Update Testimonial List
      </Typography>
      <Box style={{ padding: "1.5rem" }}>
        <Form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Controls.Input
                name="client_name"
                label="Client Name"
                value={values.client_name}
                onChange={handleInputChange}
              />
              <Controls.Input
                name="client_testimonial"
                label="Client Testimonial "
                value={values.client_testimonial}
                onChange={handleInputChange}
              />
              <Controls.RadioGroup
                name="is_active"
                label="Is Active"
                value={values.is_active.toString()}
                onChange={handleInputChange}
                items={[
                  { id: "false", title: "False" },
                  { id: "true", title: "True" },
                ]}
              />
            </Grid>
            <Grid item xs={6}>
              <Box style={{ marginTop: "6px" }}>
                <Controls.Image setFile={setFile} aspectRatio={1 / 1} />
              </Box>
            </Grid>
            <Grid style={{ marginTop: "0px",marginLeft:'1rem' }}>
              <Controls.Button
                type="submit"
                text="Submit"
                backgroundColor="#1b284b"
                onClick={handleSubmit}
              />
            </Grid>
          </Grid>
        </Form>
        {SuccessMessege === null ? null : (
          <Box className="successmessege">
            <Typography className="messegefonts">
              <FontAwesomeIcon icon={faCheckCircle} className="messegeicon" />
              {SuccessMessege}
            </Typography>
          </Box>
        )}
        {ErrorMessege === null ? null : (
          <Box className="errormessege">
            <Typography className="messegefonts">
              <FontAwesomeIcon
                icon={faExclamationTriangle}
                className="messegeicon"
              />
              {ErrorMessege}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default TestimonialEditPage;
