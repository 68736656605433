import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Sidebar from "../../component/sidebar";
import { Grid, Pagination, Stack, Typography } from "@mui/material";
import Header from "../../component/header";
import BasicTable from "../tables/BasicTable";
import {
  useGetContactsQuery,
  useUpdateContactMutation,
  useDeleteContactMutation,
  useAddContactMutation,
  useGetContactsPaginationQuery,
} from "../../../../../api/contactSlice";
import ContactAddButton from "./contactAddButton";
import { Box } from "@mui/system";
import usePagination from "../../../../../components/reuseable/pagination";

const DashboardContacts = () => {
  return (
    <Grid container className="dashboard-full-background">
      <Grid item xs={2} spacing={4}>
        <Sidebar />
      </Grid>
      <Grid item xs={10}>
        <Header />
        <DashboardContactsPages />
      </Grid>
    </Grid>
  );
};

const DashboardContactsPages = () => {
  const [pageId, setPageId] = useState(1);
  const { data, isLoading, isSuccess } = useGetContactsPaginationQuery(pageId);

  const [deleteContact] = useDeleteContactMutation();

  const deleteConfirm = (e, id) => {
    e.preventDefault();

    deleteContact({ id });
  };
  let headers = [];
  let tableData = [];
  let content;

  var count;
  if (isLoading) {
    content = <p>"Loading..."</p>;
  } else if (isSuccess) {
    headers = ["Full Name", "Email", "Replied", "Contact No", "Suggestion"];
    data.results.forEach((key) => {
      tableData.push({
        "Full Name": key.full_name,
        id: key.id,
        Email: key.email,
        Replied: key.is_replied,
        "Contact No": key.contact_no,
        Suggestion: key.message,
      });
    });
    var newData = data.count / 10;
    count = Math.ceil(newData);
  }

  const [page, setPage] = useState(1);
  const PER_PAGE = 10;

  const _DATA = usePagination(tableData, count, PER_PAGE);

  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
    setPageId(p);
  };
  return (
    <>
      <Box className="table-design-background">
        <Grid container>
          <Grid item xs={6}>
            <Typography className="dashboard-home-page-text">
              Suggestion Box Details
            </Typography>
          </Grid>
          <Grid item xs={6}></Grid>
        </Grid>
        <Box style={{ padding: "1rem" }}>
          <BasicTable
            headers={headers}
            data={tableData}
            isDelete={true}
            deleteColor={"#f50057"}
            isEdit={true}
            editColor={"#3f51b5"}
            addColor={""}
            tableName={"contact"}
            deleteConfirm={deleteConfirm}
            activeSign={["Replied"]}
          />
        </Box>
        <Box
          style={{
            marginTop: "20px",
            textAlign: "center",
            paddingBottom: "1rem",
            paddingLeft: "1rem",
          }}
        >
          <Stack spacing={2} style={{ textAlign: "center" }}>
            <Pagination
              count={count}
              size="small"
              page={page}
              variant="outlined"
              onChange={handleChange}
            />
          </Stack>
        </Box>
      </Box>
    </>
  );
};
export default DashboardContacts;
