const convertArrayRepeatedToObject = (responseData) =>{    
    const result = responseData.reduce((acc, cur) => {
        Object.entries(cur).forEach(([key, value]) => {
            if (!acc[key]) acc[key] = Array.isArray(value) ? [] : value;
            if (Array.isArray(acc[key])) {
                if(!acc[key].includes(value))
                {
                    acc[key].push(value)};
                }
            else{
                if(acc[key] !== value)
                {
                    acc[key] = [acc[key], value]
                }
            }
            });
            return acc;
            }, {});
    return result
}

export default convertArrayRepeatedToObject;

// const convertArrayRepeatedToObject = (responseData) =>{
//     const returnDict = {}
//     responseData.forEach((key)=>{
//         Object.entries(key).forEach(([key, value])=>{
//             if(!returnDict[key])
//             {returnDict[key] = value}
//             else{

//             }
//         })
//     })
// }

// export default convertArrayRepeatedToObject;