import {
    createSelector,
    createEntityAdapter
} from "@reduxjs/toolkit";

import { apiSlice } from "./apiSlice";

const centralPresidentsAdapter = createEntityAdapter()

const initialState = centralPresidentsAdapter.getInitialState()

export const centralPresidentSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getCentralPresidents: builder.query({
            query: () => '/basic/central-past-president/',
            transformResponse: responseData => {
                return centralPresidentsAdapter.setAll(initialState, responseData)
            },
            providesTags: (result, error, arg) => 
            [
                { type: 'CentralPresidents', id: "LIST" },
                ...result.ids.map(id => ({ type: 'CentralPresidents', id }))
            ]
        }),
        getActiveCentralPresidents: builder.query({
            query: () => '/basic/central-past-president/?is_active=True',
            transformResponse: responseData => {
                return centralPresidentsAdapter.setAll(initialState, responseData)
            },
            providesTags: (result, error, arg) => 
            [
                { type: 'CentralPresidents', id: "LIST" },
                ...result.ids.map(id => ({ type: 'CentralPresidents', id }))
            ]
        }),
        
        getCentralPresidentsById: builder.query({
            query: (id)=>({
                url:`/basic/central-past-president/${id}/`,
                method: 'GET',
            }),
            providesTags: ['CentralPresidents']
        }),
        addCentralPresident: builder.mutation({
            query: (centralPresident) => {
            return{
                url: '/basic/central-past-president/',
                method: 'POST',
                body: centralPresident
            }
        },
            invalidatesTags: ['CentralPresidents']
        }),
        updateCentralPresident: builder.mutation({
           
            query: (updateCentralPresident) => {
                return{
                url: `/basic/central-past-president/${updateCentralPresident.get('id')}/`,
                method: 'PATCH',
                body: updateCentralPresident
                }
            },
            invalidatesTags: ['CentralPresidents']
        }),
        deleteCentralPresident: builder.mutation({
            query: ({ id }) => {
                return{
                url: `/basic/central-past-president/${id}/`,
                method: 'DELETE',
                body: id
            }},
            invalidatesTags: ['CentralPresidents']
        }),
    })
})

export const {
    useGetCentralPresidentsQuery,
    useAddCentralPresidentMutation,
    useUpdateCentralPresidentMutation,
    useDeleteCentralPresidentMutation,
    useGetCentralPresidentsByIdQuery,
    useGetActiveCentralPresidentsQuery,
} = centralPresidentSlice

// returns the query result object
export const selectCentralPresidentsResult = centralPresidentSlice.endpoints.getCentralPresidents.select()

// Creates memoized selector
const selectCentralPresidentssData = createSelector(
    selectCentralPresidentsResult,
    centralPresidentsResult => centralPresidentsResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllCentralPresidents,
    selectById: selectCentralPresidentById,
    selectIds: selectIds
    // Pass in a selector that returns the posts slice of state
} = centralPresidentsAdapter.getSelectors(state => selectCentralPresidentssData(state) ?? initialState)