import { Box, Button, Grid, Typography } from "@material-ui/core";
import { useParams } from "react-router-dom";
import Sidebar from "../../component/sidebar";
import Header from "../../component/header";
import React, { useState, useEffect } from "react";
import Controls from "../forms/controls/Controls";
import { useForm, Form } from "../forms/useForm";
import useDebounce from "../../../../../utils/debounce";
import {
  useGetHospitalIdQuery,
  useGetHospitalsQuery,
} from "../../../../../api/hospitalSlice";
import { useGetActiveHospitalHolidaysQuery } from "../../../../../api/hospitalHolidaySlice";
import {
  useCheckBookingMutation,
  useCheckHospitalBookingMutation,
  useGetBookingListByIdQuery,
  useUpdateBookingMutation,
} from "../../../../../api/hospitalBookingSlice";
import axios from "axios";
import { BASE_URL } from "../../../../../constant/baseURL";
import Cookies from "js-cookie";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";

const UpdatemyBooking = () => {
  return (
    <Box className="dashboard-full-background">
      <Grid container spacing={0}>
        <Grid item xs={2}>
          <Sidebar />
        </Grid>
        <Grid item xs={10}>
          <Header />
          <BookMyHospitalPage />
        </Grid>
      </Grid>
    </Box>
  );
};

const BookMyHospitalPage = () => {
  const { id } = useParams();
  const [hospitalID, setHospitalID] = useState(null);
  const [disableDates, setDisableDates] = useState([]);
  const hospitalData = useGetHospitalsQuery();
  const hospitalHoliday = useGetActiveHospitalHolidaysQuery(hospitalID);
  const [selectedDates, setSelectedDates] = useState(new Date());

  const [bookingResponse, setBookingResponse] = useState({
    is_bookable: false,
    current_bookings: 0,
    error: "No data selected",
  });

  const [SuccessMessege, setSuccesMessege] = useState(null);
  const [ErrorMessege, setErrorMessege] = useState(null);
  const [checkHospitalBookings] = useCheckHospitalBookingMutation();
  const [HospitalBookingCheck] = useUpdateBookingMutation();

  const idData = useGetBookingListByIdQuery(id);
  
  useEffect(() => {
    if (hospitalHoliday.status === "fulfilled") {
      
      setDisableDates(hospitalHoliday.data.date);
    }
  }, [hospitalHoliday]);

  let hospitalList = [];
  if (hospitalData.status === "fulfilled") {
    hospitalData.data.ids.forEach((key) => {
      hospitalList.push({
        id: key,
        title: hospitalData.data.entities[key].name,
      });
    });
  }


  const HospitalID = useGetHospitalIdQuery(idData ?.data ?.hospital_date_id);



  const onDateSelect = (e) => {
    setSelectedDates(e.target.value);
    checkHospitalBookings({
      hospital_id: HospitalID ?.data ?.hospital ?.id,
      date: e.target.value,
      user: Cookies.get("access"),
      is_update: true,
    })
      .then((res) => {
        setBookingResponse(res.data);
        setSuccesMessege("Date is Valid.");
        setTimeout(() => {
          setSuccesMessege(null);
        }, [2000]);
      })
      .catch((err) => {
        setBookingResponse(err.response.data);
        setErrorMessege("Some Error occur while Showing a Data.");
        setTimeout(() => {
          setErrorMessege(null);
        }, [2000]);
      });
  };

  const confirmDate = () => {
    
    
    if (bookingResponse.data.is_bookable) {
      const formData = new FormData();
      const data = {
        hospital_date_id: bookingResponse.data.hospital_date_id,
        mr_id: idData?.data?.mr_id?.id,
        id: id,
      };
      formData.append('hospital_date_id', bookingResponse.data.hospital_date_id);
      formData.append('mr_id', idData?.data?.mr_id?.id);
      formData.append('id', id);
      HospitalBookingCheck(formData)
        .then((res) => {
          setSuccesMessege("Data is Showing.");
          setTimeout(() => {
            setSuccesMessege(null);
          }, [2000]);
        })
        .catch((error) => {
          setErrorMessege("Some Error occur while Showing a Data.");
          setTimeout(() => {
            setErrorMessege(null);
          }, [2000]);
        });
    }
  };

  return (
    <>
      <Box className="table-design-background">
        <Typography className="dashboard-home-page-text">
          Update My Booking
        </Typography>
        <Box style={{ margin: "1.5rem", paddingBottom: "2rem" }}>
          <Form>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <Controls.DatePickers
                  buttonText="Select Booking Date"
                  name="date"
                  onChange={onDateSelect}
                  excludeDates={disableDates}
                  selectedDate={selectedDates}
                />
              </Grid>
              <Grid xs={12} item>
                <Grid xs={2} item>
                  <Controls.Button
                    onClick={confirmDate}
                    text={"Update Bookings"}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Form>
          {SuccessMessege === null ? null : (
            <Box className="successmessege">
              <Typography className="messegefonts">
                <FontAwesomeIcon icon={faCheckCircle} className="messegeicon" />
                {SuccessMessege}
              </Typography>
            </Box>
          )}
          {ErrorMessege === null ? null : (
            <Box className="errormessege">
              <Typography className="messegefonts">
                <FontAwesomeIcon
                  icon={faExclamationTriangle}
                  className="messegeicon"
                />
                {ErrorMessege}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};

export default UpdatemyBooking;
