import {
    createSelector,
    createEntityAdapter
} from "@reduxjs/toolkit";

import { apiSlice } from "./apiSlice";

const chairpersonsAdapter = createEntityAdapter()

const initialState = chairpersonsAdapter.getInitialState()

export const chairpersonSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getChairpersons: builder.query({
            query: () => '/basic/chairperson/',
            transformResponse: responseData => {
                
                return chairpersonsAdapter.setAll(initialState, responseData)
            },
            providesTags: (result, error, arg) => 
            [
                { type: 'Chairpersons', id: "LIST" },
                ...result.ids.map(id => ({ type: 'Chairpersons', id }))
            ]
        }),
        getChairpersonsActive: builder.query({
            query: () => '/basic/chairperson/?is_active=True',
            transformResponse: responseData => {
                
                return chairpersonsAdapter.setAll(initialState, responseData)
            },
            providesTags: (result, error, arg) => 
            [
                { type: 'Chairpersons', id: "LIST" },
                ...result.ids.map(id => ({ type: 'Chairpersons', id }))
            ]
        }),
        getChairpersonsById: builder.query({
            query: (id)=>({
                url:`/basic/chairperson/${id}/`,
                method: 'GET',
            }),
            providesTags: ['Chairpersons']
        }),
        addChairperson: builder.mutation({
            query: (chairperson) => {
            
            return{
                url: '/basic/chairperson/',
                method: 'POST',
                body: chairperson
            }
        },
            invalidatesTags: ['Chairpersons']
        }),
        updateChairperson: builder.mutation({
           
            query: (updateChairperson) => {
                return{
                url: `/basic/chairperson/${updateChairperson.get('id')}/`,
                method: 'PATCH',
                body: updateChairperson
                }
            },
            invalidatesTags: ['Chairpersons']
        }),
        deleteChairperson: builder.mutation({
            query: ({ id }) => {
                return{
                url: `/basic/chairperson/${id}/`,
                method: 'DELETE',
                body: id
            }},
            invalidatesTags: ['Chairpersons']
        }),        
    })
})

export const {
    useGetChairpersonsQuery,
    useAddChairpersonMutation,
    useUpdateChairpersonMutation,
    useDeleteChairpersonMutation,
    useGetChairpersonsByIdQuery,
    useGetChairpersonsActiveQuery,
} = chairpersonSlice

// returns the query result object
export const selectChairpersonsResult = chairpersonSlice.endpoints.getChairpersons.select()

// Creates memoized selector
const selectChairpersonssData = createSelector(
    selectChairpersonsResult,
    chairpersonsResult => chairpersonsResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllChairpersons,
    selectById: selectChairpersonById,
    selectIds: selectIds
    // Pass in a selector that returns the posts slice of state
} = chairpersonsAdapter.getSelectors(state => selectChairpersonssData(state) ?? initialState)
