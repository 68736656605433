import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Sidebar from "../../component/sidebar";
import { Grid, Pagination, Stack, Typography } from "@mui/material";
import Header from "../../component/header";
import BasicTable from "../tables/BasicTable";
import {
  useGetHospitalsQuery,
  useUpdateHospitalMutation,
  useDeleteHospitalMutation,
  useAddHospitalMutation,
  useGetHospitalPaginationQuery,
} from "../../../../../api/hospitalSlice";
import HospitalAddButton from "./hospitalAddButton";
import { Box } from "@mui/system";
import usePagination from "../../../../../components/reuseable/pagination";

const DashboardHospitals = () => {
  return (
    <Grid container className="dashboard-full-background">
      <Grid item xs={2} spacing={4}>
        <Sidebar />
      </Grid>
      <Grid item xs={10}>
        <Header />
        <DashboardHospitalsPages />
      </Grid>
    </Grid>
  );
};

const DashboardHospitalsPages = () => {
  const [pageId, setPageId] = useState(1);
  const { data, isLoading, isSuccess } = useGetHospitalPaginationQuery(pageId);

  const [deleteHospital] = useDeleteHospitalMutation();

  const deleteConfirm = (e, id) => {
    e.preventDefault();

    deleteHospital({ id });
  };

  let headers = [];
  let tableData = [];
  let content;

  var count;
  if (isLoading) {
    content = <p>"Loading..."</p>;
  } else if (isSuccess) {
    headers = ["Name", "Address", "Active", "Allotment"];
    data.results.forEach((key) => {
      tableData.push({
        Name: key.name,
        id: key.id,
        Address: key.address,
        Active: key.is_active,
        Allotment: key.fixed_allotment,
      });
    });
    var newData = data.count / 10;
    count = Math.ceil(newData);
  }

  const [page, setPage] = useState(1);
  const PER_PAGE = 10;

  const _DATA = usePagination(tableData, count, PER_PAGE);

  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
    setPageId(p);
  };
  return (
    <>
      <Box className="table-design-background">
        <Grid container>
          <Grid item xs={6}>
            <Typography className="dashboard-home-page-text">
              Hospital Details
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Link
              to="/dashboard/new/hospitals"
              style={{ textDecoration: "none" }}
            >
              <HospitalAddButton />
            </Link>
          </Grid>
        </Grid>
        <Box style={{ padding: "1rem" }}>
          <BasicTable
            headers={headers}
            data={tableData}
            isDelete={true}
            deleteColor={"#f50057"}
            isEdit={true}
            editColor={"#3f51b5"}
            addColor={""}
            tableName={"hospitals"}
            deleteConfirm={deleteConfirm}
            activeSign={["Active"]}
          />
        </Box>
        <Box
          style={{
            marginTop: "20px",
            textAlign: "center",
            paddingBottom: "1rem",
            paddingLeft: "1rem",
          }}
        >
          <Stack spacing={2} style={{ textAlign: "center" }}>
            <Pagination
              count={count}
              size="small"
              page={page}
              variant="outlined"
              onChange={handleChange}
            />
          </Stack>
        </Box>
      </Box>
    </>
  );
};
export default DashboardHospitals;
