import { Grid } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import CardDesignOne from "../component/cardDesign";
import Header from "../component/header";
import Sidebar from "../component/sidebar";
import Image1 from "../../../../static/image/target.png";
import Image2 from "../../../../static/image/eyetap.png";
import Image3 from "../../../../static/image/goal.png";
import Image4 from "../../../../static/image/mayor.png";
import Image5 from "../../../../static/image/information.png";
import Image7 from "../../../../static/image/businessman.png";
import Image8 from "../../../../static/image/mayor_2.png";
import Image9 from "../../../../static/image/teamwork.png"

const AllAboutPage = () => {
  return (
    <Grid container className="dashboard-full-background">
      <Grid item xs={2} spacing={4}>
        <Sidebar />
      </Grid>
      <Grid item xs={10}>
        <Header />
        <DashboardAboutPages />
      </Grid>
    </Grid>
  );
};
const DashboardAboutPages = () => {
  return (
    <Box style={{ padding: "1rem" }}>
      <Grid container spacing={4}>
        <Grid item xs={3}>
          <CardDesignOne
            cardImage={Image5}
            cardName="About Page"
            cardLink="dashboard/about-pages"
          />
        </Grid>
        <Grid item xs={3}>
          <CardDesignOne
            cardImage={Image1}
            cardName="Mission"
            cardLink="dashboard/mission"
          />
        </Grid>
        <Grid item xs={3}>
          <CardDesignOne
            cardImage={Image2}
            cardName="Vision"
            cardLink="dashboard/vision"
          />
        </Grid>
        <Grid item xs={3}>
          <CardDesignOne
            cardImage={Image3}
            cardName="Objectives"
            cardLink="dashboard/work"
          />
        </Grid>
        <Grid item xs={3}>
          <CardDesignOne
            cardImage={Image4}
            cardName="Message President"
            cardLink="dashboard/chairperson"
          />
        </Grid>
        <Grid item xs={3}>
          <CardDesignOne
            cardImage={Image7}
            cardName="Janakpur Executive Body"
            cardLink="dashboard/executive-bodies"
          />
        </Grid>
        <Grid item xs={3}>
          <CardDesignOne
            cardImage={Image8}
            cardName="Centeral President"
            cardLink="dashboard/central-presidents"
          />
        </Grid>
        <Grid item xs={3}>
          <CardDesignOne
            cardImage={Image9}
            cardName="Janakpur Office Staff"
            cardLink="dashboard/office-staffs"
          />
        </Grid>
        <Grid item xs={3}>
          <CardDesignOne
            cardImage={Image8}
            cardName="Janakpur Past President"
            cardLink="dashboard/kathmandu-presidents"
          />
        </Grid>
      </Grid>
    </Box>
  );
};
export default AllAboutPage;
