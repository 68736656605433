import { createSelector, createEntityAdapter } from "@reduxjs/toolkit";

import { apiSlice } from "./apiSlice";

const newsAdapter = createEntityAdapter();

const initialState = newsAdapter.getInitialState();

const NewsSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getNews: builder.query({
      query: () => "/blog/educational-blog/",
      transformResponse: (responseData) => {
        let returnResponse = [];
        responseData.map((i) => {
          let newData = new Date(i.created_at);

          returnResponse.push({ ...i, created_at: newData.toDateString().split(" ") });
        });

        return newsAdapter.setAll(initialState, returnResponse);
      },
      providesTags: (result, error, arg) => [
        { type: "News", id: "LIST" },
        ...result.ids.map((id) => ({ type: "News", id })),
      ],
    }),
    getAllNews: builder.query({
      query: (id) => ({
        url: `/blog/educational-blog/`,
        method: "GET",
      }),
      providesTags: ["News"],
    }),
    getNewsPagination: builder.query({
      query: (id) => ({
        url: `/blog/educational-blog-pagination/?page=${id}`,
        method: "GET",
      }),
      providesTags: ["News"],
    }),
    addNews: builder.mutation({
      query: (news) => {
        return {
          url: "/blog/educational-blog/",
          method: "POST",
          body: news,
        };
      },
      invalidatesTags: ["News"],
    }),
    getNewsById: builder.query({
      query: (id) => ({
        url: `/blog/educational-blog/${id}`,
        method: "GET",
      }),
      providesTags: ["News"],
    }),
    updateNewsPage: builder.mutation({
      query: (updateAboutPage) => {
        return {
          url: `/blog/educational-blog/${updateAboutPage.get("id")}/`,
          method: "PATCH",
          body: updateAboutPage,
        };
      },
      invalidatesTags: ["News"],
    }),
    deleteNewsPage: builder.mutation({
      query: ({ id }) => {
        return {
          url: `/blog/educational-blog/${id}/`,
          method: "DELETE",
          body: id,
        };
      },
      invalidatesTags: ["News"],
    }),
  }),
});

export const {
  useGetNewsQuery,
  useAddNewsMutation,
  useGetNewsByIdQuery,
  useGetNewsPaginationQuery,
  useUpdateNewsPageMutation,
  useDeleteNewsPageMutation,
  useGetAllNewsQuery,
} = NewsSlice;
