import { Box, Grid, Typography } from "@material-ui/core";
import React, { useState } from "react";
import ReusableForms from "../../forms/controls/ReusableForms";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import {
  useGetMemberMembershipPeriodsQuery,
  useGetMemberMembershipPeriodsByIdQuery,
  useAddMemberMembershipPeriodMutation,
  useUpdateMemberMembershipPeriodMutation,
  useDeleteMemberMembershipPeriodMutation,
} from "../../../../../../api/memberMembershipPeriodSlice";
import { useTransition } from "react-transition-state";

const EditMembers = ({ id, context, editApi }) => {
  const [showActivity, setShowActivity] = useState(false);
  const [state, toggle] = useTransition({ timeout: 750, preEnter: true });
  const [File, setFile] = useState(null);

  return (
    <Grid item xs={12}>
      <Box className="box-design-service">
        <Grid
          container
          className="box-design-service1"
          onClick={() => toggle()}
        >
          <Grid item xs={10}>
            <Typography className="form-label-design" style={{color:'white',fontWeight:"600",marginBottom:"1.5rem"}}>Add Members</Typography>
          </Grid>
        </Grid>
        <Box className={`box-form-design example ${state.status}`}>
          <ReusableForms
            originalId={id}
            context={context}
            editApi={editApi}
            getApi={useGetMemberMembershipPeriodsByIdQuery}
            fieldName="member_list"
            fieldList={["member_list"]}
            postAPI={useAddMemberMembershipPeriodMutation}
            deleteAPI={useDeleteMemberMembershipPeriodMutation}
            imageField={{
              image: {
                imageFieldValue: File,
                imageSetFieldValue: setFile,
              },
            }}
            mappedData={[
              {
                field_name: "name",
                field_form: "input",
                label: "Member Name",
                default: "",
                debounceTime: 2000,
              },
              {
                field_name: "role",
                field_form: "input",
                label: "Member Role",
                default: "",
                debounceTime: 2000,
              },
              {
                field_name: "nmsra_no",
                field_form: "input",
                label: "NMSRA MEMBERSHIP NO",
                default: "",
                debounceTime: 2000,
              },
              {
                field_name: "image",
                field_form: "image",
                label: "Member Image",
                aspect_ratio: 1 / 1,
                default: null,
              },
            ]}
          />
        </Box>
      </Box>
    </Grid>
  );
};

export default EditMembers;
