import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Sidebar from "../../component/sidebar";
import { Grid, Typography } from "@mui/material";
import Header from "../../component/header";
import BasicTable from "../tables/BasicTable";
import {
  useGetChairpersonsQuery,
  useUpdateChairpersonMutation,
  useDeleteChairpersonMutation,
  useAddChairpersonMutation,
} from "../../../../../api/chairpersonSlice";
import ChairpersonAddButton from "./chairpersonAddButton";
import { Box } from "@mui/system";

const DashboardChairpersons = () => {
  return (
    <Grid container className="dashboard-full-background">
      <Grid item xs={2} spacing={4}>
        <Sidebar />
      </Grid>
      <Grid item xs={10}>
        <Header />
        <DashboardChairpersonsPages />
      </Grid>
    </Grid>
  );
};

const DashboardChairpersonsPages = () => {
  const { data, isLoading, isSuccess, isError, error } =
    useGetChairpersonsQuery("getChairpersons");

  const [deleteChairperson] = useDeleteChairpersonMutation();

  const deleteConfirm = (e, id) => {
    e.preventDefault();
    
    deleteChairperson({ id });
  };

  let headers = [];
  let tableData = [];
  let content;
  if (isLoading) {
    content = <p>"Loading..."</p>;
  } else if (isSuccess) {
    headers = ["Name", "Image", "Active"];
    tableData = [];
    data.ids.forEach((key) => {
      tableData.push({
        "Name": data.entities[key].name,
        "Image": data.entities[key].image,
        "Active": data.entities[key].is_active,
        id: data.entities[key].id,
      });
    });
  }
  return (
    <>
      <Box className="table-design-background">
        <Grid container>
          <Grid item xs={6}>
            <Typography className="dashboard-home-page-text">
              Chairperson List
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Link to="/dashboard/new/chairpersons" style={{textDecoration:'none'}}>
              <ChairpersonAddButton />
            </Link>
          </Grid>
        </Grid>
        <Box style={{ padding: "1.5rem" }}>
          <BasicTable
            headers={headers}
            data={tableData}
            isDelete={true}
            deleteColor={"#f50057"}
            isEdit={true}
            editColor={"#3f51b5"}
            addColor={""}
            tableName={"chairpersons"}
            deleteConfirm={deleteConfirm}
            activeSign={['Active']}
          />
        </Box>
      </Box>
    </>
  );
};
export default DashboardChairpersons;
