import { createSelector, createEntityAdapter } from "@reduxjs/toolkit";
import { BASE_URL } from "../constant/baseURL";

import { apiSlice } from "./apiSlice";

const lfNoAdapter = createEntityAdapter();

const initialState = lfNoAdapter.getInitialState();

const URL = "/bookings/lf-no/";

const SearchSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    searchFliterPage: builder.mutation({
      query: (NMSRANO) => {
        return {
          url: "/bookings/lf-no/search_lf_no/",
          method: "POST",
          body: NMSRANO,
        };
      },
      invalidatesTags: ["searchFliterPage"],
    }),
    searchMemberNumber: builder.mutation({
      query: (Member) => {
        return {
          url: "/bookings/mr-registration-pagination/search_member/",
          method: "POST",
          body: Member,
        };
      },
      invalidatesTags: ["searchFliterPage"],
    }),
  }),
});

export const { useSearchFliterPageMutation,useSearchMemberNumberMutation } = SearchSlice;
