import {
    createSelector,
    createEntityAdapter
} from "@reduxjs/toolkit";

import { apiSlice } from "./apiSlice";

const kathmanduPresidentsAdapter = createEntityAdapter()

const initialState = kathmanduPresidentsAdapter.getInitialState()

export const kathmanduPresidentSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getKathmanduPresidents: builder.query({
            query: () => '/basic/kathmandu-past-president/',
            transformResponse: responseData => {
                return kathmanduPresidentsAdapter.setAll(initialState, responseData)
            },
            providesTags: (result, error, arg) => 
            [
                { type: 'KathmanduPresidents', id: "LIST" },
                ...result.ids.map(id => ({ type: 'KathmanduPresidents', id }))
            ]
        }),
        getActiveKathmanduPresidents: builder.query({
            query: () => '/basic/kathmandu-past-president/?is_active=True',
            transformResponse: responseData => {
                return kathmanduPresidentsAdapter.setAll(initialState, responseData)
            },
            providesTags: (result, error, arg) => 
            [
                { type: 'KathmanduPresidents', id: "LIST" },
                ...result.ids.map(id => ({ type: 'KathmanduPresidents', id }))
            ]
        }),
        
        getKathmanduPresidentsById: builder.query({
            query: (id)=>({
                url:`/basic/kathmandu-past-president/${id}/`,
                method: 'GET',
            }),
            providesTags: ['KathmanduPresidents']
        }),
        addKathmanduPresident: builder.mutation({
            query: (kathmanduPresident) => {
            return{
                url: '/basic/kathmandu-past-president/',
                method: 'POST',
                body: kathmanduPresident
            }
        },
            invalidatesTags: ['KathmanduPresidents']
        }),
        updateKathmanduPresident: builder.mutation({
           
            query: (updateKathmanduPresident) => {
                return{
                url: `/basic/kathmandu-past-president/${updateKathmanduPresident.get('id')}/`,
                method: 'PATCH',
                body: updateKathmanduPresident
                }
            },
            invalidatesTags: ['KathmanduPresidents']
        }),
        deleteKathmanduPresident: builder.mutation({
            query: ({ id }) => {
                return{
                url: `/basic/kathmandu-past-president/${id}/`,
                method: 'DELETE',
                body: id
            }},
            invalidatesTags: ['KathmanduPresidents']
        }),
    })
})

export const {
    useGetKathmanduPresidentsQuery,
    useAddKathmanduPresidentMutation,
    useUpdateKathmanduPresidentMutation,
    useDeleteKathmanduPresidentMutation,
    useGetKathmanduPresidentsByIdQuery,
    useGetActiveKathmanduPresidentsQuery,
} = kathmanduPresidentSlice

// returns the query result object
export const selectKathmanduPresidentsResult = kathmanduPresidentSlice.endpoints.getKathmanduPresidents.select()

// Creates memoized selector
const selectKathmanduPresidentssData = createSelector(
    selectKathmanduPresidentsResult,
    kathmanduPresidentsResult => kathmanduPresidentsResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllKathmanduPresidents,
    selectById: selectKathmanduPresidentById,
    selectIds: selectIds
    // Pass in a selector that returns the posts slice of state
} = kathmanduPresidentsAdapter.getSelectors(state => selectKathmanduPresidentssData(state) ?? initialState)