import React from "react";
import TextButton from "../../../../../components/button/buttonicon";

const BlogAddButton = () =>{

    return(
        <>
            <TextButton buttonText="Add News" />
        </>
    )
}

export default BlogAddButton;