import React from "react";
import SearchBar from "material-ui-search-bar";


const Search = (props) =>{
    

    const {onChange, name,label } = props;
    
return(
        <SearchBar
            style={{
                    height: "60px",
                    borderRadius: "5px",
                    }}
                    placeholder={label}
            onChange={onChange}
            name={name}        
            />
            
    )
}

export default Search;