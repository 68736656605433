import { Grid } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import CardDesignOne from "../component/cardDesign";
import Header from "../component/header";
import Sidebar from "../component/sidebar";
import Image1 from "../../../../static/image/newspaper.png";
import Image2 from "../../../../static/image/address.png";
import Image3 from "../../../../static/image/gallery.png";
import Image4 from "../../../../static/image/mayor.png";
import Image5 from "../../../../static/image/mobile.png";
import Image6 from "../../../../static/image/testimonial.png";
import Image7 from "../../../../static/image/home.png";
import Image8 from "../../../../static/image/testimonial.png";

const AllOtherData = () => {
  return (
    <Grid container className="dashboard-full-background">
      <Grid item xs={2} spacing={4}>
        <Sidebar />
      </Grid>
      <Grid item xs={10}>
        <Header />
        <DashboardAboutPages />
      </Grid>
    </Grid>
  );
};
const DashboardAboutPages = () => {
  return (
    <Box style={{ padding: "1rem" }}>
      <Grid container spacing={4}>
        {/* <Grid item xs={3}>
          <CardDesignOne
            cardImage={Image7}
            cardName="Home Page Image Slider"
            cardLink="dashboard/landing-pages"
          />
        </Grid>
        <Grid item xs={3}>
          <CardDesignOne
            cardImage={Image7}
            cardName="Home Page"
            cardLink="dashboard/home-pages"
          />
        </Grid>
        <Grid item xs={3}>
          <CardDesignOne
            cardImage={Image8}
            cardName="Testimonial"
            cardLink="dashboard/testimonial"
          />
        </Grid> */}
        <Grid item xs={3}>
          <CardDesignOne
            cardImage={Image1}
            cardName="News"
            cardLink="dashboard/news"
          />
        </Grid>
        <Grid item xs={3}>
          <CardDesignOne
            cardImage={Image2}
            cardName="Suggestion Box"
            cardLink="dashboard/contact"
          />
        </Grid>
        <Grid item xs={3}>
          <CardDesignOne
            cardImage={Image3}
            cardName="Gallery"
            cardLink="dashboard/image-gallerys"
          />
        </Grid>
        <Grid item xs={3}>
          <CardDesignOne
            cardImage={Image4}
            cardName="Events"
            cardLink="dashboard/events"
          />
        </Grid>
        <Grid item xs={3}>
          <CardDesignOne
            cardImage={Image5}
            cardName="Activities"
            cardLink="dashboard/activity"
          />
        </Grid>
        <Grid item xs={3}>
          <CardDesignOne
            cardImage={Image6}
            cardName="Partners"
            cardLink="dashboard/partners"
          />
        </Grid>
      </Grid>
    </Box>
  );
};
export default AllOtherData;
