import React, { useState, useMemo } from "react";
import {
  faCheckCircle,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Grid } from "@material-ui/core";
import { Button, Typography } from "@mui/material";
import { Box } from "@mui/system";
import Controls from "../../../components/forms/controls/Controls";
import countryList from "react-select-country-list";
import { Stepper } from "react-form-stepper";
import MultiStep from "react-multistep";
import Qr from "../../../static/image/qr_code.jpeg";

const prevStyle = {
  backgroundColor: "red",
  color: "white",
  borderRadius: "4px",
  padding: "10px 20px",
  marginRight: "10px",
};

const nextStyle = {
  backgroundColor: "green",
  color: "white",
  borderRadius: "4px",
  padding: "10px 20px",
};
const Step1 = ({ values, handleInputChange }) => {
  return (
    <Box style={{ marginBottom: "15px" }}>
      <Grid container spacing={3}>
        <Grid item md={4} xs={12}>
          <Box>
            <Controls.Input
              onChange={handleInputChange}
              values={values.first_name}
              name={"first_name"}
              label={"First Name"}
            />
          </Box>
        </Grid>
        <Grid item md={4} xs={12}>
          <Box>
            <Controls.Input
              onChange={handleInputChange}
              values={values.middle_name}
              name={"middle_name"}
              label={"Middle Name"}
            />
          </Box>
        </Grid>
        <Grid item md={4} xs={12}>
          <Box>
            <Controls.Input
              onChange={handleInputChange}
              values={values.last_name}
              name={"last_name"}
              label={"Last Name"}
            />
          </Box>
        </Grid>
      </Grid>
      <Box className="passwordbtn">
        <Controls.Input
          onChange={handleInputChange}
          values={values.email}
          name={"email"}
          label={"Email"}
        />
      </Box>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Box className="passwordbtn">
            <Controls.Input
              onChange={handleInputChange}
              values={values.contact_no}
              name={"contact_no"}
              label={"Contact No"}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box className="passwordbtn">
            <Controls.Input
              onChange={handleInputChange}
              values={values.emergency_contact}
              name={"emergency_contact"}
              label={"Family Contact"}
            />
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Box className="passwordbtn">
            <Controls.Password
              onChange={handleInputChange}
              values={values.password}
              name={"password"}
              label={"password"}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box className="passwordbtn">
            <Controls.Password
              onChange={handleInputChange}
              values={values.cpassword}
              name={"cpassword"}
              label={"confirm password"}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
const Step2 = ({ values, handleInputChange }) => {
  const countryLists = [];
  const options = useMemo(() => countryList().getData(), []);
  options.forEach((country) => {
    countryLists.push({ id: country.value, title: country.label });
  });
  return (
    <Box>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Box className="passwordbtn">
            <Controls.Input
              onChange={handleInputChange}
              values={values.permanent_street}
              name="permanent_street"
              label="Tole/Street"
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box className="passwordbtn">
            <Controls.Input
              onChange={handleInputChange}
              values={values.permanent_ward_no}
              name={"permanent_ward_no"}
              label={"Ward Number"}
            />
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Box className="passwordbtn mt-2">
            <Controls.Select
              onChange={handleInputChange}
              values={values.permanent_country}
              name={"permanent_country"}
              label={"Permanent Country"}
              options={[
                {id: 'Nepal', title: 'Nepal'},
                {id: 'India', title: 'India'}]}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box className="passwordbtn">
            <Controls.Input
              onChange={handleInputChange}
              values={values.permanent_district}
              name={"permanent_district"}
              label={"Permanent District"}
            />
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Box className="passwordbtn">
            <Controls.Input
              onChange={handleInputChange}
              values={values.permanent_local_level}
              name={"permanent_local_level"}
              label={"Permanent Local Level"}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
const Step3 = ({ values, handleInputChange,  isSamePermanent, setIsSamePermanent}) => {

  const sameAsPermanent = (event) =>{
    setIsSamePermanent(event.target.value);
}
  return (
    <Box>
      <Box className="mt-4">
      <Controls.Checkbox
          label={"Same as Permanent Address"}
          onChange={(event)=>sameAsPermanent(event)}/>
      </Box>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Box className="passwordbtn">
            {isSamePermanent?
            <></>:<Controls.Input
            onChange={handleInputChange}
            values={values.temporary_street}
            name={"temporary_street"}
            label={"Temporary Street"}
          />}
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box className="passwordbtn">
            {isSamePermanent?<></>:
            <Controls.Input
              onChange={handleInputChange}
              values={values.temporary_ward_no}
              name={"temporary_ward_no"}
              label={"Temporary Ward No"}
            />
            }
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Box className="passwordbtn">
            {isSamePermanent?<></>:
            <Controls.Input
              onChange={handleInputChange}
              values={values.temporary_local_level}
              name={"temporary_local_level"}
              label={"Temporary Local Level"}
            />
          }
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box className="passwordbtn">
            {isSamePermanent?<></>:
            <Controls.Input
              onChange={handleInputChange}
              values={values.temporary_district}
              name={"temporary_district"}
              label={"Temporary District"}
            />
        }
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

const Step4 = ({ values, handleInputChange }) => {
  return (
    <Box>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Box className="passwordbtn">
            <Controls.Select
              onChange={handleInputChange}
              values={values.blood_group}
              name={"blood_group"}
              label={"Blood Group"}
              options={[
                {id:'A+', title: 'A Positive'},
                {id:'A-', title: 'A Negative'},
                {id: 'B+', title: 'B Positive'},
                {id:'B-', title: 'B Negative'},
                {id:'O+', title: 'O Positive'},
                {id:'O-', title: 'O Negative'},
                {id: 'AB+', title: 'AB Positive'},
                {id:'AB-', title: 'AB Negative'}]}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box className="passwordbtn">
            <Controls.Input
              onChange={handleInputChange}
              values={values.name_of_company}
              name={"name_of_company"}
              label={"Name of Company"}
            />
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Box className="passwordbtn">
          <label className="emailtext">Date of Joining*</label>
            <Controls.DatePickerWithYear
              onChange={handleInputChange}
              value={values.date_of_joining}
              name={"date_of_joining"}
              label={"Date of Joining"}
              showMonthYearDropdown={true}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box className="passwordbtn">
            <Controls.Input
              onChange={handleInputChange}
              values={values.current_position}
              name={"current_position"}
              label={"Current Position"}
            />
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Box className="passwordbtn">
            <Controls.Input
              onChange={handleInputChange}
              values={values.division}
              name={"division"}
              label={"Division"}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box className="passwordbtn">
            <Controls.Input
              onChange={handleInputChange}
              values={values.territory}
              name={"territory"}
              label={"Territory"}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

const Step5 = ({
  values,
  handleInputChange,
  setImage,
  handleSubmit,
  acceptTerms,
}) => {
  
  return (
    <Box style={{ marginBottom: "15px" }}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Box className="passwordbtn">
            <label className="emailtext">Degree*</label>
            <Controls.Select
              onChange={handleInputChange}
              values={values.academic_degree}
              name={"academic_degree"}
              label={"Academic Degree"}
              options={[
                {
                  id: "bachelors",
                  title: "Bachelors",
                },
                {
                  id: "master and above",
                  title: "Masters and above",
                },
              ]}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box className="passwordbtn">
            <label className="emailtext">Faculty*</label>
            <Controls.Input
              onChange={handleInputChange}
              values={values.academic_faculty}
              name={"academic_faculty"}
              label={"Academic Faculty"}
            />
          </Box>
        </Grid>
      </Grid>
      <Box className="mt-4">
        <span style={{ fontWeight: "bolder" }}>Enclosed Documents</span>
      </Box>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Box className="passwordbtn">
            <label className="emailtext">Appointment Letter of Company*</label>
            <Controls.File
              name={"appointment_letter"}
              onChange={handleInputChange}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box className="passwordbtn">
            <label className="emailtext">Academic Transcript*</label>
            {/* <Button variant="contained" component="label" style={{marginTop:'16px'}}>
                    Upload File
                    <input type="file" hidden />
                  </Button> */}
            <Controls.File
              name={"academic_transcript"}
              onChange={handleInputChange}
            />
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Box className="passwordbtn">
            <label className="emailtext">PP Size Photographs*</label>
            <Controls.Image setFile={setImage} aspectRatio={1 / 1} />
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
          <Box className="passwordbtn">
            {/* <Controls.Select
              id="year"
              title="year"
              name={"year"}
              label={"Select Payment Year"}
              value={values.year}
              onChange={handleInputChange}
              options={[
                { id: "2022", title: "2022" },
                { id: "2023", title: "2023" },
                { id: "2024", title: "2024" },
                { id: "2025", title: "2025" },
                { id: "2026", title: "2026" },
                { id: "2027", title: "2027" },
                { id: "2028", title: "2028" },
                { id: "2029", title: "2029" },
                { id: "2030", title: "2030" },
                { id: "2031", title: "2031" },
                { id: "2032", title: "2032" },
                { id: "2033", title: "2033" },
              ]}
            /> */}
            <Controls.Checkbox
              name="is_accept"
              label="I accept all the Term and Condition"
              onChange={(e) => acceptTerms(e)}
              // value={values.is_active}
              // onChange={handleInputChange}
            />
          </Box>
        </Grid>
      </Grid>

      <button
        className="signinbutton"
        onClick={handleSubmit}
        style={{ border: "none" }}
      >
        Sign Up
      </button>
    </Box>
  );
};

// const Step6 = ({
//   handleInputChange,
//   values,
//   handleSubmit,
//   setImage,
// }) => {
//   return (
//     <Grid container spacing={3}>
//       <Grid item xs={12} md={6}>
//         <img src={Qr} alt="qr_code" style={{width:"100%"}} />
//       </Grid>
//       <Grid item xs={12} md={6}>
//         <label className="emailtext">Payment File*</label>
//         <Controls.File name={"payment_details"} onChange={handleInputChange} />
//       </Grid>
//     </Grid>
//   );
// };

const SignUpPageNew = ({
  handleInputChange,
  values,
  handleSubmit,
  setImage,
  acceptTerms,
  setIsSamePermanent,
  isSamePermanent 
}) => {
  const steps = [
    {
      name: "Step 1",
      component: (
        <Step1 handleInputChange={handleInputChange} values={values} />
      ),
    },
    {
      name: "Step 2",
      component: (
        <Step2 handleInputChange={handleInputChange} values={values} />
      ),
    },
    {
      name: "Step 3",
      component: (
        <Step3 handleInputChange={handleInputChange} values={values} setIsSamePermanent={setIsSamePermanent} isSamePermanent={isSamePermanent}/>
      ),
    },
    {
      name: "Step 4",
      component: (
        <Step4
          handleInputChange={handleInputChange}
          values={values}
          handleSubmit={handleSubmit}
        />
      ),
    },
    {
      name: "Step 5",
      component: (
        <Step5
          handleInputChange={handleInputChange}
          values={values}
          setImage={setImage}
          handleSubmit={handleSubmit}
          acceptTerms={acceptTerms}
        />
      ),
    },
    // {
    //   name: "Step 6",
    //   component: (
    //     <Step6
    //       handleInputChange={handleInputChange}
    //       values={values}
    //       setImage={setImage}
    //       handleSubmit={handleSubmit}
    //     />
    //   ),
    // },
  ];

  return (
    <Box className="newforms">
      <MultiStep
        activeStep={0}
        steps={steps}
        prevStyle={prevStyle}
        nextStyle={nextStyle}
        handleInputChange={handleInputChange}
        values={values}
        setImage={setImage}
      />
    </Box>
  );
};

export default SignUpPageNew;
