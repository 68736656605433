import React, { useState } from "react";
import Header from "../component/header";
import Footer from "../component/footer";
import {
  useGetGalleryQuery,
  useGetImageGallerysPaginationQuery,
} from "../../../api/galleryPageSlice";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { Box, Pagination, Stack } from "@mui/material";
import usePagination from "../../../components/reuseable/pagination";

const Gallery = () => {
  return (
    <>
      <Header />
      <GalleryPage />
      <Footer />
    </>
  );
};

const GalleryPage = () => {
  const [pageId, setPageId] = useState(1);
  const data = useGetImageGallerysPaginationQuery(pageId);

  const [page, setPage] = useState(1);
  const PER_PAGE = 10;

  var newData = data?.data?.count / 10;
  var count = Math.ceil(newData);

  const _DATA = usePagination(data, count, PER_PAGE);

  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
    setPageId(p);
  };

  return (
    <div>
      <section className="page-title bg-1">
        <div className="overlay"></div>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="block text-center">
                <span className="text-white">Gallery</span>
                <h1 className="text-capitalize mb-5 text-lg">Image Gallery</h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="mt-5 mb-3">
        <div className="container px-0">
          <div className="pp-gallery">
            <div className="card-columns">
              {data !== undefined ? (
                <>
                  {data.status === "fulfilled" ? (
                    <>
                      {data.data.results.length !== 0 ? (
                        <>
                          {data.data.results.map((key) => (
                            <div
                              className="card"
                              data-groups='["nature"]'
                              key={key}
                            >
                              <a href="#">
                                <figure class="pp-effect">
                                  <img
                                    class="img-fluid"
                                    src={key.image}
                                    alt="Nature"
                                  />
                                  <figcaption>
                                    <div class="h4">{key.image_title}</div>
                                    <p>{key.description}</p>
                                  </figcaption>
                                </figure>
                              </a>
                            </div>
                          ))}
                        </>
                      ) : (
                        <>
                          <div className="card" data-groups='["nature"]'>
                            <a href="#">
                              <Box>
                                <SkeletonTheme
                                  color="grey"
                                  highlightColor="#444"
                                >
                                  <p>
                                    <Skeleton
                                      height={202}
                                      width={"100%"}
                                      count={1}
                                    />
                                  </p>
                                </SkeletonTheme>
                              </Box>
                            </a>
                          </div>
                          <div className="card" data-groups='["nature"]'>
                            <a href="#">
                              <Box>
                                <SkeletonTheme
                                  color="grey"
                                  highlightColor="#444"
                                >
                                  <p>
                                    <Skeleton
                                      height={202}
                                      width={"100%"}
                                      count={1}
                                    />
                                  </p>
                                </SkeletonTheme>
                              </Box>
                            </a>
                          </div>
                          <div className="card" data-groups='["nature"]'>
                            <a href="#">
                              <Box>
                                <SkeletonTheme
                                  color="grey"
                                  highlightColor="#444"
                                >
                                  <p>
                                    <Skeleton
                                      height={202}
                                      width={"100%"}
                                      count={1}
                                    />
                                  </p>
                                </SkeletonTheme>
                              </Box>
                            </a>
                          </div>
                          <div className="card" data-groups='["nature"]'>
                            <a href="#">
                              <Box>
                                <SkeletonTheme
                                  color="grey"
                                  highlightColor="#444"
                                >
                                  <p>
                                    <Skeleton
                                      height={202}
                                      width={"100%"}
                                      count={1}
                                    />
                                  </p>
                                </SkeletonTheme>
                              </Box>
                            </a>
                          </div>
                          <div className="card" data-groups='["nature"]'>
                            <a href="#">
                              <Box>
                                <SkeletonTheme
                                  color="grey"
                                  highlightColor="#444"
                                >
                                  <p>
                                    <Skeleton
                                      height={202}
                                      width={"100%"}
                                      count={1}
                                    />
                                  </p>
                                </SkeletonTheme>
                              </Box>
                            </a>
                          </div>
                          <div className="card" data-groups='["nature"]'>
                            <a href="#">
                              <Box>
                                <SkeletonTheme
                                  color="grey"
                                  highlightColor="#444"
                                >
                                  <p>
                                    <Skeleton
                                      height={202}
                                      width={"100%"}
                                      count={1}
                                    />
                                  </p>
                                </SkeletonTheme>
                              </Box>
                            </a>
                          </div>
                          <div className="card" data-groups='["nature"]'>
                            <a href="#">
                              <Box>
                                <SkeletonTheme
                                  color="grey"
                                  highlightColor="#444"
                                >
                                  <p>
                                    <Skeleton
                                      height={202}
                                      width={"100%"}
                                      count={1}
                                    />
                                  </p>
                                </SkeletonTheme>
                              </Box>
                            </a>
                          </div>
                          <div className="card" data-groups='["nature"]'>
                            <a href="#">
                              <Box>
                                <SkeletonTheme
                                  color="grey"
                                  highlightColor="#444"
                                >
                                  <p>
                                    <Skeleton
                                      height={202}
                                      width={"100%"}
                                      count={1}
                                    />
                                  </p>
                                </SkeletonTheme>
                              </Box>
                            </a>
                          </div>
                          <div className="card" data-groups='["nature"]'>
                            <a href="#">
                              <Box>
                                <SkeletonTheme
                                  color="grey"
                                  highlightColor="#444"
                                >
                                  <p>
                                    <Skeleton
                                      height={202}
                                      width={"100%"}
                                      count={1}
                                    />
                                  </p>
                                </SkeletonTheme>
                              </Box>
                            </a>
                          </div>
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      <div className="card" data-groups='["nature"]'>
                        <a href="#">
                          <Box>
                            <SkeletonTheme color="grey" highlightColor="#444">
                              <p>
                                <Skeleton
                                  height={202}
                                  width={"100%"}
                                  count={1}
                                />
                              </p>
                            </SkeletonTheme>
                          </Box>
                        </a>
                      </div>
                      <div className="card" data-groups='["nature"]'>
                        <a href="#">
                          <Box>
                            <SkeletonTheme color="grey" highlightColor="#444">
                              <p>
                                <Skeleton
                                  height={202}
                                  width={"100%"}
                                  count={1}
                                />
                              </p>
                            </SkeletonTheme>
                          </Box>
                        </a>
                      </div>
                      <div className="card" data-groups='["nature"]'>
                        <a href="#">
                          <Box>
                            <SkeletonTheme color="grey" highlightColor="#444">
                              <p>
                                <Skeleton
                                  height={202}
                                  width={"100%"}
                                  count={1}
                                />
                              </p>
                            </SkeletonTheme>
                          </Box>
                        </a>
                      </div>
                      <div className="card" data-groups='["nature"]'>
                        <a href="#">
                          <Box>
                            <SkeletonTheme color="grey" highlightColor="#444">
                              <p>
                                <Skeleton
                                  height={202}
                                  width={"100%"}
                                  count={1}
                                />
                              </p>
                            </SkeletonTheme>
                          </Box>
                        </a>
                      </div>
                      <div className="card" data-groups='["nature"]'>
                        <a href="#">
                          <Box>
                            <SkeletonTheme color="grey" highlightColor="#444">
                              <p>
                                <Skeleton
                                  height={202}
                                  width={"100%"}
                                  count={1}
                                />
                              </p>
                            </SkeletonTheme>
                          </Box>
                        </a>
                      </div>
                      <div className="card" data-groups='["nature"]'>
                        <a href="#">
                          <Box>
                            <SkeletonTheme color="grey" highlightColor="#444">
                              <p>
                                <Skeleton
                                  height={202}
                                  width={"100%"}
                                  count={1}
                                />
                              </p>
                            </SkeletonTheme>
                          </Box>
                        </a>
                      </div>
                      <div className="card" data-groups='["nature"]'>
                        <a href="#">
                          <Box>
                            <SkeletonTheme color="grey" highlightColor="#444">
                              <p>
                                <Skeleton
                                  height={202}
                                  width={"100%"}
                                  count={1}
                                />
                              </p>
                            </SkeletonTheme>
                          </Box>
                        </a>
                      </div>
                      <div className="card" data-groups='["nature"]'>
                        <a href="#">
                          <Box>
                            <SkeletonTheme color="grey" highlightColor="#444">
                              <p>
                                <Skeleton
                                  height={202}
                                  width={"100%"}
                                  count={1}
                                />
                              </p>
                            </SkeletonTheme>
                          </Box>
                        </a>
                      </div>
                      <div className="card" data-groups='["nature"]'>
                        <a href="#">
                          <Box>
                            <SkeletonTheme color="grey" highlightColor="#444">
                              <p>
                                <Skeleton
                                  height={202}
                                  width={"100%"}
                                  count={1}
                                />
                              </p>
                            </SkeletonTheme>
                          </Box>
                        </a>
                      </div>
                    </>
                  )}
                </>
              ) : null}
            </div>
          </div>
          <Box style={{ marginTop: "1.5rem", marginBottom: "20px" }}>
            <Stack spacing={2}>
              <Pagination
                count={count}
                size="large"
                page={page}
                variant="outlined"
                onChange={handleChange}
              />
            </Stack>
          </Box>
        </div>
      </section>
    </div>
  );
};

export default Gallery;
