import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Sidebar from "../../component/sidebar";
import { Grid, Typography } from "@mui/material";
import Header from "../../component/header";
import BasicTable from "../tables/BasicTable";
import {
  useGetAboutPagesQuery,
  useUpdateAboutPageMutation,
  useDeleteAboutPageMutation,
  useAddAboutPageMutation,
} from "../../../../../api/aboutPageSlice";
import AboutPageAddButton from "./aboutPageAddButton";
import { Box } from "@mui/system";

const DashboardAboutPages = () => {
  return (
    <Grid container className="dashboard-full-background">
      <Grid item xs={2} spacing={4}>
        <Sidebar />
      </Grid>
      <Grid item xs={10}>
        <Header />
        <DashboardAboutPagesPages />
      </Grid>
    </Grid>
  );
};

const DashboardAboutPagesPages = () => {
  const { data, isLoading, isSuccess, isError, error } =
    useGetAboutPagesQuery("getAboutPages");

  const [deleteAboutPage] = useDeleteAboutPageMutation();

  const deleteConfirm = (e, id) => {
    e.preventDefault();
    deleteAboutPage({ id });
  };

  let headers = [];
  let tableData = [];
  let content;
  if (isLoading) {
    content = <p>"Loading..."</p>;
  } else if (isSuccess) {
    headers = ["Title", "Sub-Title", "Active"];
    tableData = [];
    data.ids.forEach((key) => {
      tableData.push({
        Title: data.entities[key].title,
        "Sub-Title": data.entities[key].subtitle,
        Active: data.entities[key].is_active,
        id: data.entities[key].id,
      });
    });
  }
  return (
    <>
      <Box className="table-design-background">
        <Grid container>
          <Grid item xs={6}>
            <Typography className="dashboard-home-page-text">
              About Lists
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Link
              to="/dashboard/new/about-pages"
              style={{ textDecoration: "none" }}
            >
              <AboutPageAddButton />
            </Link>
          </Grid>
        </Grid>
        <Box style={{ padding: "1.5rem" }}>
          <BasicTable
            headers={headers}
            data={tableData}
            isDelete={true}
            deleteColor={"#f50057"}
            isEdit={true}
            editColor={"#3f51b5"}
            addColor={""}
            tableName={"about-pages"}
            deleteConfirm={deleteConfirm}
            activeSign={["Active"]}
          />
        </Box>
      </Box>
    </>
  );
};
export default DashboardAboutPages;
