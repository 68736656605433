import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Sidebar from "../../component/sidebar";
import { Grid, Pagination, Stack, Typography } from "@mui/material";
import Header from "../../component/header";
import BasicTable from "../tables/BasicTable";
import {
  useDeleteImageGalleryMutation,
  useGetImageGallerysPaginationQuery,
} from "../../../../../api/galleryPageSlice";
import ImageGalleryAddButton from "./imageGalleryAddButton";
import { Box } from "@mui/system";
import usePagination from "../../component/pagination";

const DashboardImageGallerys = () => {
  return (
    <Grid container className="dashboard-full-background">
      <Grid item xs={2} spacing={4}>
        <Sidebar />
      </Grid>
      <Grid item xs={10}>
        <Header />
        <DashboardImageGallerysPages />
      </Grid>
    </Grid>
  );
};

const DashboardImageGallerysPages = () => {
  const [pageId, setPageId] = useState(1);
  const { data, isLoading, isSuccess, isError, error } =
    useGetImageGallerysPaginationQuery(pageId);

  const [deleteImageGallery] = useDeleteImageGalleryMutation();

  const deleteConfirm = (e, id) => {
    e.preventDefault();

    deleteImageGallery({ id });
  };

  let headers = [];
  let tableData = [];
  let content;

  var count;
  if (isLoading) {
    content = <p>"Loading..."</p>;
  } else if (isSuccess) {
    headers = ["Title", "Description", "Image", "Active"];
    data.results.forEach((key) => {
      tableData.push({
        "Title": key.image_title,
        id: key.id,
        Description: key.description,
        Image: key.image,
        Active: key.is_active,
      });
    });
    var newData = data.count / 10;
    count = Math.ceil(newData);
  }

  const [page, setPage] = useState(1);
  const PER_PAGE = 10;

  const _DATA = usePagination(tableData, count, PER_PAGE);

  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
    setPageId(p);
  };
  return (
    <>
      <Box className="table-design-background">
        <Grid container>
          <Grid item xs={6}>
            <Typography className="dashboard-home-page-text">
              Gallery Lists
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Link
              to="/dashboard/new/image-gallerys"
              style={{ textDecoration: "none" }}
            >
              <ImageGalleryAddButton />
            </Link>
          </Grid>
        </Grid>
        <Box style={{ padding: "1.5rem" }}>
          <BasicTable
            headers={headers}
            data={tableData}
            isDelete={true}
            deleteColor={"#f50057"}
            isEdit={true}
            editColor={"#3f51b5"}
            addColor={""}
            tableName={"image-gallerys"}
            deleteConfirm={deleteConfirm}
            activeSign={["Active"]}
          />
          <Box style={{ marginTop: "1.5rem" }}>
            <Stack spacing={2}>
              <Pagination
                count={count}
                size="small"
                page={page}
                variant="outlined"
                onChange={handleChange}
              />
            </Stack>
          </Box>
        </Box>
      </Box>
    </>
  );
};
export default DashboardImageGallerys;
